import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { GrgNotificationModel } from 'app/models/GrgNotificationModel';
import { UserService } from './user.service';
import { NGXLogger } from 'ngx-logger';
import { GRGSnackBarService } from 'app/common/grg-snack-bar/grg-snak-bar.service';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationBehavior = new BehaviorSubject<GrgNotificationModel[]>(null);
  notificationsChanged$ = this.notificationBehavior.asObservable();
  private currentNotifications: GrgNotificationModel[] = [];
  private timeSinceLastPoll: Date = new Date();
  private isFetchingNotifications = false;
  private refreshNotificationThreshold = 90; // number of seconds to pass before refetching notifications on a page change

  constructor(private apiService: ApiService,
    private userService: UserService,
    private logger: NGXLogger,
    private notificationService: GRGSnackBarService) {
    this.userService.clientContactBehaviorSub.subscribe(response => {
      if (response != null) {
        this.getNotifications();
      }
    });
  }

  getNotifications(): void {
    const guid = this.userService.clientContact.clientContactGuid;
    this.isFetchingNotifications = true;
    this.apiService.getNotifications(guid).subscribe(response => {
      this.logger.debug('Notifications fetched');
      this.timeSinceLastPoll = new Date();
      this.currentNotifications = response;
      this.notificationBehavior.next(this.currentNotifications);
    }, error => {
      // do we want to do anything other than logging if this errors?
      this.logger.error(error);
    },
      () => {
        this.isFetchingNotifications = false;
      });
  }

  dismissNotification(notificationId: number): void {
    const guid = this.userService.clientContact.clientContactGuid;
    this.apiService.dismissNotification(guid, notificationId).subscribe(response => {
      this.currentNotifications = this.currentNotifications.filter(el => {
        return el.id !== notificationId;
      });

      this.notificationBehavior.next(this.currentNotifications);
    }, error => {
      this.notificationService.show('There was an issue dismissing the notification', 'error');
      this.logger.error(error);
    });
  }

  refreshNotificationsIfEnoughTimeHasPassed() {
    const currentDate = new Date();

    const timeDiff = (this.timeSinceLastPoll.getTime() - currentDate.getTime()) / 1000;
    const secondsPassedSinceLastRefresh = Math.abs(Math.floor(timeDiff));

    if (secondsPassedSinceLastRefresh > this.refreshNotificationThreshold && !this.isFetchingNotifications) {
      this.logger.debug(`${secondsPassedSinceLastRefresh} seconds have passed since last notification fetch..preparing to fetch`);
      this.getNotifications();
    } else {
      this.logger.debug(`Not fetching notifications - only ${secondsPassedSinceLastRefresh} seconds have passed..must pass ${this.refreshNotificationThreshold} first and not already be fetching..${this.isFetchingNotifications}`);
    }
  }
}
