import { Directive, EventEmitter, Output, HostListener } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Directive({
  selector: '[appTableRowClick]'
})
export class TableRowClickDirective {

  constructor(private logger: NGXLogger) { }

  @Output() rowClick = new EventEmitter();

  @HostListener('click', ['$event'])
  clickEvent(event) {
    this.logger.debug('table row clicked', event);

    if (event.target && event.target.className && event.target.className.includes('ignore-row-click')) {
      this.logger.debug('row click ignored', event.target.className);
    } else {
      event.preventDefault();
      event.stopPropagation();
      this.rowClick.emit();
    }
  }

}
