import { Injectable } from '@angular/core';
import { UserJwtModel } from 'app/models/UserJwtModel';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ApiService } from './api.service';
import { ClientContactModel } from 'app/models/ClientContactModel';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { map, catchError, take } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { PermissionsService } from './permissions.service';
import { LookupService } from './lookup.service';
import Utils, { ROUTE_PORTAL_HOME } from 'app/common/Utils';
import { TagManagerService } from './tag-manager.service';


@Injectable()
export class UserService {
  userImportedData: UserJwtModel;
  clientContactBehaviorSub = new BehaviorSubject<any>(null);
  clientContact?: ClientContactModel;
  public isAuthenticated = false;
  urlBehaviorSub = new BehaviorSubject<any>(null);
  localStorageUser = 'USER';
  likedProperties: string[];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private logger: NGXLogger,
    private permissionsService: PermissionsService,
    private lookupService: LookupService,
    private tagManagerService: TagManagerService) { }

  public init(userImportedDataObject: UserJwtModel, authToken: string): void {
    if (!userImportedDataObject) {
      this.failedAction();
      return;
    }
    this.userImportedData = userImportedDataObject;
    this.apiService.getUserAccount(this.userImportedData.sub).subscribe(
      result => {
        // If result has response, client contact was not found.
        if (result.response != null) {
          // clear our the storage incase a different profile or data is being held onto
          localStorage.clear();

          this.fireTagManagerAppLoad('', '', false, '');

          // throw error so it can be handled accordingly and redirect to public site
          this.clientContactBehaviorSub.error('not found');

          // this.redirectToPublicSite('client contact not found');
          this.router.navigate(['/']);
        } else {
          this.logger.info('Client contact found! Profile: ', result);
          // if for some reason the SSN comes through without dashes, lets format those real quick
          // if (result.ssn && result.ssn.indexOf('-') === -1 && result.ssn.length === 9) {
          //   result.ssn = `${result.ssn.substr(0, 3)}-${result.ssn.substr(3, 2)}-${result.ssn.substr(5)}`;
          // }

          this.logger.setCustomHttpHeaders(new HttpHeaders({
            'Authorization': `Bearer ${authToken}`
          }));

          // Client contact object was found
          this.clientContact = result;
          this.isAuthenticated = true;
          this.permissionsService.init(result, this.lookupService, this);

          // wait for the first role changed call to fire our GTM result from this perspective
          // we take 2 because a behavior subject sends out the initial value it is instanced with which will be null
          this.clientContactBehaviorSub.subscribe(response => {
            if (response) {
              this.fireTagManagerAppLoad(this.permissionsService.role, Utils.getUserType(this.clientContact), this.isAuthenticated, this.clientContact.clientContactGuid);
              this.logger.log('role changed - preparing to fire gtm', (<any>window).dataLayer);
            }
          });
        }
      },
      notFound => {
        this.failedAction();
      }
    );
  }

  getUser(id: string) {
    // jwt.sub
    this.apiService.getUserAccount(id).subscribe((response) => {
      if (response) {
        this.setUserStorage(response);
      }
    });
  }

  getLikedProperties() {
    this.likedProperties = []
    return this.apiService.getClientContactMiscData(this.clientContact.clientContactGuid, "favoriteproperties").pipe(map((response: string) => {
      if (response !== undefined && response !== "" && response.length > 0) {
        this.likedProperties = response.split(",");
      }
      return this.likedProperties;
    }));
  }

  private failedAction() {
    // clear our the storage incase a different profile or data is being held onto
    localStorage.clear();

    this.fireTagManagerAppLoad('', '', false, '');

    // throw error so it can be handled accordingly
    this.clientContactBehaviorSub.error('error');

    // if we can't find the current users account, we need to have them log in and generate a new token with their credentials
    //this.redirectToPublicSite('error trying to get users account');
    if (this.router.url.includes('portal')) {
      this.router.navigate(['/']);
    }
  }

  private fireTagManagerAppLoad(userRole: string, userType: string, isLoggedIn: boolean, userGuid: string) {
    this.tagManagerService.onPageLoad(userGuid, userRole, userType, isLoggedIn, 'user import service, app loading');
  }

  public refreshPermissionsFromToken(userImportedDataObject: UserJwtModel, authToken: string): void {

    // grab a new user instance for our app incase anything changed
    this.logger.log('refreshing permissions in user service');
    this.refreshUser().subscribe(response => {
      this.userImportedData = userImportedDataObject;
      this.permissionsService.refreshPermissions(response, this.lookupService);

      // update our logger token
      this.logger.setCustomHttpHeaders(new HttpHeaders({
        'Authorization': `Bearer ${authToken}`
      }));

    }, error => {
      this.logger.error(this.redirectToPublicSite);
      //this.redirectToPublicSite('error attempting to refresh permissions from token');
      this.router.navigate(['/']);
    });

  }


  refreshUser() {
    return this.apiService.getUserAccount(this.userImportedData.sub)
      .pipe(map(res => {
        this.clientContact = res;
        return res;
      }),
        catchError(error => {
          return throwError(error);
        }));
  }

  // had to move the redirect to the user service
  redirectToPublicSite(reason?: string, captureCurrentLocation = true) {

    if (reason && (location.href.includes('192.168') || location.href.includes('localhost'))) {
      // alert(`reason: ${reason}, capture current location? ${captureCurrentLocation}`);
    }

    this.logger.debug('preparing for redirect via user service...');
    this.urlBehaviorSub.next('redirect');
    const this$ = this;
    const timeoutLength = this.isAuthenticated ? 1 : 1000;
    const encodedUri = captureCurrentLocation
      ? encodeURIComponent(location.href)
      : location.protocol + '%2F%2F' + location.host + ROUTE_PORTAL_HOME;

    this.logger.debug('current route - ', encodedUri);

    this.router.navigate(['/']);
    // setTimeout(function () {
    //   window.open(loginUrl + '?redirtoken=' + encodedUri, '_self');
    // }, timeoutLength);
  }

  setTokenStorage(token: string) {
    localStorage.setItem("AUTH_TOKEN", token);
  }
  getTokenStorage(): string {
    return localStorage.getItem("AUTH_TOKEN");
  }

  setRefreshTokenStorage(token: string) {
    localStorage.setItem("REFRESH_TOKEN", token);
  }
  getRefreshTokenStorage(): string {
    return localStorage.getItem("REFRESH_TOKEN");
  }

  setUserStorage(user: ClientContactModel) {
    this.clientContact = user;
    this.isAuthenticated = true;
    this.clientContactBehaviorSub.next(user);
    localStorage.setItem("USER", btoa(JSON.stringify(user)));
  }

  getUserStorage(): ClientContactModel {
    let json = localStorage.getItem("USER");
    if (json) {
      return JSON.parse(atob(json));
    }
    return null;
  }

  getAddressBook(guid): any {
    return this.apiService.getAddressBook(guid);
  }

  updateContactStorage(contact: ClientContactModel) {
    localStorage.setItem("USER", btoa(JSON.stringify(contact)));
  }

}
