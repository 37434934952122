import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-wp-iframe',
  templateUrl: './wp-iframe.component.html',
  styleUrls: ['./wp-iframe.component.scss']
})
export class WpIframeComponent implements OnInit,OnChanges {
  @Input() wordPressUrl: string;
  wpUrl: SafeResourceUrl;
  wpDev: "";
  wpProd: "";
  dataPassed: string;

  constructor(private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.router.events.subscribe(val => {
       if (val instanceof NavigationStart) {
         if(val.url.includes("search") === undefined){
          val.url = val.url;
        }
        else if(val.url.includes("search")){
          val.url = this.wordPressUrl;
        }
        this.wpUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://wp-web.gorovergo.com" + val.url)
      }
    })
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.wordPressUrl !== undefined){
      this.wpUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://wp-web.gorovergo.com" +this.wordPressUrl);
    }else{
      this.wpUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://wp-web.gorovergo.com" + this.router.url)
    }
    
  }

  ngOnInit() {
    if(this.wordPressUrl !== undefined){
      this.wpUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://wp-web.gorovergo.com" +this.wordPressUrl);
    }else{
      this.wpUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://wp-web.gorovergo.com" + this.router.url)
    }
    // let hostname = window.location.hostname
  }

  onLoadFunc(frame: any) {
    // if (window.location.hostname !== "localhost") {
    //   console.log(frame.contentWindow.location.href);
    // }
    // console.log(frame.contentWindow.location.href); //Cross-Origin
  }
}


