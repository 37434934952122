import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'maintenance-modal',
    templateUrl: 'maintenance-modal.component.html'
})

export class MaintenanceModalComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}