import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class FileDragWatcherService {

  private dragTimer;

  constructor(private logger: NGXLogger) {

  }

  initAppWatcher() {
    var this$ = this;
    // listen for drag & drop to add styling to drop areas across portal
    let dropzone = document.getElementById('app-height-wrap');
    dropzone.addEventListener('dragover', function () {
      this$.dragenterDragleave(this$);
    }, false);
  }

  initModalWatcher(className?: string) {

    const this$ = this;
    setTimeout(function () {
      const target = className && className.length ? className : 'modal';

      const element = document.getElementsByClassName(target)[0];

      if (!element) {
        this$.logger.warn(`Element could not be found with selector: ${target}`);
        return;
      }

      element.addEventListener('dragover', function () {
        this$.dragenterDragleave(this$);
      }, false);

      this$.logger.info('successfully registered event', element);
    }, 50);
  }

  private dragenterDragleave(instance: any) {
    const this$ = instance;

    clearTimeout(this$.dragTimer);

    // page doesn't have the drag highlight class yet
    if (!document.body.classList.contains('drag-highlight')) {
      document.body.classList.add('drag-highlight');
    }

    this$.dragTimer = setTimeout(function () {
      document.body.classList.remove('drag-highlight');
    }, 250);
  }
}
