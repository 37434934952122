import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  constructor() {

  }

  getCurrentScreenHeight(): number {
    return window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight;
  }

  getCurrentScreenWidth(): number {
    return window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
  }

  // scroll the user to the top of the page
  returnScreenToTop(): void {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 1);
  }

  returnBookingWizardToTop(): void {
    // const el = document.getElementById('booking-content');
    // if (el) {
    //   setTimeout(function () {
    //     el.scrollTop = 0;
    //   }, 1)
    // }
    this.returnScreenToTop();
  }

  // given an DOM element reference, is that in the current viewport?
  isElementInViewport = function (elem) {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  isMobileViewport(): boolean {
    return (window.innerWidth || document.documentElement.clientWidth) <= 767;
  }

  isMobileTabletAndUnder(): boolean {
    return this.getCurrentScreenWidth() <= 991;
  }

  isSidebarOpenedAtThisViewport(): boolean {
    return (window.innerWidth || document.documentElement.clientWidth) >= 991;
  }
}
