import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PropertyDetailModel } from 'app/models/PropertyDetailModels';
import { APISearchResult } from 'app/models/SearchResultModel';
import { ApiService } from 'app/services/api.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'property-favorite-button',
  templateUrl: './property-favorite-button.component.html',
  styleUrls: ['./property-favorite-button.component.scss']
})
export class PropertyFavoriteButtonComponent implements OnInit {
  @Input() item: PropertyDetailModel;
  @Input() idsuffix;
  @Input() mode;
  @ViewChild('modalContent', { read: TemplateRef, static: false }) modalContent: TemplateRef<any>;
  initLoaded: boolean;
  redirectRoute: string;
  get isFavorite(): boolean {
    if (this.userService.likedProperties !== undefined && this.userService.likedProperties.length > 0) {
      for (let i = 0; i < this.userService.likedProperties.length; i++) {
        if (this.userService.likedProperties[i] == this.item.guid) {
          return true;
        }
      }
    }
    return false;
  }

  constructor(private apiService: ApiService, private userService: UserService, private modalService: NgbModal, private route: Router) { }

  ngOnInit() {
  }

  toggleFavorite(guid, event) {
    event.preventDefault();

    console.log(guid);
    console.log(this.item);
    if (this.userService.isAuthenticated) {
      const index = this.userService.likedProperties.indexOf(guid);
      if (index > -1) {
        this.userService.likedProperties.splice(index, 1);
      } else {
        this.userService.likedProperties.push(guid);
      }

      let ids = this.userService.likedProperties.join(",")
      this.apiService.updateClientContactMiscSetting(this.userService.clientContact.clientContactGuid, "favoriteproperties", ids).subscribe((response) => {
        // console.log(response)
        if (response) {
          // this.userService.likedProperties = response.split(",");
        }
      });
    } else {
      this.redirectRoute = this.route.url;
      this.modalService.open(this.modalContent, { centered: true, size: 'sm' });
    }
  }
}
