import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'terms-of-service-modal',
    templateUrl: 'terms-of-service-modal.component.html'
})

export class TermsOfServiceModalComponent implements OnInit {
    @Input() scrollToPayments = false;
    wpUrl: any;
    paymentTermsAnchor = "#paymentTerms";
    constructor(private sanitizer: DomSanitizer) { }

    public html: any;

    ngOnInit() {
        let url = "https://wp-web.gorovergo.com/terms-and-conditions";
        if (this.scrollToPayments) {
            url += this.paymentTermsAnchor;
        }
        this.wpUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}