import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { AppLayoutComponent } from './app-layout/app-layout-component/app-layout.component';
import { AppLayoutModule } from './app-layout/app-layout.module';
import { CompanyRoleGuard } from './auth/company-role.guard';
import { CompanyRoles } from './models/CompanyRoles';
import { TokenGuard } from './auth/token.guard';
import { LogoutComponent } from './logout/logout.component';
import { LayoutComponent } from './pre-booking-wizard/layout/layout.component';
import { SearchResultsComponent } from './pre-booking-wizard/search-results/search-results.component';
import { WpIframeComponent } from './pre-booking-wizard/wp-iframe/wp-iframe.component';
import { PropertyDetailComponent } from './pre-booking-wizard/property-detail/property-detail.component';
import { ActivateAccountComponent } from './account/activate-account/activate-account.component';
import { OpenGuard } from './auth/open.guard';
import { FavoritePropertiesComponent } from './pre-booking-wizard/favorite-properties/favorite-properties.component';
import { AdditionalOccupantBackgroundCheckComponent } from './landing-pages/additional-occupant-background-check/additional-occupant-background-check.component';

const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: WpIframeComponent },
      { path: 'search', component: SearchResultsComponent },
      { path: 'search/:city', component: SearchResultsComponent },
      { path: 'search/:city/:state', component: SearchResultsComponent },
      { path: 'search/:city/:state/:country', component: SearchResultsComponent },
      { path: 'properties', component: PropertyDetailComponent },
      { path: 'properties/:name/:id', component: PropertyDetailComponent },
      { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
      { path: 'activate-account', component: ActivateAccountComponent },
      { path: 'favorites', component: FavoritePropertiesComponent, canActivate: [TokenGuard] },
      { path: 'booking', canActivate: [TokenGuard], loadChildren: () => import('./book/book.module').then(m => m.BookModule) },
      { matcher: wpPaths, component: WpIframeComponent }
    ]
  },
  {
    path: 'booking',
    component: AppLayoutComponent,
    canActivate: [TokenGuard],
    children: [{
      path: '',
      loadChildren: () => import('./booking-wizard/booking-wizard.module').then(m => m.BookingWizardModule)
    }]
  },
  {
    path: 'occupant',
    component: LayoutComponent,
    children: [
      {
        path: 'background',
        component: AdditionalOccupantBackgroundCheckComponent
      }
    ]
  },
  {
    path: 'portal',
    component: AppLayoutComponent,
    canActivate: [TokenGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./portal/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'bookings',
        canActivate: [CompanyRoleGuard],
        data: {
          roles: [CompanyRoles.Admin,
          CompanyRoles.LeaseApprover,
          CompanyRoles.LeaseSigner,
          CompanyRoles.LeaseApproverAndSigner,
          CompanyRoles.User],
          isNormalUserAllowed: true
        },
        loadChildren: () => import('./portal/bookings/bookings.module').then(m => m.BookingsModule)
      },
      {
        path: 'approvals',
        canActivate: [CompanyRoleGuard],
        data: {
          roles: [CompanyRoles.Admin,
          CompanyRoles.LeaseApprover,
          CompanyRoles.LeaseSigner,
          CompanyRoles.LeaseApproverAndSigner]
        },
        loadChildren: () => import('./portal/approvals/approvals.module').then(m => m.ApprovalsModule)
      },
      {
        path: 'support',
        loadChildren: () => import('./portal/tickets/tickets.module').then(m => m.TicketsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./portal/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'settings/account',
        loadChildren: () => import('./portal/account-profile/account-profile.module').then(m => m.AccountProfileModule)
      },
      {
        path: 'settings/company',
        canActivate: [CompanyRoleGuard],
        data: {
          roles: [CompanyRoles.Admin,
          CompanyRoles.LeaseApprover,
          CompanyRoles.LeaseSigner,
          CompanyRoles.LeaseApproverAndSigner],
          isNormalUserAllowed: false
        },
        loadChildren: () => import('./portal/company-profile/company-profile.module').then(m => m.CompanyProfileModule)
      },
      {
        path: 'reservations',
        loadChildren: () => import('./portal/reservations/reservations.module').then(m => m.ReservationsModule)
      },
      {
        path: 'inquiries',
        canActivate: [CompanyRoleGuard],
        data: {
          roles: [CompanyRoles.Admin,
          CompanyRoles.LeaseApprover,
          CompanyRoles.LeaseSigner,
          CompanyRoles.LeaseApproverAndSigner,
          CompanyRoles.User],
          isNormalUserAllowed: true
        },
        loadChildren: () => import('./portal/inquiries-quotes/inquiries-quotes.module').then(m => m.InquiriesQuotesModule)
      },
      {
        path: 'extensions',
        canActivate: [CompanyRoleGuard],
        data: {
          roles: [CompanyRoles.Admin,
          CompanyRoles.LeaseApprover,
          CompanyRoles.LeaseSigner,
          CompanyRoles.LeaseApproverAndSigner,
          CompanyRoles.User],
          isNormalUserAllowed: true
        },
        loadChildren: () => import('./portal/extensions/extensions.module').then(m => m.ExtensionsModule)
      },
      // {
      //   path: 'add/:guid',
      //   loadChildren: () => import('./landing-pages/background-check/background-check.module').then(m => m.BackgroundCheckModule)
      // },
      // {
      //   path: 'identity/:guid',
      //   loadChildren: () => import('./landing-pages/background-check/background-check.module').then(m => m.BackgroundCheckModule)
      // },
      {
        path: 'booking',
        loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule)
      },
      { path: '', redirectTo: 'portal/home', pathMatch: 'full' },
      { path: '**', redirectTo: 'portal/home', pathMatch: 'full' }
    ]
  },
  { path: '**', redirectTo: '404', pathMatch: 'full' }
];

export function wpPaths(url: UrlSegment[]) {
  if (url.length == 1) {
    const path = url[0].path;
    if (path === 'our-apartments'
      || path === 'workforce-mobility-solutions'
      || path === 'property-managers'
      || path === 'about-us'
      || path === 'contact-us'
      // || path === 'locations'
      || path === 'terms-and-conditions'
      || path === 'privacy-policy'
      || path === 'schedule-a-demo'
      || path === 'cookie-policy'
      || path === 'covid-19-faq'
      || path === 'thanks'
      || path === '404'
      || path === 'faq'
      || path === 'ccpa-request-page'
    ) {
      return { consumed: url };
    }
  }
  return null;
}

@NgModule({
  imports: [
    AppLayoutModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
      // enableTracing: true
    })
  ],
  exports: [RouterModule],
  providers: [TokenGuard, CompanyRoleGuard, OpenGuard]
})
export class AppRoutingModule { }
