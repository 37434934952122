declare var $: any;
declare var google: any;

export default class Extensions {
    static minimumStartBookDays = 8;
    static maximumStartBookMonths = 12;
    static minimumEndBookDays = 30; //min end days from start date
    static maximumEndBookMonths = 15; //max end months from start date, ending on last day of that month
    static defaultSearchEndDays = 6 * 30; //6 months if no end date given

    static showEndDatePicker() {
        $('#check-in').datepicker('show');
    }
    static bindGoogleAutoComplete($input, componentObject?) {
        var $currentInput = $($input);
        var $parentHolder = $currentInput.closest(".text-location-holder");

        var options = {
            // types: ['(cities)'],
            types: ['geocode'],
            componentRestrictions: { country: ["us", "ca"] }
        };
        var autocomplete = new google.maps.places.Autocomplete($currentInput[0], options);

        $currentInput.keyup(function () {
            $(this).removeAttr("data-lat");
            $(this).removeAttr("data-lon");
        });
        $currentInput.blur(function () {
            if ($parentHolder.length > 0) {
                if ($currentInput.val() === "") { $parentHolder.removeClass("selected"); }
                else {
                    $parentHolder.addClass("selected");
                }
            }
        });

        google.maps.event.addListener(autocomplete, 'place_changed', function () {
            var place = autocomplete.getPlace();
            if (place !== null && place.geometry) {
                $currentInput.attr("data-lat", place.geometry.location.lat());
                $currentInput.attr("data-lon", place.geometry.location.lng());

                if ($parentHolder.length > 0) { $parentHolder.addClass("selected"); }

                if (componentObject !== undefined && componentObject.autoCompleteCallback !== undefined) {
                    componentObject.autoCompleteCallback(place.formatted_address);
                }
            }
            else {
                $currentInput.removeAttr("data-lat");
                $currentInput.removeAttr("data-lon");
                if ($parentHolder.length > 0) { $parentHolder.removeClass("selected"); }
            }
        });
    }
    static scrollToElementTop(ele, g_headerHeight?) {
        g_headerHeight = typeof g_headerHeight !== 'undefined' ? g_headerHeight : null;
        var screenHeight = $(window).height();
        var distanceFromTop = ($(ele).offset().top - $(window).scrollTop());
        var scrollAmount = distanceFromTop;
        var headerHeight = 0;
        var offset = 10;

        if (g_headerHeight !== null) {
            headerHeight = g_headerHeight;
        }
        else {
            headerHeight = $("header").height();
        }

        scrollAmount -= headerHeight;
        scrollAmount -= offset;

        // now that we know how much of the element is visible
        // we can scroll down the appropriate amount to make the 
        // entire element visible to the user

        // If the height of the element is greater than the height of the 
        // viewport, then just scroll the distance from top,
        // otherwise scroll the scrollAmount to bring the entire element into view
        var scroll = "+=" + (scrollAmount + 0) + 'px';

        $("html,body").animate({ scrollTop: scroll }, 200);
    }
    static scrollToElementBottom(ele) { /*unused*/
        var screenHeight = $(window).height();
        var distanceFromTop = ($(ele).offset().top - $(window).scrollTop());
        var scrollAmount = distanceFromTop;
        var headerHeight = $("header").height();
        var offset = 10;
        //scrollAmount -= headerHeight;
        scrollAmount -= offset;

        // now that we know how much of the element is visible
        // we can scroll down the appropriate amount to make the 
        // entire element visible to the user

        // If the height of the element is greater than the height of the 
        // viewport, then just scroll the distance from top,
        // otherwise scroll the scrollAmount to bring the entire element into view
        var scroll = "+=" + (scrollAmount - $(ele).outerHeight()) + 'px';

        $("html,body").animate({ scrollTop: scroll }, 500);
    }
    static fixedOnScroll($g_markerDom, $g_elementDom, g_offset, g_offset_bottom, $g_beforeElement, g_minMaxScreenWidth, $g_domWidthToMatch, $g_endMarkerDom?, g_relativeToParent?) {
        $g_endMarkerDom = typeof $g_endMarkerDom !== 'undefined' ? $g_endMarkerDom : null;
        g_relativeToParent = typeof g_relativeToParent !== 'undefined' ? g_relativeToParent : false;
        $g_beforeElement = typeof $g_beforeElement !== 'undefined' ? $g_beforeElement : null;

        let $markerDom = $g_markerDom;
        let $elementDom = $g_elementDom;
        let offset = g_offset;
        let offset_bottom = g_offset_bottom;
        let minMaxScreenWidth = g_minMaxScreenWidth; //negative values are max, positive values are min
        let $domWidthToMatch = $g_domWidthToMatch;
        let $endMarkerDom = $g_endMarkerDom;
        let relativeToParent = g_relativeToParent;

        let headerHeight = $("header").height();
        let currentWidth = $(window).width();
        if (minMaxScreenWidth === 0 ||
            (minMaxScreenWidth > 0 && currentWidth >= minMaxScreenWidth) ||
            (minMaxScreenWidth < 0 && currentWidth <= minMaxScreenWidth * -1)) {

            if ($domWidthToMatch !== null) {
                $elementDom.css("width", $domWidthToMatch.width());
            }
        }

        $(window).resize(function () {
            currentWidth = $(window).width();
            headerHeight = $("header").height();
            //if (self.currentWidth >= self.minScreenWidth) {
            if (minMaxScreenWidth === 0 ||
                (minMaxScreenWidth > 0 && currentWidth >= minMaxScreenWidth) ||
                (minMaxScreenWidth < 0 && currentWidth <= minMaxScreenWidth * -1)) {
                if ($domWidthToMatch !== null) {
                    $elementDom.css("width", $domWidthToMatch.width());
                }
            }
            else {
                $elementDom.css("width", "");
            }
            //reset px values, if fixed, set px, if not, set px
            if ($elementDom.hasClass("fixed")) {
                var offsetCalcFixed = (headerHeight + offset);
                $elementDom.css("top", (offsetCalcFixed) + "px");
            }
            else {
                //abs
                var offsetCalcNoFixed = offset;
                $elementDom.css("top", (offsetCalcNoFixed) + "px");
            }
            setPosition();
        });

        $(window).scroll(function () {
            setPosition();
        });

        //methods
        function setPosition() {
            var amtScrolled = $(window).scrollTop();
            //var posInlineNav = amtScrolled + $inlineNav.offset().top;
            //if (self.currentWidth >= self.minScreenWidth) {
            if (minMaxScreenWidth === 0 ||
                (minMaxScreenWidth > 0 && currentWidth >= minMaxScreenWidth) ||
                (minMaxScreenWidth < 0 && currentWidth <= minMaxScreenWidth * -1)) {
                if (amtScrolled + offset > $markerDom.offset().top - headerHeight) {
                    var offsetCalc = offset;

                    // $endMarkerDom = null;
                    if ($endMarkerDom !== null) {

                        if ((!relativeToParent && (amtScrolled + headerHeight + $elementDom.outerHeight() + offset_bottom > $endMarkerDom.offset().top)) ||
                            (relativeToParent && (amtScrolled + headerHeight + $elementDom.outerHeight() + offset + offset_bottom > $endMarkerDom.offset().top))) {
                            //end reached
                            if (!$elementDom.hasClass("absolute")) {
                                $elementDom.removeClass("fixed").addClass("absolute");
                            }
                            if (!relativeToParent) {
                                // offsetCalc = $endMarkerDom.offset().top - (headerHeight + $elementDom.outerHeight() + offset_bottom + $g_beforeElement.height());
                                offsetCalc = $endMarkerDom.offset().top - headerHeight - $elementDom.outerHeight() - offset_bottom;
                            }
                            else {
                                offsetCalc = $endMarkerDom.offset().top - $elementDom.outerHeight() - offset_bottom - $elementDom.parent().offset().top;
                            }
                            $elementDom.css("top", (offsetCalc) + "px");
                        }
                        else {
                            //normal
                            if (!$elementDom.hasClass("fixed")) {
                                offsetCalc = (headerHeight + offset);
                                $elementDom.removeClass("absolute").addClass("fixed");
                                $elementDom.css("top", (offsetCalc) + "px");
                            }
                        }

                    }
                    else {
                        //standard
                        if (!$elementDom.hasClass("fixed")) {
                            offsetCalc = (headerHeight + offset);
                            $elementDom.removeClass("absolute").addClass("fixed");
                            $elementDom.css("top", (offsetCalc) + "px");
                        }
                    }


                }
                else {
                    $elementDom.removeClass("fixed").removeClass("absolute");
                    $elementDom.css("top", "0px");
                }
            }
            else {
                //straight up disabled
                $elementDom.removeClass("fixed").removeClass("absolute");
                $elementDom.css("top", "");
            }
        };
        //init hit
        setPosition();
    }
    static padNumber(number) {
        var ret = new String(number);
        if (ret.length === 1) ret = "0" + ret;
        return ret;
    }
    static getDateFromString(date_string): Date {
        var myDate = null;
        if (date_string !== null && date_string !== "" && date_string.indexOf("/") > -1) {
            //split
            var parts = date_string.split('/');
            myDate = new Date(parts[2] * 1, parts[0] - 1, parts[1]);
        }
        return myDate;
    }
    static getDateStringFromDate(date_obj, format = '/'): string {
        var returnString = "";
        if (date_obj !== null) {
            var year = date_obj.getFullYear();
            // months and days are inserted into the array in the form, e.g "01/01/2009", but here the format is "1/1/2009"
            var month = this.padNumber(date_obj.getMonth() + 1);
            var day = this.padNumber(date_obj.getDate());
            returnString = month + format + day + format + year;
        }
        return returnString;
    }
    static getMinimumStartDate(returnAsString?) {
        returnAsString = returnAsString || false;
        //get min start date
        var minStartDate = new Date();
        minStartDate.setHours(0, 0, 0, 0);
        minStartDate.setDate(minStartDate.getDate() + this.minimumStartBookDays);

        if (returnAsString) {
            return this.getDateStringFromDate(minStartDate);
        }
        return minStartDate;
    }
    static getMaximumStartDate(returnAsString?) {
        returnAsString = returnAsString || false;
        //get max start date
        var maxStartDate = new Date();
        maxStartDate.setHours(0, 0, 0, 0);
        maxStartDate.setMonth(maxStartDate.getMonth() + Extensions.maximumStartBookMonths + 1, 0);
        if (returnAsString) {
            return Extensions.getDateStringFromDate(maxStartDate);
        }
        return maxStartDate;
    }
    static getMinimumEndDate(startDate, returnAsString?) {
        returnAsString = returnAsString || false;
        //get min end date
        var minEndDate = new Date(startDate);
        minEndDate.setHours(0, 0, 0, 0);
        minEndDate.setDate(minEndDate.getDate() + this.minimumEndBookDays);
        if (returnAsString) {
            return Extensions.getDateStringFromDate(minEndDate);
        }
        return minEndDate;
    }
    static getMaximumEndDate(startDate, returnAsString?) {
        returnAsString = returnAsString || false;
        //get max start date
        var newDate = new Date(startDate);
        newDate.setHours(0, 0, 0, 0);
        newDate.setMonth(newDate.getMonth() + Extensions.maximumEndBookMonths + 1, 0);
        var maxEndDate = new Date(newDate);
        if (returnAsString) {
            return Extensions.getDateStringFromDate(maxEndDate);
        }
        return maxEndDate;
    }
    static getDefaultSearchStartDate(returnAsString?) {
        returnAsString = returnAsString || false;
        //get min start date
        var minStartDate = new Date();
        minStartDate.setHours(0, 0, 0, 0);
        minStartDate.setDate(minStartDate.getDate() + Extensions.minimumStartBookDays + 1);

        if (returnAsString) {
            return Extensions.getDateStringFromDate(minStartDate);
        }
        return minStartDate;
    }
    static getDefaultEndDate(startDateString, returnAsString) {
        returnAsString = returnAsString || false;
        //get max start date
        var defaultEndDate = Extensions.getDateFromString(startDateString);
        defaultEndDate.setDate(defaultEndDate.getDate() + Extensions.defaultSearchEndDays);
        if (returnAsString) {
            return Extensions.getDateStringFromDate(defaultEndDate);
        }
        return defaultEndDate;
    }
    //determine to set start or end on select
    static setDateRangeDates(componentObject, selfRangeObject, date, bookingDateValidation?): any {
        bookingDateValidation = bookingDateValidation || false;
        var retvalArray = [];
        var chosenStartDateVal = selfRangeObject.getStartValue();
        var chosenEndDateVal = selfRangeObject.getEndValue();
        var maxStartDate = Extensions.getMaximumStartDate() as Date;
        //only override default start / end under specific circumstances
        if (date !== null) {
            if (chosenStartDateVal !== "") {
                var chosenStartDate = Extensions.getDateFromString(chosenStartDateVal);
                var minDate = Extensions.getMinimumEndDate(chosenStartDate) as Date;
                var maxDate = Extensions.getMaximumEndDate(chosenStartDate) as Date;
                if (chosenEndDateVal !== "") {
                    if (selfRangeObject.getUpdateMode() === "endDate") {
                        if (date.getTime() > chosenStartDate.getTime() && date.getTime() < minDate.getTime()) {
                            retvalArray = [Extensions.getDateStringFromDate(date), ""];
                        }
                    }
                }

                if (chosenEndDateVal !== "") {
                    var chosenEndDate = Extensions.getDateFromString(chosenEndDateVal);
                    if (selfRangeObject.getUpdateMode() === "startDate") {
                        if (date.getTime() > maxStartDate.getTime()) {
                            //start date is invalid, too late, update end date instead if possible
                            if (date.getTime() <= maxDate.getTime()) {
                                retvalArray = [chosenStartDateVal, Extensions.getDateStringFromDate(date)];
                            }
                        }
                        else {
                            if (date.getTime() < chosenEndDate.getTime()) {
                                if (date.getTime() > chosenStartDate.getTime() && date.getTime() < minDate.getTime()) {
                                    retvalArray = [Extensions.getDateStringFromDate(date), ""];
                                }
                                else if (date.getTime() < chosenStartDate.getTime()) {
                                    retvalArray = [Extensions.getDateStringFromDate(date), ""];
                                }
                                else {
                                    retvalArray = [chosenStartDateVal, Extensions.getDateStringFromDate(date)];
                                }
                            } else {
                                retvalArray = [chosenStartDateVal, Extensions.getDateStringFromDate(date)];
                            }
                            //so far, it is a valid start date click.
                            if (bookingDateValidation) {
                                var string = $.datepicker.formatDate('mm-dd-yy', date);
                                var dayOfWeek = date.getUTCDay();
                                if (componentObject.propertyDetail.calcExcludedDates !== undefined) {
                                    var validDay = componentObject.properyDetail.calcExcludedDates.indexOf(string) === -1;
                                    if (validDay) {
                                        if (componentObject.properyDetail.calcDaysClosed !== undefined) {
                                            validDay = componentObject.properyDetail.calcDaysClosed.indexOf(dayOfWeek) === -1;
                                        }
                                    }
                                    if (!validDay) {
                                        //NOT VALID START, but cant just set end
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return retvalArray;
    }
    static enforceDisabledDates(selfRangeObject, date: Date, returnSimpleBool?, allowStartForInvalidEnd?) {
        returnSimpleBool = returnSimpleBool || false;
        allowStartForInvalidEnd = allowStartForInvalidEnd || false;
        var validDay = true;
        var chosenStartDateVal = selfRangeObject.getStartValue();
        var chosenEndDateVal = selfRangeObject.getEndValue();
        var maxStartDate = Extensions.getMaximumStartDate() as Date;
        if (date !== null) {
            if (chosenStartDateVal !== null && chosenStartDateVal !== "") {
                var chosenStartDate = new Date(chosenStartDateVal);
                var minDate = Extensions.getMinimumEndDate(chosenStartDate) as Date;
                var maxDate = Extensions.getMaximumEndDate(chosenStartDate) as Date;
                //user has chosen, enforce min end and max end rules per grg's ruleset.
                if (chosenEndDateVal === null || chosenEndDateVal === "") {
                    //has start, no end
                    if (date.getTime() !== chosenStartDate.getTime() && (date.getTime() < minDate.getTime() || date.getTime() > maxDate.getTime())) {
                        validDay = false;
                    }
                    if (date.getTime() === chosenStartDate.getTime()) {
                        validDay = false;
                    }
                } else {
                    //both selected, only restrict min date, as next selection will change end date if not before start date
                    if (date.getTime() > maxDate.getTime()) {
                        //restrict max enddates
                        validDay = false;
                    }
                    //now restrict min end date
                    if (!allowStartForInvalidEnd) {
                        if (date.getTime() > chosenStartDate.getTime() && date.getTime() < minDate.getTime()) {
                            validDay = false;
                        }
                    } else {
                        //allow click of invalid end date that is too soon, will make start date instead
                        if (date.getTime() > chosenStartDate.getTime() && date.getTime() < minDate.getTime()) {
                            //validDay = false;
                        }
                    }


                    //both selected? still restrict start date selection afterwards
                    /*var chosenEndDate = window.GRGExtensions.getDateFromString(chosenEndDateVal);
                    if (date.getTime() > maxStartDate.getTime() && date.getTime() > chosenEndDate.getTime()) {
                        validDay = false;
                    }
                    //what about those dates between selections?
                    //make dates between start and end disabled
                    if (date.getTime() > maxStartDate.getTime() && date.getTime() <= chosenEndDate.getTime()) {
                        validDay = false;
                    }*/

                    //do restrict end date mode from selecting a date before start
                    if (selfRangeObject.getUpdateMode() === "endDate" && date.getTime() < chosenStartDate.getTime()) {
                        validDay = false;
                    }
                }
            }
            else {
                //get max start date
                if (date.getTime() > maxStartDate.getTime()) {
                    validDay = false;
                }
            }
        }

        if (!returnSimpleBool) {
            return [validDay];
        }
        else {
            return validDay;
        }
    }
    static validateDatesTextEntry(selfRangeObject, startDate, endDate) {
        var validDay = true;
        var chosenStartDateVal = selfRangeObject.getStartValue();
        var chosenEndDateVal = selfRangeObject.getEndValue();
        var minStartDate = Extensions.getMinimumStartDate() as Date;
        var maxStartDate = Extensions.getMaximumStartDate() as Date;
        if (startDate !== null) {
            //start date validation
            if (startDate.getTime() < minStartDate.getTime() || startDate.getTime() > maxStartDate.getTime()) {
                validDay = false;
            }
        }
        else if (endDate !== null) {
            //end date validation
            if (chosenStartDateVal !== "") {
                var chosenStartDate = Extensions.getDateFromString(chosenStartDateVal);
                var minEndDate = Extensions.getMinimumEndDate(chosenStartDate) as Date;
                var maxEndDate = Extensions.getMaximumEndDate(chosenStartDate) as Date;

                if (endDate.getTime() <= chosenStartDate.getTime() || endDate.getTime() > maxEndDate.getTime() || endDate.getTime() < minEndDate.getTime()) {
                    validDay = false;
                }

            }
        }
        return validDay;
    }
    static setDateTimePickerYearAriaLabel() {
        setTimeout(function () {
            $("select.ui-datepicker-year").attr("aria-label", "Select Year");
        }, 10);
    }
    //Header Search DatePickers
    static bindDatePickers(componentObject) {
        var mobileHeaderDateFieldsController;
        var desktopHeaderDateFieldsRange;
        $(".form-header-search, .form-banner-search, .form-banner-search-mobile, .form-mobile-header-search, .form-inline-search, .form-inline-search-mobile")
            .each(function () {
                var $startField = $(this).find(".date-start");
                var $endField = $(this).find(".date-end");
                var $locationField = $(this).find(".text-location");
                var $locationSpan = $locationField.closest(".text-location-span");
                var $submitBtn = $(this).find("input[type='submit'], button[type='submit']");
                var isMobileHeaderSearch = $(this).hasClass("form-mobile-header-search");
                var isMobileBannerSearch = $(this).hasClass("form-banner-search-mobile");
                var isMobileInlineSearch = $(this).hasClass("form-inline-search-mobile");
                var isSearchPage = $("body").hasClass("searchPage");
                var $currentForm = $(this);
                var myMinStartDate = Extensions.getMinimumStartDate();

                $startField.focus(function () {
                    $startField.parent().find("label.error.invalid-text-date").fadeOut().delay(400).remove();
                });
                $endField.focus(function () {
                    $endField.parent().find("label.error.invalid-text-date").fadeOut().delay(400).remove();
                });

                if (!isMobileHeaderSearch && !isMobileBannerSearch && !isMobileInlineSearch) {

                    //desktop date range pickers
                    var $desktopDateRangeObject = new $.daterange(null, {
                        changeYear: true,
                        startDateDom: $startField,
                        endDateDom: $endField,
                        minDate: myMinStartDate,
                        numberOfMonths: 2,
                        closeSelectedDelay: 400,
                        minDateCalculation: function () {
                            return Extensions.getMinimumStartDate(true);
                        },
                        maxDateCalculation: function () {
                            return Extensions.getMaximumStartDate(true);
                        },
                        clearDatesOnInvalidMidnight: true,
                        customSetOnSelect: function (date) {
                            return Extensions.setDateRangeDates(componentObject, this, date);
                        },
                        beforeShowDay: function (date) {
                            Extensions.setDateTimePickerYearAriaLabel();
                            return Extensions.enforceDisabledDates(this, date, false, true);
                        },
                        onTextEntryValidator: function (startDate, endDate) {
                            $startField.parent().find("label.error.invalid-text-date").remove();
                            $endField.parent().find("label.error.invalid-text-date").remove();
                            return Extensions.validateDatesTextEntry(this, startDate, endDate);
                        },
                        onTextEntryStartFieldError: function () {
                            $startField.after("<label class=\"error invalid-text-date\">" + "Invalid date." + "</label>");
                        },
                        onTextEntryEndFieldError: function () {
                            $endField.after("<label class=\"error invalid-text-date\">" + "Invalid date." + "</label>");
                        },
                        onSelect: function () {
                            if (componentObject !== undefined && componentObject.dateRangeCallback !== undefined) {
                                componentObject.dateRangeCallback(this.startDateDom.val(), this.endDateDom.val());
                            }
                            // var startVal = $startDateDom.val();
                            // var endVal = $endDom.val();

                            // if (startVal !== "" && endVal !== "") {
                            //     //close it down
                            //     setTimeout(function () {
                            //         self._closeAll();
                            //     }, 300);
                            // }
                        }
                    });

                    //desktop header daterange picker
                    if ($(this).closest("header").length > 0) {
                        desktopHeaderDateFieldsRange = $desktopDateRangeObject;
                    }

                    //on focus of submit, hide datepicker popup
                    $submitBtn.focus(function () {
                        $desktopDateRangeObject.hideDatepicker();
                    });

                    $locationField.blur(function () {

                        if ($locationField.val() === "") {
                            $locationSpan.removeClass("selected");
                        }
                        else {
                            $locationSpan.addClass("selected");
                        }
                    });

                }
                else if (isMobileHeaderSearch) {   //mobile header
                    mobileHeaderDateFieldsController = {
                        $mobileInlineDate: null,
                        $mobileTextLocation: null,
                        $mobileTextLocationSpan: null,
                        $mobileSearchDropdown: null,
                        $startDom: $startField,
                        $endDom: $endField,
                        $dateRangeObject: null,
                        $closeBtn: null,
                        $clearBtn: null,
                        $clearAllBtn: null,
                        _closeAll: function () {
                            var self = this;
                            self.$mobileSearchDropdown.removeClass("start-date-open").removeClass("end-date-open");
                            self.$mobileSearchDropdown.css("height", "");
                        },
                        _clearDates: function () {
                            var self = this;
                            self.$startDom.val("");
                            self.$endDom.val("");
                            self.$dateRangeObject.clearDates();
                            self.$mobileSearchDropdown.find("label.error").hide();
                            self.$mobileSearchDropdown.find(".date-start-span, .date-end-span").removeClass("selected");
                        },
                        _openDatepicker: function () {
                            var self = this;
                            self.$mobileSearchDropdown.removeClass("end-date-open").addClass("start-date-open");
                            self._calculateSetPopupHeight();
                            //scroll
                            self.$mobileSearchDropdown.animate({ scrollTop: self.$mobileInlineDate.position().top }, 500);
                        },
                        _calculateSetPopupHeight: function () {
                            var self = this;
                            var calcHeight = 0;
                            if (self.$mobileSearchDropdown.hasClass("start-date-open")) {
                                self.$mobileSearchDropdown.find(".inner-content > form").each(function () {
                                    calcHeight += $(this).outerHeight();
                                });
                                self.$mobileSearchDropdown.css("height", (calcHeight + 44) + "px");
                            }

                        },
                        _toggleDatepicker: function () {
                            var self = this;
                            if (!self.$mobileSearchDropdown.hasClass("start-date-open")) {
                                self.$mobileSearchDropdown.removeClass("end-date-open").addClass("start-date-open");
                            }
                            else {
                                self.$mobileSearchDropdown.removeClass("start-date-open");
                            }
                        },
                        init: function () {
                            var self = this;
                            //add inline datepickers
                            self.$mobileInlineDate = $("#mobile-date-both-datepicker-inline");
                            // self.$mobileSearchDropdown = $(".nav-primary > nav > .mobile-search-dropdown");
                            self.$mobileSearchDropdown = $(".mobile-search-dropdown");
                            self.$startDom = $(".form-mobile-header-search .date-start");
                            self.$endDom = $(".form-mobile-header-search .date-end");
                            self.$closeBtn = self.$mobileSearchDropdown.find(".close-datepicker");
                            self.$clearBtn = self.$mobileSearchDropdown.find(".clear-datepicker");
                            self.$mobileTextLocation = self.$mobileSearchDropdown.find(".text-location");
                            self.$mobileTextLocationSpan = self.$mobileSearchDropdown.find(".text-location-span");
                            self.$clearAllBtn = self.$mobileSearchDropdown.find(".clear-all");

                            //custom double date range picker
                            self.$dateRangeObject = new $.daterange(self.$mobileInlineDate, {
                                changeYear: true,
                                startDateDom: self.$startDom,
                                endDateDom: self.$endDom,
                                minDate: myMinStartDate,
                                minDateCalculation: function () {
                                    return Extensions.getMinimumStartDate(true);
                                },
                                maxDateCalculation: function () {
                                    return Extensions.getMaximumStartDate(true);
                                },
                                clearDatesOnInvalidMidnight: true,
                                customSetOnSelect: function (date) {
                                    return Extensions.setDateRangeDates(componentObject, this, date);
                                },
                                beforeShowDay: function (date) {
                                    Extensions.setDateTimePickerYearAriaLabel();
                                    return Extensions.enforceDisabledDates(this, date, false, true);
                                },
                                onChangeMonthYear: function (e) {
                                    setTimeout(function () {
                                        //calculate popup window height
                                        self._calculateSetPopupHeight();
                                        //self.$mobileSearchDropdown.animate({ scrollTop: self.$mobileInlineDate.position().top }, 500);
                                    });
                                },
                                onTextEntryValidator: function (startDate, endDate) {
                                    $startField.parent().find("label.error.invalid-text-date").remove();
                                    $endField.parent().find("label.error.invalid-text-date").remove();
                                    return Extensions.validateDatesTextEntry(this, startDate, endDate);
                                },
                                onTextEntryStartFieldError: function () {
                                    self.$startDom.after("<label class=\"error invalid-text-date\">" + "Invalid date." + "</label>");
                                },
                                onTextEntryEndFieldError: function () {
                                    self.$endDom.after("<label class=\"error invalid-text-date\">" + "Invalid date." + "</label>");
                                },
                                onSelect: function () {
                                    if (componentObject !== undefined && componentObject.dateRangeCallback !== undefined) {
                                        componentObject.dateRangeCallback(this.startDateDom.val(), this.endDateDom.val());
                                    }
                                    // var startVal = self.$startDom.val();
                                    // var endVal = self.$endDom.val();

                                    // if (startVal !== "" && endVal !== "") {
                                    //     //close it down
                                    //     setTimeout(function () {
                                    //         self._closeAll();
                                    //     }, 300);
                                    // }
                                }
                            });

                            //bring up section
                            self.$startDom.focus(function (e) {
                                e.preventDefault();
                                self.$dateRangeObject.showStartDate();
                                self._openDatepicker();
                                $(this).blur();
                            });
                            self.$endDom.focus(function (e) {
                                self.$dateRangeObject.showEndDate();
                                self._openDatepicker();
                                $(this).blur();
                            });
                            self.$startDom.click(function (e) {
                                e.preventDefault();
                            });

                            self.$closeBtn.click(function (e) {
                                e.preventDefault();
                                self._closeAll();
                            });
                            self.$clearBtn.click(function (e) {
                                e.preventDefault();
                                self._clearDates();
                            });
                            self.$clearAllBtn.click(function (e) {
                                e.preventDefault();
                                self.$mobileTextLocation.val("");
                                self.$mobileTextLocationSpan.removeClass("selected");
                                self._clearDates();
                            });
                            self.$mobileTextLocation.click(function (e) {
                                self._closeAll();
                            });
                            self.$mobileTextLocation.blur(function (e) {
                                if (self.$mobileTextLocation.val() !== "") {
                                    self.$mobileTextLocationSpan.addClass("selected");
                                }
                                else {
                                    self.$mobileTextLocationSpan.removeClass("selected");
                                }
                            });

                        }
                    };
                    mobileHeaderDateFieldsController.init();

                } else if (isMobileBannerSearch || isMobileInlineSearch) { //
                    var mobileBannerDateFieldsController = {
                        $mobileInlineDate: null,
                        $mobileTextLocation: null,
                        $mobileSearchDropdown: null,
                        mobileScrollTimeout: null,
                        $startDom: $startField,
                        $endDom: $endField,
                        $dateRangeObject: null,
                        $closeBtn: null,
                        $clearBtn: null,
                        _closeAll: function () {
                            var self = this;
                            self.$mobileSearchDropdown.removeClass("start-date-open").removeClass("end-date-open");
                        },
                        _clearDates: function () {
                            var self = this;
                            self.$startDom.val("");
                            self.$endDom.val("");
                            self.$dateRangeObject.clearDates();
                            self.$dateStartSpanDom.removeClass("selected");
                            self.$dateEndSpanDom.removeClass("selected");
                            self.$mobileSearchDropdown.find("label.error").hide();
                        },
                        _openDatepicker: function () {
                            var self = this;
                            self.$mobileSearchDropdown.removeClass("end-date-open").addClass("start-date-open");

                            clearTimeout(self.mobileScrollTimeout);
                            self.mobileScrollTimeout = setTimeout(function () {
                                Extensions.scrollToElementTop(self.$mobileSearchDropdown);
                            }, 100);

                        },
                        _toggleDatepicker: function () {
                            var self = this;
                            if (!self.$mobileSearchDropdown.hasClass("start-date-open")) {
                                self.$mobileSearchDropdown.removeClass("end-date-open").addClass("start-date-open");
                            }
                            else {
                                self.$mobileSearchDropdown.removeClass("start-date-open");
                            }
                        },
                        init: function () {
                            var self = this;
                            //add inline datepickers
                            self.$mobileInlineDate = $currentForm.find(".mobile-date-both-datepicker-inline");//$("#mobile-banner-date-both-datepicker-inline");
                            self.$mobileSearchDropdown = $currentForm.find(".mobile-search-dropdown");//$(".form-banner-search-mobile .mobile-search-dropdown");
                            self.$startDom = $currentForm.find(".date-start");
                            self.$endDom = $currentForm.find(".date-end");
                            self.$dateStartSpanDom = self.$startDom.closest(".date-start-span");
                            self.$dateEndSpanDom = self.$endDom.closest(".date-end-span");
                            self.$closeBtn = self.$mobileSearchDropdown.find(".close-datepicker");
                            self.$clearBtn = self.$mobileSearchDropdown.find(".clear-datepicker");
                            self.$mobileTextLocation = self.$mobileSearchDropdown.find(".text-location");

                            //custom double date range picker
                            self.$dateRangeObject = new $.daterange(self.$mobileInlineDate, {
                                changeYear: true,
                                startDateDom: self.$startDom,
                                endDateDom: self.$endDom,
                                minDate: myMinStartDate,
                                minDateCalculation: function () {
                                    return Extensions.getMinimumStartDate(true);
                                },
                                maxDateCalculation: function () {
                                    return Extensions.getMaximumStartDate(true);
                                },
                                clearDatesOnInvalidMidnight: true,
                                customSetOnSelect: function (date) {
                                    return Extensions.setDateRangeDates(componentObject, this, date);
                                },
                                beforeShowDay: function (date) {
                                    Extensions.setDateTimePickerYearAriaLabel();
                                    return Extensions.enforceDisabledDates(this, date, false, true);
                                },
                                onTextEntryValidator: function (startDate, endDate) {
                                    $startField.parent().find("label.error.invalid-text-date").remove();
                                    $endField.parent().find("label.error.invalid-text-date").remove();
                                    return Extensions.validateDatesTextEntry(this, startDate, endDate);
                                },
                                onTextEntryStartFieldError: function () {
                                    self.$startDom.after("<label class=\"error invalid-text-date\">" + "Invalid date." + "</label>");
                                },
                                onTextEntryEndFieldError: function () {
                                    self.$endDom.after("<label class=\"error invalid-text-date\">" + "Invalid date." + "</label>");
                                },
                                onSelect: function () {
                                    var startVal = self.$startDom.val();
                                    var endVal = self.$endDom.val();

                                    if (startVal !== "" && endVal !== "") {
                                        //close it down
                                        setTimeout(function () {
                                            self._closeAll();
                                            //scrolltop
                                            Extensions.scrollToElementTop($currentForm);
                                        }, 300);
                                    }
                                }
                            });

                            //bring up section
                            self.$startDom.focus(function (e) {
                                e.preventDefault();
                                self.$dateRangeObject.showStartDate();
                                self._openDatepicker();
                                $(this).blur();
                            });
                            self.$endDom.focus(function (e) {
                                self.$dateRangeObject.showEndDate();
                                self._openDatepicker();
                                $(this).blur();
                            });
                            self.$startDom.click(function (e) {
                                e.preventDefault();
                            });

                            self.$closeBtn.click(function (e) {
                                e.preventDefault();
                                self._closeAll();
                            });
                            self.$clearBtn.click(function (e) {
                                e.preventDefault();
                                self._clearDates();
                            });
                            self.$mobileTextLocation.click(function (e) {
                                self._closeAll();
                            });

                        }
                    };
                    mobileBannerDateFieldsController.init();
                }
                //green color if content
                $([$startField, $endField]).each(function () {

                    $(this).blur(function () {
                        var $closestParent = $(this).closest("span");
                        if ($(this).val() !== "") {
                            $closestParent.addClass("selected");
                        } else {
                            $closestParent.removeClass("selected");
                        }
                        //close if 
                        //$mobileSearchDropdown.removeClass("start-date-open").removeClass("end-date-open");
                    });

                });

                //!$("body").hasClass("searchPage")
                // $(this).validate({
                //     onfocusout: false,
                //     invalidHandler: function (form, validator) {
                //         setTimeout(function () {
                //             $currentForm.find("label.error").fadeOut();
                //         }, 1500);
                //     },
                //     submitHandler: function (form, event) {
                //         event.preventDefault();
                //         $("body").addClass("fetching");
                //         var locationName = $locationField.val();
                //         var startDate = $startField.val();
                //         var endDate = $endField.val();
                //         var searchUrl = Extensions.searchUrlHelper.buildSearchUrlFromExternal(locationName, startDate, endDate);

                //         //gtm
                //         if ($(form).closest("header").length > 0) {
                //             Extensions.GTMHelper.dataLayerPush({ 'searchForm': 'top' });
                //             window.GRGExtensions.GTMHelper.dataLayerPush({ 'searchCity': locationName });
                //             window.GRGExtensions.GTMHelper.dataLayerPushEvent('housingSearch');
                //         }
                //         else if ($(form).closest(".homepage-banner").length > 0) {
                //             window.GRGExtensions.GTMHelper.dataLayerPush({ 'searchForm': 'homeHero' });
                //             window.GRGExtensions.GTMHelper.dataLayerPush({ 'searchCity': locationName });
                //             window.GRGExtensions.GTMHelper.dataLayerPushEvent('housingSearch');
                //         }

                //         setTimeout(function () {
                //             window.location.href = searchUrl;
                //         }, 250);

                //     }
                // });


            });
    }
    static closeMobileNavbar() {
        $("#mobileNavbarToggler").removeClass("open");
        $("#menu").removeClass("open");
        setTimeout(function () {
            $("#menu").addClass("sr-none");
        }, 400);
    }
    static closeMobileNavSearch() {
        $(".mobile-search-toggler").removeClass("open");
        $(".mobile-search-dropdown").removeClass("in");
        setTimeout(function () {
            $(".mobile-search-dropdown").removeClass("open");
        }, 300);
    }
}

//PropertyDetail Component DatePickers 
export class dateContolManager {
    $desktopBookingDateRangeObject;
    $desktopBookingDateRangeInline;
    $startDateDesktopDom;
    $endDateDesktopDom;

    $mobileBookingDateRangeObject;
    $mobileBookingDateRangeInline;
    $startDateMobileDom;
    $endDateMobileDom;

    $datepickerInlineDesktopHolder;
    $datepickerInlineMobileHolder;
    prevStartValue: "";
    prevEndValue: "";

    // search only, no booking login
    $desktopSearchDateRangeObject;
    $desktopSearchDateRangeInline;
    $startDateSearchDesktopDom;
    $endDateSearchDesktopDom;

    $datepickerSearchInlineDesktopHolder;
    $datepickerSearchInlineMobileHolder;

    $mobileSearchDateRangeObject;
    $mobileSearchDateRangeInline;
    $startDateSearchMobileDom;
    $endDateSearchMobileDom;

    $dateDesktopFieldsHolder;
    $dateStartSpan;
    $dateEndSpan;
    $dateStartSpanMobile;
    $dateEndSpanMobile;
    $dateDesktopSearchFieldsHolder;
    $dateDesktopSearchStartSpan;
    $dateDesktopSearchEndSpan;
    $dateMobileSearchFieldsHolder;
    $dateMobileSearchStartSpan;
    $dateMobileSearchEndSpan;

    init() {
        let self = this;
        self.$desktopBookingDateRangeInline = $("#booking-datepicker-inline");
        self.$startDateDesktopDom = $("#bk-date-move-in");
        self.$endDateDesktopDom = $("#bk-date-move-out");
        self.$datepickerInlineDesktopHolder = $(".booking-sidebar-box form.booking-form .datepicker-inline");
        self.$dateDesktopFieldsHolder = self.$startDateDesktopDom.closest(".date-fields");
        self.$dateStartSpan = self.$startDateDesktopDom.closest(".date-start-span");
        self.$dateEndSpan = self.$endDateDesktopDom.closest(".date-end-span");
        //-----
        self.$mobileBookingDateRangeInline = $("#mobile-booking-datepicker-inline");
        self.$startDateMobileDom = $("#bk-date-move-in-mobile");
        self.$endDateMobileDom = $("#bk-date-move-out-mobile");
        self.$datepickerInlineMobileHolder = $(".booking-form-mobile .datepicker-inline");
        self.prevStartValue = self.$startDateDesktopDom.val();
        self.prevEndValue = self.$endDateDesktopDom.val();
        self.$dateStartSpanMobile = self.$startDateMobileDom.closest(".date-start-span");
        self.$dateEndSpanMobile = self.$endDateMobileDom.closest(".date-end-span");

        //build disabled search datepickers
        self.$desktopSearchDateRangeInline = $("#search-datepicker-inline");
        self.$startDateSearchDesktopDom = $("#search-date-move-in");
        self.$endDateSearchDesktopDom = $("#search-date-move-out");
        self.$datepickerSearchInlineDesktopHolder = $(".booking-sidebar-box form.search-form-booking-disabled .datepicker-inline");
        self.$dateDesktopSearchFieldsHolder = self.$startDateSearchDesktopDom.closest(".date-fields");
        self.$dateDesktopSearchStartSpan = self.$startDateSearchDesktopDom.closest(".date-start-span");
        self.$dateDesktopSearchEndSpan = self.$endDateSearchDesktopDom.closest(".date-end-span");
        //-----
        self.$mobileSearchDateRangeInline = $("#mobile-search-datepicker-inline");
        self.$startDateSearchMobileDom = $("#search-date-move-in-mobile");
        self.$endDateSearchMobileDom = $("#search-date-move-out-mobile");
        self.$datepickerSearchInlineMobileHolder = $(".search-form-booking-disabled-mobile .datepicker-inline");
        self.$dateMobileSearchFieldsHolder = self.$startDateSearchMobileDom.closest(".date-fields");
        self.$dateMobileSearchStartSpan = self.$startDateSearchMobileDom.closest(".date-start-span");
        self.$dateMobileSearchEndSpan = self.$endDateSearchMobileDom.closest(".date-end-span");

        //popup window events
        self.$datepickerInlineDesktopHolder.find(".close-datepicker").click(function (e) {
            e.preventDefault();
            self._closeDesktopDatepicker();
        });
        self.$datepickerInlineDesktopHolder.find(".clear-datepicker").click(function (e) {
            e.preventDefault();
            self._clearDates();
        });
        self.$datepickerInlineMobileHolder.find(".close-datepicker").click(function (e) {
            e.preventDefault();
            self._closeMobileDatepicker();
        });
        self.$datepickerInlineMobileHolder.find(".clear-datepicker").click(function (e) {
            e.preventDefault();
            self._clearDates();
        });

        //search popup window events
        self.$datepickerSearchInlineDesktopHolder.find(".close-datepicker").click(function (e) {
            e.preventDefault();
            self._closeDesktopDatepicker();
        });
        self.$datepickerSearchInlineDesktopHolder.find(".clear-datepicker").click(function (e) {
            e.preventDefault();
            self._clearDates();
        });
        self.$datepickerSearchInlineMobileHolder.find(".close-datepicker").click(function (e) {
            e.preventDefault();
            self._closeMobileDatepicker();
        });
        self.$datepickerSearchInlineMobileHolder.find(".clear-datepicker").click(function (e) {
            e.preventDefault();
            self._clearDates();
        });

        self.$startDateDesktopDom.blur(function () {
            if ($(this).val() === "") {
                self.$dateStartSpan.removeClass("selected");
                self.$dateEndSpan.removeClass("selected");
                self.$dateStartSpanMobile.removeClass("selected");
                self.$dateEndSpanMobile.removeClass("selected");
            }
            else {
                self.$dateStartSpan.addClass("selected");
                self.$dateStartSpanMobile.addClass("selected");
            }
        });
        self.$endDateDesktopDom.blur(function () {
            if ($(this).val() === "") {
                self.$dateEndSpan.removeClass("selected");
                self.$dateEndSpanMobile.removeClass("selected");
            }
            else {
                self.$dateEndSpan.addClass("selected");
                self.$dateEndSpanMobile.addClass("selected");
            }
        });
        self.$startDateMobileDom.blur(function () {
            if ($(this).val() === "") {
                self.$dateStartSpanMobile.removeClass("selected");
                self.$dateEndSpanMobile.removeClass("selected");
                self.$dateStartSpan.removeClass("selected");
                self.$dateEndSpan.removeClass("selected");
            }
            else {
                self.$dateStartSpanMobile.addClass("selected");
                self.$dateStartSpan.addClass("selected");
                self.$dateEndSpan.addClass("selected");
            }
        });
        self.$endDateMobileDom.blur(function () {
            if ($(this).val() === "") {
                self.$dateEndSpanMobile.removeClass("selected");
            }
            else {
                self.$dateEndSpanMobile.addClass("selected");
            }
        });
    }
    createDesktopRangeControl(componentObject) {
        var self = this;
        //create custom range control
        self.$desktopBookingDateRangeObject = new $.daterange(self.$desktopBookingDateRangeInline, {
            startDateDom: self.$startDateDesktopDom,
            endDateDom: self.$endDateDesktopDom,
            minDate: Extensions.getMinimumStartDate(),
            changeYear: true,
            minDateCalculation: function () {
                return Extensions.getMinimumStartDate(true);
            },
            maxDateCalculation: function () {
                return Extensions.getMaximumStartDate(true);
            },
            clearDatesOnInvalidMidnight: true,
            customSetOnSelect: function (date) {
                return Extensions.setDateRangeDates(componentObject, this, date);
            },
            beforeShowDay: function (date) {
                var selfRangeObject = this;
                var string = $.datepicker.formatDate('mm-dd-yy', date);
                var dayOfWeek = date.getUTCDay();
                var validDay = true;

                var currentStart = selfRangeObject.getStartValue();
                var currentEnd = selfRangeObject.getEndValue();

                //enforce excluded property dates and holidays only in startdate mode
                if (selfRangeObject.getUpdateMode() === "startDate") {
                    if ((currentStart === "" && currentEnd === "") || (currentStart !== "" && currentEnd !== "")) {
                        //exclusion dates only apply to start dates
                        if (componentObject.propertyDetail.calcExcludedDates !== undefined) {
                            validDay = componentObject.propertyDetail.calcExcludedDates.indexOf(string) === -1;
                            if (validDay) {
                                if (componentObject.propertyDetail.calcDaysClosed !== undefined) {
                                    validDay = componentObject.propertyDetail.calcDaysClosed.indexOf(dayOfWeek) === -1;
                                }
                            }
                        }
                    }
                }

                //maxStartDate rule
                if (validDay) {
                    if (selfRangeObject.getUpdateMode() === "startDate" && (currentStart === null || currentStart === "")) {
                        if (date > Extensions.getMaximumStartDate()) {
                            validDay = false;
                        }
                    }
                }
                Extensions.setDateTimePickerYearAriaLabel();
                if (validDay) {
                    validDay = Extensions.enforceDisabledDates(selfRangeObject, date, true, true) as boolean;
                }
                //exclude excluded months
                if (validDay) {
                    validDay = componentObject.validateExcludedMoveOutMonth(date, true);
                }

                return [validDay];
            },
            onTextEntryValidator: function (startDate, endDate) {
                return Extensions.validateDatesTextEntry(this, startDate, endDate);
            },
            onSelect: function () {
                //hide errors
                componentObject.error = false;
                componentObject.errorMessages = [];

                //hide when selected two
                var startVal = self.$desktopBookingDateRangeObject.getStartValue();
                var endVal = self.$desktopBookingDateRangeObject.getEndValue();

                //sync values back to mobile inputs
                self.setStartDates(startVal);
                self.setEndDates(endVal);
                // self.$mobileBookingDateRangeObject.setStartEndDates(startVal, endVal);
                self.$dateStartSpanMobile.addClass("selected");
                self.$dateEndSpanMobile.addClass("selected");

                if (startVal !== "" && endVal !== "") {
                    self.$dateStartSpan.addClass("selected");
                    self.$dateEndSpan.addClass("selected");

                    //close it down
                    setTimeout(function () {
                        self._closeDesktopDatepicker();

                        //reset shown month by setting start date?
                        //self.$desktopBookingDateRangeObject.setStartEndDates(startVal, endVal);
                        self.$desktopBookingDateRangeObject.showStartDate();

                        //we have two dates selected
                        //if start changed, ajax
                        if (self.prevStartValue !== startVal || self.prevEndValue !== endVal) {
                            componentObject.onDatRangeChange(startVal, endVal);
                        }

                    }, 300);
                }
            }
        });

        //bring up section
        self.$startDateDesktopDom.focus(function (e) {
            e.preventDefault();
            self.$desktopBookingDateRangeObject.showStartDate();
            self._openDesktopDatepicker();
            //$(this).blur();
        });
        self.$endDateDesktopDom.focus(function (e) {
            e.preventDefault();
            self.$desktopBookingDateRangeObject.showEndDate();
            self._openDesktopDatepicker();
            //$(this).blur();
        });

        //init range values
        var initStartDate = self.$startDateDesktopDom.val();
        var initEndDate = self.$endDateDesktopDom.val();

        initStartDate = componentObject.propertyDetail.startDate;
        initEndDate = componentObject.propertyDetail.endDate;

        self.$desktopBookingDateRangeObject.setStartEndDates(initStartDate, initEndDate);
        // initStartDate = Extensions.getDateFromString(componentObject.propertyDetail.startDate);
        self.$desktopBookingDateRangeObject.setStartEndDates(initStartDate, initEndDate);
        if (initStartDate !== "" && initEndDate !== "") {
            self.$dateStartSpan.addClass("selected");
            self.$dateEndSpan.addClass("selected");
            self.$dateStartSpanMobile.addClass("selected");
            self.$dateEndSpanMobile.addClass("selected");
        }
    }
    createMobileRangeControl(componentObject) {
        var self = this;
        //create custom range control
        self.$mobileBookingDateRangeObject = new $.daterange(self.$mobileBookingDateRangeInline, {
            startDateDom: self.$startDateMobileDom,
            endDateDom: self.$endDateMobileDom,
            minDate: Extensions.getMinimumStartDate(),
            minDateCalculation: function () {
                return Extensions.getMinimumStartDate(true);
            },
            maxDateCalculation: function () {
                return Extensions.getMaximumStartDate(true);
            },
            clearDatesOnInvalidMidnight: true,
            changeYear: true,
            customSetOnSelect: function (date) {
                return Extensions.setDateRangeDates(componentObject, this, date);
            },
            beforeShowDay: function (date) {
                var selfRangeObject = this;
                var string = $.datepicker.formatDate('mm-dd-yy', date);
                var dayOfWeek = date.getUTCDay();
                var validDay = true;
                var currentEnd = selfRangeObject.getEndValue();
                var currentStart = selfRangeObject.getStartValue();

                //excluded dates only in startdate mode
                if (selfRangeObject.getUpdateMode() === "startDate") {
                    if ((currentStart === "" && currentEnd === "") || (currentStart !== "" && currentEnd !== "")) {
                        //exclusion dates only apply to start dates
                        if (componentObject.propertyDetail.calcExcludedDates !== undefined) {
                            validDay = componentObject.propertyDetail.calcExcludedDates.indexOf(string) === -1;
                            if (validDay) {
                                if (componentObject.propertyDetail.calcDaysClosed !== undefined) {
                                    validDay = componentObject.propertyDetail.calcDaysClosed.indexOf(dayOfWeek) === -1;
                                }
                            }
                        }
                    }
                }
                //maxStartDate
                if (validDay) {
                    if (selfRangeObject.getUpdateMode() === "startDate" && (currentStart === null || currentStart === "")) {
                        if (date > Extensions.getMaximumStartDate()) {
                            validDay = false;
                        }
                    }
                }
                Extensions.setDateTimePickerYearAriaLabel();
                if (validDay) {
                    //generic
                    validDay = Extensions.enforceDisabledDates(selfRangeObject, date, true, true) as boolean;
                }
                //exclude excluded months
                if (validDay) {
                    validDay = componentObject.validateExcludedMoveOutMonth(date, true);
                }
                return [validDay];
            },
            onTextEntryValidator: function (startDate, endDate) {
                return Extensions.validateDatesTextEntry(this, startDate, endDate);
            },
            onSelect: function () {
                //var self = this;
                //sync
                //fetch internal start, set desk start value
                //fetch internal end, set desk end value

                //hide errors
                componentObject.error = false;
                componentObject.errorMessages = [];

                //hide when selected two
                var startVal = self.$mobileBookingDateRangeObject.getStartValue();
                var endVal = self.$mobileBookingDateRangeObject.getEndValue();

                //sync values back to desktop inputs
                self.setStartDates(startVal);
                self.setEndDates(endVal);
                self.$desktopBookingDateRangeObject.setStartEndDates(startVal, endVal);
                self.$dateStartSpan.addClass("selected");

                if (startVal !== "" && endVal !== "") {
                    self.$dateEndSpan.addClass("selected");
                    self.$mobileBookingDateRangeObject.showStartDate();
                    //close it down
                    setTimeout(function () {
                        self._closeMobileDatepicker();
                        //we have two dates selected
                        //if start changed, ajax
                        if (self.prevStartValue !== startVal || self.prevEndValue !== endVal) {
                            componentObject.onDatRangeChange(startVal, endVal);
                        }

                    }, 300);
                }

            }
        });

        //bring up section
        self.$startDateMobileDom.focus(function (e) {
            e.preventDefault();
            self.$mobileBookingDateRangeObject.showStartDate();
            self._openMobileDatepicker();
            $(this).blur();
        });
        self.$endDateMobileDom.focus(function (e) {
            e.preventDefault();
            self.$mobileBookingDateRangeObject.showEndDate();
            self._openMobileDatepicker();
            $(this).blur();
        });


        //init range values
        var initStartDate = self.$startDateMobileDom.val();
        var initEndDate = self.$endDateMobileDom.val();
        self.$mobileBookingDateRangeObject.setStartEndDates(initStartDate, initEndDate);
        if (initStartDate !== "" && initEndDate !== "") {
            //self.$dateStartSpan.addClass("selected");
            self.$dateStartSpanMobile.addClass("selected");
            self.$dateEndSpanMobile.addClass("selected");
        }
    }
    _openDesktopDatepicker() {
        var self = this;
        self.$datepickerInlineDesktopHolder.addClass("open");
        self.$dateDesktopFieldsHolder.addClass("open");
        // self.$desktopBookingDateRangeObject.refreshDatepicker();

        //search only, usually hidden
        self.$datepickerSearchInlineDesktopHolder.addClass("open");
        self.$dateDesktopSearchFieldsHolder.addClass("open");
        // self.$desktopSearchDateRangeObject.refreshDatepicker();
    }
    _closeDesktopDatepicker() {
        var self = this;
        self.$datepickerInlineDesktopHolder.removeClass("open");
        self.$dateDesktopFieldsHolder.removeClass("open");

        //search only, usually hidden
        self.$datepickerSearchInlineDesktopHolder.removeClass("open");
        self.$dateDesktopSearchFieldsHolder.removeClass("open");
    }
    _openMobileDatepicker() {
        var self = this;
        self.$datepickerInlineMobileHolder.addClass("open");
        // self.$mobileBookingDateRangeObject.refreshDatepicker();
        //search
        self.$datepickerSearchInlineMobileHolder.addClass("open");
        // self.$mobileSearchDateRangeObject.refreshDatepicker();
    }
    _closeMobileDatepicker() {
        var self = this;
        self.$datepickerInlineMobileHolder.removeClass("open");
        self.$datepickerSearchInlineMobileHolder.removeClass("open");
    }
    _clearDates() {
        var self = this;
        self.$startDateDesktopDom.val("");
        self.$endDateDesktopDom.val("");
        self.$startDateMobileDom.val("");
        self.$endDateMobileDom.val("");
        // self.$desktopBookingDateRangeObject.clearDates();
        // self.$mobileBookingDateRangeObject.clearDates();

        //now search only fields
        self.$startDateSearchDesktopDom.val("");
        self.$endDateSearchDesktopDom.val("");
        self.$startDateSearchMobileDom.val("");
        self.$endDateSearchMobileDom.val("");
        // self.$desktopSearchDateRangeObject.clearDates();
        // self.$mobileSearchDateRangeObject.clearDates();
        setTimeout(function () {
            self.$startDateSearchDesktopDom.parent().find("label.error.invalid-text-date").remove();
            self.$endDateSearchDesktopDom.parent().find("label.error.invalid-text-date").remove();


        }, 500);

        self.$dateStartSpan.removeClass("selected");
        self.$dateStartSpanMobile.removeClass("selected");
        self.$dateEndSpan.removeClass("selected");
        self.$dateEndSpanMobile.removeClass("selected");
        self.$dateDesktopSearchStartSpan.removeClass("selected");
        self.$dateDesktopSearchEndSpan.removeClass("selected");
        self.$dateMobileSearchStartSpan.removeClass("selected");
        self.$dateMobileSearchEndSpan.removeClass("selected");
    }
    setStartDates(stringDate) {
        var self = this;
        self.$startDateDesktopDom.val(stringDate);
        self.$startDateMobileDom.val(stringDate);
    }
    setEndDates(stringDate) {
        var self = this;
        self.$endDateDesktopDom.val(stringDate);
        self.$endDateMobileDom.val(stringDate);
    }
}

export class mobileSearchToggler {
    $mobileSearchToggler = $(".mobile-search-toggler");
    $mobileSearchDropdown = $(".mobile-search-dropdown");
    searchAnimateTimeout = null;
    bind() {
        let self = this;
        self.$mobileSearchToggler.click(function (e) {
            e.preventDefault();
            //close
            new mobileNavbarToggler().close();

            if (!$(this).hasClass("open")) {
                self.open();
            } else {
                self.close();
            }
        });
    }
    open() {
        let self = this;
        self.$mobileSearchToggler.addClass("open");
        self.$mobileSearchDropdown.addClass("open");
        if (!self.searchAnimateTimeout === null) {
            clearTimeout(self.searchAnimateTimeout);
        }
        self.searchAnimateTimeout = setTimeout(function () {
            self.$mobileSearchDropdown.addClass("in");
        }, 20);
    }
    close() {
        let self = this;
        self.$mobileSearchToggler.removeClass("open");
        self.$mobileSearchDropdown.removeClass("in");
        if (!self.searchAnimateTimeout === null) {
            clearTimeout(self.searchAnimateTimeout);
        }
        self.searchAnimateTimeout = setTimeout(function () {
            self.$mobileSearchDropdown.removeClass("open");
        }, 300);
    }
}

export class mobileNavbarToggler {
    $navbarToggler = $("#mobileNavbarToggler");
    $mobilemenuWrapper = $("#menu");
    $mobilemenuWrapperClose = this.$mobilemenuWrapper.find(".close");
    srDelayTimeout = null;
    bind() {
        var self = this;
        self.$navbarToggler.click(function (e) {
            e.preventDefault();
            if (!$(this).hasClass("open")) {
                self.open();
            } else {
                self.close();
            }
        });
        self.$mobilemenuWrapperClose.click(function (e) {
            e.preventDefault();
            self.close();
        });

        //ul li accordion
        self.$mobilemenuWrapper.find("ul > li.has-dropdown > a").click(function (e) {
            e.preventDefault();
            var $closestLi = $(this).closest("li");

            var $ulChild = $closestLi.children("ul");
            var totalHeight = 0;
            $ulChild.children("li").each(function () {
                totalHeight += $(this).outerHeight();
            });
            totalHeight += 10;
            if (!$closestLi.hasClass("open")) {
                $closestLi.addClass("open");
                $closestLi.children("ul").height(totalHeight + "px");
            }
            else {
                $closestLi.removeClass("open");
                $closestLi.children("ul").height("1px");
            }
        });
        //resize accordions
        $(window).resize(function () {
            self.$mobilemenuWrapper.find("ul > li.has-dropdown").each(function () {
                var $closestLi = $(this);

                if ($closestLi.hasClass("open")) {
                    var $ulChild = $closestLi.children("ul");
                    var totalHeight = 0;
                    $ulChild.children("li").each(function () {
                        totalHeight += $(this).outerHeight();
                    });
                    totalHeight += 10;
                    if (!$closestLi.hasClass("open")) {
                        $closestLi.addClass("open");
                        $closestLi.children("ul").height(totalHeight + "px");
                    }
                }
            });
        });
    }
    open() {
        new mobileSearchToggler().close();
        this.$navbarToggler.addClass("open");
        this.$mobilemenuWrapper.addClass("open");
        if (this.srDelayTimeout !== null) {
            clearTimeout(this.srDelayTimeout);
        }
        this.$mobilemenuWrapper.removeClass("sr-none");
    }
    close() {
        let self = this;
        this.$navbarToggler.removeClass("open");
        this.$mobilemenuWrapper.removeClass("open");
        this.srDelayTimeout = setTimeout(function () {
            self.$mobilemenuWrapper.addClass("sr-none");
        }, 400);

    }
}
