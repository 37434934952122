import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/services/api.service';
import { CountryStateService } from 'app/services/country-state.service';
import { CountryStateModel } from 'app/models/CountryStateModel';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Utils, { MONTHS_NUMBER, MONTHS_NAME, REGEX_TRANSUNION_NAME } from '../Utils';
import { CreditCardModel } from 'app/models/CreditCardModel';
import { NGXLogger } from 'ngx-logger';
import { GRGSnackBarService } from '../grg-snack-bar/grg-snak-bar.service';
import { UserService } from 'app/services/user.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { HashTable } from 'app/models/HashTable';

@Component({
  selector: 'app-customer-credit-card',
  templateUrl: './customer-credit-card.component.html',
  styleUrls: ['./customer-credit-card.component.scss']
})
export class CustomerCreditCardComponent extends BaseComponent implements OnInit {

  addCreditCard: FormGroup;
  countryStates: CountryStateModel;
  isSubmitDisabled = false;
  months: string[] = [];
  monthnames: HashTable<string> = {};
  years: number[] = [];
  debounceSub = new Subject<string>();
  cardType = '';
  isUpdateMode = false;
  isCardProviderError = false;
  isCardValidationError = false;
  cvvLength = 3;

  @Input()
  creditCard: CreditCardModel;

  @Output()
  creditCardCreated = new EventEmitter<CreditCardModel>();

  @Output()
  creditCardUpdated = new EventEmitter<CreditCardModel>();

  constructor(private fb: FormBuilder,
    private apiService: ApiService,
    private countryStateService: CountryStateService,
    public activeModal: NgbActiveModal,
    private logger: NGXLogger,
    private notificationService: GRGSnackBarService,
    private userService: UserService) {
    super(logger);
    this.initForm();
  }

  ngOnInit() {

    this.countryStateService.countryStates.subscribe(
      (response: CountryStateModel) => {
        this.countryStates = response;
      }
    );

    if (this.creditCard) {
      this.isUpdateMode = true;
    }

    this.months = MONTHS_NUMBER;
    this.monthnames = Utils.getMonthsHash();

    // get the current year + the next 20 years as options for CC expiration
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i <= (currentYear + 20); i++) {
      this.years.push(i);
    }

    this.prefillForm();

    this.debounceSub.pipe(debounceTime(50),
      distinctUntilChanged(),
      takeUntil(this.unsubscribeOnDestroy$))
      .subscribe(response => {
        this.cardType = Utils.getCardType(response);

        if (response.length > 2 && !this.cardType) {
          this.isCardProviderError = true;
          this.isCardValidationError = false;
        } else {
          this.isCardProviderError = false;
          this.isCardValidationError = false;
          // if amex, enforce a max length of 4 digits for cvv, otherwise 3 for all others
          if (this.cardType === 'American Express') {
            this.cvvLength = 4;
          } else {
            this.cvvLength = 3;
          }
        }

      });
  }

  initForm(): void {
    this.addCreditCard = this.fb.group({
      firstName: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
      lastName: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
      cardNumber: [{ value: '', disabled: false }, [Validators.required]],
      cvv: [{ value: '', disabled: false }, [Validators.required]],
      expirationMonth: [{ value: '', disabled: false }, [Validators.required]],
      expirationYear: [{ value: '', disabled: false }, [Validators.required]],
      billingZip: [{ value: '', disabled: false }, [Validators.required]],
      billingCountry: [{ value: '', disabled: false }, [Validators.required]],
      defaultPayment: [{ value: '', disabled: false }]
    });
  }

  prefillForm(): void {
    if (this.isUpdateMode) {
      this.addCreditCard = this.fb.group({
        firstName: [{ value: this.userService.clientContact.firstName, disabled: true }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
        lastName: [{ value: this.userService.clientContact.lastName, disabled: true }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
        cardNumber: [{ value: this.creditCard.maskCardNumber, disabled: true }, [Validators.required]],
        cvv: [{ value: this.creditCard.cvv, disabled: true }],
        expirationMonth: [{ value: this.creditCard.expirationMonth, disabled: false }, [Validators.required]],
        expirationYear: [{ value: this.creditCard.expirationYear, disabled: false }, [Validators.required]],
        billingZip: [{ value: this.userService.clientContact.globalContactDetails.postalCode, disabled: true }, [Validators.required]],
        billingCountry: [{
          value: this.userService.clientContact.globalContactDetails.globalCountryId != null
            ? this.countryStateService.countriesById[this.userService.clientContact.globalContactDetails.globalCountryId]
            : 'United States', disabled: true
        }, [Validators.required]],
        defaultPayment: [{ value: this.creditCard.defaultCard, disabled: false }]
      });
    } else {
      this.addCreditCard = this.fb.group({
        firstName: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
        lastName: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
        cvv: [{ value: '', disabled: false }, [Validators.required]],
        cardNumber: [{ value: '', disabled: false }, [Validators.required]],
        expirationMonth: [{ value: '', disabled: false }, [Validators.required]],
        expirationYear: [{ value: '', disabled: false }, [Validators.required]],
        billingZip: [{ value: '', disabled: false }, [Validators.required]],
        billingCountry: [{ value: 'United States', disabled: false }, [Validators.required]],
        defaultPayment: [{ value: '', disabled: false }]
      });
    }
  }

  isInvalid(controlName: string): boolean {
    const control = this.addCreditCard.get(controlName);
    const isInvalid = control.invalid && (control.dirty || control.touched);

    return isInvalid;
  }

  saveInfo(): void {
    Utils.touchFormFields(this.addCreditCard);
    if (this.addCreditCard.valid) {

      if (this.isUpdateMode === false && this.addCreditCard.get('cardNumber').value && !this.cardType) {
        this.isCardProviderError = true;
        return;
      }

      if (this.isUpdateMode === false && this.addCreditCard.get('cardNumber').value && Utils.isCreditCardValid(this.addCreditCard.get('cardNumber').value) === false) {
        this.isCardValidationError = true;
        return;
      } else {
        this.isCardValidationError = false;
      }

      this.isSubmitDisabled = true;

      const model: CreditCardModel = {
        chargeCardAccountingId: 0,
        customerAccountingId: this.userService.clientContact.accountingId ? parseInt(this.userService.clientContact.accountingId) : 0,
        cardNumber: this.addCreditCard.get('cardNumber').value,
        expirationMonth: this.addCreditCard.get('expirationMonth').value,
        expirationYear: this.addCreditCard.get('expirationYear').value,
        defaultCard: this.addCreditCard.get('defaultPayment').value ? true : false,
        cardType: this.cardType
      };

      if (this.isUpdateMode) {

        this.creditCard.expirationMonth = this.addCreditCard.get('expirationMonth').value;
        this.creditCard.expirationYear = this.addCreditCard.get('expirationYear').value;
        this.creditCard.defaultCard = this.addCreditCard.get('defaultPayment').value ? true : false;

        this.apiService.updateCreditCard(this.creditCard).subscribe(response => {
          this.logger.debug('Updated credit card!');
          this.isSubmitDisabled = false;
          this.notificationService.show('Credit card updated!', 'success');

          // pass the credit card out of this after it's been created
          this.creditCardUpdated.emit(null);

          this.activeModal.close();
        }, error => {
          this.notificationService.show('There was an error updating the credit card', 'error');
          this.isSubmitDisabled = false;
          this.logger.error(error);
        });
      } else {
        this.apiService.createCreditCard(model).subscribe(response => {
          this.logger.debug('Created credit card!');
          this.isSubmitDisabled = false;
          this.notificationService.show('Credit card successfully added!', 'success');

          // pass the credit card out of this after it's been created
          this.creditCardCreated.emit(null);

          this.activeModal.close(response);
        }, error => {
          let hasShownError = false;
          if (error && error.error) {
            const err = error.error.toLowerCase();
            if (err.includes('already exists')) {
              hasShownError = true;
              this.notificationService.show('This card number already exists under your payment methods!', 'error');
            }
          }

          if (!hasShownError) {
            this.notificationService.show('There was an error adding the credit card', 'error');
          }

          this.isSubmitDisabled = false;
          this.logger.error(error);
        });
      }

    }
  }

  getCountryArray(): string[] {
    // Handle loading case
    if (this.countryStates == null) {
      return null;
    }
    // Use hash keys as country choices
    return Object.keys(this.countryStates.countries);
  }

  cardOnKeyUp(card): void {
    this.debounceSub.next(card);
  }

  get firstName() { return this.addCreditCard.get('firstName') }
  get lastName() { return this.addCreditCard.get('lastName') }
}
