import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ImportDataService } from './import-data.service';
import { ApiService } from './api.service';
import { CountryStateService } from './country-state.service';
import { PortalLookupModel, LookupUnitSizeTypes } from 'app/models/PortalLookupModel';
import { BehaviorSubject } from 'rxjs';
import { HashTable } from 'app/models/HashTable';
import { UserService } from './user.service';
import { petBreedType } from 'app/models/PetModel';
import { PermissionsService } from './permissions.service';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { ClientContactModel } from 'app/models/ClientContactModel';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  private portalLookupsSubject = new BehaviorSubject<PortalLookupModel>(null);
  $portalLookupsObservable = this.portalLookupsSubject.asObservable();
  data: PortalLookupModel;
  companyRoles: HashTable<string> = {};
  unitSizeTypes: HashTable<string> = {};
  unitSizes: LookupUnitSizeTypes[] = [];
  inquiryStatusTypes: HashTable<string> = {};
  quoteStatusTypes: HashTable<string> = {};
  clientStatusCheckStatusTypes: HashTable<string> = {};
  petBreedTypes: petBreedType[] = [];

  constructor(
    private apiService: ApiService,
    public logger: NGXLogger,
    private countryStateService: CountryStateService, private permissionService: PermissionsService
  ) { }

  init() {
    this.logger.debug('Lookup service init');
    this.apiService.getPortalLookups().subscribe(response => {
      if (response != null) {

        this.data = response;

        // for additional lookups we can create their own service and init them off of the lookup return
        // if there is more complex logic in transforming the data.  This way dependencies can key off of the main lookup behavior
        // subject or the specific lookups service if it employs its own behavior subject
        this.countryStateService.initWithData(response.countriesAndStates);

        this.initCompanyRoles();
        this.initUnitSizeTypes();
        this.initInquiryStatusTypes();
        this.initQuoteStatusTypes();
        this.initClientStatusCheckStatusTypes();
        this.initPetBreedTypes();

        this.logger.log('portal lookups retrieved!', this.data);

        // once all the dependency lookups have been initialized, then we can call the portal lookups
        // behavior subject to inform anyone who might be listening
        this.portalLookupsSubject.next(response);
      }
    }, error => {
      this.logger.error(error);
    });
  }

  load(){
    return this.apiService.getPortalLookups().pipe(map(response => {
      this.data = response;
      // for additional lookups we can create their own service and init them off of the lookup return
      // if there is more complex logic in transforming the data.  This way dependencies can key off of the main lookup behavior
      // subject or the specific lookups service if it employs its own behavior subject
      this.countryStateService.initWithData(response.countriesAndStates);

      this.initCompanyRoles();
      this.initUnitSizeTypes();
      this.initInquiryStatusTypes();
      this.initQuoteStatusTypes();
      this.initClientStatusCheckStatusTypes();
      this.initPetBreedTypes();

      this.logger.log('portal lookups retrieved!', this.data);

      // this.permissionService.setRoles(this.userService.clientContact, this.companyRoles)


      // once all the dependency lookups have been initialized, then we can call the portal lookups
      // behavior subject to inform anyone who might be listening
      this.portalLookupsSubject.next(response);
      return true;
    }));
  }

  initCompanyRoles(): void {
    if (this.data && this.data.companyRelationshipTypes) {
      for (let i = 0; i < this.data.companyRelationshipTypes.length; i++) {
        this.companyRoles[this.data.companyRelationshipTypes[i].id] = this.data.companyRelationshipTypes[i].name;
      }
    }
  }

  initUnitSizeTypes(): void {
    if (this.data && this.data.unitSizeTypes) {
      for (let i = 0; i < this.data.unitSizeTypes.length; i++) {
        if (!this.data.unitSizeTypes[i].displayName.toLowerCase().includes('all')) {
          this.unitSizes.push(this.data.unitSizeTypes[i]);
          this.unitSizeTypes[this.data.unitSizeTypes[i].id] = this.data.unitSizeTypes[i].displayName;
        }
      }
    }
  }

  initInquiryStatusTypes(): void {
    if (this.data && this.data.inquiryStatusTypes) {
      for (let i = 0; i < this.data.inquiryStatusTypes.length; i++) {
        this.inquiryStatusTypes[this.data.inquiryStatusTypes[i].id] = this.data.inquiryStatusTypes[i].name;
      }
    }
  }

  initQuoteStatusTypes(): void {
    if (this.data && this.data.quoteStatusTypes) {
      for (let i = 0; i < this.data.quoteStatusTypes.length; i++) {
        this.quoteStatusTypes[this.data.quoteStatusTypes[i].id] = this.data.quoteStatusTypes[i].name;
      }
    }
  }

  initClientStatusCheckStatusTypes(): void {
    if (this.data && this.data.clientStatusCheckStatusTypes) {
      for (let i = 0; i < this.data.clientStatusCheckStatusTypes.length; i++) {
        this.clientStatusCheckStatusTypes[this.data.clientStatusCheckStatusTypes[i].id] = this.data.clientStatusCheckStatusTypes[i].name;
      }
    }
  }

  initPetBreedTypes(): void {
    if (this.data && this.data.petBreedTypes) {
      this.petBreedTypes = this.data.petBreedTypes;
    }
  }
}
