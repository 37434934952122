import { SearchResultsComponent } from "./search-results.component";

declare var $: any;
declare var daterange: any;

export function initDateRange(component: SearchResultsComponent) {
    var $sectionCoreFiltersRow = $(".section-required-filters-row");
    var $sectionCoreDateStartDom = $sectionCoreFiltersRow.find(".date-start");
    var $sectionCoreDateEndDom = $sectionCoreFiltersRow.find(".date-end");

    $sectionCoreDateEndDom.val(component.searchResultFilter.endDate);

    //custom double date range picker
    let dateRangeObj = new $.daterange(null, {
        changeYear: true,
        startDateDom: $sectionCoreDateStartDom,
        endDateDom: $sectionCoreDateEndDom,
        startDate: component.searchResultFilter.startDate,
        endDate: component.searchResultFilter.endDate,
        minDate: getMinimumStartDate(),
        minDateCalculation: function () {
            return getMinimumStartDate(true);
        },
        maxDateCalculation: function () {
            return getMaximumStartDate(true);
        },
        numberOfMonths: 2,
        closeSelectedDelay: 400,
        onSelect: function (e) {
            //hide when selected two
            var startVal = this.getStartValue();
            var endVal = this.getEndValue();
            component.searchResultFilter.endDate = startVal;
            if (startVal !== "" && endVal !== "") {
                component.submitDateField(startVal, endVal);
                //attemptSubmitLocationDateFields($locationInput.val(), $desktopSearchDateRangeObject.getStartValue(), $desktopSearchDateRangeObject.getEndValue());
            }
        },
        customSetOnSelect: function (date) {
            return setDateRangeDates(this, date);
        },
        beforeShowDay: function (date) {
            setDateTimePickerYearAriaLabel();
            return enforceDisabledDates(this, date, false, true);
        },
        onTextEntryValidator: function (startDate, endDate) {
            $sectionCoreDateStartDom.parent().find("label.error.invalid-text-date").remove();
            $sectionCoreDateEndDom.parent().find("label.error.invalid-text-date").remove();
            return validateDatesTextEntry(this, startDate, endDate);
        },
        onTextEntryStartFieldError: function () {
            $('.date-start').after("<label class=\"error invalid-text-date\">" + "Invalid date." + "</label>");
        },
        onTextEntryEndFieldError: function () {
            $('.date-end').after("<label class=\"error invalid-text-date\">" + "Invalid date." + "</label>");
        }
    });
}
export function initMobile(component: SearchResultsComponent) {
    var $mobileSearchDateRangeObject;
    var $mobileSearchBar = $(".mobile-search-bar");
    var $mobileInlineDate = $("#mobile-search-date-both-datepicker-inline");
    var $mobileSearchDateStartDom = $mobileSearchBar.find(".date-start");
    var $mobileSearchDateEndDom = $mobileSearchBar.find(".date-end");

    var $sectionCoreFiltersRow = $(".section-required-filters-row");
    var $sectionCoreDateStartDom = $sectionCoreFiltersRow.find(".date-start");
    var $sectionCoreDateEndDom = $sectionCoreFiltersRow.find(".date-end");

    $mobileSearchDateStartDom.val(component.searchResultFilter.startDate);
    if ($mobileSearchDateStartDom.val() !== "") {
        var $closestParent = $mobileSearchDateStartDom.closest("span");
        $closestParent.addClass("selected");
    }
    $mobileSearchDateEndDom.val(component.searchResultFilter.endDate);
    if ($mobileSearchDateEndDom.val() !== "") {
        var $closestParent = $mobileSearchDateEndDom.closest("span");
        $closestParent.addClass("selected");
    }

    //toggle dates
    $mobileSearchBar.find(".filter-dates-btn").click(function (e) {
        e.preventDefault();
        //tap startdate
        $mobileSearchDateStartDom.trigger("click");
    });

    $mobileSearchBar.find(".dates .clear-element").click(function (e) {
        e.preventDefault();
        //searchPageVue.clearDatesFields();
        //searchPageVue.resetDatesFields();

        $mobileSearchDateStartDom.val("");
        $mobileSearchDateEndDom.val("");
        $mobileSearchDateRangeObject.clearDates();
        // component.clearDates();

        $sectionCoreDateStartDom.val("");
        $sectionCoreDateEndDom.val("");

        $mobileSearchDateStartDom.closest(".date-start-span").removeClass("selected");
        $mobileSearchDateEndDom.closest(".date-end-span").removeClass("selected");
    });

    var $startDateMobileSearchDom = $mobileSearchBar.find(".dates-fields input.date-start");
    var $endDateMobileSearchDom = $mobileSearchBar.find(".dates-fields input.date-end");
    $([$startDateMobileSearchDom, $endDateMobileSearchDom]).each(function () {
        $(this).on("blur", function () {
            setTimeout(function () {
                $mobileSearchBar.find(".dates-fields").find("label.error").fadeOut();
            }, 1000);

            //color icon
            var $closestParent = $(this).closest("span");
            if ($(this).val() !== "") {
                $closestParent.addClass("selected");
            } else {
                $closestParent.removeClass("selected");
            }
        });
    });

    //custom double date range picker
    $mobileSearchDateRangeObject = new $.daterange($mobileInlineDate, {
        changeYear: true,
        startDateDom: $mobileSearchDateStartDom,
        endDateDom: $mobileSearchDateEndDom,
        startDate: component.searchResultFilter.startDate,
        endDate: component.searchResultFilter.endDate,
        minDate: getMinimumStartDate(),
        minDateCalculation: function () {
            return getMinimumStartDate(true);
        },
        maxDateCalculation: function () {
            return getMaximumStartDate(true);
        },
        customSetOnSelect: function (date) {
            return setDateRangeDates(this, date);
        },
        beforeShowDay: function (date) {
            setDateTimePickerYearAriaLabel();
            return enforceDisabledDates(this, date, false, true);
        },
        onSelect: function (e) {
            //hide when selected two
            var startVal = this.getStartValue();
            var endVal = this.getEndValue();
            component.searchResultFilter.endDate = startVal;
            if (startVal !== "" && endVal !== "") {
                component.submitDateField(startVal, endVal, true);
            }
        },
        onTextEntryValidator: function (startDate, endDate) {
            return validateDatesTextEntry(this, startDate, endDate);
        }
    });
    $mobileSearchDateStartDom.on("focus", function () {
        $(this).blur();
        $mobileSearchDateRangeObject.showStartDate();
    });
    $mobileSearchDateEndDom.on("focus", function () {
        $(this).blur();
        $mobileSearchDateRangeObject.showEndDate();
    });
}
function getMinimumStartDate(returnAsString?): any {
    returnAsString = returnAsString || false;
    //get min start date
    var minStartDate = new Date();
    minStartDate.setHours(0, 0, 0, 0);
    minStartDate.setDate(minStartDate.getDate() + 8); //minimumStartBookDays = 7

    if (returnAsString) {
        return getDateStringFromDate(minStartDate);
    }
    return minStartDate;
}
function getDateStringFromDate(date_obj) {
    var returnString = "";
    if (date_obj !== null) {
        var year = date_obj.getFullYear();
        // months and days are inserted into the array in the form, e.g "01/01/2009", but here the format is "1/1/2009"
        var month = padNumber(date_obj.getMonth() + 1);
        var day = padNumber(date_obj.getDate());
        returnString = month + "/" + day + "/" + year;
    }
    return returnString;
}
function padNumber(number) {
    var ret = new String(number);
    if (ret.length === 1) ret = "0" + ret;
    return ret;
}
function getDateFromString(date_string) {
    var myDate = null;
    //console.log("getting date from string: " + date_string);
    if (date_string !== null && date_string !== "" && date_string.indexOf("/") > -1) {
        //split
        var parts = date_string.split('/');
        //console.log("splitting");
        //console.log(parts);
        myDate = new Date(parts[2] * 1, parts[0] - 1, parts[1]);
        //console.log(myDate);
    }
    return myDate;
}
function getMaximumStartDate(returnAsString?): any {
    returnAsString = returnAsString || false;
    //get max start date
    var maxStartDate = new Date();
    maxStartDate.setHours(0, 0, 0, 0);
    maxStartDate.setMonth(maxStartDate.getMonth() + 6 + 1, 0); //maximumStartBookMonths = 6
    if (returnAsString) {
        return getDateStringFromDate(maxStartDate);
    }
    return maxStartDate;
}
function getMinimumEndDate(startDate, returnAsString?): any {
    returnAsString = returnAsString || false;
    //get min end date
    var minEndDate = new Date(startDate);
    minEndDate.setHours(0, 0, 0, 0);
    minEndDate.setDate(minEndDate.getDate() + 30);
    // minEndDate = minEndDate.addDays(7); //minimumStartBookDays = 7
    if (returnAsString) {
        return getDateStringFromDate(minEndDate);
    }
    return minEndDate;
}
function getMaximumEndDate(startDate, returnAsString?): any {
    returnAsString = returnAsString || false;
    //get max start date
    var newDate = new Date(startDate);
    newDate.setHours(0, 0, 0, 0);
    newDate.setMonth(newDate.getMonth() + 15 + 1, 0); //maximumEndBookMonths = 15;
    var maxEndDate = new Date(newDate);
    if (returnAsString) {
        return getDateStringFromDate(maxEndDate);
    }
    return maxEndDate;
}
//determine to set start or end on select
function setDateRangeDates(selfRangeObject, date, bookingDateValidation?) {
    bookingDateValidation = bookingDateValidation || false;
    var retvalArray = [];
    var chosenStartDateVal = selfRangeObject.getStartValue();
    var chosenEndDateVal = selfRangeObject.getEndValue();
    var maxStartDate = getMaximumStartDate();
    //console.log("chosenStartDateVal + end", { chosenStartDateVal: chosenStartDateVal, chosenEndDateVal: chosenEndDateVal, date: date});

    //only override default start / end under specific circumstances
    if (date !== null) {
        // date = new Date(date);
        if (chosenStartDateVal !== "") {
            var chosenStartDate = new Date(getDateFromString(chosenStartDateVal));
            var minDate = new Date(getMinimumEndDate(chosenStartDate));
            var maxDate = new Date(getMaximumEndDate(chosenStartDate));
            if (chosenEndDateVal !== "") {
                if (selfRangeObject.getUpdateMode() === "endDate") {
                    if (date.getTime() > chosenStartDate.getTime() && date.getTime() < minDate.getTime()) {
                        retvalArray = [getDateStringFromDate(date), ""];
                    }
                }
            }

            if (chosenEndDateVal !== "") {
                var chosenEndDate = getDateFromString(chosenEndDateVal);
                if (selfRangeObject.getUpdateMode() === "startDate") {
                    if (date.getTime() > maxStartDate.getTime()) {
                        //start date is invalid, too late, update end date instead if possible
                        if (date.getTime() <= maxDate.getTime()) {
                            retvalArray = [chosenStartDateVal, getDateStringFromDate(date)];
                        }
                    }
                    else {
                        //so far, it is a valid start date click.
                        if (bookingDateValidation) {
                            var string = $.datepicker.formatDate('mm-dd-yy', date);
                            var dayOfWeek = date.getUTCDay();
                            // var validDay = this.property.calcExcludedDates.indexOf(string) === -1;
                            // if (validDay) {
                            //   validDay = this.property.calcDaysClosed.indexOf(dayOfWeek) === -1;
                            // }
                            // if (!validDay) {
                            //   //NOT VALID START, but cant just set end

                            // }
                        }
                    }
                }
            }
        }
    }
    //console.log("custom set: " + retvalArray);
    return retvalArray;
}
//takes in any date, compares against min max rules
//rules for all daterange pickers, does not contain specific rules for booking date range picker
function enforceDisabledDates(selfRangeObject, date, returnSimpleBool, allowStartForInvalidEnd) {
    returnSimpleBool = returnSimpleBool || false;
    allowStartForInvalidEnd = allowStartForInvalidEnd || false;
    var validDay = true;
    var chosenStartDateVal = selfRangeObject.getStartValue();
    var chosenEndDateVal = selfRangeObject.getEndValue();
    var maxStartDate = getMaximumStartDate();
    if (date !== null) {
        //console.log("chosenStartDateVal", chosenStartDateVal);
        if (chosenStartDateVal !== "") {
            var chosenStartDate = getDateFromString(chosenStartDateVal);
            var minDate = new Date(getMinimumEndDate(chosenStartDate));
            var maxDate = getMaximumEndDate(chosenStartDate);
            //user has chosen, enforce min end and max end rules per grg's ruleset.
            if (chosenEndDateVal === null || chosenEndDateVal === "") {
                //has start, no end
                if (date.getTime() !== chosenStartDate.getTime() && (date.getTime() < minDate.getTime() || date.getTime() > maxDate.getTime())) {
                    validDay = false;
                }
                if (date.getTime() === chosenStartDate.getTime()) {
                    validDay = false;
                }
            } else {
                //both selected, only restrict min date, as next selection will change end date if not before start date
                if (date.getTime() > maxDate.getTime()) {
                    //restrict max enddates
                    validDay = false;
                }
                //now restrict min end date
                if (!allowStartForInvalidEnd) {
                    if (date.getTime() > chosenStartDate.getTime() && date.getTime() < minDate.getTime()) {
                        validDay = false;
                    }
                } else {
                    //allow click of invalid end date that is too soon, will make start date instead
                    if (date.getTime() > chosenStartDate.getTime() && date.getTime() < minDate.getTime()) {
                        //validDay = false;
                    }
                }


                //both selected? still restrict start date selection afterwards
                /*var chosenEndDate = window.GRGExtensions.getDateFromString(chosenEndDateVal);
                if (date.getTime() > maxStartDate.getTime() && date.getTime() > chosenEndDate.getTime()) {
                    validDay = false;
                }
                //what about those dates between selections?
                //make dates between start and end disabled
                if (date.getTime() > maxStartDate.getTime() && date.getTime() <= chosenEndDate.getTime()) {
                    validDay = false;
                }*/

                //do restrict end date mode from selecting a date before start
                if (selfRangeObject.getUpdateMode() === "endDate" && date.getTime() < chosenStartDate.getTime()) {
                    validDay = false;
                }
            }
        }
        else {
            //get max start date
            if (date.getTime() > maxStartDate.getTime()) {
                validDay = false;
            }
        }
    }

    if (!returnSimpleBool) {
        return [validDay];
    }
    else {
        return validDay;
    }
}
function validateDatesTextEntry(selfRangeObject, startDate, endDate) {
    var validDay = true;
    var chosenStartDateVal = selfRangeObject.getStartValue();
    var chosenEndDateVal = selfRangeObject.getEndValue();
    var minStartDate = getMinimumStartDate();
    var maxStartDate = getMaximumStartDate();


    if (startDate !== null) {
        //start date validation
        if (startDate.getTime() < minStartDate.getTime() || startDate.getTime() > maxStartDate.getTime()) {
            validDay = false;
        }
    }
    else if (endDate !== null) {
        //end date validation
        if (chosenStartDateVal !== "") {
            var chosenStartDate = getDateFromString(chosenStartDateVal);
            var minEndDate = new Date(getMinimumEndDate(chosenStartDate));
            var maxEndDate = getMaximumEndDate(chosenStartDate);

            if (endDate.getTime() <= chosenStartDate.getTime() || endDate.getTime() > maxEndDate.getTime() || endDate.getTime() < minEndDate.getTime()) {
                validDay = false;
            }

        }
    }
    //console.log("valid day is " + validDay + "startDate" + startDate + "endDate " + endDate);

    return validDay;
}

function setDateTimePickerYearAriaLabel() {
    setTimeout(function () {
        $("select.ui-datepicker-year").attr("aria-label", "Select Year");
    }, 10);
}
