import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UserService } from 'app/services/user.service';
import { NavSidebarService } from 'app/services/nav-sidebar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/services/api.service';
import { NGXLogger } from 'ngx-logger';
import { BaseComponent } from 'app/common/base/base.component';
import { GenerateResetPasswordTokenModel, ResetPasswordModel } from 'app/models/EmployeesModel';
import Utils from 'app/common/Utils';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-forgor-password',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})

export class ForgotPasswordComponent extends BaseComponent implements OnInit {

  SendReqLinkFormGroup: FormGroup
  ResetPasswordFormGroup: FormGroup
  ResetPasswordTokenModel: GenerateResetPasswordTokenModel
  ResetPassword : ResetPasswordModel
  displayHints = "none"
  IsPasswordInvalid: boolean
  doPswdsMatch: boolean

  steps = {
    step1: true,
    step2: false,
    step3: false,
    step4: false
  }
  hints = {
    hintstep1: false,
    hintstep2: false,
    hintstep3: false,
    hintstep4: false,
    hintstep5: false,
  }

  isTokenInvalid = false;
  @Input() isModal = false;

  constructor(private fb: FormBuilder,
    private apiService: ApiService,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private activeModal: NgbActiveModal) {
    super(logger);
    this.InitForms();


  }

  ngOnInit() {

    // Check to see it the token is in the params, if it is it is the third step and the user needs to reset their password
    this.route.queryParams.subscribe(params => {
      if (params['token']){
        this.steps = {
          step1: false,
          step2: false,
          step3: true,
          step4: false
        }
        // initialize the reset password model
        this.ResetPassword = {
          password: '',
          confirmPassword: '',
          token: params['token']
        }
      }
    })

  }

  PasswordEventHandler(value: string): void {

    if (value.length >= 8) {
      this.hints.hintstep1 = true
    }
    else {
      this.hints.hintstep1 = false
    }
    if ((/[A-Z]/.test(value))) {
      this.hints.hintstep2 = true
    }
    else {
      this.hints.hintstep2 = false
    }
    if ((/[a-z]/.test(value))) {
      this.hints.hintstep3 = true
    }
    else {
      this.hints.hintstep3 = false
    }
    if ((/\d/.test(value))) {
      this.hints.hintstep4 = true
    }
    else {
      this.hints.hintstep4 = false
    }
    if ((/[!@#$%^&*+~-]/.test(value))) {
      this.hints.hintstep5 = true
    }
    else {
      this.hints.hintstep5 = false
    }

    this.IsPasswordInvalid = this.isPasswordInvalid(value)

  }

  isPasswordInvalid(controlName: string): boolean {
    var hint = this.hints

    if (controlName == "createPassword2") {
      if (this.ResetPasswordFormGroup.get(controlName).value != this.ResetPasswordFormGroup.get("createPassword").value) {
        this.doPswdsMatch = false
        return true
      }
      else {
        this.doPswdsMatch = true
        return false
      }
    }
    else if (!hint.hintstep1 || !hint.hintstep2 || !hint.hintstep3 || !hint.hintstep4 || !hint.hintstep5) {
      return true
    }


    return false
  }

  onFocusPswdEvent(event: any) {
    this.displayHints = "block"
  }
  onFocusOutPswdEvent(event: any) {
    this.displayHints = "none"
  }

  isInvalid(controlName: string): boolean {
    const control = this.SendReqLinkFormGroup.get(controlName);

    // if we can't find the control for whatever reason...return false
    if (!control) {
      this.logger.warn('Control not found...FYI', controlName);
      return false;
    }

    const isInvalid = control.invalid && (control.dirty || control.touched);

    return isInvalid;
  }

  InitForms(): void {
    this.SendReqLinkFormGroup = this.fb.group({
      email: [{ value: '', disabled: false }, [Validators.required]]
    })

    this.ResetPasswordFormGroup = this.fb.group({

      createPassword: [{ value: '', disabled: false }, [Validators.required]],
      createPassword2: [{ value: '', disabled: false }, [Validators.required]]
    })

    this.ResetPasswordTokenModel = {
      email: '',
    }

  }

  isFormValid(form: FormGroup): boolean {
    Utils.touchFormFields(form);
    return form.valid;
  }

  // Step 1 user clicks on "request reset link" to request token and send confirmation email if one exists
  ReqResetLink(): void {

    if (this.isFormValid(this.SendReqLinkFormGroup)) {
      this.ResetPasswordTokenModel.email = this.SendReqLinkFormGroup.get('email').value;

      if (this.ResetPasswordTokenModel.email != '') {
        //make the api call to send reset email and get authoriztion token back
        this.apiService.sendPasswordRestEmail(this.ResetPasswordTokenModel).subscribe(res => {
          // if call was successfull and there are no error messages we should get a token back which we will use to reset the password
          // also if successfull activate part 2 of the step process 
          this.steps = {
            step1: false,
            step2: true,
            step3: false,
            step4: false,
          }
        },
          error => {

          })
      }
    }
  }

  // once we get the verification token from request and user clicks the reset password link we can make the request to reset the password
  ResetPasswordSubmit(): void {

    this.ResetPassword.password = this.ResetPasswordFormGroup.get('createPassword').value
    this.ResetPassword.confirmPassword = this.ResetPasswordFormGroup.get('createPassword2').value

    if (this.isFormValid(this.ResetPasswordFormGroup)){
      this.apiService.resetPasswordConfirmation(this.ResetPassword).subscribe(res => {

        this.steps = {
          step1: false,
          step2: false,
          step3: false,
          step4: true,
        }
  
      },
      error => {
        console.log("Something went wrong")
      });
    }
    

  }

  openSignInModal() {
    this.activeModal.close('signin');
  }
}