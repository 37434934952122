import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Utils from 'app/common/Utils';
import { PropertyDetailModel } from 'app/models/PropertyDetailModels';
import { ApiService } from 'app/services/api.service';
import { UserService } from 'app/services/user.service';

declare var google: any;
declare var $: any;

@Component({
  selector: 'app-favorite-properties',
  templateUrl: './favorite-properties.component.html',
  styleUrls: ['./favorite-properties.component.scss']
})
export class FavoritePropertiesComponent implements OnInit {
  results: PropertyDetailModel[] = [];
  likedProperties: string[];
  loaded = false;
  isLoading = false;
  private mapElement: ElementRef
  @ViewChild('map', { static: false }) set content(ele: ElementRef) {
    if (ele) {
      this.mapElement = ele;
      this.initGoogleMap();
    }
  };
  imgMarkerPreload1: HTMLImageElement;
  imgMarkerPreload2: HTMLImageElement;
  map: any
  infoWindow: any;
  markers = [];
  //gmap icons
  iconLeftOffset = 48;
  iconTopOffset = 44;
  iconWidth = 100;
  iconHeight = 80;
  iconWidthScaledDown = 50;
  iconHeightScaledDown = 40;
  iconFontSize = 32;

  constructor(private apiService: ApiService, private userService: UserService) { }

  ngOnInit() {
    this.getUserFavorites();
  }

  getUserFavorites() {
    this.isLoading = true;
    if (this.likedProperties == undefined) {
      this.userService.getLikedProperties().subscribe(response => {
        this.likedProperties = response;
        this.loadProperties();
      });
    } else {
      this.likedProperties = this.userService.likedProperties;
      this.loadProperties()
    }
  }

  // loadProperties() {
  //   if (this.likedProperties !== undefined && this.likedProperties.length > 0) {
  //     this.likedProperties.forEach(id => this.getPropertyDetail(id));
  //     setTimeout(() => {
  //       this.populateGoogleMap();
  //     }, 1000);
  //   } else {
  //     this.loaded = true
  //   }
  //   this.isLoading = false;
  // }


  loadProperties() {
    if (this.likedProperties !== undefined && this.likedProperties.length > 0) {
      this.likedProperties.forEach((id, index) => this.getPropertyDetail(id, index));
    } else {
      this.loaded = true
      this.isLoading = false;
    }
  }


  getPropertyDetail(id: string, index: number) {

    let startDate = new Date(new Date().getTime()+(14*24*60*60*1000));
    let endDate = new Date(new Date().getTime()+(75*24*60*60*1000));
    
    let startDatePipe = new DatePipe('en-US').transform(startDate, 'MM-dd-yyyy');
    let endDatePipe = new DatePipe('en-US').transform(endDate, 'MM-dd-yyyy');


    this.apiService.getPropertyDetails(id, null, null).subscribe((response) => {
      response.guid = id;
      response.startDate = startDatePipe;
      response.endDate = endDatePipe;
      this.results.push(response);
      this.propertiesLoaded(index);
    }, (error) => {
      this.propertiesLoaded(index);
    });
  }

  hasMultipleImages(propertyItem): boolean {
    return propertyItem.images.length > 1;
  }

  propertyUrl(propertyItem: PropertyDetailModel): string {
    let name = propertyItem.name.toLowerCase().replace(/ /gi, '-');
    return `/properties/${name}/${propertyItem.guid}`;
  }

  propertyURLQueryParams(propertyItem: PropertyDetailModel) {
    return {
      start: propertyItem.startDate.replace(/\//g, '-'),
      end: propertyItem.endDate.replace(/\//g, '-')
    }
  }

  propertiesLoaded(index: number) {

    if (index == this.likedProperties.length - 1) {
      if (!this.loaded) {
        this.loaded = true;
        this.isLoading = false;
      }

      setTimeout(() => {

        var $propertiesListDom = $("#property-card");
        //$propertiesListDom.find(".images").
        //$propertiesListDom.imagesLoaded(function () {
        //BindSizing();
        $propertiesListDom.each(function () {
          var $thisProperty = $(this);
          $thisProperty.find(".images-section").each(function () {
            var $thisSection = $(this);
            var $thisSlider = $thisSection.children(".image-slider");
            if (($thisSlider).hasClass("slick-initialized")) {
              $thisSlider.slick("unslick");
            }
            $thisSection.children(".image-slider").remove();

            //generate new slider dom, bind
            var $imageSource = $thisSection.find(".images.image-slider-source");
            if ($imageSource.length > 0) {
              $thisSection.append("<div class=\"image-slider\">" + $imageSource.clone().html() + "</div>");
              //$thisSection.append("<div class=\"image-slider\">" + "<div>my bubbles</div><div>my bubbles2</div>" + "</div>");
              $thisSlider = $thisSection.children(".image-slider");
              $thisSlider.slick({
                dots: false,
                autoplay: false,
                arrows: true,
                autoplaySpeed: 2000,
                speed: 800,
                slidesToShow: 1,
                slidesToScroll: 1
              });
            }
          });
        });

        $propertiesListDom.find(".name").matchHeight();
        $propertiesListDom.find(".address").matchHeight();
        $propertiesListDom.find(".images-section").css("height", "");

        // wait till imaegs loaded
        $propertiesListDom.imagesLoaded(function () {
          $propertiesListDom.find(".images-section").matchHeight();
        });
        $propertiesListDom.find(".scaled-image").Lazy({
          bind: "event",
          onFinishedAll: function () {
            $propertiesListDom.find(".images-section").matchHeight();
          }
        });

        this.populateGoogleMap();
      }, 500);
    }
  }

  //#region Google Maps
  initGoogleMap() {
    this.imgMarkerPreload1 = new Image();
    this.imgMarkerPreload2 = new Image();
    this.imgMarkerPreload1.src = "/assets/img/FavPin.png";
    this.imgMarkerPreload2.src = "/assets/img/FavPinHover.png";

    let latLon: [number, number];
    if (this.results !== undefined && this.results.length > 0) {
      latLon = new google.maps.LatLng(this.results[0].address.latitude, this.results[0].address.longitude);
    } else {
      latLon = new google.maps.LatLng(34.4385368, -117.7790122); //set default? center of america?
    }

    let mapOptions = {
      zoom: 7, //initMapZoom
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      center: latLon,
      streetViewControl: false,
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement as HTMLElement, mapOptions);
    this.map.initialZoom = true;
    if (this.results.length > 0) {
      setTimeout(() => {
        this.populateGoogleMap();
      }, 300)
    }
  }
  populateGoogleMap() {
    let self = this;
    let bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.infoWindow = new google.maps.InfoWindow();
    google.maps.event.addListener(this.infoWindow, 'closeclick', function () {
      //close others
      for (var j = 0; j < self.markers.length; j++) {
        var myIconSrc = Utils.createMarker(self.iconWidth, self.iconHeight, "", self.imgMarkerPreload1, "bold " + self.iconFontSize + "px Hind", [self.iconLeftOffset, self.iconTopOffset]);
      }
    });
    google.maps.event.addListener(self.infoWindow, 'domready', function () {
      // Reference to the DIV which receives the contents of the infowindow using jQuery
      var $iwOuter = $('.gm-style-iw');
      $iwOuter.css("top", "1px").css("left", "1px");
    });
    for (var i = 0; i < this.results.length; i++) {
      var latlng = new google.maps.LatLng(this.results[i].address.latitude, this.results[i].address.longitude);
      var myIconSrc = Utils.createMarker(this.iconWidth, this.iconHeight, "", this.imgMarkerPreload1, "bold " + this.iconFontSize + "px Hind", [this.iconLeftOffset, this.iconTopOffset]);
      var myIcon = new google.maps.MarkerImage(myIconSrc, null, null, null, new google.maps.Size(this.iconWidthScaledDown, this.iconHeightScaledDown));
      this.markers[i] = new google.maps.Marker({
        map: this.map,
        position: latlng,
        title: this.results[i].name,
        icon: myIcon
      });
      var imageThumbnail = "";
      if (this.results[i].images != null && this.results[i].images.length > 0) {
        imageThumbnail = this.results[i].images[0].url;
      }
      var addressLine = this.results[i].address.address1 + " " + this.results[i].address.address2 + "<br />" + this.results[i].address.city + ", " + this.results[i].address.state + " " + this.results[i].address.zip;
      var imgItem = "<div class=\"no-image\"></div>";
      if (imageThumbnail !== "") {
        imgItem = "<div class=\"image-col\"><div class=\"img\" style=\"background-image: url('" + imageThumbnail + "');\"><a class=\"img-cell-link\" href=\"" + this.propertyUrl(this.results[i]) + "\"></a></div>" + "</div>";
      }
      this.markers[i]._content = "<div class=\"infowindow-content\">" + imgItem +
        "<div class=\"content-col\">" + "<div class=\"units\" style=\"display:none\">" + this.results[i].availableUnits + " Units Available</div>" +
        "<a href=\"" + this.propertyUrl(this.results[i]) + "\"><span class=\"name\">" + this.results[i].name + "</span>" + "<span class=\"address\">" + "<span class=\"map-icon\"><svg><use xlink:href=\"#map-point-reuse\" class=\"map-point-reuse\" height=\"14\" width=\"10\"></use></svg></span>" + "<span class=\"address-value\">" + addressLine + "</span></span></a>" + "</div></div>";
      // this.markers[i]._price = this.results[i].pricePerNight;
      this.markers[i]._id = this.results[i].id;

      this.markers[i].addListener('click', function (e) {
        //close others
        for (var j = 0; j < self.markers.length; j++) {
          var myIconOtherSrc = Utils.createMarker(self.iconWidth, self.iconHeight, "", self.imgMarkerPreload1, "bold " + self.iconFontSize + "px Hind", [self.iconLeftOffset, self.iconTopOffset]);
          var myIconOther = new google.maps.MarkerImage(myIconOtherSrc, null, null, null, new google.maps.Size(self.iconWidthScaledDown, self.iconHeightScaledDown));
          self.markers[j].setIcon(myIconOther);
        }
        var iconSrc = Utils.createMarker(self.iconWidth, self.iconHeight, "", self.imgMarkerPreload2, "bold " + self.iconFontSize + "px Hind", [self.iconLeftOffset, self.iconTopOffset]);
        var myIconActive = new google.maps.MarkerImage(iconSrc, null, null, null, new google.maps.Size(self.iconWidthScaledDown, self.iconHeightScaledDown));
        this.setIcon(myIconActive);

        self.infoWindow.setContent(this._content);
        self.infoWindow.open(self.map, this);
      });
      bounds.extend(self.markers[i].position);
      window.onresize = function () {
        self.infoWindow.close();
      };
    }
  }
  //#endregion
}
