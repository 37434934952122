import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpResponseBase, HttpHeaders } from '@angular/common/http';
import { LoadingModalComponent } from "./loading-modal/loading-modal.component";
import { UtilityLogModel } from "../models/UtilityLogModel";
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError, of } from 'rxjs';
import { catchError, switchMap, map, delay, filter, take, finalize } from 'rxjs/operators';
import { ImportDataService } from 'app/services/import-data.service';

import { ApiService } from 'app/services/api.service';
import { UrlService } from 'app/services/url.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal, NgbActiveModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalBackdrop } from '@ng-bootstrap/ng-bootstrap/modal/modal-backdrop';
import { UserService } from 'app/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { ERRORS_ONHOLD, ERRORS_NOTACTIVE, ROUTE_PORTAL_HOME } from 'app/common/Utils';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _refreshSubject: Subject<any> = new Subject();
  private _timer: NodeJS.Timer;
  private modal: NgbModalRef;
  private refreshingToken = false;

  constructor(
    private importedDataService: ImportDataService,
    private apiService: ApiService,
    private userService: UserService,
    private modalService: NgbModal,
    private logger: NGXLogger,
    private router: Router) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.logger.log('intercepting request');
    // this.logger.log('checking req url', req.url.toLowerCase(), req.url.toLowerCase().includes(this.apiService.API_URL))
    if (req.url.toLowerCase().includes(this.apiService.API_URL)) {
      req = this.updateHeader(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          if (this.refreshingToken) {
            return this._refreshSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.updateHeader(req)))
            );
          } else {
            this.refreshingToken = true;
            this._refreshSubject.next(null);
            return this.refreshAccessToken().pipe(
              switchMap((success: boolean) => {
                this._refreshSubject.next(success);
                return next.handle(this.updateHeader(req));
              }),
              finalize(() => this.refreshingToken = false)
            );
          }
        } else {
          return throwError(error);
        }
      })
    );


    // return next.handle(req).pipe(
    //   catchError((error, caught) => {
    //     // if they are a HTTP response error
    //     if (error instanceof HttpErrorResponse) {
    //       if (error.status === 401) {
    //         // only allow tokens that are currently expired
    //         if (!this._isCurrentTokenValid()) {
    //           // as long as they are an expired token error (HTTP 401)
    //           if (this._checkTokenExpiryErr(error)) {
    //             return this._getRefreshTokenAndUpdate().pipe(
    //               switchMap((x) => {
    //                 return next.handle(this.updateHeader(req));
    //               })
    //             );
    //           } else {
    //             return throwError(error);
    //           }
    //         } else {
    //           // they are a valid token but are attempting to access unauthorized content
    //           // const model: UtilityLogModel = {
    //           //   message: 'User is unauthorized to get content...req: {0}',
    //           //   object: [req]
    //           // };
    //           // we dont need to log this error explicitly since the thrown error will trigger an error to bubble up
    //           // this.logger.error(model);
    //           // return throwError(error);

    //           if (this._checkTokenExpiryErr(error)) {
    //             this.logger.debug('user attempted to access unathorized content..redirecting to the homepage');
    //             this.router.navigate([ROUTE_PORTAL_HOME], {
    //               state: {
    //                 error: 'You are not authorized to access that content.'
    //               }
    //             });
    //             return of(null);
    //           } else {
    //             return throwError(error);
    //           }
    //         }
    //       } else {
    //         this.logger.error(error);
    //       }
    //     }
    //     return caught;
    //   })
    // );
  }


  private refreshAccessToken() {
    this.logger.info('auth-interceptor: _getRefreshTokenAndUpdate');
    return this.apiService.getRefreshToken(this.userService.getRefreshTokenStorage()).pipe(
      map((x) => {
        if (x) {
          this.userService.setRefreshTokenStorage(x.refreshTokenGuid)
          this.userService.setTokenStorage(x.token);
        } else {
          this.importedDataService.logout(true);
        }
        return x;
      })
    );
  }

  private _ifTokenExpired() {
    this.logger.info('auth-interceptor: _ifTokenExpired');
    // subscribe to subject to reinstantiate on completion to clean up any old subjects
    // this._refreshSubject.subscribe({
    //   complete: () => {
    //     this._refreshSubject = new Subject<any>();
    //   }
    // });


    // supports parallel requests - so only regenerate the refresh token on the first request (when length is 1)
    if (this._refreshSubject.observers.length === 1) {

      // get our refresh token and close the modal when that's done if one is displaying
      this._getRefreshTokenAndUpdate().pipe(map((res) => { // delay(4000) delay was set manually before to test the modal for token refresh
        // clearTimeout(this._timer);
        // if (this.modalService.hasOpenModals() && this.modal) {
        //   this.modal.dismiss();
        // }
      })).subscribe(this._refreshSubject);
    }

    return this._refreshSubject;
  }

  private _getRefreshTokenAndUpdate() {
    this.logger.info('auth-interceptor: _getRefreshTokenAndUpdate');
    return this.apiService.getRefreshToken(this.userService.getRefreshTokenStorage()).pipe(
      map((x) => {
        if (x) {
          this.userService.setRefreshTokenStorage(x.refreshTokenGuid)
          this.userService.setTokenStorage(x.token);
        } else {
          this.importedDataService.logout(true);
        }
        return x;
      })
    );
  }

  private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {

    return (
      error.status &&
      error.status === 401
    );
  }

  private _hasOtherApiError(error: HttpErrorResponse): boolean {

    // we have a valid error so lets dive a little deeper..
    // initially these couple of errors mean we just need to redirect the user to the public site
    if (error.status && error.status === 400 && error.error) {
      if (error.error.includes(ERRORS_ONHOLD) ||
        error.error.includes(ERRORS_NOTACTIVE)) {
        this.userService.redirectToPublicSite(`API returned not allowed error..${error.error}`);
        return true;
      }
    }
    return false;
  }

  private _isCurrentTokenValid(): boolean {
    if (!this.importedDataService || !this.importedDataService.importedData || !this.importedDataService.importedData.authToken) {
      return false;
    }
    return new JwtHelperService().isTokenExpired(this.importedDataService.importedData.authToken) === false;
  }

  updateHeader(req: HttpRequest<any>) {
    const authToken = this.userService.getTokenStorage()
    if (authToken) {
      // Clone the request and set the new header in one step.
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`)
      });
    }
    return req;
  }

}
