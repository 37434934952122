import { Injectable, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

export interface GtmTransactionProduct {
  'sku': string;
  'name': string;
  'category': 'Rent' | 'Fee';
  'price': number;
  'quantity': number;
}

@Injectable({
  providedIn: 'root'
})
export class TagManagerService {

  // private mems
  private _dataLayer;

  /*

  Example of using the service to push a gtm event would look something like this:
    - User opens a modal, so on open of that modal you could do something like
      this.tagManagerService.pushMainEvent(this.tagManagerService.GTM_EVENT_MODAL_OPENED_ADDUSER, 'opening add user modal');

    - User submits a form and you have to push an event and a variable could look something like
      var model = {
        [this.tagManagerService.GTM_VAR_USERID]: <dynamic user id from submitted form user>
      };
      this.tagManagerSersvice.pushVariablesAndMainEvent(model, this.tagManagerService.GTM_EVENT_FORM_SUBMITTED_ADDUSER, 'submitting add user form');
      
  */

  constructor(private logger: NGXLogger) {
    this._dataLayer = (<any>window).dataLayer;
    // this.logger.debug('tag manager is live', this._dataLayer);
  }

  // variable mappings
  public GTM_VAR_ERROR = 'error';
  public GTM_VAR_EVENT = 'event';
  public GTM_VAR_EVENT_ACTION = 'eventAction';
  public GTM_VAR_EVENT_LOCATION = 'eventLocation';

  public GTM_VAR_USER_ID = 'userId';
  public GTM_VAR_USER_ROLE = 'userRole';
  public GTM_VAR_USER_TYPE = 'userType';
  public GTM_VAR_USER_LOGGED_IN = 'userLoggedIn';
  public GTM_VAR_POPUP_TYPE = 'popupType';
  public GTM_VAR_CREDIT_STATUS = 'creditStatus';
  public GTM_VAR_BACKGROUND_STATUS = 'backgroundStatus';
  public GTM_VAR_IDENTITY_STATUS = 'identityStatus';
  public GTM_VAR_COMPANY_ROLE = 'companyRole';

  // event mappings
  public GTM_EVENT_PAGEVIEW = 'gaPageView';
  public GTM_EVENT_POPUP = 'popup';
  public GTM_EVENT_CREDIT_STATUS = 'creditCheckStatus';
  public GTM_EVENT_BACKGROUND_STATUS = 'backgroundCheckStatus';
  public GTM_EVENT_IDENTITY_STATUS = 'identityCheckStatus';
  public GTM_EVENT_TENANT_ADD = 'addTenant';
  public GTM_EVENT_TENANT_REMOVE = 'removeTenant';
  public GTM_EVENT_PET_ADD = 'addPet';
  public GTM_EVENT_PET_REMOVE = 'removePet';
  public GTM_EVENT_BOOKING_CONFIRMED = 'bookingConfirmed';
  public GTM_EVENT_BOOKING_FINALIZED = 'bookingProcessed';
  public GTM_EVENT_ADD_COMPANY_PERSON = 'addCompanyPerson';

  // ecommerce variables
  public GTM_ECOMMERCE_TRANSACTION_ID = 'transactionId';
  public GTM_ECOMMERCE_TRANSACTION_TOTAL = 'transactionTotal';
  public GTM_ECOMMERCE_TRANSACTION_TAX = 'transactionTax';
  public GTM_ECOMMERCE_TRANSACTION_PRODUCTS = 'transactionProducts';

  // useful if we need to inform gtm about a different view / page where the window.history state is not being affected
  generateNewPageView(route, customErrorMsg = '') {

    var src = 'generateNewPageView';
    this.logger.debug(`generating new page view: ${route}`);
    if (this._isRoutePresent(route, src)) {
      const model = {
        [this.GTM_EVENT_PAGEVIEW]: route
      };
      this._pushToDataLayer(model, src, customErrorMsg);
    }
  }

  public onPageLoad(userId, userRole, userType, userLoggedIn, customErrorMsg = '') {
    if (!userId) {
      return;
    }

    const model = {
      [this.GTM_VAR_USER_ID]: userId,
      [this.GTM_VAR_USER_ROLE]: userRole,
      [this.GTM_VAR_USER_TYPE]: userType,
      [this.GTM_VAR_USER_LOGGED_IN]: userLoggedIn,
      [this.GTM_VAR_EVENT]: this.GTM_EVENT_PAGEVIEW
    };

    this._pushToDataLayer(model, 'onPageLoad', customErrorMsg);
  }

  public pushBookingWizardTourAction(eventAction: 'open' | 'close' | 'skip' | 'start') {
    var model = {
      [this.GTM_VAR_POPUP_TYPE]: 'bookingTour',
      [this.GTM_VAR_EVENT_ACTION]: eventAction,
      [this.GTM_VAR_EVENT]: this.GTM_EVENT_POPUP
    };

    // on tour open, add eventLocation variable
    if (eventAction === 'open') {
      model[this.GTM_VAR_EVENT_LOCATION] = 'bookingWizard';
    }

    this._pushToDataLayer(model, 'pushBookingWizardTourAction', `booking wizard tour action: ${eventAction}`);
  }

  public pushIdentityCheckStatus(identityStatus: 'approved' | 'denied') {
    var model = {
      [this.GTM_VAR_IDENTITY_STATUS]: identityStatus,
      [this.GTM_VAR_EVENT]: this.GTM_EVENT_IDENTITY_STATUS
    };

    this._pushToDataLayer(model, 'pushIdentityCheckStatus', `identity check status: ${identityStatus}`);
  }

  public pushBookingWizardCreditCheckStatus(creditStatus: 'approved' | 'denied' | 'inReview') {
    var model = {
      [this.GTM_VAR_CREDIT_STATUS]: creditStatus,
      [this.GTM_VAR_EVENT]: this.GTM_EVENT_CREDIT_STATUS
    };

    this._pushToDataLayer(model, 'pushBookingWizardCreditCheckStatus', `booking wizard credit check status: ${creditStatus}`);
  }

  public pushBackgroundCheckStatus(backgroundStatus: 'approved' | 'denied' | 'inReview') {
    var model = {
      [this.GTM_VAR_BACKGROUND_STATUS]: backgroundStatus,
      [this.GTM_VAR_EVENT]: this.GTM_EVENT_BACKGROUND_STATUS
    };

    this._pushToDataLayer(model, 'pushBookingWizardBackgroundCheckStatus', `booking wizard background check status: ${backgroundStatus}`);
  }

  public pushBookingWizardTenantAction(addedTenant: boolean) {
    var model = {
      [this.GTM_VAR_EVENT]: addedTenant ? this.GTM_EVENT_TENANT_ADD : this.GTM_EVENT_TENANT_REMOVE
    };

    this._pushToDataLayer(model, 'pushBookingWizardTenantAction', `booking wizard tenant action: ${addedTenant ? this.GTM_EVENT_TENANT_ADD : this.GTM_EVENT_TENANT_REMOVE}`);
  }

  public pushBookingWizardBookingConfirmation() {
    var model = {
      [this.GTM_VAR_EVENT]: this.GTM_EVENT_BOOKING_CONFIRMED
    };

    this._pushToDataLayer(model, 'pushBookingWizardBookingConfirmation', `booking wizard booking confirmation`);
  }

  public pushNewCompanyUser(roleName: string) {
    var model = {
      [this.GTM_VAR_COMPANY_ROLE]: roleName,
      [this.GTM_VAR_EVENT]: this.GTM_EVENT_ADD_COMPANY_PERSON
    };

    this._pushToDataLayer(model, 'pushNewCompanyUser', `add new company user`);
  }

  public pushEcommerceTransactionForCompletedBooking(reservationGuid: string, totalCost: number, totalTax: number, applicationFeeTotal: number, petFeeTotal: number, firstMonthRent: number, firstMonthPetRent: number) {
    var applicationFeeProduct: GtmTransactionProduct = {
      'sku': 'ApplicationFee',
      'name': 'ApplicationFee',
      'category': 'Fee',
      'price': applicationFeeTotal,
      'quantity': 1
    };
    var petFeeProduct: GtmTransactionProduct = {
      'sku': 'PetFee',
      'name': 'PetFee',
      'category': 'Fee',
      'price': petFeeTotal,
      'quantity': 1
    };
    var firstMonthRentProduct: GtmTransactionProduct = {
      'sku': 'FirstMonthRent',
      'name': 'FirstMonthRent',
      'category': 'Rent',
      'price': firstMonthRent,
      'quantity': 1
    };
    var firstMonthPetRentProduct: GtmTransactionProduct = {
      'sku': 'FirstMonthPetRent',
      'name': 'FirstMonthPetRent',
      'category': 'Rent',
      'price': firstMonthPetRent,
      'quantity': 1
    };
    var model = {
      [this.GTM_VAR_EVENT]: this.GTM_EVENT_BOOKING_FINALIZED,
      [this.GTM_ECOMMERCE_TRANSACTION_ID]: reservationGuid,
      [this.GTM_ECOMMERCE_TRANSACTION_TOTAL]: totalCost,
      [this.GTM_ECOMMERCE_TRANSACTION_TAX]: totalTax,
      [this.GTM_ECOMMERCE_TRANSACTION_PRODUCTS]: [
        applicationFeeProduct,
        petFeeProduct,
        firstMonthRentProduct,
        firstMonthPetRentProduct
      ]
    };

    this._pushToDataLayer(model, 'pushEcommerceTransactionForCompletedBooking', 'push ecommerce transaction when booking completed');
  }

  public pushBookingWizardPetAction(addedPet: boolean) {
    var model = {
      [this.GTM_VAR_EVENT]: addedPet ? this.GTM_EVENT_PET_ADD : this.GTM_EVENT_PET_REMOVE
    };

    this._pushToDataLayer(model, 'pushBookingWizardPetAction', `booking wizard pet action: ${addedPet ? this.GTM_EVENT_PET_ADD : this.GTM_EVENT_PET_REMOVE}`);
  }

  // for pushing gtm objects that contain an event with 1 or more variables
  pushVariablesAndMainEvent(gtmObject, eventName, customErrorMsg = '') {
    var src = 'pushVariablesAndEvent';
    this.logger.debug(`pushing variables and event: ${eventName}`, gtmObject);
    if (this._isEventPresent(eventName, src)) {
      gtmObject[this.GTM_VAR_EVENT] = eventName;
      this._pushToDataLayer(gtmObject, src, customErrorMsg)
    }
  }

  // for pushing a tracked gtm event
  pushMainEvent(eventName, customErrorMsg = '') {
    var src = 'pushEvent';
    this.logger.debug(`pushing event ${eventName}`);
    if (this._isEventPresent(eventName, src)) {
      var model = {
        [this.GTM_VAR_EVENT]: eventName
      };
      this._pushToDataLayer(model, src, customErrorMsg);
    }
  }

  // for pushing gtm objects, these can be however many variables or events that are pushed all at once
  // i'm not fond of having a generalized push wrapper but this will be more extensible and useful 
  // when working with multiple events or events that don't utilize the 'event' variable
  pushToGtm(gtmObject, customErrorMsg = '') {
    var src = 'pushToGtm';
    this.logger.debug(`pushing gtmObject`, gtmObject);
    this._dataLayer.push(gtmObject, src, customErrorMsg);
  }

  // going to allow a accessible error push for consumption for the time being..
  // this will be good if we have other non-gtm logic that we want to track errors on
  pushErrorMessage(errorMessage) {
    if (!errorMessage) {
      this.logger.warn('No error message provided to push');
      return;
    }

    try {
      this._dataLayer.push({
        [this.GTM_VAR_ERROR]: errorMessage
      });
    } catch (e) {
      this.logger.error(`Error pushing error to datalayer (${e.name} - ${e.message})`);
    }

    return;
  }

  // the main method of the service, it will do all of the final checks while utilizing a try catch to attempt error reporting
  private _pushToDataLayer(gtmObject, src, customErrorMsg = '') {
    try {
      // lets check a few things before attempting our datalayer access
      if (!gtmObject) {
        this.logger.warn('No GTM object provided to push');
        throw new Error(`No GTM object provided to push from ${src} ...${customErrorMsg}`);
      }
      if (!this._dataLayer) {
        this.logger.error('No dataLayer found!');
        return;
      }
      this._dataLayer.push(gtmObject);
    } catch (e) {
      // local logging plus attempt to upload the error to GTM
      // allow setting a custom error message if pushing a particular event that could be better than tracking a plain js error
      this.logger.error('gtm error..', e);
      if (customErrorMsg) {
        this.pushErrorMessage(customErrorMsg);
      }
      // if no custom error was pushed then we will just pass the technical error up into GTM
      this.logger.warn(`No custom error provided when pushing to dataLayer, it is recommended to provide a custom error when calling the gtm methods. caller source: ${src} -- object: ${JSON.stringify(gtmObject)}`);
      this.pushErrorMessage(`${e.name} ... ${e.message}`);
    }
  }

  // these are just helper methods to control logging statements and manage conditional flow support in one area
  private _isEventPresent(eventName, src) {
    if (!eventName) {
      this.logger.warn(`no event name provided to ${src}`);
      return false;
    }
    return true;
  }

  private _isRoutePresent(route, src) {
    if (!route) {
      this.logger.warn(`no route provided to ${src}`);
      return false;
    }
    return true;
  }

}
