import { Component, OnInit, Output, EventEmitter, Input, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/services/api.service';
import { CountryStateService } from 'app/services/country-state.service';
import { CountryStateModel, StateModel } from 'app/models/CountryStateModel';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Utils, { MONTHS_NUMBER, MONTHS_NAME, REGEX_CC_DATE, REGEX_NUMBERS, REGEX_TRANSUNION_NAME } from '../Utils';
import { CreditCardModel, creditCardCustomerModel } from 'app/models/CreditCardModel';
import { NGXLogger } from 'ngx-logger';
import { GRGSnackBarService } from '../grg-snack-bar/grg-snak-bar.service';
import { UserService } from 'app/services/user.service';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';
import { HashTable } from 'app/models/HashTable';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-credit-card',
  templateUrl: './add-credit-card.component.html',
  styleUrls: ['./add-credit-card.component.scss']
})
export class AddCreditCardComponent extends BaseComponent implements OnInit {
  addCreditCard: FormGroup;
  countryStates: CountryStateModel;
  isSubmitDisabled = false;
  months: string[] = [];
  monthnames: HashTable<string> = {};
  years: number[] = [];
  cardMonth: string;
  cardYear: string;
  debounceSub = new Subject<string>();
  cardType = '';
  isUpdateMode = false;
  isCardProviderError = false;
  isCardValidationError = false;
  cvvLength = 3;
  // creditCard: CreditCardModel;
  userCreditCard: creditCardCustomerModel;
  isCompany = false;
  showSpinner = false;

  @Input() set UserCreditCard(UserCreditCard: creditCardCustomerModel) {
    this.userCreditCard = UserCreditCard;
    if (UserCreditCard.creditCard.chargeCardAccountingId != 0) {
      this.isUpdateMode = true;
    }
    this.prefillForm();
  }

  //@Input() companyAccountingId:string;
  @Input() set IsCompany(IsCompany: boolean) { this.isCompany = IsCompany; }
  @Input() IsFirstCard = false; // if this is their first card, we will enforce default payment
  @ViewChild('MissingInfoMessage', { static: true }) private missingInfoMessageTemplate: TemplateRef<any>;
  @Output() creditCardCreated = new EventEmitter<CreditCardModel>();
  @Output() creditCardUpdated = new EventEmitter<CreditCardModel>();
  @Output() accountingIdCreated = new EventEmitter<creditCardCustomerModel>();
  @Output() navBack = new EventEmitter(); 0
  @Input() isBookingWizard = true;

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddCreditCardComponent>,
    private apiService: ApiService,
    private countryStateService: CountryStateService,
    public activeModal: NgbActiveModal,
    private logger: NGXLogger,
    private notificationService: GRGSnackBarService,
    private modalService: NgbModal,
    private userService: UserService) {
    super(logger);
    this.initForm();
  }

  ngOnInit() {
    this.logger.log('Opening Credit Card Modal..first credit card?', this.IsFirstCard);
    this.logger.log('cc modal..isUpdateMode?', this.isUpdateMode);
    this.addCreditCard.patchValue({ country: 'United States' });
    // this.addCreditCard.setValue({
    //   country: "United States",
    //   state: "California"
    // });
    this.countryStateService.countryStates.subscribe(
      (response: CountryStateModel) => {
        this.countryStates = response;
      }
    );

    this.months = MONTHS_NUMBER;
    this.monthnames = Utils.getMonthsHash();

    // get the current year + the next 20 years as options for CC expiration
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i <= (currentYear + 20); i++) {
      this.years.push(i);
    }

    this.prefillForm();

    this.debounceSub.pipe(debounceTime(50),
      distinctUntilChanged(),
      takeUntil(this.unsubscribeOnDestroy$))
      .subscribe(response => {
        this.cardType = Utils.getCardType(response);

        if (response.length > 2 && !this.cardType) {
          this.isCardProviderError = true;
          this.isCardValidationError = false;
        } else {
          this.isCardProviderError = false;
          this.isCardValidationError = false;
          // if amex, enforce a max length of 4 digits for cvv, otherwise 3 for all others
          if (this.cardType === 'American Express') {
            this.cvvLength = 4;
          } else {
            this.cvvLength = 3;
          }
        }

      });
  }

  initForm(): void {
    this.addCreditCard = this.fb.group({
      name: [{ value: '', disabled: false }, [Validators.required]],
      firstName: [{ value: '', disabled: false }, [Validators.required]],
      lastName: [{ value: '', disabled: false }, [Validators.required]],
      cardNumber: [{ value: '', disabled: false }, [Validators.required]],
      expDate: [{ value: '', disabled: false }],
      description: [{ value: '', disabled: false }, [Validators.required]],
      cvv: [{ value: '', disabled: false }],
      defaultPayment: [{ value: '', disabled: false }]
      // addressLine1: [{ value: '', disabled: false }, [Validators.required]],
      // addressLine2: [],
      // city: [{ value: '', disabled: false }, [Validators.required]],
      // state: [{ value: '', disabled: false }, [Validators.required]],
      // zipCode: [{ value: '', disabled: false }, [Validators.required]],
      // country: [{ value: 'United States', disabled: false }, [Validators.required]],
      // expirationMonth: [{ value: '', disabled: false }, [Validators.required]],
      // expirationYear: [{ value: '', disabled: false }, [Validators.required]],
    });
  }

  prefillForm(): void {
    if (!this.isUpdateMode) {
      const usePaymentProfileInformation = (this.userCreditCard && this.userCreditCard.addressLine1 && this.userCreditCard.city && this.userCreditCard.state);
      let address1, address2, city, state, postalCode, country;
      // if they have payment profile information already saved then lets load those details
      // otherwise lets pull the current users information to partially fill the form out for them
      if (usePaymentProfileInformation) {
        this.logger.debug('Prefilling credit card modal..payment profile found, loading data from that');
        address1 = this.userCreditCard.addressLine1;
        address2 = this.userCreditCard.addressLine2;
        city = this.userCreditCard.city;
        state = this.userCreditCard.state;
        postalCode = this.userCreditCard.postalCode;
        country = this.userCreditCard.country;

      } else {
        // lets also check if their billing info is not the same and prioritize that if its available
        if (this.userService.clientContact.globalContactDetails.billingIsSameAsPrimary === false) {
          this.logger.debug('Prefilling credit card modal..no payment profile found, loading data from billing info');
          address1 = this.userService.clientContact.globalContactDetails.billingStreetAddressLine1;
          address2 = this.userService.clientContact.globalContactDetails.billingStreetAddressLine2;
          city = this.userService.clientContact.globalContactDetails.billingCity;
          state = this.userService.clientContact.globalContactDetails.billingGlobalStateProvId ? this.countryStateService.statesById[this.userService.clientContact.globalContactDetails.billingGlobalStateProvId] : '';
          postalCode = this.userService.clientContact.globalContactDetails.billingPostalCode;
          country = this.userService.clientContact.globalContactDetails.billingGlobalCountryId ? this.countryStateService.countriesById[this.userService.clientContact.globalContactDetails.billingGlobalCountryId] : 'United States'; // default them to united states if no country is selected
        } else {
          this.logger.debug('Prefilling credit card modal..no payment profile found, loading data from primary info');
          address1 = this.userService.clientContact.globalContactDetails.streetAddressLine1;
          address2 = this.userService.clientContact.globalContactDetails.streetAddressLine2;
          city = this.userService.clientContact.globalContactDetails.city;
          state = this.userService.clientContact.globalContactDetails.globalStateProvId ? this.countryStateService.statesById[this.userService.clientContact.globalContactDetails.globalStateProvId] : '';
          postalCode = this.userService.clientContact.globalContactDetails.postalCode;
          country = this.userService.clientContact.globalContactDetails.globalCountryId ? this.countryStateService.countriesById[this.userService.clientContact.globalContactDetails.globalCountryId] : 'United States'; // default them to united states if no country is selected
        }
      }

      this.addCreditCard = this.fb.group({
        name: [{ value: this.userCreditCard.name, disabled: false }, [Validators.required]],
        firstName: [{ value: this.userCreditCard.firstName, disabled: false }, [Validators.required]],
        lastName: [{ value: this.userCreditCard.lastName, disabled: false }, [Validators.required]],
        cardNumber: [{ value: this.userCreditCard.creditCard.cardNumber, disabled: false }, [Validators.required, Utils.isValidCreditCard()]],
        expDate: [{ value: '', disabled: false }],
        expirationMonth: [{ value: this.userCreditCard.creditCard.expirationMonth, disabled: false }],
        expirationYear: [{ value: this.userCreditCard.creditCard.expirationYear, disabled: false }],
        cvv: [{ value: '', disabled: false }],
        description: [{ value: this.userCreditCard.creditCard.description, disabled: false }],
        defaultPayment: [{ value: this.userCreditCard.creditCard.defaultCard, disabled: false }],
        addressLine1: [{ value: address1, disabled: false }, [Validators.required]],
        addressLine2: [{ value: address2, disabled: false }],
        city: [{ value: city, disabled: false }, [Validators.required]],
        state: [{ value: state, disabled: false }, [Validators.required]],
        zipCode: [{ value: postalCode, disabled: false }, [Validators.required]],
        country: [{ value: country, disabled: false }, [Validators.required]],
      });

      // if this is their first credit card we want to enforce that the default payment box is checked
      if (this.IsFirstCard) {
        this.addCreditCard.get('defaultPayment').setValidators(Validators.requiredTrue);
        this.addCreditCard.get('defaultPayment').setValue(true);
        this.addCreditCard.get('defaultPayment').disable();
        this.addCreditCard.get('defaultPayment').updateValueAndValidity();
      }

      // TODO: Temporary code block to check if card is added in the booking wizard or somewhere else. If is bookingWizard, we need to check for cvv and expDate string in this formar "MM/YY"
      if (this.isBookingWizard) {
        this.addCreditCard.get('cvv').setValidators([Validators.required, Validators.pattern(REGEX_NUMBERS)]);
        this.addCreditCard.get('expDate').setValidators([Validators.required, Validators.pattern(REGEX_CC_DATE)]);
      }
      else if (!this.isBookingWizard) {
        this.addCreditCard.get('expirationMonth').setValidators(Validators.required);
        this.addCreditCard.get('expirationYear').setValidators(Validators.required);
      }

      // going to try to re set the state after the initial form...
      // I think this is needed because when the country gets set, the state dropdown gets repopulated with options so its an order of options issue!
      // var this$ = this;
      // setTimeout(function () {
      //   this$.addCreditCard.get('state').patchValue(state);
      //   this$.addCreditCard.get('state').updateValueAndValidity();
      // }, 1);

    } else { // this is a CC update
      let monthIndex = MONTHS_NAME.indexOf(this.userCreditCard.creditCard.expirationMonth);
      let expirationDate = MONTHS_NUMBER[monthIndex] + '/' + this.userCreditCard.creditCard.expirationYear.substring(2, 4);
      this.setCvvLength(this.userCreditCard.creditCard.cardType);
      this.addCreditCard = this.fb.group({
        description: [{ value: this.userCreditCard.creditCard.description, disabled: false }],
        cardNumber: [{ value: this.userCreditCard.creditCard.maskCardNumber, disabled: true }, [Validators.required]],
        cvv: ['', Validators.required],
        //expirationMonth: [{ value: this.userCreditCard.creditCard.expirationMonth, disabled: false }, [Validators.required]],
        //expirationYear: [{ value: this.userCreditCard.creditCard.expirationYear, disabled: false }, [Validators.required]],
        expDate: [{ value: expirationDate, disabled: false }, [Validators.required]],
        // if update mode, default payment should force checkbox as checked if its checked or allow checking if it was unchecked
        defaultPayment: [{ value: this.userCreditCard.creditCard.defaultCard, disabled: this.userCreditCard.creditCard.defaultCard }]
      });
    }
  }

  isInvalid(controlName: string): boolean {
    const control = this.addCreditCard.get(controlName);

    if (controlName === 'expDate') {
      let value = this.addCreditCard.get(controlName).value;
      if (value.length == 2) {
        control.patchValue(value + '/');
      }
      if (value.length == 4 && value.endsWith('//')) {
        control.patchValue(value.slice(0, -1));
      }
    }
    // we need to validate the new expDate format from booking wizard format: MM/YY
    // if (controlName == 'expDate') {
    //   let expDateControl = this.addCreditCard.get(controlName).value;
    //   console.log(expDateControl);
    //   if (expDateControl.length != 5 || expDateControl[2] !== '/') {
    //     console.log()
    //     return false;
    //   }
    //   let monthYear = this.addCreditCard.get(controlName).value.split("/");
    //   let month = monthYear[0];
    //   let year = "20" + monthYear[1].toString();
    //   if (!this.monthnames[month] || this.years.filter(x => x.toString() == year).length == 0) {
    //     return false;
    //   }
    // }

    // if we can't find the control for whatever reason...return false
    if (!control) {
      this.logger.warn('Control not found...FYI', controlName);
      return false;
    }

    const isInvalid = control.invalid && (control.dirty || control.touched);

    return isInvalid;
  }

  saveInfo(): void {
    this.logger.debug('save info!');
    Utils.touchFormFields(this.addCreditCard);
    if (this.addCreditCard.valid) {

      if (this.isUpdateMode === false && this.addCreditCard.get('cardNumber').value && !this.cardType) {
        this.isCardProviderError = true;
        return;
      }

      if (this.isUpdateMode === false && this.addCreditCard.get('cardNumber').value && Utils.isCreditCardValid(this.addCreditCard.get('cardNumber').value) === false) {
        this.isCardValidationError = true;
        return;
      } else {
        this.isCardValidationError = false;
      }

      this.isSubmitDisabled = true;

    } else {
      this.modalService.open(this.missingInfoMessageTemplate, {
        size: 'lg',
        backdrop: 'static',
        windowClass: 'onboarding-modal',
        centered: true
      });
      return;
    }

    if (!this.isUpdateMode) {
      this.userCreditCard.name = this.addCreditCard.get('name').value;
      this.userCreditCard.firstName = this.addCreditCard.get('firstName').value;
      this.userCreditCard.lastName = this.addCreditCard.get('lastName').value;
      this.userCreditCard.addressLine1 = this.addCreditCard.get('addressLine1').value;
      this.userCreditCard.addressLine2 = this.addCreditCard.get('addressLine2').value;
      this.userCreditCard.city = this.addCreditCard.get('city').value;
      this.userCreditCard.state = this.addCreditCard.get('state').value;
      this.userCreditCard.postalCode = this.addCreditCard.get('zipCode').value;
      this.userCreditCard.country = this.addCreditCard.get('country').value;

      this.userCreditCard.creditCard = {
        chargeCardAccountingId: this.userCreditCard.creditCard.chargeCardAccountingId ? this.userCreditCard.creditCard.chargeCardAccountingId : 0,
        customerAccountingId: 0,/*this.userCreditCard.accountingId ? this.userCreditCard.accountingId : 0,*/
        cardNumber: this.addCreditCard.get('cardNumber').value,
        expirationMonth: this.addCreditCard.get('expirationMonth').value,
        expirationYear: this.addCreditCard.get('expirationYear').value,
        defaultCard: this.addCreditCard.get('defaultPayment').value ? true : false,
        cardType: this.cardType,
        description: this.addCreditCard.get('description').value,
        status: "active",
        enableOnlinePayment: true,
        cvv: this.addCreditCard.get('cvv').value
      };
      this.dialogRef.close();
    }

    if (this.isUpdateMode) {
      this.userCreditCard.creditCard.expirationMonth = this.addCreditCard.get('expirationMonth').value;
      // this.userCreditCard.creditCard.customerAccountingId = this.userCreditCard.customerId ? this.userCreditCard.customerId : 0,
      this.userCreditCard.creditCard.expirationYear = this.addCreditCard.get('expirationYear').value;
      this.userCreditCard.creditCard.defaultCard = this.addCreditCard.get('defaultPayment').value ? true : false;
      this.userCreditCard.creditCard.description = this.addCreditCard.get('description').value;
      this.userCreditCard.creditCard.maskCardNumber = this.addCreditCard.get('cardNumber').value;
      this.userCreditCard.creditCard.status = "active";
      this.userCreditCard.creditCard.enableOnlinePayment = true;

      this.getUpdateCreditCardsMethod(this.isCompany, this.userCreditCard.guid, this.userCreditCard).subscribe(response => {
        this.logger.debug('Updated credit card!', response);
        this.isSubmitDisabled = false;
        this.notificationService.show('Credit card updated!', 'success');

        // pass the credit card out of this after it's been created
        this.creditCardUpdated.emit(response.creditCard);

        //this.activeModal.close();
        this.dialogRef.close();
      }, error => {
        this.notificationService.show('There was an error updating the credit card', 'error');
        this.isSubmitDisabled = false;
        this.logger.error(error);
      });
    } else {
      this.getAddCreditCardsMethod(this.isCompany, this.userCreditCard.guid, this.userCreditCard).subscribe(response => {

        this.logger.debug('Created credit card!', response);
        this.isSubmitDisabled = false;
        this.notificationService.show('Credit card successfully added!', 'success');

        // could add another emit here for accounting id..
        this.accountingIdCreated.emit(response);

        // pass the credit card out of this after it's been created
        this.creditCardCreated.emit(response.creditCard);

        //this.activeModal.close(response.creditCard);
        this.dialogRef.close(response.creditCard);
      }, error => {
        let hasShownError = false;
        if (error && error.error) {
          const err = error.error.toLowerCase();
          if (err.includes('already exists')) {
            hasShownError = true;
            this.notificationService.show('This card number already exists under your payment methods!', 'error');
          }
        }
        if (!hasShownError) {
          this.notificationService.show('There was an error adding the credit card', 'error');
        }

        this.isSubmitDisabled = false;
        this.logger.error(error);
      });
    }

  }

  submitAddBWCard(): void {
    this.logger.debug('save info from booking wizard!');
    Utils.touchFormFields(this.addCreditCard);
    if (this.addCreditCard.valid) {

      if (this.isUpdateMode === false && this.addCreditCard.get('cardNumber').value && !this.cardType) {
        this.isCardProviderError = true;
        return;
      }

      if (this.isUpdateMode === false && this.addCreditCard.get('cardNumber').value && Utils.isCreditCardValid(this.addCreditCard.get('cardNumber').value) === false) {
        this.isCardValidationError = true;
        return;
      } else {
        this.isCardValidationError = false;
      }

      this.isSubmitDisabled = true;

    } else {
      this.modalService.open(this.missingInfoMessageTemplate, {
        size: 'lg',
        backdrop: 'static',
        windowClass: 'onboarding-modal',
        centered: true
      });
      return;
    }

    if (!this.isUpdateMode) {

      // get month and year from expDate field
      let expDatefield = this.addCreditCard.get('expDate').value;
      let monthYear = expDatefield.split("/");
      let expMonth = this.monthnames[monthYear[0]];
      let expYear = "20" + monthYear[1].toString();

      this.userCreditCard.name = this.addCreditCard.get('name').value;
      this.userCreditCard.firstName = this.addCreditCard.get('firstName').value;
      this.userCreditCard.lastName = this.addCreditCard.get('lastName').value;

      this.userCreditCard.creditCard = {
        chargeCardAccountingId: this.userCreditCard.creditCard.chargeCardAccountingId ? this.userCreditCard.creditCard.chargeCardAccountingId : 0,
        customerAccountingId: 0,/*this.userCreditCard.accountingId ? this.userCreditCard.accountingId : 0,*/
        cardNumber: this.addCreditCard.get('cardNumber').value,
        expirationMonth: expMonth, //this.addCreditCard.get('expirationMonth').value,
        expirationYear: expYear, //this.addCreditCard.get('expirationYear').value,
        defaultCard: this.addCreditCard.get('defaultPayment').value ? true : false,
        cardType: this.cardType,
        description: this.addCreditCard.get('description').value,
        status: "active",
        enableOnlinePayment: true,
        cvv: this.addCreditCard.get('cvv').value
      };

      this.showSpinner = true;

      this.getAddCreditCardsMethod(this.isCompany, this.userCreditCard.guid, this.userCreditCard).subscribe(response => {

        this.showSpinner = false;
        this.logger.debug('Created credit card!', response);
        this.isSubmitDisabled = false;
        this.notificationService.show('Credit card successfully added!', 'success');

        // could add another emit here for accounting id..
        this.accountingIdCreated.emit(response);

        // pass the credit card out of this after it's been created
        this.creditCardCreated.emit(response.creditCard);

        this.dialogRef.close(response.creditCard);
      }, error => {
        let hasShownError = false;
        this.showSpinner = false;
        if (error && error.error) {
          const err = error.error.toLowerCase();
          if (err.includes('already exists')) {
            hasShownError = true;
            this.notificationService.show('This card number already exists under your payment methods!', 'error');
          }
        }
        if (!hasShownError) {
          this.notificationService.show('There was an error adding the credit card', 'error');
        }
        if (this.isCompany) {
          this.notificationService.show('Please complete the company profile in the dashboard to add a company card', 'error');
        }

        this.isSubmitDisabled = false;
        this.logger.error(error);
      });

    }
    //If updating card
    else {
      let expDatefield = this.addCreditCard.get('expDate').value;
      let monthYear = expDatefield.split("/");
      let expMonth = this.monthnames[monthYear[0]];
      let expYear = "20" + monthYear[1].toString();

      this.userCreditCard.creditCard = {
        chargeCardAccountingId: this.userCreditCard.creditCard.chargeCardAccountingId ? this.userCreditCard.creditCard.chargeCardAccountingId : 0,
        customerAccountingId: 0,
        cardNumber: this.addCreditCard.controls['cardNumber'].value,
        expirationMonth: expMonth,
        expirationYear: expYear,
        defaultCard: this.addCreditCard.controls['defaultPayment'].value ? true : false,
        description: this.addCreditCard.get('description').value,
      };

      this.showSpinner = true;

      this.getUpdateCreditCardsMethod(this.isCompany, this.userCreditCard.guid, this.userCreditCard).subscribe(response => {

        this.showSpinner = false;
        this.logger.debug('Updated credit card!', response);
        this.isSubmitDisabled = false;
        this.notificationService.show('Credit card successfully updated!', 'success');

        // could add another emit here for accounting id..
        this.accountingIdCreated.emit(response);

        // pass the credit card out of this after it's been created
        this.creditCardCreated.emit(response.creditCard);

        this.dialogRef.close(response.creditCard);
      }, error => {
        let hasShownError = false;
        this.showSpinner = false;

        if (!hasShownError) {
          this.notificationService.show('There was an error updating the credit card', 'error');
        }

        this.isSubmitDisabled = false;
        this.logger.error(error);
      });

    }
  }

  getStateArray(country?: string): StateModel[] {
    // Handle loading case
    if (this.countryStates == null) {
      return null;
    }
    // Use selected country as hash key. 'United States' is default.
    // If country was entered, provide the state list for the specific country.
    // Otherwise use the country that has been selected on the form.
    return country ? this.countryStates.countries[country]
      : this.countryStates.countries[this.addCreditCard.get('country').value];
  }

  setCvvLength(cardType: string) {
    if (cardType == 'American Express') {
      this.cvvLength = 4;
    }
    else {
      this.cvvLength = 3;
    }
  }

  getCountryArray(): string[] {
    // Handle loading case
    if (this.countryStates == null) {
      return null;
    }
    // Use hash keys as country choices
    return Object.keys(this.countryStates.countries);
  }

  getAddCreditCardsMethod(isCompany: boolean, guid: string, ccUser: creditCardCustomerModel): Observable<creditCardCustomerModel> {
    if (isCompany) {

      return this.apiService.addCompanyCreditCard(guid, ccUser);
    }
    else {
      return this.apiService.addUserCreditCard(guid, ccUser);
    }
  }

  getUpdateCreditCardsMethod(isCompany: boolean, guid: string, ccUser: creditCardCustomerModel): Observable<creditCardCustomerModel> {
    if (isCompany) {
      return this.apiService.updateCompanyCreditCard(guid, ccUser);
    }
    else {
      return this.apiService.updateUserCreditCard(guid, ccUser);
    }
  }

  cardOnKeyUp(card: any): void {
    this.debounceSub.next(card);
    this.setCvvLength(card.cardType);
  }

  navigateBack() {
    this.navBack.emit();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  get firstName() { return this.addCreditCard.get('firstName') }
  get lastName() { return this.addCreditCard.get('lastName') }
}

