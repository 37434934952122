import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbCarousel, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { CreateAccountComponent } from "app/account/create-account-form/create-account-form.component";
import { ForgotPasswordComponent } from "app/account/forgot-password/forgot-password-form.component";
import { SignInComponent } from "app/account/sign-in-form/sign-in-form.component";
import { GRGSnackBarService } from "app/common/grg-snack-bar/grg-snak-bar.service";
import { MaintenanceModalComponent } from "app/common/maintenance-modal/maintenance-modal.component";
import Utils from "app/common/Utils";
import { FloorPlan, PropertyDetailModel, Unit, Image, GoogleMapData } from "app/models/PropertyDetailModels";
import { ApiService } from "app/services/api.service";
import { LoadingService } from "app/services/loading.service";
import { UserService } from "app/services/user.service";
import { environment } from "environments/environment";

declare var $: any;
declare var Chart: any;
declare var google: any;

@Component({
    selector: 'app-property-detail',
    templateUrl: './property-detail.component.html',
    styleUrls: ['./property-detail.component.scss'],
})

export class PropertyDetailComponent implements OnInit {
    property: PropertyDetailModel;
    floorPlans: FloorPlan[] = [];
    units: Unit[] = [];
    unitPage: number = 0;
    maxUnitPage: number = 1;
    unitStartIndex: number = 0;
    unitEndIndex: number = 0;
    showUnitCount = 10;
    propertyId: string;
    startDate: string;
    endDate: string;
    location: string;
    selectedUnit: Unit;
    fpBedrooms?: number;
    selectedBedroom: number = 5;
    bedroomGroup = [
        { name: 'All', selected: true, value: 5 },
        { name: 'Studio', selected: false, value: 0 },
        { name: '1 Bedroom', selected: false, value: 1 },
        { name: '2 Bedrooms', selected: false, value: 2 },
        { name: '3 Bedrooms', selected: false, value: 3 },
        { name: '4 Bedrooms', selected: false, value: 4 }
    ]
    availableBedrooms = [];
    fpURL: string
    @ViewChild('walkScoreTarget', { static: false }) walkScroreElement: ElementRef;
    @ViewChild('transitScoreTarget', { static: false }) transitScoreElement: ElementRef;
    @ViewChild('bikeScoreTarget', { static: false }) bikeScoreElement: ElementRef;
    @ViewChild('signInModal', { static: false }) sigInModalElement: ElementRef;
    @ViewChild('FloorPlanModal', { static: false }) FloorPlanModalElement: ElementRef;
    @ViewChild('tenantOnlyModal', { static: false, read: TemplateRef }) tenantOnlyModalElement: TemplateRef<any>;
    @ViewChild('carousel', { static: false }) carousel: NgbCarousel;
    @ViewChild('mobileCarousel', { static: false }) mobileCarousel: NgbCarousel;
    Utils = Utils;

    //#region Google Maps
    nearbyPlaces = [
        { name: 'Restaurants', type: 'restaurant', icon: 'far fa-utensils' },
        { name: 'Bars', type: 'bar', icon: 'far fa-glass-martini-alt' },
        { name: 'Coffee', type: 'cafe', icon: 'far fa-mug-hot' },
        { name: 'Shopping', type: 'shopping_mall', icon: 'far fa-shopping-bag' },
        { name: 'Groceries', type: 'supermarket', icon: 'far fa-shopping-cart' },
        { name: 'Banks', type: 'bank', icon: 'far fa-university' }
    ];
    @ViewChild('googleMap', { static: false }) googleMap: ElementRef;
    map: any;
    placesService: any;
    currentZoom: number = 13;
    propertyLatLng: any;
    infowindow: any;
    places: any[] = [];
    placesMarkers = [];
    imageMarker: any;
    imageMarkerSize: any;
    //#region 

    @ViewChild('directionsPanel', { static: true }) directionElement: ElementRef;
    google_map = GoogleMapData;
    busStop: any;
    railStop: any;
    placeType: any;
    place: any;
    directionsService: any;
    directionsDisplay: any;
    noRouteMessage = '';
    locationAutocomplete: string;
    locationAutocompleteMobile: string;

    constructor(private apiService: ApiService, private route: ActivatedRoute, private userService: UserService, private changeDetector: ChangeDetectorRef,
        private loadingService: LoadingService, private router: Router, private modalService: NgbModal, private notificationService: GRGSnackBarService,) { }

    ngOnInit(): void {
        this.propertyId = this.route.snapshot.paramMap.get('id')
        this.startDate = this.route.snapshot.queryParamMap.get('start');
        this.endDate = this.route.snapshot.queryParamMap.get('end');
        this.location = this.route.snapshot.queryParamMap.get('location');

        this.getPropertyDetails();

        // if (this.userService.isAuthenticated) {
        //     this.userService.getLikedProperties().subscribe();
        // }
    }

    isProduction() {
        if(environment.production){
            return true;
        } else { return false; }
    }

    getPropertyDetails() {
        this.loadingService.fetch();
        this.apiService.getPropertyDetails(this.propertyId, this.startDate, this.endDate).subscribe(r => {
            this.property = r;
            this.floorPlans = this.property.floorPlans;

            this.bedroomGroup.forEach(grp => {
                if (this.fpHasBedroomType(grp.value)) {
                    this.availableBedrooms.push(grp)
                }
            });

            this.changeDetector.detectChanges();
            this.mobileCarousel.pause();

            this.initMap();

            this.loadingService.pet();

            this.initWalkScore();
        }, (error: HttpErrorResponse) => {
            this.loadingService.pet();
            console.log("error", error);
            this.notificationService.show('Something went wrong, retrieving property details', 'error');
            
        })
    }

    selectUnit(u: Unit) {
        this.selectedUnit = u;
    }

    showUnits(units: Unit[]) {
        this.unitPage = 1;
        this.maxUnitPage = Math.ceil(units.length / this.showUnitCount);
        this.displayUnits(units);
    }

    nextUnits(units: Unit[]) {
        this.unitPage += 1;
        this.displayUnits(units);
    }

    previousUnits(units: Unit[]) {
        this.unitPage -= 1;
        this.displayUnits(units);
    }

    displayUnits(units: Unit[]) {
        this.unitStartIndex = (this.unitPage - 1) * this.showUnitCount;
        this.unitEndIndex = this.unitStartIndex + this.showUnitCount - 1;
        this.units = units.slice(this.unitStartIndex, this.unitEndIndex);
    }

    filterFloorPlan(bedrooms: number) {
        this.selectedBedroom = bedrooms;
        if (bedrooms === 5) {
            this.floorPlans = this.property.floorPlans
        }
        else {
            this.floorPlans = this.property.floorPlans.filter(x => x.bedrooms === bedrooms);
        }
    }

    fpHasBedroomType(bedroom: number) {
        if (bedroom == 5) {
            return true;
        }
        return this.property.floorPlans.map(x => x.bedrooms).includes(bedroom);
    }

    beginBooking() {
        const authToken = this.userService.getTokenStorage();
        if (authToken) {
            this.startBooking();
            // this.apiService.canCreateBooking(authToken).subscribe(r => {
            //     if (r.allowBooking) {
            //         this.startBooking();
            //     } else {
            //         //show error
            //     }
            // })
        } else {
            this.openSignInModal();
        }
    }

    startBooking() {
        this.router.navigate([`/booking`], { queryParams: { property: this.propertyId, startdate: this.startDate, enddate: this.endDate, unit: this.selectedUnit.id } });
    }

    imagesToggle(imgSrc: string) {
        if (imgSrc) {
            this.fpURL = imgSrc;
        } else {
            this.fpURL = null
            const this$ = this;
            setTimeout(function () {
                this$.changeDetector.detectChanges();
                this$.carousel.pause();
            }, 1000);

        }
    }

    //#region Walk Scrore
    initWalkScore() {
        let lowColor = "#FA8334";
        let medColor = "#0AB6D8";
        let highColor = "#79A448";
        let bgColor = "#e9ecee";
        //--
        let wsColor = lowColor;
        let tsColor = lowColor;
        let bsColor = lowColor;

        if (this.property.walkScore !== null) {
            //medium
            if (this.property.walkScore.walkScoreResult !== null && this.property.walkScore.walkScoreResult.walkscore !== null && this.property.walkScore.walkScoreResult.walkscore >= 50) {
                wsColor = medColor;
            }
            if (this.property.walkScore.walkScoreResult !== null && this.property.walkScore.walkScoreResult.transitscore !== null && this.property.walkScore.walkScoreResult.transitscore >= 50) {
                tsColor = medColor;
            }
            if (this.property.walkScore.walkScoreResult !== null && this.property.walkScore.walkScoreResult.bikescore !== null && this.property.walkScore.walkScoreResult.bikescore >= 50) {
                bsColor = medColor;
            }
            //high
            if (this.property.walkScore.walkScoreResult !== null && this.property.walkScore.walkScoreResult.walkscore !== null && this.property.walkScore.walkScoreResult.walkscore >= 75) {
                wsColor = highColor;
            }
            if (this.property.walkScore.walkScoreResult !== null && this.property.walkScore.walkScoreResult.transitscore !== null && this.property.walkScore.walkScoreResult.transitscore >= 75) {
                tsColor = highColor;
            }
            if (this.property.walkScore.walkScoreResult !== null && this.property.walkScore.walkScoreResult.bikescore !== null && this.property.walkScore.walkScoreResult.bikescore >= 75) {
                bsColor = highColor;
            }
            let configWalkScore = null;
            if (this.property.walkScore !== null && this.property.walkScore.walkScoreResult !== null) {
                configWalkScore = {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: [
                                this.property.walkScore.walkScoreResult.walkscore,
                                100 - this.property.walkScore.walkScoreResult.walkscore
                            ],
                            backgroundColor: [
                                wsColor,
                                bgColor,
                            ],
                            label: 'Walk Score'
                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: false
                        },
                        tooltips: {
                            enabled: false
                        },
                        layout: {
                            padding: {
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        cutoutPercentage: 70,
                        animation: {
                            animateScale: false,
                            animateRotate: true
                        }
                    }
                };
            }
            let configTransitScore = null;
            if (this.property.walkScore.walkScoreResult !== null && this.property.walkScore.walkScoreResult.transitscore !== null) {
                configTransitScore = {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: [
                                this.property.walkScore.walkScoreResult.transitscore,
                                100 - this.property.walkScore.walkScoreResult.transitscore
                            ],
                            backgroundColor: [
                                tsColor,
                                bgColor
                            ],
                            label: 'Transit Score'
                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: false
                        },
                        tooltips: {
                            enabled: false
                        },
                        layout: {
                            padding: {
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        cutoutPercentage: 70,
                        animation: {
                            animateScale: false,
                            animateRotate: true
                        }
                    }
                };
            }
            let configBikeScore = null;
            if (this.property.walkScore.walkScoreResult !== null && this.property.walkScore.walkScoreResult.bikescore !== null) {
                configBikeScore = {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: [
                                this.property.walkScore.walkScoreResult.bikescore,
                                100 - this.property.walkScore.walkScoreResult.bikescore
                            ],
                            backgroundColor: [
                                bsColor,
                                bgColor
                            ],
                            label: 'Bike Score'
                        }]
                    },
                    options: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: false
                        },
                        tooltips: {
                            enabled: false
                        },
                        layout: {
                            padding: {
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        cutoutPercentage: 70,
                        animation: {
                            animateScale: false,
                            animateRotate: true
                        }
                    }
                };
            }

            // let wsDOM = $("#walkScore");
            setTimeout(() => {
                // if (!wsDOM.hasClass("charted")) {
                if (configWalkScore !== null) {
                    let $walkScoreTarget = this.walkScroreElement.nativeElement as HTMLCanvasElement;
                    let ctxWalkScore = $walkScoreTarget.getContext('2d');
                    (window as any).myDoughnutWS = new Chart(ctxWalkScore, configWalkScore);
                }
                if (configTransitScore !== null) {
                    let $transitScoreTarget = this.transitScoreElement.nativeElement as HTMLCanvasElement;
                    var ctxTransitScore = $transitScoreTarget.getContext('2d');
                    (window as any).myDoughnutTS = new Chart(ctxTransitScore, configTransitScore);
                }
                if (configBikeScore !== null) {
                    let $bikeScoreTarget = this.bikeScoreElement.nativeElement as HTMLCanvasElement;
                    var ctxBikeScore = $bikeScoreTarget.getContext('2d');
                    (window as any).myDoughnutBS = new Chart(ctxBikeScore, configBikeScore);
                }
                // wsDOM.addClass("charted");
                // }
            }, 700);
        }
    }
    //#endregion

    //#region Google Map
    initMap() {
        this.imageMarker = new Image;
        this.imageMarker.src = "/assets/img/map-point-selected.svg";
        this.imageMarkerSize = new google.maps.Size(20, 20);

        this.infowindow = new google.maps.InfoWindow({
            content: "holding..."
        });

        this.propertyLatLng = new google.maps.LatLng(this.property.address.latitude, this.property.address.longitude);

        // initialize google map
        this.map = new google.maps.Map(this.googleMap.nativeElement as HTMLElement, {
            center: this.propertyLatLng,
            position: this.propertyLatLng,
            zoom: this.currentZoom,
            maxZoom: 16,
            streetViewControl: false,
            gestureHandling: 'greedy',
            disableDoubleClickZoom: true,
            mapTypeID: google.maps.MapTypeId.ROADMAP
        });

        this.setMarkers();

        // initialize maps services
        // this.directionsService = new google.maps.DirectionsService();
        // this.directionsDisplay = new google.maps.DirectionsRenderer();
        this.placesService = new google.maps.places.PlacesService(this.map);
    }

    searchNearbyPlaces(type: string) {
        this.clearMarkers();
        this.places = [];
        this.placesMarkers = [];
        let infowindow = this.infowindow;
        let selectedPlace = this.google_map.allPlaces.find(x => x.search_name === type);
        let request = {
            types: [type],
            location: this.propertyLatLng,
            radius: '4000',
        };
        this.placesService.nearbySearch(request, (response, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                this.places.push(...response)
                // sort places list by distance
                this.places.sort(this.dynamicSort('distance'));
                // loop through every place that was just added and calculate the distance in miles.
                this.places.forEach((place, index) => {
                    place.distance = Utils.calculateDistance(this.propertyLatLng.lat(), this.propertyLatLng.lng(), place.geometry.location.lat(), place.geometry.location.lng(), "N");
                    place.distanceLabel = (Math.round(place.distance * 100) / 100) + " mi";

                    // create a marker for every place
                    let marker = new google.maps.Marker({
                        map: this.map,
                        position: place.geometry.location,
                        icon: {
                            url: selectedPlace.icon,
                            scaledSize: this.imageMarkerSize
                        }
                    });

                    place.markerID = type + "_" + index;
                    marker.content = '<div class="marker-content"><h4>' + place.name + '</h4><p>Distance: ' + place.distanceLabel + '</p><a href="https://www.google.com/maps?q=' + place.vicinity + '" class="map-it-button" target="_blank">MAP IT</a></div>';
                    marker['category'] = type;
                    marker['markerID'] = type + "_" + index;
                    marker.iconUnSelectedUrl = selectedPlace.icon;
                    marker.iconSelectedUrl = selectedPlace.selected_icon;
                    marker.initZIndex = marker.getZIndex();

                    // open infowindow on click 
                    marker.addListener('click', function (e) {
                        infowindow.setContent(marker.content);
                        infowindow.open(this.map, this);

                        if (this.placesMarkers.length > 0) {
                            this.placesMarkers.forEach(marker => {
                                marker.setIcon({ url: marker.iconUnSelectedUrl, scaledSize: this.place_icon_scaled_size });
                                marker.setZIndex(marker.initZIndex);
                            });
                        }
                    });

                    marker.setVisible(true);
                    this.placesMarkers.push(marker);
                });
            }
        });
    }

    setMarkers() {
        // Set Property Location Marker
        new google.maps.Marker({
            map: this.map,
            position: this.propertyLatLng,
            title: this.property.name,
            icon: {
                url: this.imageMarker.src,
                scaledSize: new google.maps.Size(50, 40)
            }
        });
    }

    clearMarkers() {
        this.placesMarkers.forEach(m => m.setMap(null))
    }

    dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        };
    }
    //#endregion

    //#region Authentication Modals
    openSignInModal() {
        const modalRef = this.modalService.open(SignInComponent, { size: 'lg', centered: true });
        modalRef.componentInstance.isModal = true;
        modalRef.componentInstance.unitId = this.selectedUnit.id;
        modalRef.componentInstance.startDate = this.startDate;
        modalRef.componentInstance.endDate = this.endDate;
        modalRef.result.then((result) => {
            if (result == "create") {
                this.openCreateModal();
            }
            if (result == "forgot") {
                this.openForgotModal();
            }
        }).catch(() => { })
    }
    openCreateModal() {
        const modalRef = this.modalService.open(CreateAccountComponent, { size: 'lg', centered: true });
        modalRef.componentInstance.isModal = true;
        modalRef.result.then((result) => {
            if (result == 'signin') {
                this.openSignInModal();
            }
        }).catch(() => { })
    }
    openForgotModal() {
        const modalRef = this.modalService.open(ForgotPasswordComponent, { size: 'lg', centered: true });
        modalRef.componentInstance.isModal = true;
        modalRef.result.then((result?) => {
            if (result == 'signin') {
                this.openSignInModal();
            }
        }).catch(() => { })
    }

    openMaintenanceModal(useSmall = false) {
        let config: NgbModalOptions = useSmall ? { size: 'sm', centered: true} : { centered: true}
        const modalRef = this.modalService.open(MaintenanceModalComponent, config);
        modalRef.componentInstance.isModal = true;
        modalRef.result.then((result) => {
            console.log(result);
        })
    }

    propertyURLQueryParams() {

        return {
          location: this.location,
          start: this.startDate,
          end: this.endDate,
        }
      }
    //#endregion

}
