import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Router, ActivatedRoute, NavigationEnd, ActivationEnd, UrlSerializer, DefaultUrlSerializer } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UserService } from 'app/services/user.service';
import { VERSION } from 'environments/version';
import { LookupService } from 'app/services/lookup.service';
import { ImportDataService } from 'app/services/import-data.service';
import { ApiService } from 'app/services/api.service';
import Utils, { ROUTE_PORTAL_HOME, ROUTE_PORTAL_ADD_TENANT, ROUTE_PORTAL_IDENTITY_CHECK } from 'app/common/Utils';
import { NotificationService } from 'app/services/notification.service';
import { GrgNotificationModel } from 'app/models/GrgNotificationModel';
import { TemplateAst } from '@angular/compiler';
import { MatMenu } from '@angular/material';
import { NavSidebarComponent } from 'app/nav-sidebar/nav-sidebar.component';
import { NavSidebarService } from 'app/services/nav-sidebar.service';
import { PermissionsService } from 'app/services/permissions.service';
import { FileDragWatcherService } from 'app/services/file-drag-watcher.service';
import { TagManagerService } from 'app/services/tag-manager.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      state('full', style({
        marginLeft: '*'
      })),
      state('minimized', style({
        marginLeft: '57px'
      })),
      transition('full => minimized', animate('200ms ease-in')),
      transition('minimized => full', animate('300ms ease-out'))
    ])
  ]
})
export class AppLayoutComponent implements OnInit, AfterViewInit {

  animState = 'full';
  showMobileMenu = false;
  color: 'accent';
  version: any;
  totalNotifications: number;
  currentNotifications: GrgNotificationModel[] = [];
  currentRole = 'User';
  showSidebar = true; // assume sidebar is shown except for a select few pages
  showBorderAboveMainContent = false;

  routePortalHome = ROUTE_PORTAL_HOME;

  @ViewChild('notificationMenu', { static: false }) notificationMenu: MatMenu;
  @ViewChild('navSidebar', { static: false }) navSidebar: NavSidebarComponent;

  constructor(
    private router: Router,
    public userService: UserService,
    private logger: NGXLogger,
    private lookupService: LookupService,
    private importedDataService: ImportDataService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private navSidebarService: NavSidebarService,
    private permissionsService: PermissionsService,
    private notifications: NotificationService,
    private fileDragWatcherService: FileDragWatcherService,
    private tagManagerService: TagManagerService
  ) { }

  ngAfterViewInit() {
    // init nav sidebar service with our sidebar reference
    this.navSidebarService.init(this.navSidebar);
  }

  ngOnInit() {
    this.logger.log('init app layout..router url:', this.router.url);
    this.version = VERSION;
    this.permissionsService.refreshPermissions(this.userService.clientContact, this.lookupService);

    // this handles the INITIAL route check for whether the sidebar should display or not
    const currentRoute = this.router.url.toLowerCase();
    this.checkSidebarDisplay(currentRoute);
    this.checkMainContentBorderDisplay(currentRoute);

    // this will handle checking the sidebar display for every route navigated AFTER the initial one
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        const url = val.url.toLowerCase();
        this.logger.debug(`nav end occurred...evaluating route for sidebar display: ${url}`);

        var userGuid = this.userService.clientContact.clientContactGuid;
        var userType = Utils.getUserType(this.userService.clientContact);
        var userRole = this.permissionsService.role;
        var isLoggedIn = this.userService.isAuthenticated;

        this.tagManagerService.onPageLoad(userGuid, userRole, userType, isLoggedIn, 'on page load / navigation end event in app-layout.component.ts');

        this.checkSidebarDisplay(url);
        this.checkMainContentBorderDisplay(url);
        this.notifications.refreshNotificationsIfEnoughTimeHasPassed();
      }
    });

    // whenenever notifications change,load the first 5 into the list :D
    this.notificationService.notificationsChanged$.subscribe(response => {
      if (response != null) {
        this.totalNotifications = response.length;
        this.currentNotifications = response.slice(0, 5);
      }
    });

    this.permissionsService.onRoleChanged$.subscribe(response => {
      if (response != null) {
        this.currentRole = response;
      }
    });

    this.fileDragWatcherService.initAppWatcher();
  }


  checkSidebarDisplay(url: string): void {
    if (url.includes(ROUTE_PORTAL_ADD_TENANT) || url.includes(ROUTE_PORTAL_IDENTITY_CHECK)) {
      this.showSidebar = false;
    } else if (url.includes('/booking') && url.includes('/portal') === false) {
      this.showSidebar = false;
    }
    else if (url.includes('/create-account') || url.includes('/account/sign-in')) {
      this.showSidebar = false;
    }
    else {
      this.showSidebar = true;
    }
  }

  checkMainContentBorderDisplay(url: string): void {
    if (url.includes(ROUTE_PORTAL_ADD_TENANT) || (ROUTE_PORTAL_IDENTITY_CHECK)) {
      this.showBorderAboveMainContent = true;
    } else if (url.includes('/booking') && url.includes('/portal') === false) {
      this.showBorderAboveMainContent = true;
    } else if (url.includes('/dashboard') || url.includes('/home')) {
      this.showBorderAboveMainContent = true;
    } else {
      this.showBorderAboveMainContent = false;
    }
  }

  loadNotification(evt: any, notification: GrgNotificationModel) {

    // if the notification has a url then lets take them there
    if (notification.url) {
      var tree = this.router.parseUrl(notification.url);
      this.router.navigateByUrl(tree);
    }
  }

  notificationMenuOpened(): void {
    this.totalNotifications = 0;
    // setTimeout(function () {
    //   const menu = (<HTMLElement>document.getElementsByClassName('cdk-overlay-connected-position-bounding-box')[0]);

    //   menu.style.top = parseInt(menu.style.top, 10) + 10 + 'px';
    // }, 1);
  }

  dismissNotification(evt: any, notification: GrgNotificationModel): void {
    evt.preventDefault();
    evt.stopPropagation();

    this.notificationService.dismissNotification(notification.id);
  }

  onSidebarChange(event: string): void {
    this.animState = event;
  }

  getUsersFirstLetter(): string {
    if (this.userService.clientContact != null && this.userService.clientContact.firstName != null) {
      return this.userService.clientContact.firstName[0];
    }
  }
  logout() {
    this.importedDataService.logout();
  }

  getUsersName(): string {
    if (this.userService.clientContact != null && this.userService.clientContact.firstName != null && this.userService.clientContact.lastName != null) {
      return this.userService.clientContact.firstName + ' ' + this.userService.clientContact.lastName;
    }
  }

  getUsersEmail(): string {
    if (this.userService.clientContact != null && this.userService.clientContact.globalContactDetails.primaryEmailAddress != null) {
      return this.userService.clientContact.globalContactDetails.primaryEmailAddress;
    }
  }

  navigateToFaqPage(evt): void {
    if (evt) {
      evt.preventDefault();
    }

    //TODO: change to use wp iframe of FAQ page
    window.open('/the-grg-experience/faq', '_self');
  }

  navLinkSelected(evt): void {

    // if the evt is true (meaning they are probably selecting a link from the mobile version)
    // then close the mobile menu
    if (evt) {
      const this$ = this;
      window.setTimeout(function () {
        this$.showMobileMenu = false;
      }, 50);

    }
  }

}
