import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { UrlService } from 'app/services/url.service';
import { ImportDataService } from 'app/services/import-data.service';
import { debounceTime, windowTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OpenGuard implements CanActivate {

  hasRun = false;
  private loader = new Subject<boolean>();
  public loader$ = this.loader.asObservable();

  constructor(private router: Router,
    private logger: NGXLogger,
    private urlService: UrlService,
    private importedDataService: ImportDataService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.hasRun) {
      this.loader.next(false);
      return true;
    } else {
      this.loader.next(true);
    }

    const url = state.url;
    //initialize importedDataService
    this.importedDataService.initFromOpenGuard();
    this.hasRun = true;
    return true;
  }
}
