import { Injectable } from '@angular/core';
import { NavSidebarComponent } from 'app/nav-sidebar/nav-sidebar.component';

@Injectable({
  providedIn: 'root'
})
export class NavSidebarService {

  sidebar: NavSidebarComponent;

  constructor() { }

  init(s: NavSidebarComponent) {
    this.sidebar = s;
  }

  toggleSidebar() {
    this.sidebar.toggleBarStatus();
  }

  toggleSidebarIfOpen() {
    if (this.isOpen()) {
      this.toggleSidebar();
    }
  }

  isOpen(): boolean {
    return this.sidebar.isMenuOpen();
  }
}
