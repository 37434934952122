import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonUIModule } from 'app/common/common-ui.module';
import { ContactVerificationModule } from 'app/common/contact-verification/contact-verification.module';
import { BackgroundCheckModule } from '../background-check/background-check.module';
import { AdditionalOccupantBackgroundCheckComponent } from './additional-occupant-background-check.component';


@NgModule({
    imports: [
        CommonUIModule,
        CommonModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        BackgroundCheckModule,
        ContactVerificationModule
    ],
    declarations: [AdditionalOccupantBackgroundCheckComponent],
    exports: [AdditionalOccupantBackgroundCheckComponent],
    providers: [],
})
export class AdditionalOccupantModule { }
