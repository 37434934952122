export class ContactVerificationStatus {
    identityVerified: VerificationStatus;
    backgroundVerified: VerificationStatus;
    creditVerified: VerificationStatus;
}

export enum VerificationStatus {
    NotFound = 1,
    Pending,
    Verified,
    Failed,
    Expired
}