import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { ImportDataService } from 'app/services/import-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import Utils, { ROUTE_PORTAL_HOME, ROUTE_PORTAL_USER_SETTINGS } from 'app/common/Utils';
import { PermissionsService } from 'app/services/permissions.service';
import { ApiService } from 'app/services/api.service';
import Extensions, { mobileNavbarToggler, mobileSearchToggler } from '../extensions';
import { PropertySearch } from 'app/models/SearchResultModel';
import { CookieService } from 'ngx-cookie-service';
import { LoadingService } from 'app/services/loading.service';

// Header + Footer
@Component({
  selector: 'booking-layout',
  templateUrl: './layout.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {
  // dropdownNavigation = [{
  //   name: "Our Apartments",
  //   value: "our-apartments"
  // }, {
  //   name: "Workforce Mobility Solutions",
  //   value: "workforce-mobility-solutions"
  // }, {
  //   name: "Property Managers",
  //   value: "property-managers"
  // }];
  disclaimer = "GoRoverGo. All rights reserved.";
  currentYear: number = new Date().getFullYear();
  footerBlurbCopy = "GoRoverGo makes finding housing for 30+ days simple, efficient and seamless. It's a single website offering real-time availability and pricing for multiple sources. No calls. No wait. More options."
  footerColumn2Title = "COMPANY";
  footerNavigation = [{ name: "About Us", value: "about-us" }, { name: "Contact Us", value: "contact-us" }, { name: "FAQ", value: "faq" }]; //{ name: "Locations", value: "locations" }
  footerColumn2Title2 = "LEARN MORE";
  footerNavigation2 = [{ name: "Our Apartments", value: "our-apartments" }, { name: "Workforce Mobility Solutions", value: "workforce-mobility-solutions" }, { name: "Property Managers", value: "property-managers" },];
  mobileFooterPhoneLinks = [];
  mobileFooterNavigation = [];
  footerColumn3Title = "Social";
  footerNavigationItems = [
    {
      name: "281-897-5100",
      //value: "-"
    }, {
      name: "Terms of Service",
      value: "terms-and-conditions"
    }, {
      name: "Privacy Policy",
      value: "privacy-policy"
    }];
  mobilefooterNavigationItems = [
    {
      name: "Terms",
      value: "terms-and-conditions"
    },
    {
      name: "Privacy Policy",
      value: "privacy-policy"
    }
  ]
  socialMediaItems = [
    {
      title: "Facebook",
      icon: "-f",
      url: "https://www.facebook.com/gorovergo"
    }, {
      title: "Instagram",
      url: 'https://www.instagram.com/gorovergo'
    }, {
      title: "Twitter",
      url: "https://www.twitter.com/GoRoverGo"
    }, {
      title: "LinkedIn",
      url: "https://www.linkedin.com/company/gorovergo"
    }];
  currentRole: string;
  routePortalHome = ROUTE_PORTAL_HOME;
  accountRoute = ROUTE_PORTAL_USER_SETTINGS;
  isSearchActive = false;
  cookieAlertClosed = false;
  siteAlertClosed = true;
  isLoggedIn = false;
  mobileSearchToggle = false;
  mobileSideNavToggle = false;
  recentProperties = [];
  isRoverFetching = false;
  isButtonHover: boolean;
  @ViewChild('textLocation', { static: false }) cityInput: ElementRef
  @ViewChild('mobileTextCity', { static: false }) mobileCityInput: ElementRef

  search: PropertySearch = {
    location: "",
    checkIn: "",
    checkOut: ""
  };

  mobileNavEndSocialTitle = "Find us on social";
  isDropdown = false;
  mobileNavEndLinksTitle = "COMPANY";

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public userService: UserService,
    private permissionsService: PermissionsService,
    private importedDataService: ImportDataService,
    private apiService: ApiService,
    private zone: NgZone,
    private cookieService: CookieService,
    public loadingService: LoadingService
  ) { this.router.routeReuseStrategy.shouldReuseRoute = () => false; }

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams) {
      this.search.location = queryParams.location;
      this.search.checkIn = queryParams.start === undefined ? queryParams.startdate : queryParams.start;
      this.search.checkOut = queryParams.end === undefined ? queryParams.enddate : queryParams.end;
    }

    this.permissionsService.onRoleChanged$.subscribe(response => {
      if (response != null) {
        this.currentRole = response;
      } else {
        this.currentRole = "User";
      }
    });
    this.cookieAlertClosed = !!Utils.getCookie('CookieAlertClosed');
    // this.siteAlertClosed = !!Utils.getCookie('SiteAlertClosed');

    // Get the list of viewed properties from the cookie
    if (this.cookieService.get("recentlyViewedProperties") != '') {
      var recentlyViewedProperties = JSON.parse(this.cookieService.get("recentlyViewedProperties"))


      recentlyViewedProperties.forEach(element => {
        this.recentProperties.push(element);
      });
    }


  }

  ngAfterViewInit(): void {
    //console.log('MOBILE CITY', this.mobileCityInput);
    Extensions.bindGoogleAutoComplete(this.cityInput.nativeElement as HTMLInputElement, this);
    Extensions.bindGoogleAutoComplete(this.mobileCityInput.nativeElement as HTMLInputElement, this);
    Extensions.bindDatePickers(this);
    new mobileSearchToggler().bind();
    new mobileNavbarToggler().bind();
  }

  getUsersName(): string {
    if (this.userService.clientContact != null && this.userService.clientContact.firstName != null && this.userService.clientContact.lastName != null) {
      return this.userService.clientContact.firstName + ' ' + this.userService.clientContact.lastName;
    }
  }

  getUsersEmail(): string {
    if (this.userService.clientContact != null && this.userService.clientContact.globalContactDetails !== null && this.userService.clientContact.globalContactDetails.primaryEmailAddress != null) {
      return this.userService.clientContact.globalContactDetails.primaryEmailAddress;
    }
  }

  getUsersFirstLetter(): string {
    if (this.userService.clientContact != null && this.userService.clientContact.firstName != null) {
      return this.userService.clientContact.firstName[0];
    }
  }

  getPublicSiteUrl(): string {
    return "/";
  }

  logout() {
    this.importedDataService.logout();
  }

  private _hasRequiredFields() {
    return !!(this.search.location && this.search.checkIn && this.search.checkOut);
  }

  autoCompleteCallback(location) {
    this.zone.run(() => {
      this.search.location = location;
      if (this._hasRequiredFields()) {
        //this.searchHousing();
      }
    });
  }
  dateEndClick() {
    Extensions.showEndDatePicker();
  }
  dateRangeCallback(checkIn, checkOut) {
    this.zone.run(() => {
      this.search.checkIn = checkIn;
      this.search.checkOut = checkOut;
      if (this._hasRequiredFields()) {
        //this.searchHousing();
      }
    });
  }
  searchHousing() {
    this.isSearchActive = false;
    Extensions.closeMobileNavSearch();
    let location = "";
    let split = this.search.location ? this.search.location.split(',') : [];
    if (split.length) {
      split.slice().reverse().forEach((x, i) => {
        if (split.length !== i + 1) {
          location += `${x.trim()}-`;
        } else {
          location += x.trim();
        }
      });
    }
    let start = this.search.checkIn.replace(/\//g, '-');
    let end = this.search.checkOut.replace(/\//g, '-');
    // if we are in the property details page we stay in the same page but we update the start and end dates.
    if (window.location.href.indexOf('properties') > -1) {
      this.router.navigate([`${decodeURI(window.location.pathname)}`], { queryParams: { start: start, end: end, location: location } }).then(() => {
        window.location.reload();
      });
    }
    else {
      this.router.navigate([`/search`], { queryParams: { start: start, end: end, location: location } });
    }

  }

  closeCookieAlert() {
    document.cookie = "CookieAlertClosed=" + true;
    this.cookieAlertClosed = true;
  }
  closeSiteAlert() {
    document.cookie = "SiteAlertClosed=" + true;
    this.siteAlertClosed = true;
  }

  mouseOnHoveronButton() {
    this.isButtonHover = true;
  }
  mouseOffHoveronButton() {
    this.isButtonHover = false;
  }
  closeMobileNavbar() {
    Extensions.closeMobileNavbar();
  }

  hideMobile() {
    Extensions.closeMobileNavbar();
    Extensions.closeMobileNavSearch();
  }

}
