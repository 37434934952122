import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import Utils, { ROUTE_PORTAL_HOME, ROUTE_PORTAL_RESERVATIONS } from 'app/common/Utils';

@Component({
  selector: 'app-identity-verification-failed',
  templateUrl: './identity-verification-failed.component.html',
  styleUrls: ['./identity-verification-failed.component.scss']
})
export class IdentityVerificationFailedComponent implements OnInit {

  constructor(private router: Router) { }

  @Input()
  removeSidebar: boolean;

  routePortalHome = ROUTE_PORTAL_HOME;
  
  ngOnInit() {
  }

  navigateBackToDashboard(e: any) {
    if (e) {
      e.preventDefault();
    }

    this.router.navigate([ROUTE_PORTAL_HOME]);
  }

}
