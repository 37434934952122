import { Component, OnInit, Output, EventEmitter, Input, TemplateRef, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { BaseComponent } from "./../../../common/base/base.component";
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { StateModel, CountryStateModel } from 'app/models/CountryStateModel';
import { ApiService } from 'app/services/api.service';
import { ClientContactModel } from 'app/models/ClientContactModel';
import { UserService } from 'app/services/user.service';
import { EmergencyContactModel } from 'app/models/EmergencyContactModel';
import Utils, { STATUS_CHECK_BACKGROUND, FORM_MASKED_INPUT, ROUTE_PORTAL_HOME, REGEX_ALPHANUMERIC, STATUS_CHECK_EXPIRED, STATUS_CHECK_APPROVED, REGEX_SSN, ROUTE_PORTAL_IDENTITY_CHECK, REGEX_TRANSUNION_NAME, REGEX_TRANSUNION_ADDRESS, REGEX_TRANSUNION_CITY, REGEX_NUMBERS } from '../../../common/Utils';
import { CountryStateService } from 'app/services/country-state.service';
import { NGXLogger } from 'ngx-logger';
import { takeUntil, zip } from 'rxjs/operators';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IdentityVerificationService } from 'app/services/identity-verification.service';
import { SubmitScreeningModel } from 'app/models/SubmitScreeningModel';
import { BookingWizardModel } from 'app/models/BookingWizardModel';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilityService } from 'app/services/utility.service';
import { HashTable } from 'app/models/HashTable';
import { CreditStatus } from 'app/models/CreditStatusEnum';
import { StatusCheckModel } from 'app/models/StatusCheckModel';
import { IdentityVerificationComponent } from 'app/common/identity-verification/identity-verification.component';
import { ScreenService } from 'app/services/screen.service';
import { TermsOfServiceModalComponent } from 'app/common/terms-of-service-modal/terms-of-service-modal.component';
import { WhyBackgroundCheckModalComponent } from 'app/common/why-background-check-modal/why-background-check-modal.component';
import { BookingTenantModel } from 'app/models/BookingTenantModel';
import { TagManagerService } from 'app/services/tag-manager.service';

@Component({
  selector: 'app-background-check-form',
  templateUrl: './background-check-form.component.html',
  styleUrls: ['./background-check-form.component.scss']
})

export class BackgroundCheckFormComponent extends BaseComponent implements OnInit, OnChanges {

  @Output() formSubmitted = new EventEmitter();
  @Output() acknowledgedStatus = new EventEmitter();

  @Input() showHeader = true;
  @Input() booking: BookingWizardModel; // optional input for use when used in the booking wizard
  @Input() showSecureMessage = true;
  @Input() removeSidebar: boolean = true;
  @Input() header: string;
  @Input() subtext: string;

  @ViewChild('bgLoadingModal', { read: TemplateRef, static: true }) loadingModal: TemplateRef<any>;
  // @ViewChild('backgroundCheckStartModal', { read: TemplateRef, static: true }) backgroundCheckStartModal: TemplateRef<any>;
  @ViewChild('identityServiceErrorModal', { read: TemplateRef, static: true }) identityErrorModal: TemplateRef<any>;
  @ViewChild('screeningServiceErrorModal', { read: TemplateRef, static: true }) screeningErrorModal: TemplateRef<any>;
  @ViewChild(IdentityVerificationComponent, { static: false }) identityVerificationComponent: IdentityVerificationComponent;

  emergencyContactTimer: NodeJS.Timer;
  emergencyContactBlurFlag = 'None';
  emergencyContactBlurQueueFlag = 'None';

  bgCheckForm: FormGroup;
  countryStates: CountryStateModel;
  activeModal: NgbModalRef;
  isSubmitDisabled = false;
  showLargeSplashResults = false;
  hasSubmittedForm = false;
  backgroundStatusNum = 0;
  isIdentityHidden = true; // defaults to true and toggle to false to show identity component
  isIdentityVerified = false;
  dontPrefillForm = false;
  isUpdatingInformation: HashTable<string> = {};
  updatingInformationCount = 0;
  interval;
  loadedData: ClientContactModel;
  hasUnexpiredApprovedBackgroundCheck = false;
  wasIdentityCheckCreatedLocally = false;
  maxDate = new Date();
  inquiryGuid = '';
  tenants: BookingTenantModel[] = [];
  bgCheckLandingPageBooking: BookingWizardModel;
  showSpinner = false;

  runOnlyIdentityCheck = false;

  regexSsn = REGEX_SSN;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private fb: FormBuilder,
    private countryStateService: CountryStateService,
    public logger: NGXLogger,
    private modalService: NgbModal,
    private identityVerificationService: IdentityVerificationService,
    private router: Router,
    private utilityService: UtilityService,
    public screenService: ScreenService,
    private activatedRoute: ActivatedRoute,
    private tagManagerService: TagManagerService) {
    super(logger);
    this.initForm();
  }

  ngOnChanges() {
    this.prefillForm(this.userService.clientContact);
  }

  ngOnInit() {

    if (this.activatedRoute.snapshot.params['guid']) {
      this.logger.debug('inquiry guid found in url', this.activatedRoute.snapshot.params['guid']);
      this.inquiryGuid = this.activatedRoute.snapshot.params['guid'];
    }

    // check to see if this page is acting as a standalone identity check
    // if so, set our variables up and add content to the page
    if (this.router.url.includes(ROUTE_PORTAL_IDENTITY_CHECK)) {
      this.runOnlyIdentityCheck = true;
      this.header = 'Identity Verification';
      this.subtext = `It looks like our system didn't recognize you that time. Because of this, we need to ask you to do a second identity verification. Please review the information below for accuracy before submitting. Do not worry, <span style="font-weight: 600;">this does not effect your credit score.</span>`;
    } else if (this.activatedRoute.snapshot.params['guid']) {

      this.showSpinner = true;

      // if we have a guid and its not an identity check, then its a bg check landing page
      // so lets validate that the current user is a tenant before we proceed
      // and load booking details

      this.apiService.getBooking(this.activatedRoute.snapshot.params['guid']).subscribe(response => {
        if (response) {
          this.bgCheckLandingPageBooking = response;
          this.logger.debug('booking found for landing page', this.bgCheckLandingPageBooking);
          this.showSpinner = false;
        }
      },
        error => {
          this.logger.error('there was an issue loading the booking', error);
          this.showSpinner = false;
        });

      this.apiService.getBookingTenants(this.inquiryGuid).subscribe(response => {

        if (response) {
          this.tenants = response;
          var isTenantOnBooking = false;
          response.forEach(tenant => {
            if (tenant.clientContact.clientContactGuid === this.userService.clientContact.clientContactGuid) {
              isTenantOnBooking = true;
            }
          });
          // if current user is not found on the booking, then we need to kick them out
          if (!isTenantOnBooking) {
            this.logger.debug('Tenant was not found on booking');
            this.router.navigate([ROUTE_PORTAL_HOME], {
              state: {
                error: 'You are no longer a tenant on the booking'
              }
            });
          } else {
            this.logger.debug('Tenant verified to be a part of the booking');
          }
        }
      },
        error => {
          // if for some reason we cant validate that this tenant should be here lets let them continue just so we don't deteriorate from the UX journey
          this.logger.info('Tenants could not be loaded.');
        });
    }

    // create a deep copy of our loaded user service so that we can check if any properties have changed
    this.loadedData = JSON.parse(JSON.stringify(this.userService.clientContact));

    // set the text for add tenant page
    if (!this.booking && !this.runOnlyIdentityCheck) {
      const bgCheck = Utils.getStatusCheckOfType(this.userService.clientContact.statusChecks, STATUS_CHECK_BACKGROUND);
      this.header = 'Background Check';
      this.logger.log('Does client have a previous background check?', bgCheck, Utils.getStatus(bgCheck));

      // if they have a background check already then let see if it's expired or not..
      if (bgCheck && bgCheck.status) {
        const status = Utils.getStatus(bgCheck);
        // if expired, then we just need messaging saying as much...
        this.header = 'Please confirm the information below is correct and press submit.';
        if (status === CreditStatus.APPROVED) {
          this.subtext = `Our records indicate that you have had a background check performed within the past year. If the
          information presented is still valid, please press submit to approve. If you need to make changes to sensitive information,
          you may do so and we will need to redo the background check.`;
          this.hasUnexpiredApprovedBackgroundCheck = true;
        } else {
          this.subtext = `Our records indicate that you have previously had a background check ran that is no longer valid.
           Please review the fields below making any changes necessary to reflect your current information and press submit to
           process your new background check.`;
        }
      } else {
        this.subtext = `Now, we need to run a quick background check. You're almost there!`;
      }
    }

    this.prefillForm(this.userService.clientContact);

    this.countryStateService.countryStates.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(
      (response: CountryStateModel) => {
        this.countryStates = response;
      }
    );

    // in case we need to verify their identity, we need to know when to dismiss our modal or handle an error
    this.identityVerificationService.identityCheckCreated$.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(response => {
      if (response !== null && this.wasIdentityCheckCreatedLocally) {
        if (response === 'error') {
          // we really only expect to get an error if the identity service is down for some reason
          // so lets make the page usable again after showing them an info message
          this.handleIdentityServiceError();
        } else {
          // unhide identity component so we can proceed with questioning them
          this.isIdentityHidden = false;

          if (this.activeModal) {
            this.activeModal.close();
          }
        }
      }
    });

    this.identityVerificationService.identityCheckSubmittedError$.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(response => {
      if (response != null) {
        // if they submit their response, we will try to resubmit it once, but if it doesnt work
        // we need to reset them back to the form so they can attempt to create another identity check 
        // because something went wrong if they received an error and not an accept / reject answer from TU
        this.isIdentityHidden = true;
        this.handleIdentityServiceError();
      }
    });

    // listen for changes to the identity status of this user so we can react accordingly
    this.identityVerificationService.identityStatusChanged.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(response => {
      if (response !== null) {
        this.isIdentityVerified = this.identityVerificationService.isIdentityVerified();

        if (this.identityVerificationService.isIdentityDenied()) {
          this.isIdentityHidden = false;
          return;
        }

        // if this is a identity only check page and they are verified, lets show their results
        if (this.isIdentityVerified && this.identityVerificationService.submitVerificationCode && this.runOnlyIdentityCheck) {
          this.isIdentityHidden = false;
          return;
        }

        // if they submitted a verification request and they are verified, then we need to submit their main check next
        if (this.isIdentityVerified && this.identityVerificationService.submittedVerification && this.wasIdentityCheckCreatedLocally) {

          // since they're verified and they submitted the form, lets go ahead and hide the component from view
          this.isIdentityHidden = true;

          if (this.runOnlyIdentityCheck === false) {
            this.submitBackgroundCheck();
          } else {
            this.isIdentityHidden = false;
          }

        }
      }
    });
  }

  getAuthorizationCheckboxText(): string {
    if (this.runOnlyIdentityCheck) {
      return 'I authorize identity verification';
    } else if (this.isIdentityVerified === false) {
      return 'I authorize criminal background check and identity verification';
    } else {
      return 'I authorize criminal background check';
    }
  }

  viewCreditCheckAuthorizationPage(e: any) {
    // add links for tos and credit check boxes
    if (e) {
      e.preventDefault();
    }

    this.modalService.open(WhyBackgroundCheckModalComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
  }

  viewTermsOfService(e: any) {
    // add links for tos and credit check boxes
    if (e) {
      e.preventDefault();
    }

    this.modalService.open(TermsOfServiceModalComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
  }

  handleIdentityServiceError(): void {
    // close the current modal and assign the new modal to the active modal object
    if (this.activeModal) {
      this.activeModal.close();
    }

    // we don't need to show a modal here because the one from the
    // credit check page has a listener attached to it that will display if there's an issue (on booking wizard)
    if (!this.booking) {
      this.activeModal = this.modalService.open(this.identityErrorModal, {
        centered: true,
        backdrop: 'static',
        windowClass: 'onboarding-modal',
        size: 'sm'
      });
    }

    this.isSubmitDisabled = false;
  }

  handleScreeningServiceError(): void {
    // close the current modal and assign the new modal to the active modal object
    if (this.activeModal) {
      this.activeModal.close();
    }

    this.activeModal = this.modalService.open(this.screeningErrorModal, {
      centered: true,
      backdrop: 'static',
      windowClass: 'onboarding-modal',
      size: 'sm'
    });

    this.isSubmitDisabled = false;
  }

  onPhoneBlur() {
    // Note: don't use isInvalid in case autofilled forms.
    // Reference: https://stackoverflow.com/questions/41932810/angular-2-and-browser-autofill
    if (!this.bgCheckForm.get('phone').invalid) {
      this.userService.clientContact.globalContactDetails.primaryPhoneNumber = this.bgCheckForm.get('phone').value;

      this.updateContactDetails();
    }
  }

  onEmailBlur() {
    // Note: don't use isInvalid in case autofilled forms.
    // Reference: https://stackoverflow.com/questions/41932810/angular-2-and-browser-autofill
    if (!this.bgCheckForm.get('email').invalid) {
      this.userService.clientContact.globalContactDetails.primaryEmailAddress = this.bgCheckForm.get('email').value;

      this.updateContactDetails();
    }
  }

  onAddressBlur() {
    if (this.hasValidAddressFields()) {
      const countryKey = this.bgCheckForm.get('country').value;

      this.userService.clientContact.globalContactDetails.streetAddressLine1 = this.bgCheckForm.get('street').value;
      this.userService.clientContact.globalContactDetails.city = this.bgCheckForm.get('city').value;
      this.userService.clientContact.globalContactDetails.postalCode = this.bgCheckForm.get('zip').value;
      this.userService.clientContact.globalContactDetails.globalStateProvId = this.bgCheckForm.get('state').value;
      this.userService.clientContact.globalContactDetails.globalCountryId = this.countryStates.countries[countryKey][0].countryId;

      this.updateContactDetails();
    }
  }

  onDetailsBlur() {
    const generalDetails: ClientContactModel = {
      clientContactGuid: this.userService.clientContact.clientContactGuid,
      firstName: this.getValidControlStringValue('firstName'),
      lastName: this.getValidControlStringValue('lastName'),
      dob: this.getValidControlDateValue('dob'),
      ssn: this.getValidControlStringValue('ssn'),
      jobTitle: this.getValidControlStringValue('jobTitle'),
      driversLicenseNo: this.getValidControlStringValue('driversLicenseNo'),
      driversLicenseStateId: this.getValidControlNumberValue('driversLicenseStateId'),
      passportNo: this.getValidControlStringValue('passportNo'),
      termsAndConditions: this.getValidControlBooleanValue('tosAgreement'),
      statusCheckAuthorization: this.getValidControlBooleanValue('bgAgreement')
      
    };
    
    this.userService.clientContact.firstName = generalDetails.firstName;
    this.userService.clientContact.lastName = generalDetails.lastName;
    this.userService.clientContact.dob = generalDetails.dob;
    this.userService.clientContact.jobTitle = generalDetails.jobTitle;
    this.userService.clientContact.driversLicenseStateId = generalDetails.driversLicenseStateId;

    this.userService.setUserStorage(this.userService.clientContact);

    this.updateDetailsInfo(generalDetails);
  }

  onEmergencyContactBlur() {

    // use a timer to track the calls to this method
    // every blur event for emergency contact fields will start a timer that will wait up to 3 seconds
    // if another blur event is received and the API is already being updated, the next n number of calls will be queued into 1 call to occur 3 seconds after the latest API call is finished
    // this guarantees that if emergency contact is null, then the record will be created without any risk of duplicate records
    const this$ = this;
    clearTimeout(this.emergencyContactTimer);

    // if we dont have an emergency contact record yet, set the timer to 3 seconds, otherwise just half a second so the delay isn't too long
    const timerAmount = this.userService.clientContact.emergencyContact == null ? 3000 : 500;
    this.emergencyContactTimer = setTimeout(function () {

      const emContactDetails: EmergencyContactModel = {
        id: this$.userService.clientContact.emergencyContact != null ? this$.userService.clientContact.emergencyContact.id : 0,
        relativeName: this$.getValidControlStringValue('emergencyContact', 'relativeName'),
        relativePhoneNumber: this$.getValidControlStringValue('emergencyContact', 'relativePhoneNumber'),
        relativeRelationship: this$.getValidControlStringValue('emergencyContact', 'relativeRelationship'),
        relativeStreetAddressLine1: this$.getValidControlStringValue('emergencyContact', 'relativeStreetAddressLine1'),
        relativeStreetAddressLine2: '',
        relativeCity: this$.getValidControlStringValue('emergencyContact', 'relativeCity'),
        relativeGlobalStateProvId: this$.getValidControlStringValue('emergencyContact', 'relativeGlobalStateProvId'),
        relativeGlobalCountryId: this$.getValidControlStringValue('emergencyContact', 'relativeGlobalCountryId')
          ? this$.countryStates.countries[this$.getValidControlStringValue('emergencyContact', 'relativeGlobalCountryId')][0].countryId
          : null,
        relativePostalCode: this$.getValidControlStringValue('emergencyContact', 'relativePostalCode'),
        companyName: this$.getValidControlStringValue('emergencyContact', 'companyName'),
        supervisor1Name: this$.getValidControlStringValue('emergencyContact', 'supervisor1Name'),
        supervisor1PhoneNumber: this$.getValidControlStringValue('emergencyContact', 'supervisor1PhoneNumber'),
        superviser2Name: this$.getValidControlStringValue('emergencyContact', 'superviser2Name'),
        supervisor2PhoneNumber: this$.getValidControlStringValue('emergencyContact', 'supervisor2PhoneNumber'),
        type: 'placeholder'
      };

      this$.logger.log('deets', emContactDetails);

      if (this$.emergencyContactBlurFlag !== 'Sending') {
        this$.emergencyContactBlurFlag = 'Sending';

        this$.apiService.updateEmergencyContact(emContactDetails, this$.userService.clientContact.clientContactGuid).subscribe(
          response => {
            // 200 is a success
            this$.userService.clientContact.emergencyContact = response;
            this.userService.setUserStorage(this.userService.clientContact);
            this$.emergencyContactBlurFlag = 'Sent';
          },
          error => {
            this$.logger.error(error);
            this$.emergencyContactBlurFlag = 'Sent';
          }
        );

      } else if (this$.emergencyContactBlurQueueFlag !== 'Queued') {
        this$.emergencyContactBlurQueueFlag = 'Queued';

        const interval = setInterval(function () {
          if (this$.emergencyContactBlurFlag === 'Sent') {
            clearInterval(interval);
            this$.emergencyContactBlurQueueFlag = 'None';
            this$.onEmergencyContactBlur();
          }
        }, 100);

      }
    }, timerAmount);
  }

  getValidControlStringValue(controlName: string, nestedControlName?: string): any {
    if (nestedControlName != null) {
      return this.bgCheckForm.get(controlName).get(nestedControlName).valid ?
        this.bgCheckForm.get(controlName).get(nestedControlName).value : '';
    }
    // reactive forms treat disabled fields as not valid, thats why we have to check if the controlName is diabled to still be able to get the value using form.inactive instead
    if (this.bgCheckForm.get(controlName).disabled){
      return !this.bgCheckForm.get(controlName).invalid ? this.bgCheckForm.get(controlName).value : '';
    }
    // If valid, return value. Otherwise return an empty string
    return this.bgCheckForm.get(controlName).valid ? this.bgCheckForm.get(controlName).value : '';
  }
  getValidControlDateValue(controlName: string): any {
    // If valid, return value. Otherwise return null
    return this.bgCheckForm.get(controlName).valid ? this.bgCheckForm.get(controlName).value : null;
  }

  getValidControlNumberValue(controlName: string): any {
    // If valid, return value. Otherwise return 0
    return this.bgCheckForm.get(controlName).valid ? this.bgCheckForm.get(controlName).value : 0;
  }

  getValidControlBooleanValue(controlName: string): any {
    // If valid, return value. Otherwise return false
    return this.bgCheckForm.get(controlName).valid ? this.bgCheckForm.get(controlName).value : false;
  }

  createIdentityRequestAfterServerReturn(): void {
    const this$$ = this;

    // check our hashtable to ensure all requests have returned from the server to save data before sending the request out
    if (Object.keys(this.isUpdatingInformation).length) {
      this.interval = setInterval(function () {
        this$$.logger.log('checking interval..keys found: ', this$$.isUpdatingInformation);
        if (!Object.keys(this$$.isUpdatingInformation).length) {
          this$$.logger.log('interval cleared!');
          clearInterval(this$$.interval);
          this$$.wasIdentityCheckCreatedLocally = true;
          this$$.identityVerificationService.createIdentityRequest();
        }
      }, 50);
    } else {
      this$$.wasIdentityCheckCreatedLocally = true;
      this.identityVerificationService.createIdentityRequest();
    }
  }

  confirmBackgroundCheckSubmission(): void {

    this.isSubmitDisabled = true;

    // if in the booking wizard, we will do a company on hold check first
    if (this.booking) {
      const this$ = this;
      this.utilityService.checkIfCompanyIsOnHold(this.booking.clientContact.clientCompanyGuid, true, function () {
        if (this$.isIdentityVerified) {
          this$.submitBackgroundCheck();
        } else {
          // we need to proceed with an identity check before we can do the background check
          // check if any server calls are still waiting to return before allowing the identity check to run
          this$.createIdentityRequestAfterServerReturn();
        }
      }, function () {
        this$.isSubmitDisabled = false;
      });
    } else {
      if (this.isIdentityVerified && !this.runOnlyIdentityCheck) {
        this.submitBackgroundCheck();
      } else {
        // we need to proceed with an identity check before we can do the background check
        this.createIdentityRequestAfterServerReturn();
      }
    }

  }

  openLoadingModal(): void {
    this.activeModal = this.modalService.open(this.loadingModal, {
      backdropClass: 'loading-backdrop',
      centered: true, backdrop: 'static',
      windowClass: 'loading-modal'
    });
  }

  openBackgroundCheckStartModal(): void {

    // ensure background check form is valid before continuing
    if (this.validateBackgroundCheckForm() === false) {
      return;
    }

    // if any modals are currently open for this component, lets close those since we are about to open a new one
    if (this.activeModal) {
      this.activeModal.close();
    }

    this.confirmBackgroundCheckSubmission();
  }

  haveRequiredFieldsChanged(): boolean {
    if (this.bgCheckForm.get('firstName').value !== this.loadedData.firstName) {
      this.logger.log('first name has changed');
      return true;
    } else if (this.bgCheckForm.get('lastName').value !== this.loadedData.lastName) {
      this.logger.log('last name has changed');
      return true;
    } else if (this.loadedData.globalContactDetails.primaryPhoneNumber
      && this.bgCheckForm.get('phone').value !== this.loadedData.globalContactDetails.primaryPhoneNumber) {//parsePhoneNumber(this.loadedData.globalContactDetails.primaryPhoneNumber, 'US').formatNational()) {
      this.logger.log('phone has changed');
      return true;
    } else if (new Date(this.bgCheckForm.get('dob').value).getTime() !== new Date(this.loadedData.dob).getTime()) {
      this.logger.log('date has changed');
      return true;
    } else if (this.bgCheckForm.get('ssn').value !== this.loadedData.ssn) {
      this.logger.log('ssn has changed');
      return true;
    } else if (this.bgCheckForm.get('driversLicenseNo').value !== this.loadedData.driversLicenseNo) {
      this.logger.log('driver license has changed');
      return true;
    } else if (this.bgCheckForm.get('driversLicenseStateId').value !== this.loadedData.driversLicenseStateId) {
      this.logger.log('driver license state has changed');
      return true;
    } else if (this.bgCheckForm.get('street').value !== this.loadedData.globalContactDetails.streetAddressLine1) {
      this.logger.log('street has changed');
      return true;
    } else if (this.bgCheckForm.get('city').value !== this.loadedData.globalContactDetails.city) {
      this.logger.log('city has changed');
      return true;
    } else if (this.bgCheckForm.get('state').value !== this.loadedData.globalContactDetails.globalStateProvId) {
      this.logger.log('state has changed');
      return true;
    } else if (this.bgCheckForm.get('zip').value !== this.loadedData.globalContactDetails.postalCode) {
      this.logger.log('zip has changed');
      return true;
    } else if (this.bgCheckForm.get('country').value !==
      this.countryStateService.countriesById[this.loadedData.globalContactDetails.globalCountryId]) {
      this.logger.log('country has changed');
      return true;
    }
    return false;
  }

  submitBackgroundCheck(): void {

    if (this.validateBackgroundCheckForm()) {

      if (this.activeModal) {
        this.activeModal.close();
      }

      this.openLoadingModal();
      this.showLargeSplashResults = true;

      const model: SubmitScreeningModel = {
        requestBackgroundCheck: true,
        requestCreditCheck: false,
        inquiryGuid: this.booking ? this.booking.inquiry.guid : this.inquiryGuid
      };

      this.apiService.createCreditOrBackgroundCheck(this.userService.clientContact.clientContactGuid, model).subscribe(response => {
        this.handleScreeningSubmit(response);
      }, error => {
        this.hasSubmittedForm = false;
        this.showLargeSplashResults = false;
        this.handleScreeningServiceError();
        this.logger.error(error);
      });
    } else {
      this.logger.log('would not have submitted..');
    }
  }

  countryChanged(field: string) {
    // when the country dropdown is changed, we will select the first state for them by
    // default to keep the country & state selections consistent
    if (field == 'country') { // address country
      const states = this.getStateArray(this.bgCheckForm.get('country').value);
      const stateId = states && states.length ? states[0].stateId : null;
      this.bgCheckForm.patchValue({ state: stateId });
    } else if (field == 'emergencyCountry') {  // emergency contact country
      const states = this.getStateArray(this.bgCheckForm.get('emergencyContact.relativeGlobalCountryId').value);
      const stateId = states && states.length ? states[0].stateId : null;
      (<FormGroup>this.bgCheckForm.get('emergencyContact')).patchValue({ relativeGlobalStateProvId: stateId });
    }
  }

  handleScreeningSubmit(response: StatusCheckModel[]): void {
    // Background check is submitted
    this.hasSubmittedForm = true;

    const backgroundCheck = Utils.getStatusCheckOfType(response, STATUS_CHECK_BACKGROUND);
    this.logger.log('bg check submitted...', response);

    if (backgroundCheck) {
      var backgroundStatus = Utils.getStatus(backgroundCheck);

      if (backgroundStatus === CreditStatus.APPROVED) {
        this.tagManagerService.pushBackgroundCheckStatus('approved');
      } else if (backgroundStatus === CreditStatus.DENIED) {
        this.tagManagerService.pushBackgroundCheckStatus('denied');
      } else if (backgroundStatus === CreditStatus.IN_REVIEW) {
        this.tagManagerService.pushBackgroundCheckStatus('inReview');
      }
    }

    // if this was a booking, lets update our new status check
    if (this.booking && this.booking.clientContact) {
      Utils.replaceStatusCheckWithNewCheck(this.booking.clientContact.statusChecks, backgroundCheck);
      Utils.updateBookingAgentTenantObject(this.booking);

      this.logger.debug('booking wizard bg checks updated!', this.booking.clientContact.statusChecks);
    }

    // either way we should update this persons overall profile in case they
    // came from the landing page and go somewhere else in the portal that needs this info..
    Utils.replaceStatusCheckWithNewCheck(this.userService.clientContact.statusChecks, backgroundCheck);

    this.logger.debug('bg checks updated!', this.userService.clientContact.statusChecks);

    const bagCheck = Utils.getStatusCheckOfType(this.userService.clientContact.statusChecks, STATUS_CHECK_BACKGROUND);
    this.backgroundStatusNum = Utils.getStatus(bagCheck);

    // send out the signal that the bg check is complete
    this.formSubmitted.emit();
    if (this.activeModal) {
      this.activeModal.dismiss();
    }
  }

  // once the status has been acknowledged, we dont wanna show the splash results anymore
  acknowledgeStatus(): void {
    this.acknowledgedStatus.emit('done');

    // if this is a landing page i.e. removeSidebar is true, then we can just take them to the portal home afterwards
    if (this.removeSidebar) {
      this.router.navigate([ROUTE_PORTAL_HOME]);
    }
  }

  updateDetailsInfo(generalInfo: ClientContactModel): void {
    // Send update to server

    let currentUpdateId = this.updatingInformationCount++;
    this.isUpdatingInformation[currentUpdateId] = 'fetching';

    this.apiService.updateClientContactBasicInfo(generalInfo)
      .subscribe(
        response => {

          if (!this.userService.clientContact.profileComplete && Utils.isClientContactProfileComplete(response)) {
            this.userService.clientContact.profileComplete = true;
            generalInfo.profileComplete = true;
            this.apiService.updateClientContactBasicInfo(generalInfo).subscribe(res => {
              this.logger.log('updated profile complete');
            }, error => {
              this.logger.error('error trying to update profile complete');
            })
          }

          // 200 response is a success
          delete this.isUpdatingInformation[currentUpdateId];
        },
        error => {
          this.logger.error(error);
          delete this.isUpdatingInformation[currentUpdateId];
        }
      );
  }

  validateBackgroundCheckForm(): boolean {
    this.logger.log('touching bg check form');
    Utils.touchFormFields(this.bgCheckForm);
    return this.bgCheckForm.valid;
  }

  updateContactDetails(): void {

    let currentUpdateId = this.updatingInformationCount++;
    this.isUpdatingInformation[currentUpdateId] = 'fetching';
    this.apiService.updateContactDetails(
      this.userService.clientContact.clientContactGuid,
      this.userService.clientContact.globalContactDetails,
      'clientcontacts'
    ).subscribe(
      response => {

        // 200 response is a success
        delete this.isUpdatingInformation[currentUpdateId];
      },
      error => {
        this.logger.error(error);
        delete this.isUpdatingInformation[currentUpdateId];
      }
    );
  }

  hasValidAddressFields(): boolean {
    return !this.bgCheckForm.get('street').invalid &&
      !this.bgCheckForm.get('city').invalid &&
      !this.bgCheckForm.get('zip').invalid &&
      !this.bgCheckForm.get('state').invalid &&
      !this.bgCheckForm.get('country').invalid;
  }

  getStateArray(country?: string): StateModel[] {
    // Handle loading case
    if (this.countryStates == null) {
      return null;
    }

    // Use selected country as hash key. 'United States' is default.
    // If country was entered, provide the state list for the specific country.
    // Otherwise use the country that has been selected on the form.
    return country ? this.countryStates.countries[country]
      : this.countryStates.countries[this.bgCheckForm.get('country').value];
  }

  getEmergencyContactStateArray(country?: string): StateModel[] {
    // Handle loading case
    if (this.countryStates == null) {
      return null;
    }

    // Use selected country as hash key. 'United States' is default.
    // If country was entered, provide the state list for the specific country.
    // Otherwise use the country that has been selected on the form.
    return country ? this.countryStates.countries[country]
      : this.countryStates.countries[this.bgCheckForm.get('emergencyContact').get('relativeGlobalCountryId').value];
  }

  getCountryArray(): string[] {
    // Handle loading case
    if (this.countryStates == null) {
      return null;
    }
    // Use hash keys as country choices
    return Object.keys(this.countryStates.countries);
  }

  isInvalid(controlName: string): boolean {
    // Modify control name to use the nested 'emergencyContact' controls if control name contains emergencyContact
    // Ex: 'emergencyContact.relativePhoneNumber'
    const control = this.getControlName(controlName);

    return control.invalid && (control.dirty || control.touched);
  }

  // Parse control name for potential nested components
  getControlName(controlName: string): AbstractControl {
    const sepArray = controlName.split('.');

    // Return if split worked with more than 1 entry.
    return sepArray.length > 1 ? this.bgCheckForm.get('emergencyContact').get(sepArray[1]) : this.bgCheckForm.get(controlName);
  }

  onPhoneInputChange(controlName: string): void {
    // *** FORMATTING REMOVED FOR NOW BUT MAY RETURN AT A LATER DATE *** //
    // Parse control name for nested controls if needed
    // const control = this.getControlName(controlName);
    // if (control.value == null || control.value.length < 5) {
    //   return;
    // }

    // Format phone number as the user is typing.
    //control.patchValue(new AsYouType('US').input(control.value));
  }

  initForm(): void {
    this.bgCheckForm = this.fb.group({
      firstName: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
      lastName: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
      dob: ['', [Validators.required]],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      ssn: ['', [Validators.required, Validators.maxLength(9)]],
      jobTitle: [''],
      driversLicenseNo: ['', [Validators.required, Validators.pattern(REGEX_ALPHANUMERIC), Validators.maxLength(50)]],
      driversLicenseStateId: ['', [Validators.required]],
      passportNo: [''],
      street: ['', [Validators.required, Validators.pattern(REGEX_TRANSUNION_ADDRESS)]],
      city: ['', [Validators.required, Validators.minLength(3), Validators.pattern(REGEX_TRANSUNION_CITY)]],
      zip: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(9), Validators.pattern(REGEX_NUMBERS)]],
      state: ['', [Validators.required]],
      country: ['United States', [Validators.required]],
      tosAgreement: ['', [Validators.requiredTrue]],
      bgAgreement: ['', [Validators.requiredTrue]],
      emergencyContact: this.fb.group({
        relativeName: ['', [Validators.required]],
        relativeRelationship: ['', [Validators.required]],
        relativePhoneNumber: ['', [Validators.required]],
        relativeStreetAddressLine1: [''],
        relativeCity: [''],
        relativeGlobalStateProvId: [''],
        relativeGlobalCountryId: [''],
        relativePostalCode: [''],
        companyName: [''],
        supervisor1PhoneNumber: [''],
        supervisor1Name: [''],
        superviser2Name: [''],
        supervisor2PhoneNumber: [''],
        type: ['placeholder']
      })
    });
  }

  prefillForm(clientContact: ClientContactModel): void {
    this.bgCheckForm.patchValue({
      firstName: clientContact.firstName,
      lastName: clientContact.lastName,
      email: clientContact.globalContactDetails.primaryEmailAddress,
      dob: clientContact.dob,
      phone: Utils.getParsedPhoneNumber(clientContact.globalContactDetails.primaryPhoneNumber),
      ssn: clientContact.ssn,
      jobTitle: clientContact.jobTitle,
      driversLicenseNo: clientContact.driversLicenseNo,
      driversLicenseStateId: clientContact.driversLicenseStateId,
      passportNo: clientContact.passportNo,
      street: clientContact.globalContactDetails.streetAddressLine1,
      city: clientContact.globalContactDetails.city,
      state: clientContact.globalContactDetails.globalStateProvId,
      zip: clientContact.globalContactDetails.postalCode,
      country:
        this.countryStateService.countriesById[clientContact.globalContactDetails.globalCountryId] != null
          ? this.countryStateService.countriesById[clientContact.globalContactDetails.globalCountryId] : 'United States'
      ,
      tosAgreement: '',
      bgAgreement: '',
      emergencyContact: {
        relativeName: clientContact.emergencyContact != null ? clientContact.emergencyContact.relativeName : '',
        relativeRelationship: clientContact.emergencyContact != null ? clientContact.emergencyContact.relativeRelationship : '',
        relativePhoneNumber: clientContact.emergencyContact != null ? Utils.getParsedPhoneNumber(clientContact.emergencyContact.relativePhoneNumber) : '',
        relativeStreetAddressLine1: clientContact.emergencyContact != null ? clientContact.emergencyContact.relativeStreetAddressLine1 : '',
        relativeCity: clientContact.emergencyContact != null ? clientContact.emergencyContact.relativeCity : '',
        relativeGlobalStateProvId: clientContact.emergencyContact != null ? clientContact.emergencyContact.relativeGlobalStateProvId : '',
        relativeGlobalCountryId: clientContact.emergencyContact != null && this.countryStateService.countriesById[clientContact.emergencyContact.relativeGlobalCountryId] != null
          ? this.countryStateService.countriesById[clientContact.emergencyContact.relativeGlobalCountryId]
          : 'United States',
        relativePostalCode: clientContact.emergencyContact != null ? clientContact.emergencyContact.relativePostalCode : '',
        companyName: clientContact.emergencyContact != null ? clientContact.emergencyContact.companyName : '',
        supervisor1PhoneNumber: clientContact.emergencyContact != null ? Utils.getParsedPhoneNumber(clientContact.emergencyContact.supervisor1PhoneNumber) : '',
        supervisor1Name: clientContact.emergencyContact != null ? clientContact.emergencyContact.supervisor1Name : '',
        superviser2Name: clientContact.emergencyContact != null ? clientContact.emergencyContact.superviser2Name : '',
        supervisor2PhoneNumber: clientContact.emergencyContact != null ? Utils.getParsedPhoneNumber(clientContact.emergencyContact.supervisor2PhoneNumber) : '',
        type: ['placeholder']
      }
    });
  }

  get firstName() { return this.bgCheckForm.get('firstName') }
  get lastName() { return this.bgCheckForm.get('lastName') }
  get email() { return this.bgCheckForm.get('email') }
  get ssn() { return this.bgCheckForm.get('ssn') }
  get licenseNumber() { return this.bgCheckForm.get('driversLicenseNo') }
  get street() { return this.bgCheckForm.get('street') }
  get city() { return this.bgCheckForm.get('city') }
  get zip() { return this.bgCheckForm.get('zip') }
  get state() { return this.bgCheckForm.get('state') }
}
