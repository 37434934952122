import { Injectable } from '@angular/core';
import { ImportDataModel } from 'app/models/ImportDataModel';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { LookupService } from './lookup.service';
import { TagManagerService } from './tag-manager.service';
import { Router } from '@angular/router';

@Injectable()
export class ImportDataService {
  importedData: ImportDataModel;
  localStorageToken = 'AUTH_TOKEN';
  localStorageRefreshGuid = 'REFRESH_TOKEN';
  importedDataBehaviorSub = new BehaviorSubject<string>(null);

  constructor(private userService: UserService,
    private apiService: ApiService,
    private logger: NGXLogger,
    private tagManagerService: TagManagerService,
    private router: Router) { }

  public initFromGuard(token?: string) {
    if (token) {
      this.logger.debug('token found: ', { token });

      // this.apiService.getWebsiteToken(token).subscribe(
      //   response => {

      //     const tokenResponse = response;
      //     this.logger.debug('Token response retrieval', tokenResponse);

      //     if (tokenResponse.Success && tokenResponse.Data) {

      //       this.logger.debug('Token response success');

      //       // read the response into the imported data service so the app can use it
      //       this.init(
      //         tokenResponse.Data.token,
      //         tokenResponse.Data.refreshGuid,
      //         tokenResponse.Data.booking.propertyGuid,
      //         tokenResponse.Data.booking.moveInDate,
      //         tokenResponse.Data.booking.moveOutDate,
      //         tokenResponse.Data.booking.selectedUnit
      //       );
      //     }

      //     this.handleAuthToken();
      //   },
      //   error => {
      //     // clear our the storage incase a different profile or data is being held onto
      //     localStorage.clear();
      //     this.logger.error(error);
      //     this.logger.warn('Token retrieval failed');

      //     // if we dont reach the user service, we still want to fire a gtm event before the user gets redirected back to the public login
      //     this.tagManagerService.onPageLoad('', '', '', false);

      //     this.importedDataBehaviorSub.error('error');
      //     this.userService.redirectToPublicSite('token retrieval failed', false);
      //   });

    } else {
      this.handleAuthToken();
    }
  }

  public initFromOpenGuard(token?: string) {
    if (token) {
      this.logger.debug('token found: ', { token });

      // this.apiService.getWebsiteToken(token).subscribe(
      //   response => {

      //     const tokenResponse = response;
      //     this.logger.debug('Token response retrieval', tokenResponse);

      //     if (tokenResponse.Success && tokenResponse.Data) {

      //       this.logger.debug('Token response success');

      //       // read the response into the imported data service so the app can use it
      //       this.init(
      //         tokenResponse.Data.token,
      //         tokenResponse.Data.refreshGuid,
      //         tokenResponse.Data.booking.propertyGuid,
      //         tokenResponse.Data.booking.moveInDate,
      //         tokenResponse.Data.booking.moveOutDate,
      //         tokenResponse.Data.booking.selectedUnit
      //       );
      //     }

      //     this.handleAuthToken();
      //   },
      //   error => {
      //     // clear our the storage incase a different profile or data is being held onto
      //     localStorage.clear();
      //     this.logger.error(error);
      //     this.logger.warn('Token retrieval failed');

      //     // if we dont reach the user service, we still want to fire a gtm event before the user gets redirected back to the public login
      //     this.tagManagerService.onPageLoad('', '', '', false);

      //     this.importedDataBehaviorSub.error('error');
      //     this.userService.redirectToPublicSite('token retrieval failed', false);
      //   });
    } else {
      this.handleAuthToken(false);
    }
  }

  public logout(redirect: boolean = true): void {
    if (localStorage) {
      localStorage.clear();
    }

    if (sessionStorage) {
      sessionStorage.clear();
    }

    this.userService.clientContact = null;
    this.importedData = null;

    if (redirect) {
      location.href = "/";
    }
  }

  public init(authToken: string, refreshGuid: string, propertyGuid: string, checkInDate: Date, checkOutDate: Date, selectedUnit: number) {
    this.importedData = {
      authToken: authToken,
      refreshGuid: refreshGuid,
      propertyGuid: propertyGuid,
      checkInDate: checkInDate,
      checkOutDate: checkOutDate,
      selectedUnit: selectedUnit
    };

    this.saveToLocal();

    if (authToken != null) {
      this.importedDataBehaviorSub.next('loaded');
    }
  }

  handleAuthToken(attemptRefresh = true) {

    this.logger.debug('handle auth token...');

    // Decode jwt for user data and store it in the user service
    if (this.importedData && this.importedData.authToken) {
      this.userService.init(new JwtHelperService().decodeToken(this.importedData.authToken), this.importedData.authToken);

      // Save token if user refreshes app.
      this.saveToLocal();
    } else {
      // Attempt to use local jwt token
      const localToken = localStorage.getItem(this.localStorageToken);
      const localRefresh = localStorage.getItem(this.localStorageRefreshGuid);

      this.init(localToken, localRefresh, '', new Date(), new Date(), 0);
      if (localToken != null && localToken !== '') {
        this.userService.init(new JwtHelperService().decodeToken(localToken), localToken);
      } else {
        if (attemptRefresh) {
          this.logger.debug('attempting refresh');
          // Local token not found. Send user back to search site for renewal token or to log back in.
          this.attemptToRefreshToken();
        }
      }
    }
  }

  saveToLocal(): void {
    if (this.importedData.authToken) {
      localStorage.setItem(this.localStorageToken, this.importedData.authToken);
    }

    if (this.importedData.refreshGuid) {
      localStorage.setItem(this.localStorageRefreshGuid, this.importedData.refreshGuid);
    }
  }

  attemptToRefreshToken(): void {

    // no refresh token found
    if (!this.importedData.refreshGuid) {
      this.logger.debug('should redirect to public site for no refresh guid found');
      this.importedDataBehaviorSub.error('attempt to refresh token errored');
      this.userService.redirectToPublicSite('no refresh guid found');
      return;
    }

    this.apiService.getRefreshToken(this.importedData.refreshGuid).subscribe(
      response => {
        this.handleRefreshTokenResponseSuccess(response);
      },
      error => {
        // if there's an error it probably makes sense to redirect them as well
        this.logger.warn(error);
        this.importedDataBehaviorSub.error('error');
        this.userService.redirectToPublicSite(`get refresh token error.. ${error.error}`);
      }
    );
  }

  handleRefreshTokenResponseSuccess(response: any) {
    if (!response.Success) {
      // Redirect user to login
      this.importedDataBehaviorSub.error('error');
      this.userService.redirectToPublicSite('response from refresh token was not successful');
      return;
    }

    this.logger.debug('token refreshed', response);

    // Save new token to importedDataService and re-init userService.
    this.importedData.authToken = response.Data;
    this.userService.refreshPermissionsFromToken(new JwtHelperService().decodeToken(this.importedData.authToken), this.importedData.authToken);

    // Save token if user refreshes app.
    this.saveToLocal();
  }
}
