import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnDestroy {
  unsubscribeOnDestroy$ = new Subject<void>();

  constructor(public log: NGXLogger) { }

  ngOnDestroy(): void {
    this.log.info('ngOnDestroy :: BaseComponent...preparing to unsubscribe to observables');
    this.unsubscribeOnDestroy$.next();
    this.unsubscribeOnDestroy$.complete();
  }
}
