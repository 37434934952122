import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginModel, ResendEmailModel, LoginResponseModel } from 'app/models/UserJwtModel';
import { ApiResponseMessage } from 'app/models/ApiResponseModel';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ImportDataService } from 'app/services/import-data.service';
import { UserService } from 'app/services/user.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-activate',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent extends BaseComponent implements OnInit {
  errorMessage: any;
  token: string;
  isSuccess: boolean;
  isRedirecting = false;
  SendActivationAccountFormGroup: FormGroup;
  ResendEmailConfirmation: ResendEmailModel;
  loginFormFields: LoginModel;
  errorMessages: string[];

  constructor(private route: ActivatedRoute,
    private logger: NGXLogger,
    private router: Router,
    private apiService: ApiService,
    private fb: FormBuilder,
    private importDataService: ImportDataService,
    private userService: UserService,) {
    super(logger);
    this.InitForms();
  }

  ngOnInit() {
    this.importDataService.logout(false);

    this.token = this.route.snapshot.queryParamMap.get('token');
    if (this.token !== undefined) {
      this.isSuccess = true;
      this.confirmUser()
    }
  }

  confirmUser() {
    this.apiService.confirmAccount(this.token).subscribe((res) => {
      // if account is confirmed just redirect to home for now.
      // TODO: Add missing logic that takes care of redirectToken URL
      this.isSuccess = true;
      this.isRedirecting = false;
      // setTimeout(() => {
      //   this.router.navigate(['/account/sign-in']);
      // }, 5000);
    }, (error: HttpErrorResponse) => {
      this.isRedirecting = false;
      this.errorMessage = error.error;
      console.log(error);
    });
  }

  resendConfirmation(data) {
    console.log(data.resendEmail)
    this.ResendEmailConfirmation = {
      email: data.resendEmail
    }

    // TODO: need to fix this because I need to pass an authentication token, but I dont have it anymore (WIP)
    if (this.ResendEmailConfirmation.email != '' && this.token != '') {
      this.apiService.resendEmailConfirmation(this.ResendEmailConfirmation, this.token).subscribe(res => {

      },
        (error: HttpErrorResponse) => {
          console.log(error);
        });
    }

  }

  signInUser(data) {
    this.errorMessages = [];
    if (data.email != '' && data.password != '') {
      this.loginFormFields = {
        email: data.email,
        password: data.password
      }
      this.apiService.login(this.loginFormFields).subscribe((res: ApiResponseMessage<LoginResponseModel>) => {
        if (res.success) {
          const jwt = new JwtHelperService().decodeToken(res.data.token);
          this.importDataService.init(res.data.token, res.data.refreshTokenGuid, '', new Date(), new Date(), 0);
          this.userService.init(jwt, res.data.token);
          this.router.navigate(['/']);
        } else {
          this.errorMessages = res.messages;
        }
      },
        error => {
        });
    }
    else {
      this.logger.debug("Form is not valid")

    }
  }

  InitForms(): void {
    this.SendActivationAccountFormGroup = this.fb.group({
      resendEmail: [{ value: '', disabled: false }, [Validators.required]]
    })
  }

}
