import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { VerificationResponseModel } from 'app/models/verification-response-model';
import { VerificationQuestion } from 'app/models/verification-question';
import { BehaviorSubject, Observable, throwError, concat } from 'rxjs';
import { CreditStatus } from 'app/models/CreditStatusEnum';
import { UserService } from './user.service';
import Utils, { STATUS_CHECK_APPROVED, STATUS_CHECK_DENIED, STATUS_CHECK_IDENTITY } from 'app/common/Utils';
import { NGXLogger } from 'ngx-logger';
import { IdentityExamResponsesModel } from 'app/models/IdentityExamResponsesModel';
import { IdentityVerificationResult } from 'app/models/IdentityVerificationResult';
import { ClientContactModel } from 'app/models/ClientContactModel';
import { StatusCheckModel } from 'app/models/StatusCheckModel';
import { SubmitVerificationCodeModel } from 'app/models/SubmitVerificationCodeModel';
import { retryWhen, delay, take, concatMap, retry } from 'rxjs/operators';
import { TagManagerService } from './tag-manager.service';

@Injectable({
  providedIn: 'root'
})
export class IdentityVerificationService {

  constructor(private apiService: ApiService,
    private userService: UserService,
    private logger: NGXLogger,
    private tagManagerService: TagManagerService) {

    // load identity check once the users profile has been retrieved
    this.userService.clientContactBehaviorSub.subscribe(response => {
      if (response != null) {
        this.clientContact = response;
        this.checkIfIdentityHasChanged();
      }
    });
  }

  private identityCheckCreated = new BehaviorSubject<any>(null);
  private identityCheckSubmittedError = new BehaviorSubject<'otp' | 'questions'>(null);
  identityStatusChanged = new BehaviorSubject<any>(null);
  initialResponse: VerificationResponseModel;
  identityCheckCreated$ = this.identityCheckCreated.asObservable();
  identityCheckSubmittedError$ = this.identityCheckSubmittedError.asObservable();

  identityStatus = 0;
  clientContact: ClientContactModel;
  submittedVerification = false;

  isIdentityVerified(): boolean {
    return this.identityStatus === CreditStatus.APPROVED;
  }

  isIdentityDenied(): boolean {
    return this.identityStatus === CreditStatus.DENIED;
  }

  public checkIfIdentityHasChanged() {
    
    if (this.clientContact) {
      const oldStatus = this.identityStatus;
      const check = Utils.getStatusCheckOfType(this.userService.clientContact.statusChecks, STATUS_CHECK_IDENTITY);
      this.identityStatus = Utils.getStatus(check);

      this.logger.debug('checking if client identity check has changed', CreditStatus[oldStatus], CreditStatus[this.identityStatus]);
      if (oldStatus !== this.identityStatus) {
        this.identityStatusChanged.next(this.identityStatus);
      }
    }
  }

  createIdentityRequest(): void {
    this.apiService.createIdentityCheck(this.clientContact.clientContactGuid).subscribe(response => {
      this.initialResponse = response;

      this.identityCheckCreated.next(this.initialResponse);
      if (response.status === 1) {
        this._handleNewIdentityCheck(response.status);
      }

    }, error => {
      // throw errors so that any components subscribed can act accordingly
      this.initialResponse = null;
      this.identityCheckCreated.next('error');
    });
  }

  retrieveVerificationQuestions(): void {
    this.apiService.getIdentityQuestions(this.clientContact.clientContactGuid).subscribe(res => {
      if (res && res.examQuestions) {
        this.initialResponse.examQuestions = res.examQuestions;
      }
    });
  }

  // check if identity check is needed for credit check (company users are exempt)
  isIdentityCheckNeededForCreditCheck(clientContact: ClientContactModel): boolean {
    this.logger.log(!clientContact.clientCompanyGuid);
    return !clientContact || !clientContact.clientCompanyGuid || !clientContact.clientCompanyGuid.length;
  }

  submitVerificationQuestions(model: IdentityExamResponsesModel): void {
    this._handleVerificationResult(this.apiService.submitIdentityQuestions(this.clientContact.clientContactGuid, model), false);
  }

  submitVerificationCode(model: SubmitVerificationCodeModel): void {
    this._handleVerificationResult(this.apiService.submitIdentityCode(this.clientContact.clientContactGuid, model), true);
  }

  private _handleVerificationResult(apiCall: Observable<IdentityVerificationResult>, isOtp: boolean): void {
    apiCall.pipe(retry(1)).subscribe(response => {
      this._handleNewIdentityCheck(response.status);
    }, error => {
      this.logger.error(error);
      this.identityCheckSubmittedError.next(isOtp ? 'otp' : 'questions');
    });
  }

  private _handleNewIdentityCheck(status: number): void {
    // in case we need to track that they submitted their identity this go around
    this.submittedVerification = true;
    var identityStatus = status === 2 ? STATUS_CHECK_APPROVED : STATUS_CHECK_DENIED;

    // mock the return type for now so we can use the check differences method
    const model: StatusCheckModel = {
      id: 0,
      checkedOn: new Date(),
      expiresOn: new Date('06/14/2100'),
      status: identityStatus,
      type: STATUS_CHECK_IDENTITY
    };

    if (identityStatus === STATUS_CHECK_APPROVED) {
      this.tagManagerService.pushIdentityCheckStatus('approved');
    } else {
      this.tagManagerService.pushIdentityCheckStatus('denied');
    }

    Utils.replaceStatusCheckWithNewCheck(this.userService.clientContact.statusChecks, model);

    this.checkIfIdentityHasChanged();
  }
}
