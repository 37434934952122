import { Component, OnInit, ElementRef, ViewChild, NgZone, Input } from '@angular/core';
import { TicketModel } from 'app/models/TicketModel';
import { NgForm } from '@angular/forms';
// import UUID = require('uuid-js');
import { ApiService } from 'app/services/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadFile, FileSystemDirectoryEntry, FileSystemFileEntry, UploadEvent } from 'ngx-file-drop';
import { UserService } from 'app/services/user.service';
import Utils, { OWNERID_GRG } from 'app/common/Utils';
import { BookingService } from 'app/services/booking.service';
import { GRGSnackBarService } from 'app/common/grg-snack-bar/grg-snak-bar.service';
import { ReservationModel } from 'app/models/ReservationModel';
import { TicketReservationModel } from 'app/models/TicketReservationModel';
import { NGXLogger } from 'ngx-logger';
import { TenantModalParams } from 'app/models/TenantModalParams';
import { utils } from 'protractor';
import { NotificationService } from 'app/services/notification.service';

@Component({
  selector: 'app-add-ticket-modal',
  templateUrl: './add-ticket-modal.component.html',
  styleUrls: ['./add-ticket-modal.component.scss']
})
export class AddTicketModalComponent implements OnInit {

  @Input()
  reservations: TicketReservationModel[] = [];

  public files: File[] = [];
  public images: FormData;

  newTicket: TicketModel;
  showFormMessage = false;
  formMessage: string;
  formMessageIsError = false;
  updateErrorMessage: string;
  public uploadingPhoto: boolean = false;
  public showProgressSpinner: boolean = false;
  public uploadProgress: number = 0;
  public progressTitle: string = "";
  public multipleImages: boolean = false;
  public bufferValue = 0;
  public snackBarMsg = "";
  isSubmitDisabled = false;

  allowEmailNotifications = true;
  allowSmsNotifications = true;

  @ViewChild('petImageInput', { static: false }) petImageInput: ElementRef;

  constructor(private ticketsService: ApiService,
    public activeModal: NgbActiveModal,
    public userService: UserService,
    private bookingService: BookingService,
    private notificationService: GRGSnackBarService,
    private apiService: ApiService,
    private logger: NGXLogger,
    private zone: NgZone,
    private notifications: NotificationService) { }

  ngOnInit() {
    this.initNewTicket();
    this.images = new FormData();
  }

  onFileChanged(event) {
    this.files.push(event.target.files[0]);
  }

  onDropPhoto(object, folder: string) {

    const files: File[] = object.event;

    this.validateFileType(files, folder);
  }

  validateFileType(files: File[], folder: string) {
    let notAllowedFiles = [];

    if (files.length === 1) {
      this.multipleImages = false;
    } else {
      this.multipleImages = true;
    }

    for (const file of files) {

      if (file.name.toLowerCase().match('(.jpe?g|.pdf|.png)$')) {
        this.addFile(file, folder);
      } else {
        notAllowedFiles.push(file.name);
      }
    }

    if (notAllowedFiles.length) {
      alert(`A document was uploaded that is not allowed.  Please ensure that the document uses one of the following formats: .jpg, .jpeg, .png, or .pdf`);
    }
  }

  addFile(fileToUpload: File, folder: string): void {
    // const file: FormData = new FormData();
    this.images.append('file', fileToUpload, fileToUpload.name);
    this.files.push(fileToUpload);
  }

  UploadImage(folder: string) {
    const fi = this.petImageInput.nativeElement;
    this.validateFileType(fi.files, folder);
  }

  removeFile(i: number): void {
    this.files.splice(i, 1);
  }

  submitTicket(form: NgForm): void {

    form.form.markAllAsTouched();
    if (!form.form.valid) {
      return;
    }
    this.zone.run(() => {
      this.formMessageIsError = false;
      this.formMessage = '';
    });
    this.isSubmitDisabled = true;

    this.newTicket.createdOn = new Date();
    this.newTicket.updatedOn = new Date();

    if (this.newTicket.smsUpdates) {
      // this.newTicket.contact.smsPhoneNumber = this.newTicket.contact.phoneNumber;
    }

    if (this.files.length) {
      this.newTicket.attachments = this.images;
    }

    // this is just to ensure our rsvp guid gets saved while developing ..
    // this.newTicket.reservationGuid = '72e470ca-ccc9-4f48-8d45-5e63a475dc0f';

    let allowSmsNotes = false;
    if (this.userService.clientContact.globalContactDetails.primarySmsphoneNumber && this.allowSmsNotifications) {
      allowSmsNotes = true;
    }

    const formData = new FormData();
    formData.append('ticketId', '0');
    formData.append('reason', this.newTicket.reason);
    formData.append('critical', this.newTicket.critical ? 'true' : 'false');
    formData.append('reservationGuid', this.newTicket.reservationGuid);
    if (this.files.length != 0) {
      this.files.forEach(element => {
        formData.append('attachments', element, element.name);
      });
    }
    // formData.append('enableEmailNotification', this.allowEmailNotifications.toString());
    // formData.append('enableSmsNotification', allowSmsNotes.toString());

    this.reservations.forEach(reservation => {
      if (reservation.reservationGuid === this.newTicket.reservationGuid) {
        formData.append('organizationDivisionId', reservation.organizationDivisionId.toString());
      }
    });

    this.ticketsService.submitTicket(formData)
      .subscribe(
        response => {
          form.resetForm();
          this.bookingService.getTotalTickets();
          this.notifications.getNotifications();
          this.activeModal.close();
          this.notificationService.show('Your ticket was successfully submitted!', 'success');
          this.isSubmitDisabled = false;
        },
        error => {
          this.formMessageIsError = true;
          this.formMessage = 'There was an error submitting your ticket. Please check your data and try submitting again in a few minutes.';
          this.isSubmitDisabled = false;
        }
      );
  }

  initNewTicket(): void {
    this.newTicket = {
      id: null,
      guid: '',
      reservationGuid: '72e470ca-ccc9-4f48-8d45-5e63a475dc0f', // '5105caae-8d8f-4b6e-8fda-7fe8fe4b3b6d',
      propertyName: 'Fake property name',
      propertyLocation: 'Dummy location, BA',
      statusId: 0, // TicketStatus.New, // todo implement ticket status lookup
      critical: false,
      ticketCategoryId: 2,
      ticketTypeId: 1,
      ownerId: OWNERID_GRG,
      reason: '',
      createdBy: '',
      createdOn: null,
      updatedBy: '',
      updatedOn: null,
      smsUpdates: false
    };
    if (this.reservations.length === 1) {
      this.newTicket.reservationGuid = this.reservations[0].reservationGuid;
      this.newTicket.propertyName = this.reservations[0].propertyName;

    }

  }

}
