import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-grg-snack-bar',
  templateUrl: './grg-snack-bar.component.html',
  styleUrls: ['./grg-snack-bar.component.scss']
})
export class GrgSnackBarComponent implements OnInit {

  iconType: string;
  faIcon: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {

    this.iconType = data.type;
    switch (this.data.type) {
      case 'success':
        this.faIcon = 'status-icon fa fa-check';
        break;
      case 'warning':
        this.faIcon = 'status-icon fa fa-exclamation-triangle';
        break;
      case 'error':
        this.faIcon = 'status-icon fa fa-exclamation-circle';
        break;
      case 'info':
        this.faIcon = 'status-icon fa fa-question-circle';
        break;
    }
  }

  close() {

  }

  ngOnInit() {
  }



}
