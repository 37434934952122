import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UserService } from 'app/services/user.service';
import { NavSidebarService } from 'app/services/nav-sidebar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/services/api.service';
import { NGXLogger } from 'ngx-logger';
import { BaseComponent } from 'app/common/base/base.component';
import { CreateAccountModel, ResendEmailModel, ClientAccount } from 'app/models/UserJwtModel';
import Utils, { REGEX_TRANSUNION_NAME } from 'app/common/Utils';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-create-account',
    templateUrl: './create-account-form.component.html',
    styleUrls: ['./create-account-form.component.scss']
})

export class CreateAccountComponent extends BaseComponent implements OnInit {
    token;
    CreateAccountForm: FormGroup
    createAccountFields: CreateAccountModel
    ResendEmailConfirmation: ResendEmailModel
    clientAccount: ClientAccount
    checkEmailExists = ""
    accountType: string
    IsPasswordInvalid: boolean
    doPswdsMatch: boolean
    acntTypeSelected = true
    displayHints = "none"
    hints = {
        hintstep1: false,
        hintstep2: false,
        hintstep3: false,
        hintstep4: false,
        hintstep5: false,
    }
    nameHints = {
        hintstep1: false,
        hintstep2: false,
        hintstep3: false,
        hintstep4: false,
    }
    showclicked = false
    active = {
        account: true,
        individual: false,
        company: false,
        emailConfirm: false

    }
    emailInUse = false
    @Input() isModal = false;


    constructor(private fb: FormBuilder,
        private apiService: ApiService,
        private logger: NGXLogger,
        private route: ActivatedRoute,
        private activeModal: NgbActiveModal) {
        super(logger);
        this.InitForm();

    }

    ContinueBtnClick() {
        this.active.individual = true
        this.active.account = false
        if (this.accountType == "company") {
            this.active.account = false
            this.active.emailConfirm = false;
            this.active.company = true;
        }
    }

    backButton() {
        this.accountType = "individual"
        this.active.account = true
        this.active.emailConfirm = !this.active.account;
        this.active.individual = !this.active.account;
        this.active.company = !this.active.account;
    }

    AccountTypeRadioEventHandler(event: any) {
        this.accountType = event.target.value;
        this.acntTypeSelected = this.accountType === "individual";
    }

    EmailInUseEventHandler(value: string) {

        this.checkEmailExists = value
        if (value != "") {
            this.apiService.checkEmailExists(this.checkEmailExists).subscribe(res => {

                console.log("Is available : ", res)
                if (res) {
                    this.emailInUse = false
                }
                else {
                    this.emailInUse = true
                    this.logger.debug("That email is already in use")
                }

            },
                error => {
                    console.log("Error happened")
                })
        }
        else {
            this.emailInUse = false
        }

    }

    NameEventHandler(value: string) {
        this.nameHints.hintstep1 = value.length > 0 && value.length < 2;
        this.nameHints.hintstep2 = false;
    }

    PasswordEventHandler(value: string): void {

        if (value.length >= 8) {
            this.hints.hintstep1 = true
        }
        else {
            this.hints.hintstep1 = false
        }
        if ((/[A-Z]/.test(value))) {
            this.hints.hintstep2 = true
        }
        else {
            this.hints.hintstep2 = false
        }
        if ((/[a-z]/.test(value))) {
            this.hints.hintstep3 = true
        }
        else {
            this.hints.hintstep3 = false
        }
        if ((/\d/.test(value))) {
            this.hints.hintstep4 = true
        }
        else {
            this.hints.hintstep4 = false
        }
        if ((/[!@#$%^&*+~-]/.test(value))) {
            this.hints.hintstep5 = true
        }
        else {
            this.hints.hintstep5 = false
        }

        this.IsPasswordInvalid = this.isPasswordInvalid(value)

    }

    onFocusPswdEvent(event: any) {
        this.displayHints = "block"
    }
    onFocusOutPswdEvent(event: any) {
        this.displayHints = "none"
    }

    ngOnInit() {

    }

    InitForm(): void {
        this.CreateAccountForm = this.fb.group({
            firstName: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
            lastName: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
            companyName: [{ value: '', disabled: false }],
            email: [{ value: '', disabled: false }, [Validators.required]],
            createPassword: [{ value: '', disabled: false }, [Validators.required]],
            createPassword2: [{ value: '', disabled: false }, [Validators.required]],
        })

        this.InitializeCreateAccntModel();
    }

    async onSubmitClick() {

        if (this.isFormValid(this.CreateAccountForm) && !this.IsPasswordInvalid && this.doPswdsMatch) {
            this.logger.debug('Add Account!');

            // request validation token to pass to headers
            const tokenRes = await this.apiService.getValidationToken().toPromise()
            this.token = tokenRes.data

            this.AssignFormToModel();
            // save the account information in the database and wait for the response
            const createAccntRes = await this.apiService.createAccount(this.createAccountFields, this.token).toPromise();
            console.log("Account Response---> ", createAccntRes)
            // TODO: change tp accountresponse
            // if an guid got assigned, it means the request succeded
            if (createAccntRes.guid != '') {
                var successClientRecord = false
                var client_id = createAccntRes.guid
                // initialize the object to create the client account in the portal 
                this.clientAccount = {
                    firstName: this.createAccountFields.firstName,
                    lastName: this.createAccountFields.lastName,
                    primaryEmailAddress: this.createAccountFields.emailAddress,
                    grgIdentityId: client_id,
                    emergencyContacts: []
                };

                // Companu
                if (this.createAccountFields.companyName != '') {
                    this.clientAccount.companyName = this.createAccountFields.companyName
                    //const myRawClientProfileResponse = await this.apiService.createClientAndCompany(this.clientAccount, this.token);
                    this.apiService.createClientAndCompany(this.clientAccount, this.token).subscribe(res => {

                        this.active.account = false;
                        this.active.emailConfirm = true;
                        this.active.individual = false;
                        this.active.company = false;
                        successClientRecord = true;
                        this.logger.debug("Client and Company Added!")

                    },
                        error => {


                            this.logger.debug("Error Adding client & company: ", error)
                        })
                }
                // individual
                else {

                    this.apiService.createClient(this.clientAccount, this.token).subscribe(res => {

                        this.active.account = false
                        this.active.emailConfirm = true;
                        this.active.individual = false;
                        this.active.company = false;
                        successClientRecord = true;
                        this.logger.debug("Client Added!")

                    },
                        error => {
                            this.logger.debug("Error Adding client: ", error)
                        })
                }

            }

        }

    }

    InitializeCreateAccntModel(): void {
        var urlparams = this.route.snapshot.paramMap

        this.createAccountFields = {
            firstName: '',
            lastName: '',
            companyName: '',
            emailAddress: '',
            password: '',
            confirmPassword: '',
            accountRole: ''

        };

    }

    isInvalid(controlName: string): boolean {
        const control = this.CreateAccountForm.get(controlName);

        // if we can't find the control for whatever reason...return false
        if (!control) {
            this.logger.warn('Control not found...FYI', controlName);
            return false;
        }

        const isInvalid = control.invalid && (control.dirty || control.touched);

        return isInvalid;
    }

    isFormValid(form: FormGroup): boolean {
        if (this.accountType == "company") {
            this.CreateAccountForm.controls["companyName"].setValidators([Validators.required]);
            this.CreateAccountForm.controls["companyName"].updateValueAndValidity();
        }
        Utils.touchFormFields(form);
        return form.valid;
    }

    isPasswordInvalid(controlName: string): boolean {
        var hint = this.hints

        if (controlName == "createPassword2") {
            if (this.CreateAccountForm.get(controlName).value != this.CreateAccountForm.get("createPassword").value) {
                this.doPswdsMatch = false
                return true
            }
            else {
                this.doPswdsMatch = true
                return false
            }
        }
        else if (!hint.hintstep1 || !hint.hintstep2 || !hint.hintstep3 || !hint.hintstep4 || !hint.hintstep5) {
            return true
        }


        return false
    }

    AssignFormToModel(): void {
        this.createAccountFields.firstName = this.CreateAccountForm.get('firstName').value
        this.createAccountFields.lastName = this.CreateAccountForm.get('lastName').value
        this.createAccountFields.companyName = this.CreateAccountForm.get('companyName').value
        this.createAccountFields.emailAddress = this.CreateAccountForm.get('email').value
        this.createAccountFields.password = this.CreateAccountForm.get('createPassword').value
        this.createAccountFields.confirmPassword = this.CreateAccountForm.get('createPassword').value
        this.createAccountFields.accountRole = "grg_user"

    }

    hideShowPassword() {
        this.showclicked = !this.showclicked;
    }

    ResendConfirmationEmail() {
        this.ResendEmailConfirmation = {
            email: this.createAccountFields.emailAddress
        }
        if (this.ResendEmailConfirmation.email != "" && this.ResendEmailConfirmation.email != undefined) {
            this.apiService.resendEmailConfirmation(this.ResendEmailConfirmation, this.token).subscribe(res => {
                console.log("Resend Successful ")
            },
                error => {
                    console.log("Error in resend email", error)
                })
        }
    }

    openSignInModal() {
        this.activeModal.close('signin');
    }

    get firstName() { return this.CreateAccountForm.get('firstName') }
    get lastName() { return this.CreateAccountForm.get('lastName') }
}
