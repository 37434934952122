import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from 'app/services/lookup.service';
import { CountryStateService } from 'app/services/country-state.service';

@Pipe({
  name: 'stateById'
})
export class StateByIdPipe implements PipeTransform {

  constructor(private countryStateService: CountryStateService) {
        
  }

  transform(value: any): any {

    if (!value || value === 0) {
      return '';
    }

    if (this.countryStateService && this.countryStateService.statesById) {
      return this.countryStateService.statesById[value];
    }

    return '';
  }

}
