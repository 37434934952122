import { NgModule } from '@angular/core';
import { CommonUIModule } from "./../../common/common-ui.module";
import { CommonModule } from '@angular/common';
import { BackgroundCheckFormComponent } from './background-check-form/background-check-form.component';
import { BackgroundCheckRoutingModule } from './background-check-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatMenuModule, MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [BackgroundCheckFormComponent],
  imports: [
    CommonModule,
    CommonUIModule,
    BackgroundCheckRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatProgressSpinnerModule
  ],
  exports: [
    BackgroundCheckFormComponent
  ]
})
export class BackgroundCheckModule { }
