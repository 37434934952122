import { Component, OnInit, Input, NgZone } from '@angular/core';
import { BaseComponent } from "./../base/base.component";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiService } from 'app/services/api.service';
import { TenantInviteModel } from 'app/models/TenantInviteModel';
import { TenantModalParams } from 'app/models/TenantModalParams';
import { EmployeeInviteModel } from 'app/models/EmployeeInviteModel';
import { UserService } from 'app/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { Subject, of, never, NEVER } from 'rxjs';
import { debounceTime, switchMap, map, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import Utils, { REGEX_TRANSUNION_NAME } from '../Utils';
import { LookupService } from 'app/services/lookup.service';
import { ClientContactModel } from 'app/models/ClientContactModel';
import { GRGSnackBarService } from '../grg-snack-bar/grg-snak-bar.service';
import { PermissionsService } from 'app/services/permissions.service';
import { TagManagerService } from 'app/services/tag-manager.service';

@Component({
  selector: 'app-add-person-modal',
  templateUrl: './add-person-modal.component.html',
  styleUrls: ['./add-person-modal.component.scss']
})
export class AddPersonModalComponent extends BaseComponent implements OnInit {

  @Input() model: TenantModalParams;

  displayState = 'Adult';
  addTenantForm: FormGroup;
  submitButtonString = 'Invite Tenant';
  isSubmittingTenant = false;
  hasMaxTenants = false;
  emailMessage: string;
  emailHasError = false;
  debounceSub = new Subject<string>();
  accountIsAllowed = false;
  isAccountBeingChecked = false;
  emailString = '';
  showCompanyRoleField = false;
  existingUserGuid = '';
  clientContact: ClientContactModel;
  clientContactGuid: string;
  public showSpinner = false;
  public title = 'Add Person';

  constructor(
    private apiService: ApiService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private userService: UserService,
    public logger: NGXLogger,
    public lookupService: LookupService,
    private notificationService: GRGSnackBarService,
    public zone: NgZone,
    private permissionsService: PermissionsService,
    private tagManagerService: TagManagerService
  ) {
    super(logger);
  }

  ngOnInit() {

    this.initEmailRequirements();
    this.logger.log('Add person modal opened - current params: ', this.model);

    if (this.model != null) {
      if (this.model.clientContactGuid != null) {
        // THis is an eit, not an Add
        this.title = 'Edit Information';
        this.zone.run(() => {
          this.showSpinner = true;
        });
        this.clientContactGuid = this.model.clientContactGuid;
        this.apiService.getClientContact(this.model.clientContactGuid).subscribe(result => {
          this.clientContact = result;
          this.addTenantForm = this.fb.group({
            firstName: [result.firstName, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
            lastName: [result.lastName, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
            email: [{ value: result.globalContactDetails.primaryEmailAddress, disabled: this.isEmailDisabled() }, [Validators.required, Validators.email]],
            role: [this.lookupService.companyRoles[result.companyRoleId], [Validators.required]],
            isCompanyPOC: [result.isCompanyPrimaryContact, [Validators.nullValidator]]
          });

          if (this.permissionsService.canSeeRoleDropdownOnAddPersonModal()) {
            this.showCompanyRoleField = true;
          }
          this.isSubmittingTenant = false;
          this.accountIsAllowed = true;
          this.showSpinner = false;

        }, error => { this.showSpinner = false; }
        );
      } else {
        if (this.model.hasMaxTenants) {
          this.logger.log('init of modal', this.model.hasMaxTenants);
          this.hasMaxTenants = true;
          this.displayState = 'Infant';
          this.initChildRequirements();
        }
      }
    }

    this.debounceSub.pipe(debounceTime(150),

      // ensure they are adding an email address..without overdoing it
      // should allow us to avoid any false positives from the overly complex RFC822 valid formats
      // where even something like "very.unusual.@.unusual.com"@example.com is valid
      // in the words of Doctor Strange...it's a simple spell, but quite unbreakable
      switchMap(searchValue => {
        if (searchValue && searchValue.indexOf('@') > 0) {
          return of(searchValue);
        } else {
          // this.emailMessage = 'Please provide a valid email';
          this.emailMessage = '';
          this.emailHasError = true;
          this.accountIsAllowed = false;
          this.isAccountBeingChecked = false;
          return NEVER;
        }
      }),
      distinctUntilChanged(),
      switchMap(val => this.apiService.getClientContactFromEmail(val)),
      takeUntil(this.unsubscribeOnDestroy$))
      .subscribe(response => {
        this.logger.log('search time', this, this.model);
        this.existingUserGuid = response.guid;

        // if our response has a response field, the profile was not found
        // new users can be added to any booking
        if (response.response) {
          this.emailMessage = 'New User';
          this.emailHasError = false;
          this.accountIsAllowed = true;
          this.isAccountBeingChecked = false;

          if (this.permissionsService.canSeeRoleDropdownOnAddPersonModal()) {
            this.showCompanyRoleField = true;
          }
          return;
        }

        // we need to make sure this person is not already on the booking
        // the forEach loop doesn't support early termination so lets use a plain loop
        if (this.model && this.model.tenants) {
          for (let i = 0; i < this.model.tenants.length; i++) {
            if (this.model.tenants[i].clientContact.clientContactGuid === response.guid) {
              this.emailMessage = 'User is already a tenant for this booking';
              this.emailHasError = true;
              this.accountIsAllowed = false;
              this.isAccountBeingChecked = false;
              return;
            }
          }
        }

        // this is a company booking, we want to ensure that the person being invited is also part of this company
        if (this.model.clientCompanyGuid && response.companyGuid !== this.model.clientCompanyGuid) {
          this.emailMessage = 'Email not associated with this company. Try again.';
          this.emailHasError = true;
          this.accountIsAllowed = false;
          this.isAccountBeingChecked = false;
          return;
        } else if (!this.model.clientCompanyGuid && response.companyGuid) {
          this.emailMessage = 'Email is associated with a company.  Try again.';
          this.emailHasError = true;
          this.accountIsAllowed = false;
          this.isAccountBeingChecked = false;
          return;
        }

        this.emailMessage = 'Existing User';
        this.emailHasError = false;
        this.accountIsAllowed = true;
        this.isAccountBeingChecked = false;
        this.showCompanyRoleField = false;
      });
  }

  emailOnKeyUp(email: string) {

    if (email !== this.emailString) {
      this.isAccountBeingChecked = true;
      this.accountIsAllowed = false;
      this.emailString = email;
      this.debounceSub.next(email);
    }
  }

  onAgeChange(ageValue: string): void {

    this.displayState = ageValue;

    if (ageValue === 'Adult') {
      // Require email
      this.initEmailRequirements();
    } else {
      // Hide email requirement.
      this.initChildRequirements();
    }
  }

  initChildRequirements(): void {
    this.addTenantForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]]
    });

    this.accountIsAllowed = true;
    this.showCompanyRoleField = false;
  }

  initEmailRequirements(): void {
    this.addTenantForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
      email: [{ value: '', disabled: this.isEmailDisabled() }, [Validators.required, Validators.email]],
      role: ['User', [Validators.required]]
    });

    if (this.permissionsService.canSeeRoleDropdownOnAddPersonModal()) {
      this.showCompanyRoleField = true;
    }
  }

  isTenantModalValid(): boolean {
    Utils.touchFormFields(this.addTenantForm);
    return this.addTenantForm.valid;
  }

  isEmailDisabled(): boolean {
    return (this.model && !this.model.inquiryId && this.model.clientContactGuid && this.model.clientContactGuid.length > 0);
  }

  onSubmit() {

    this.logger.log('submitting');

    if (this.accountIsAllowed === false || this.isAccountBeingChecked || this.isTenantModalValid() === false) {
      return;
    }

    this.isSubmittingTenant = true;
    if (this.model && !this.model.inquiryId && !this.clientContactGuid) {
      // Invite is coming from dashboard and not tenants page.
      this.inviteEmployee();
      return;
    }

    this.logger.log('params sent into add person modal', this.model);

    if (!this.model.clientContactGuid) {
      // User invited an adult
      if (this.addTenantForm.value.email != null) {
        if (this.existingUserGuid) {
          this.inviteExistingAccount(this.existingUserGuid);
        } else {
          // Client contact either doesn't have a complete account or no account at all
          this.inviteNewAccount();
        }
      } else {
        // User invited an infant or child.
        this.inviteNewAccount();
      }
    } else {
      // THis is an edit to an existing employee
      this.updateEmployee();
    }
  }

  inviteNewAccount(): void {
    const newTenant: TenantInviteModel = {
      inquiryId: this.model.inquiryId,
      firstName: this.addTenantForm.get('firstName').value,
      lastName: this.addTenantForm.get('lastName').value,
      // Children and infants do not have emails
      email: this.displayState === 'Adult' ? this.addTenantForm.get('email').value : null,
      clientType: this.displayState, // displayState is the age of tenant. 'Adult' | 'Child' | 'Infant'
      parentGuid: this.model.parentGuid,
      clientCompanyGuid: this.displayState === 'Adult' ? this.model.clientCompanyGuid : null,
      isPrimary: this.displayState === 'Adult' ? true : false,
      pets: [], // new tenants won't have any pets so just initialize as empty array
      clientCompanyRole: this.showCompanyRoleField ? this.addTenantForm.get('role').value : ''
    };

    this.apiService.sendNewTenantInvite(newTenant).subscribe(
      result => {
        this.logger.log('send new tenant invite', newTenant);
        // Send tenant to tenants page so we can add to table.

        // if (newTenant.clientCompanyRole) {
        //   this.tagManagerService.pushNewCompanyUser(newTenant.clientCompanyRole);
        // }

        this.tagManagerService.pushBookingWizardTenantAction(true);

        if (result) {
          newTenant.clientContactGuid = result.clientContactGuid;
          newTenant.globalTenantId = result.globalTenantId;
        }

        this.activeModal.close(newTenant);
        this.isSubmittingTenant = false;
      },
      error => {
        this.logger.error(error);
        this.isSubmittingTenant = false;
        this.notificationService.show("Add tenant failed, please try again", "error");
      });
  }

  inviteExistingAccount(tenantGuid: string): void {
    // Set firstname, lastname, and clientType for tenants table. Fields are not needed for the api call.
    const tenant: TenantInviteModel = {
      firstName: this.addTenantForm.get('firstName').value,
      lastName: this.addTenantForm.get('lastName').value,
      clientType: this.displayState,
      inquiryId: this.model.inquiryId,
      email: this.addTenantForm.get('email').value,
      clientCompanyGuid: this.model.clientCompanyGuid,
      isPrimary: this.displayState === 'Adult' ? true : false,
      clientContactGuid: tenantGuid,
      backgroundCheck: null,
      identityCheck: null,
      pets: []
    };

    this.apiService.sendExistingClientTenantInvite(tenant, tenantGuid).subscribe(
      result => {

        this.tagManagerService.pushBookingWizardTenantAction(true);

        // if a background check was found for this tenant, we need to
        // add it to our model so the tenants page can display the appropriate status
        if (result && result.bgCheck) {
          tenant.backgroundCheck = result.bgCheck;
        }

        if (result && result.identityCheck) {
          tenant.identityCheck = result.identityCheck;
        }

        if (result && result.globalTenantId) {
          tenant.globalTenantId = result.globalTenantId;
        }

        tenant.firstName = result.firstName;
        tenant.lastName = result.lastName;
        tenant.pets = result.profilePets;

        // Send tenant to tenants page so we can add to table.
        this.activeModal.close(tenant);
      },
      error => {
        this.logger.error(error);
        this.isSubmittingTenant = false;
        this.notificationService.show("Add tenant failed, please try again", "error");
      });
  }

  inviteEmployee(): void {
    const employee: EmployeeInviteModel = {
      firstName: this.addTenantForm.get('firstName').value,
      lastName: this.addTenantForm.get('lastName').value,
      email: this.addTenantForm.get('email').value,
      role: this.addTenantForm.get('role').value
    };

    this.apiService.sendEmployeeInvite(this.userService.clientContact.clientCompanyGuid, employee).subscribe(
      response => {

        this.tagManagerService.pushNewCompanyUser(employee.role);

        this.notificationService.show("Employee Added", "success");
        this.activeModal.close(employee);
        this.isSubmittingTenant = false;
      },
      error => {
        this.notificationService.show("Add Employee failed", "error");
        this.logger.error(error);
        this.isSubmittingTenant = false;
      }
    );
  }

  updateEmployee(): void {
    const employee: EmployeeInviteModel = {
      firstName: this.addTenantForm.get('firstName').value,
      lastName: this.addTenantForm.get('lastName').value,
      email: this.addTenantForm.get('email').value,
      role: this.addTenantForm.get('role').value
    };

    this.clientContact.firstName = this.addTenantForm.get('firstName').value;
    this.clientContact.lastName = this.addTenantForm.get('lastName').value;
    this.clientContact.globalContactDetails.primaryEmailAddress = this.addTenantForm.get('email').value;
    this.clientContact.isCompanyPOC = this.addTenantForm.get('isCompanyPOC').value;

    for (const r in this.lookupService.companyRoles) {
      if (this.lookupService.companyRoles[r] === this.addTenantForm.get('role').value) {
        this.clientContact.clientCompanyRoleId = <number><any>r;
      }
    }

    this.apiService.updateClientContactBasicInfo(this.clientContact).subscribe(
      response => {
        this.notificationService.show("Employee Updated", "success");
        this.activeModal.close(employee);
        this.isSubmittingTenant = false;
      },
      error => {
        this.notificationService.show("Employee Update Filed", "error");
        this.logger.error(error);
        this.isSubmittingTenant = false;
      }
    );
  }

  isInvalid(controlName: string): boolean {
    const control = this.addTenantForm.get(controlName);
    const isInvalid = control.invalid && (control.dirty || control.touched);

    if (isInvalid) {
      this.emailMessage = '';
    }

    return isInvalid;
  }

  getSubmitButtonString(): string {

    if (this.isAccountBeingChecked) {
      return 'Searching... ';
    }

    if (this.displayState === 'Adult') {
      if (this.clientContactGuid != null) {
        return this.isSubmittingTenant ? 'Sending...' : 'Save Changes';
      }
      else {
        return this.isSubmittingTenant ? 'Sending...' : 'Add Person';
      }

    } else {
      return this.isSubmittingTenant ? 'Saving...' : 'Add Child';
    }
  }

  get firstName() { return this.addTenantForm.get('firstName') }
  get lastName() { return this.addTenantForm.get('lastName') }

}
