import { Injectable } from '@angular/core';
import { StatusCheckModel } from 'app/models/StatusCheckModel';
import { ImportDataService } from './import-data.service';
import { ApiService } from './api.service';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from './user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UrlService } from './url.service';
import { STATUS_CHECK_BACKGROUND, ERRORS_ONHOLD } from 'app/common/Utils';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class UtilityService {

  private companyOnHoldBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  companyOnHoldObservable$ = this.companyOnHoldBehaviorSubject.asObservable();

  constructor(private logger: NGXLogger,
    private apiService: ApiService) { }

  checkCompanyOnHoldError(error: any) {
    // once we've detected an error we need to alert el presidente
    if (error && error.error && error.error.includes(ERRORS_ONHOLD)) {
      this.logger.debug('Company is now on hold!');
      this.companyOnHoldBehaviorSubject.next(true);
    } else if (error && error.includes(ERRORS_ONHOLD)) {
      this.logger.debug('Company is now on hold!');
      this.companyOnHoldBehaviorSubject.next(true);
    }
  }

  checkIfCompanyIsOnHold(clientCompanyGuid: string, checkDatabase: boolean, successFunction: Function = null, errorFunction: Function = null) {
    return this.apiService.isCompanyOnHold(clientCompanyGuid, checkDatabase).subscribe(response => {
      if (successFunction) {
        successFunction();
      }
    }, error => {
      this.checkCompanyOnHoldError(error);
      this.logger.info('Company is placed on hold');

      if (errorFunction) {
        errorFunction();
      }
    });
  }

  // Use index because js is pass by value
  getBackgroundCheckIndex(checks: StatusCheckModel[]): number {
    for (let i = 0; i < checks.length; i++) {
      if (checks[i].type === STATUS_CHECK_BACKGROUND) {
        return i;
      }
    }
  }
}
