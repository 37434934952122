import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { PermissionsService } from 'app/services/permissions.service';
import { NGXLogger } from 'ngx-logger';
import { UserService } from 'app/services/user.service';
import { ImportDataService } from 'app/services/import-data.service';

@Injectable({
  providedIn: 'root'
})

export class CompanyRoleGuard implements CanActivate, CanActivateChild {

  private loader = new Subject<boolean>();
  public loader$ = this.loader.asObservable();

  constructor(private permissionsService: PermissionsService,
    private logger: NGXLogger,
    private userImportService: ImportDataService,
    private userService: UserService,
    private permissionService: PermissionsService,
    private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const roles = route.data.roles as Array<string>;
    return this.checkIfRouteIsValid(route, roles, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const roles = route.data.childRoles as Array<string>;

    return this.checkIfRouteIsValid(route, roles, state);
  }

  private checkIfRouteIsValid(route: ActivatedRouteSnapshot, roles: Array<string>, state: RouterStateSnapshot) {

    // certain exceptions can go here .. kind of lame to do it like this but this allows child components to
    // provide exceptions to guard logic and override the parent components wishes ..
    if (state.url.toLowerCase().includes('/extensions/id')) {
      return true;
    }

    const isNormalUserAllowed = route.data.isNormalUserAllowed as boolean;

    // if normal user is allowed in this section that is otherwise guarded, let them in!
    if (isNormalUserAllowed) {
      return true;
    }

    // allow this to act as a shortcut for when the user is already authenticated and just switching pages so the loading screen doesn't flash
    if (this.userService.isAuthenticated) {
      return this.permissionService.isUserInRoleWhenAuthenticated(roles);
    }

    this.loader.next(true);
    return this.permissionService.isUserInRoles(roles, this.loader);
  }

}
