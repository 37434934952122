import { Component, OnInit, Input, OnChanges, NgZone, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { CreditCardModel, creditCardCustomerModel } from 'app/models/CreditCardModel';
import { ApiService } from 'app/services/api.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddCreditCardComponent } from '../add-credit-card/add-credit-card.component';
import { CustomerCreditCardComponent } from '../customer-credit-card/customer-credit-card.component'
import { HashTable } from 'app/models/HashTable';
import Utils, { STATUS_CHECK_CREDIT, STATUS_CHECK_APPROVED, ROUTE_PORTAL_APPROVALS, ROUTE_PORTAL_COMPANY_SETTINGS, ROUTE_PORTAL_USER_SETTINGS, REGEX_TRANSUNION_NAME } from '../Utils';
import { NGXLogger } from 'ngx-logger';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { StateModel, CountryStateModel } from 'app/models/CountryStateModel';
import { CountryStateService } from 'app/services/country-state.service';
import { GRGSnackBarService } from '../grg-snack-bar/grg-snak-bar.service';
import { takeUntil, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { throwMatDialogContentAlreadyAttachedError, MatRadioChange, MatDialog } from '@angular/material';
import { BookingWizardModel } from 'app/models/BookingWizardModel';
import { UserService } from 'app/services/user.service';
import { BaseComponent } from '../base/base.component';
import { StatusCheckModel } from 'app/models/StatusCheckModel';
import { CreditStatus } from 'app/models/CreditStatusEnum';
import { Router } from '@angular/router';
import { PermissionsService } from 'app/services/permissions.service';


@Component({
  selector: 'app-manage-credit-cards',
  templateUrl: './manage-credit-cards.component.html',
  styleUrls: ['./manage-credit-cards.component.scss']
})
export class ManageCreditCardsComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() selectedCardId = 0;
  @Input() isBookingWizard = true;
  @Input() isEditBookingBilling = false;
  @Input() booking: BookingWizardModel;
  @Input() isBookingLocked: boolean;
  @Input() isCompany = false;
  @Input() guid = "";
  @Input() editCompanyCard: false;
  @Input() showBillingInformation = false;

  @Output() onCreditCardSelected = new EventEmitter();
  @Output() onAccountingIdChange = new EventEmitter();
  @Output() navigateToUserProfile = new EventEmitter();

  isCreditCheckPassed = false;
  canAccessComponent = false;
  doesUserNeedCreditCheck = false;
  isProfileComplete = false;
  currentModal: NgbModalRef;

  @ViewChild('billingInformationModal', { static: false, read: TemplateRef },) billingInformationModal: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private modalService: NgbModal,
    private countryStateService: CountryStateService,
    private notificationService: GRGSnackBarService,
    private zone: NgZone,
    private logger: NGXLogger,
    private userService: UserService,
    private dialog: MatDialog,
    private permissionsService: PermissionsService) {
    super(logger);
  }

  creditCards = [];
  expiredCreditCards = [];
  companyCreditCards = [];
  selectedCard = 0;
  selectedCompanyCard = 0;
  monthNames: HashTable<string> = {};
  isLoadingData = false;
  hasLoadedData = false;
  showSpinner: boolean = false;
  isSavingProfile = false;
  hasLoadedCards = false;
  missingFields: string[] = [];

  customerForm: FormGroup;
  ccUser: creditCardCustomerModel;
  countryStates: CountryStateModel;

  ngOnInit() {
    this.monthNames = Utils.getMonthsHash();
    this.countryStateService.countryStates.subscribe(
      (response: CountryStateModel) => {
        this.countryStates = response;
      }
    );
  }

  canAccessCreditCardComponent(): void {
    this.doesUserNeedCreditCheck = false;
    this.isProfileComplete = false;
    this.logger.log('can access credit card component?', this.userService.clientContact);

    // this component is always unlocked in the BW
    // if they have a payment profile then let them use the component
    if (this.isBookingWizard || (this.ccUser != null && this.ccUser.accountingId !== 0)) {
      this.logger.log('yes - booking wizard and / or the user has a payment profile already');
      // return true;
      this.canAccessComponent = true;
      return;
    }

    if (this.isCompany) {
      // check if company profile has enough details
      this.isProfileComplete = Utils.isCompanyProfileComplete(this.userService);
      this.canAccessComponent = this.isProfileComplete;
    } else {
      if (this.permissionsService.isEmployee) {
        // employees personal credit card
        // check if they have enough of their profile filled out
        this.isProfileComplete = Utils.isIndividualsProfileComplete(this.userService);
        this.canAccessComponent = this.isProfileComplete;
      } else {
        // individuals personal credit card
        // check if they have enough of their profile filled out
        // check if they have passed a credit check :-)
        this.doesUserNeedCreditCheck = true;
        this.checkCreditCheck();
        this.isProfileComplete = Utils.isIndividualsProfileComplete(this.userService);
        this.canAccessComponent = this.isProfileComplete;
      }
    }
  }

  getClassListForCardWrapper(): string {
    var classes = '';

    if (this.isBookingWizard) {
      classes += 'booking-wizard ';
    } else {
      classes += 'portal '
    }

    if (this.isEditBookingBilling) {
      classes += 'edit-billing';
    }

    return classes;
  }

  navigateToPublicSite(): void {
    window.open('/', '_self');
  }

  ngOnChanges() {
    if (this.guid != "" && !this.hasLoadedCards) {
      this.initCustomerDetailForm();
    }

    if (this.isBookingWizard && this.booking && this.hasLoadedCards === false) {
      this.prepareToFetchCards(true);
    } else if (!this.isBookingWizard && this.hasLoadedCards === false) {
      this.prepareToFetchCards(false);
    }

    this.checkCreditCheck();
    this.canAccessCreditCardComponent();
  }

  navigateToCorrectProfile(e: any): void {
    e.preventDefault();
    this.navigateToUserProfile.emit();

  }

  checkCreditCheck(): void {
    let statusChecks: StatusCheckModel[];

    if (this.isCompany) {
      statusChecks = this.userService.clientContact.clientCompany.statusChecks;
    } else {
      statusChecks = this.userService.clientContact.statusChecks;
    }

    const creditCheck = Utils.getStatusCheckOfType(statusChecks, STATUS_CHECK_CREDIT);
    const creditStatus = Utils.getStatus(creditCheck);

    // if it's expired atm, lets see if the status was approved otherwise
    if (creditStatus === CreditStatus.EXPIRED) {
      this.isCreditCheckPassed = creditCheck.status === STATUS_CHECK_APPROVED;
    } else {
      this.isCreditCheckPassed = creditStatus === CreditStatus.APPROVED;
    }
  }

  prepareToFetchCards(setUserGuid: boolean) {
    this.hasLoadedCards = true;
    this.userService.clientContactBehaviorSub.subscribe(response => {
      if (response != null) {
        if (setUserGuid) {
          this.guid = this.userService.clientContact.clientContactGuid;
        }

        this.fetchCreditCards();
      }
    }, error => {
      this.logger.error(error);
      this.hasLoadedCards = false;
    });
  }

  getMonth(month: string): string {
    if (!month) return '';

    return Utils.getKeyByValue(this.monthNames, month);
  }

  canContinue(): boolean {
    return this.selectedCard > 0;
  }

  fetchCreditCards(): void {
    this.companyCreditCards = [];
    this.logger.debug('Fetching credit cards...');
    this.isLoadingData = true;
    this.getCreditCardsMethod(this.isCompany, this.guid).subscribe(response => {
      if (response != null) {
        this.hasLoadedData = true;

        var cards: CreditCardModel[];

        if (!this.isCompany) {
          cards = response.userCreditCards;
          this.companyCreditCards = response.companyCreditCards;
        } else {
          cards = response;
        }

        this.logger.log('Cards found..', cards);

        // var card: CreditCardModel = {
        //   cardNumber: 'xxxxxxxxxxx1111',
        //   defaultCard: false,
        //   chargeCardAccountingId: 0,
        //   customerAccountingId: 0,
        //   expirationMonth: 'December',
        //   expirationYear: '2019',
        //   description: 'Test Expiration',
        //   maskCardNumber: 'xxxxxxxxxxxxx1111',
        //   cardId: 'American Express....0002',
        //   cardType: 'American Express'
        // }

        // cards.push(card);

        if ((cards && cards.length) || (this.companyCreditCards && this.companyCreditCards.length)) {

          this.creditCards = [];

          if (cards && cards.length) {
            this.creditCards = cards.sort((a, b) => Number(b.defaultCard) - Number(a.defaultCard));
          }

          cards = [];

          this.creditCards.forEach(el => {

            const mon = parseInt(this.getMonth(el.expirationMonth));
            const year = parseInt(el.expirationYear);
            const isCardExpired = new Date() > new Date(year, mon);
            // break cards up by expiration and verify that the card was not previously added
            if (this.expiredCreditCards.find(x => x.cardId == el.cardId) == undefined) {
              if (isCardExpired) {
                this.expiredCreditCards.push(el);
              } else {
                cards.push(el);
              }
            }
          });

          this.creditCards = cards;
          const defaultCards = this.creditCards.filter(function (el) {
            return el.defaultCard;
          });

          if (this.selectedCardId && this.isEditBookingBilling) {

            if ((cards && cards.length && cards.some(x => x.chargeCardAccountingId === this.selectedCardId)) || (this.companyCreditCards && this.companyCreditCards.length && this.companyCreditCards.some(x => x.chargeCardAccountingId === this.selectedCardId))) {
              this.selectedCard = this.selectedCardId;
              this.selectedCompanyCard = this.selectedCardId;
            }

            this.isLoadingData = false;
            this.hasLoadedData = true;
            return;
          }

          // if they have stored their card on the booking then we want to select that before the default card
          if (this.isBookingWizard && this.booking
            && this.booking.quote && this.booking.quote.grgChargeCardId
            && this.booking.quote.grgChargeCardId > 0) {

            const storedNumber = this.booking.quote.grgChargeCardId;
            this.logger.log('Card found assigned to booking..', storedNumber);
            this.selectedCard = storedNumber;
            this.selectedCompanyCard = storedNumber;
            this.onCreditCardSelected.emit(this.selectedCard);
          } else if (defaultCards && defaultCards.length) {
            this.logger.log('Card found to be marked as default..', defaultCards[0].chargeCardAccountingId);
            this.selectedCard = defaultCards[0].chargeCardAccountingId;
            this.selectedCompanyCard = 0;
            this.onCreditCardSelected.emit(defaultCards[0].chargeCardAccountingId);
          } else if (this.creditCards && this.creditCards.length) {
            // if no defaults are stored, and no CC is selected for this booking,
            // but there are cards in the profile, select the first one on their behalf
            this.logger.log('Card found on profile but no defaults are marked..', this.creditCards[0].chargeCardAccountingId);
            this.selectedCompanyCard = 0;
            this.selectedCard = this.creditCards[0].chargeCardAccountingId;
            this.onCreditCardSelected.emit(this.selectedCard);
          }
        }
      }
      this.isLoadingData = false;
      this.hasLoadedData = true;
    }, error => {
      this.logger.error(error);
      this.isLoadingData = false;
    });
  }

  openBillingInformationModal(): void {

    // open the modal and assign it to the ref variable for later use
    this.currentModal = this.modalService.open(this.billingInformationModal, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'md-modal',
      centered: true
    });


  }

  openAddCreditCardModal(): void {
    //Note: before this.modalservice was used instead of this.dialog (which is a MatDialog from Angular Material). 
    //The custom modal service did not have certain feature like close on outside etc. 
    //Also the margins in the modal service is off while those in the MatDialog work right away.
    const addCreditCardModal = this.dialog.open(AddCreditCardComponent, {
      width: '700px',
      height: 'auto',
      hasBackdrop: true
    });

    var userCreditCard: creditCardCustomerModel = this.ccUser;
    userCreditCard.creditCard = {
      chargeCardAccountingId: 0,
      customerAccountingId: 0,
      cardNumber: null,
      expirationMonth: null,
      expirationYear: null,
      status: "active",
      defaultCard: false,
    };

    if (!this.ccUser.country) {
      this.ccUser.country = 'United States';
    }

    const isFirstCard = (!this.creditCards || this.creditCards.length === 0)
      && (!this.expiredCreditCards || this.expiredCreditCards.length === 0);

    addCreditCardModal.componentInstance.IsFirstCard = isFirstCard;
    addCreditCardModal.componentInstance.isCompany = this.isCompany;
    addCreditCardModal.componentInstance.UserCreditCard = userCreditCard;

    (<AddCreditCardComponent>addCreditCardModal.componentInstance).accountingIdCreated.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(res => {

      // add account id to the local cc user since it's state has changed
      if (res && this.ccUser && this.ccUser.accountingId === 0 && res.accountingId) {
        this.ccUser.accountingId = res.accountingId;

        if (!this.userService.clientContact.accountingId && !this.isCompany) {
          this.userService.clientContact.accountingId = res.accountingId.toString();
        }
      }

      // add customer id to the local cc user since it's state has changed
      if (res && this.ccUser && !this.ccUser.customerId && res.customerId) {
        this.ccUser.customerId = res.customerId;
      }
    });

    addCreditCardModal.afterClosed().subscribe(response => {
      this.logger.log('modal closed...isBookingWizard?', this.isBookingWizard);
      if (response != null) {
        this.logger.log('added credit card!', response);

        if (isFirstCard) {
          this.logger.debug('customers first credit card was added so fetching the details of their profile');
          this.initCustomerDetailForm();
        } else {
          this.logger.debug('an additional credit card has been added, refetching cards incase default was marked');
          // if a new default card was added, we need to refetch the cards as only one default card can be shown at a time
          this.fetchCreditCards();
        }

        // for booking wizard we will add the card to the bottom of the stack and select that card for the user.
        // for account profile, we will check if the card was marked as the default payment method, if it is we will move
        // it to the top of the list and select, otherwise we will just add it to the bottom of the list
        if (this.isBookingWizard) {
          this.creditCards.push(response);
          this.selectedCard = response.chargeCardAccountingId;
          this.selectedCompanyCard = 0;
          this.onCreditCardSelected.emit(response.chargeCardAccountingId);
        } else {
          // card was marked as default so lets move it to the top
          if (response.defaultCard) {
            this.creditCards.unshift(response);
            this.selectedCard = response.chargeCardAccountingId;
            this.selectedCompanyCard = 0;
            this.onCreditCardSelected.emit(response.chargeCardAccountingId);
          } else {
            this.creditCards.push(response);
          }
        }
      }
    }, r => {
      // this is just here to stop the NGB modal error when you close
    });
  }

  editCreditCard(card: CreditCardModel): void {
    const addCreditCardModal = this.dialog.open(AddCreditCardComponent, {
      width: '700px',
      height: 'auto',
      hasBackdrop: true
    });

    if ((this.creditCards && this.creditCards.length) || (this.expiredCreditCards && this.expiredCreditCards.length)) {
      addCreditCardModal.componentInstance.IsFirstCard = true;
    }
    addCreditCardModal.componentInstance.isCompany = this.isCompany;
    var userCreditCard: creditCardCustomerModel = this.ccUser;
    userCreditCard.creditCard = card;

    addCreditCardModal.componentInstance.UserCreditCard = userCreditCard;
    this.logger.debug('preparing to update card..', userCreditCard);

    addCreditCardModal.afterClosed().subscribe(response => {
      // credit card was updated so lets refetch credit cards
      this.fetchCreditCards();
    }, r => {
      // this is just here to stop the NGB modal error when you close
    });
  }

  onDrop(event: CdkDragDrop<CreditCardModel[]>): void {
    // keep the list items within the same container
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  getStateArray(country?: string): StateModel[] {
    // Handle loading case
    if (this.countryStates == null) {
      return null;
    }

    // Use selected country as hash key. 'United States' is default.
    // If country was entered, provide the state list for the specific country.
    // Otherwise use the country that has been selected on the form.
    return country ? this.countryStates.countries[country]
      : this.countryStates.countries[this.customerForm.get('country').value];
  }



  getCountryArray(): string[] {
    // Handle loading case
    if (this.countryStates == null) {
      return null;
    }
    // Use hash keys as country choices
    return Object.keys(this.countryStates.countries);


  }

  initCustomerDetailForm(): void {

    const isDisabled = this.isBookingLocked;

    this.showSpinner = true;
    this.customerForm = this.fb.group({
      name: [{ value: '', disabled: isDisabled }],
      firstName: [{ value: '', disabled: isDisabled }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
      lastName: [{ value: '', disabled: isDisabled }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
      addressLine1: [{ value: '', disabled: isDisabled }],
      addressLine2: [{ value: '', disabled: isDisabled }],
      city: [{ value: '', disabled: isDisabled }],
      state: [{ value: '', disabled: isDisabled }],
      zipCode: [{ value: '', disabled: isDisabled }],
      country: [{ value: 'United States', disabled: isDisabled }]
    });

    if (!this.guid) {
      this.showSpinner = false;
      return;
    }

    this.getUserInfoMethod(this.isCompany, this.guid).subscribe(result => {
      this.ccUser = result;
      this.ccUser.locationId = 2;
      this.logger.log('get cc user info result', result);
      this.onAccountingIdChange.emit(result.chargeCardAccountingId);

      // this.onAccountingIdChange.emit(result.accountingId);
      this.customerForm = this.fb.group({
        name: [{ value: this.ccUser.name, disabled: isDisabled },],
        firstName: [{ value: this.ccUser.firstName, disabled: isDisabled }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
        lastName: [{ value: this.ccUser.lastName, disabled: isDisabled }, [Validators.required, Validators.minLength(2), Validators.pattern(REGEX_TRANSUNION_NAME)]],
        addressLine1: [{ value: this.ccUser.addressLine1, disabled: isDisabled }, Validators.required],
        addressLine2: [{ value: this.ccUser.addressLine2, disabled: isDisabled }],
        city: [{ value: this.ccUser.city, disabled: isDisabled }, Validators.required],
        state: [{ value: this.ccUser.state, disabled: isDisabled }, Validators.required],
        zipCode: [{ value: this.ccUser.postalCode, disabled: isDisabled }, Validators.required],
        country: [{ value: this.ccUser.country ? this.ccUser.country : 'United States', disabled: isDisabled }, Validators.required]
      });
      this.showSpinner = false;

      // going to try to re set the state after the initial form...
      // I think this is needed because when the country gets set, the state dropdown gets repopulated with options so its an order of options issue!
      var this$ = this;
      setTimeout(function () {
        this$.customerForm.get('state').patchValue(this$.ccUser.state);
        this$.customerForm.get('state').updateValueAndValidity();
      }, 1);

    }, error => {
      this.showSpinner = false;
    });
  }

  UpdateProfile(): void {
    if (this.isFormValid()) {
      this.zone.run(() => {
        this.showSpinner = true;
        this.isSavingProfile = true;
      });
      this.ccUser.name = this.customerForm.get('name').value;
      this.ccUser.firstName = this.customerForm.get('firstName').value;
      this.ccUser.lastName = this.customerForm.get('lastName').value;
      this.ccUser.addressLine1 = this.customerForm.get('addressLine1').value;
      this.ccUser.addressLine2 = this.customerForm.get('addressLine2').value;
      this.ccUser.city = this.customerForm.get('city').value;
      this.ccUser.state = this.customerForm.get('state').value;
      this.ccUser.postalCode = this.customerForm.get('zipCode').value;
      this.ccUser.country = this.customerForm.get('country').value;
      this.ccUser.status = "active"
      this.ccUser.locationId = 2;

      //update the database

      this.updateUserInfoMethod(this.isCompany, this.guid, this.ccUser).subscribe(result => {
        if (result) {
          this.ccUser = result;
          this.notificationService.show("Profile Information Updated!", "success");
          this.showSpinner = false;
          this.isSavingProfile = false;

          // CHECK: THIS SEEMS NECESSARY ALL OF A SUDDEN BECAUSE UPDATING THE PROFILE DOES NOT RETURN THE UPDATED PROFILE?
          this.initCustomerDetailForm();

          if (this.currentModal) {
            this.currentModal.close();
          }
        }
      }, error => {
        this.showSpinner = false;
        this.isSavingProfile = false;
        this.notificationService.show("Profile Update Failed!", "error");
      });
    }
  }

  getUserInfoMethod(isCompany: boolean, guid: string): Observable<creditCardCustomerModel> {

    if (isCompany) {
      return this.apiService.getCompanyProfile(guid);
    }
    else {
      return this.apiService.getUserProfile(guid);
    }
  }

  updateUserInfoMethod(isCompany: boolean, guid: string, profile: creditCardCustomerModel): Observable<creditCardCustomerModel> {
    if (isCompany) {
      return this.apiService.updateCompanyProfile(guid, profile);
    }
    else {
      return this.apiService.updateUserProfile(guid, profile);
    }
  }

  getCreditCardsMethod(isCompany: boolean, guid: string): Observable<any> {
    if (isCompany) {
      return this.apiService.getCompanyCreditCards(guid);
    }
    else {
      return this.apiService.getUserAndCompanyCreditCards(guid);
    }
  }


  SetCompanyCard(event: MatRadioChange) {
    this.logger.log('Setting company card..', event);
    this.selectedCard = 0;
    this.onCreditCardSelected.emit(event.value);
  }

  SetUserCard(event: MatRadioChange) {
    this.logger.log('Setting user card', event);
    this.selectedCompanyCard = 0;
    this.onCreditCardSelected.emit(event.value);
  }

  isFormValid(): boolean {

    return this.customerForm.valid


  }

  isInvalid(controlName: string): boolean {
    const control = this.customerForm.get(controlName);
    const isInvalid = control.invalid && (control.dirty || control.touched);

    return isInvalid;
  }

  get firstName() { return this.customerForm.get('firstName') }
  get lastName() { return this.customerForm.get('lastName') }
}
