import { Pipe, PipeTransform } from '@angular/core';
import { PetModel } from 'app/models/PetModel';

@Pipe({
  name: 'petSpecies',
  pure: true
})
export class PetSpeciesPipe implements PipeTransform {
  transform(petSpecies: string) {
    console.log(petSpecies, 'attempting to do species..');
    if (!petSpecies) {
      return '';
    }

    let displaySpecies = '';

    petSpecies = petSpecies.toLowerCase();
    switch (petSpecies) {
      case 'dog':
        displaySpecies = 'Dog';
        break;
      case 'cat':
        displaySpecies = 'Cat';
        break;
      case 'needed':
        displaySpecies = 'Needed';
        break;
    }

    return displaySpecies;
  }
}
