import { Injectable, isDevMode } from '@angular/core';
import { SubmitScreeningModel } from "./../models/SubmitScreeningModel";
import { SubmitVerificationCodeModel } from "./../models/SubmitVerificationCodeModel";
import { UtilityLogModel } from "./../models/UtilityLogModel";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TicketModel, TicketDetailsModel } from 'app/models/TicketModel';
import { BookingModel, OpenBookingModel } from 'app/models/BookingModel';
import { ReservationModel } from 'app/models/ReservationModel';
import { EmployeeListModel, ContactModel, GenerateResetPasswordTokenModel, ResetPasswordModel, BackgroundCheckModel } from 'app/models/EmployeesModel';
import { BasicInfoModel, BWTenantsModel } from 'app/models/BasicInfoModel';
import { BookingWizardModel, ClientContactContainerModel, BookingBillingDetails, BookingInvoice } from 'app/models/BookingWizardModel';
import { CountryStateModel } from 'app/models/CountryStateModel';
import { CreateIndividualAccountModel } from 'app/models/CreateIndividualAccountModel';
import { CreateCompanyAccountModel } from 'app/models/CreateCompanyAccountModel';
import { AddressBookContact, ClientContactModel, ClientContactSettings } from 'app/models/ClientContactModel';
import { ClientEmailCheckModel } from 'app/models/ClientEmailCheckModel';
import { TenantInviteModel } from 'app/models/TenantInviteModel';
import { EmergencyContactModel } from 'app/models/EmergencyContactModel';
import { CreditCheckSubmitModel, CreditCheckReturnModel } from 'app/models/CreditCheckModel';
import { StatusCheckModel } from 'app/models/StatusCheckModel';
import { CustomizationModel } from 'app/models/CustomizationModel';
import { PetModel, AccountPetImage } from 'app/models/PetModel';
import { PriceModel, NewPriceModel } from 'app/models/PriceModel';
import { ContactDetailsModel } from 'app/models/ContactDetailsModel';
import { CreateInquiryModel } from 'app/models/CreateInquiryModel';
import { ClientCompanyModel, CompanyDetailsModel, companySettings, ClientCompanyTradeReference } from 'app/models/ClientCompanyModel';
import { PaginationModel } from 'app/models/PaginationModel';
import { ApprovalModel } from 'app/models/ApprovalsModel';
import { PaginationParamsModel } from 'app/models/PaginationParamsModel';
import { EmployeeInviteModel } from 'app/models/EmployeeInviteModel';
import { environment } from './../../environments/environment';
import { VerificationResponseModel } from 'app/models/verification-response-model';
import { IdentityVerificationResult } from 'app/models/IdentityVerificationResult';
import { IdentityExamResponsesModel } from 'app/models/IdentityExamResponsesModel';
import { LeaseUnitModel } from 'app/models/LeaseUnitModel';
import { ChangePasswordViewModel } from 'app/models/ChangePasswordViewModel';
import { PortalLookupModel } from 'app/models/PortalLookupModel';
import { InquiryStatusModel } from 'app/models/InquiryStatusModel';
import { BookingTenantModel } from 'app/models/BookingTenantModel';
import { InquiriesQuotesModel, QuoteStatusModel, InquiriesQuoteModel } from 'app/models/inquiries-quotes-model';
import { NGXLogger } from 'ngx-logger';
import { InquiryNotesModel } from 'app/models/InquiryNotesModel';
import { TicketReservationModel } from 'app/models/TicketReservationModel';
import { CreditCardModel, creditCardCustomerModel, UserCompanyCreditCardModel } from 'app/models/CreditCardModel';
import { CompanySettingsModel } from 'app/models/CompanySettingsModel';
import { PropertyFee, SelectedParkingModel } from 'app/models/PropertyFee';
import { QuoteFeeValue } from 'app/models/QuoteFeeValue';
import { GrgNotificationModel } from 'app/models/GrgNotificationModel';
import { SETPAYMENT_BILLING, SETPAYMENT_CREDITCARD } from 'app/common/Utils';
import { DocusignSigningModel } from 'app/models/DocusignSigningModel';
import { TicketContactModel } from 'app/models/TicketContactModel';
import { ClientAccount, CreateAccountModel } from 'app/models/UserJwtModel';
import { ClientLeaseDocModel, LegalLogging } from 'app/models/SignLeasePaymentPage';
import { BookingVM, CreateBookingWizardModel, TermsAndSignature } from 'app/models/NewBookingWizardModels';
import { AnyAaaaRecord } from 'dns';
import { ContactVerificationStatus } from 'app/models/ContactVerificationStatus';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  API_URL: string;
  IPAddress_URL: string;

  private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '/' }) };

  constructor(private http: HttpClient, private logger: NGXLogger) {

    // API & website urls get set from environment vars
    // environment variables can also be provided through the .net app to override these environment variables

    if ((<any>window).apiUrl) {
      this.logger.debug(`appsettings variable loaded for API Url: ${(<any>window).apiUrl}`);
    }

    if ((<any>window).websiteUrl) {
      this.logger.debug(`appsettings variable loaded for Website Url: ${(<any>window).websiteUrl}`);
    }

    let hostUrl = window.location.origin;
    this.API_URL = (<any>window).apiUrl ? (<any>window).apiUrl + 'api' : environment.apiUrl;
    this.IPAddress_URL = "https://api.ipify.org/?format=json";

    const disableConsoleLogs = typeof ((<any>window).disableConsoleLogs) !== 'undefined'
      ? (<any>window).disableConsoleLogs
      : environment.logging.disableConsoleLogs;

    // update logger config now that the domain based env variables are resolved
    this.logger.updateConfig({
      serverLoggingUrl: `${this.API_URL}${environment.logging.logUrl}`,
      level: parseInt((<any>window).consoleLogLevel || environment.logging.level, 10),
      // serverLogLevel: parseInt((<any>window).serverLogLevel || environment.logging.serverLogLevel, 10),
      disableConsoleLogging: disableConsoleLogs
    });
  }

  getNotifications(clientContactGuid: string): Observable<GrgNotificationModel[]> {
    return this.http.get<GrgNotificationModel[]>(`${this.API_URL}/clientcontacts/${clientContactGuid}/notifications`);
  }

  dismissNotification(clientContactGuid: string, notificationId: number): Observable<any> {
    return this.http.delete<any>(`${this.API_URL}/clientcontacts/${clientContactGuid}/notifications/${notificationId}`);
  }

  getInquiryAndQuoteById(inquiryGuid: string): Observable<InquiriesQuotesModel> {
    return this.http.get<InquiriesQuotesModel>(`${this.API_URL}/inquiries/concierge/${inquiryGuid}`);
  }

  setBookingPayment(inquiryGuid: string, isBilling: boolean, cardId?: number): Observable<any> {

    // build the post model
    const model = {
      paymentMethod: isBilling ? SETPAYMENT_BILLING : SETPAYMENT_CREDITCARD,
      chargeCardId: cardId
    };

    return this.http.post<any>(`${this.API_URL}/inquiries/${inquiryGuid}/setbookingpayment`, model);
  }

  testAssetAmp(model: any) {
    return this.http.post<any>(`${this.API_URL}/assetamplifier/CalculateScenario`, model);
  }

  getExtensions(paginationParams: PaginationParamsModel, status?: string): Observable<PaginationModel<InquiriesQuotesModel>> {

    if (!status) {
      status = 'CURRENT';
    }

    let params = new HttpParams()
      .set('sort', paginationParams.sort)
      .set('sortby', paginationParams.sortby)
      .set('page', paginationParams.pageIndex.toString())
      .set('pageSize', paginationParams.pageSize.toString());

    // Do not include filter if null
    if (paginationParams.filter != null) {
      params = params.append('filter', paginationParams.filter);
    }

    if (paginationParams.filters != null) {
      if (paginationParams.filters.columnTextFilters.length != 0) {
        params = params.append('columntextfilters', paginationParams.filters.TextFilterString());
      }
      if (paginationParams.filters.columnDataFilters.length != 0) {
        params = params.append('columndatafilters', paginationParams.filters.DataFilterString());
      }
    }

    return this.http.get<PaginationModel<InquiriesQuotesModel>>(`${this.API_URL}/inquiries/concierge/extensions?status=${status}`, { params: params });
  }

  getInquiriesAndQuotes(paginationParams: PaginationParamsModel, status?: string): Observable<PaginationModel<InquiriesQuotesModel>> {

    if (!status) {
      status = 'current';
    }

    let params = new HttpParams()
      .set('sort', paginationParams.sort)
      .set('sortby', paginationParams.sortby)
      .set('page', paginationParams.pageIndex.toString())
      .set('pageSize', paginationParams.pageSize.toString());

    // Do not include filter if null
    if (paginationParams.filter != null) {
      params = params.append('filter', paginationParams.filter);
    }

    if (paginationParams.filters != null) {
      if (paginationParams.filters.columnTextFilters.length != 0) {
        params = params.append('columntextfilters', paginationParams.filters.TextFilterString());
      }
      if (paginationParams.filters.columnDataFilters.length != 0) {
        params = params.append('columndatafilters', paginationParams.filters.DataFilterString());
      }
    }

    return this.http.get<PaginationModel<InquiriesQuotesModel>>(`${this.API_URL}/inquiries/concierge/quotes?status=${status}`, { params: params });
  }

  getInquiryNotes(inquiryGuid: string): Observable<InquiryNotesModel[]> {
    return this.http.get<InquiryNotesModel[]>(`${this.API_URL}/inquiries/${inquiryGuid}/notes`);
  }

  addInquiryNote(inquiryGuid: string, model: InquiryNotesModel): Observable<InquiryNotesModel> {
    return this.http.post<InquiryNotesModel>(`${this.API_URL}/inquiries/${inquiryGuid}/notes/add`, model);
  }

  createEchInquiry(inquiry: InquiriesQuotesModel): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/inquiries/ech/create`, inquiry);
  }

  updateQuoteStatus(model: QuoteStatusModel[]): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/quotes/status/update`, model);
  }

  getCreditCards(): Observable<CreditCardModel[]> {
    return this.http.get<CreditCardModel[]>(`${this.API_URL}/payments/creditcards`);
  }

  createCreditCard(model: CreditCardModel): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/payments/creditcards`, model);
  }

  updateCreditCard(model: CreditCardModel): Observable<any> {
    return this.http.put<any>(`${this.API_URL}/payments/creditcards`, model);
  }

  // USER CREDIT CARDS / PROFILE

  getUserCreditCards(guid: string): Observable<CreditCardModel[]> {
    return this.http.get<CreditCardModel[]>(`${this.API_URL}/payments/creditcards/${guid}`);
  }

  getUserAndCompanyCreditCards(guid: string): Observable<UserCompanyCreditCardModel> {
    return this.http.get<UserCompanyCreditCardModel>(`${this.API_URL}/payments/creditcards/${guid}/user/all`);
  }


  updateUserCreditCard(guid: string, model: creditCardCustomerModel): Observable<creditCardCustomerModel> {
    return this.http.put<creditCardCustomerModel>(`${this.API_URL}/payments/creditcards/${guid}/user`, model);
  }

  addUserCreditCard(guid: string, model: creditCardCustomerModel): Observable<creditCardCustomerModel> {
    return this.http.post<creditCardCustomerModel>(`${this.API_URL}/payments/creditcards/${guid}/user`, model);
  }

  getUserProfile(guid: string): Observable<creditCardCustomerModel> {
    return this.http.get<creditCardCustomerModel>(`${this.API_URL}/payments/profile/${guid}/user`);
  }

  updateUserProfile(guid: string, model: creditCardCustomerModel): Observable<creditCardCustomerModel> {
    return this.http.put<creditCardCustomerModel>(`${this.API_URL}/payments/profile/${guid}/user`, model);

  }

  //COMPANY CREDIT CARDS / PROFILE

  getCompanyProfile(guid: string): Observable<creditCardCustomerModel> {
    return this.http.get<creditCardCustomerModel>(`${this.API_URL}/payments/profile/${guid}/company`);
  }

  updateCompanyProfile(guid: string, model: creditCardCustomerModel) {
    return this.http.put<creditCardCustomerModel>(`${this.API_URL}/payments/profile/${guid}/company`, model);
  }

  getCompanyCreditCards(guid: string): Observable<CreditCardModel[]> {
    return this.http.get<CreditCardModel[]>(`${this.API_URL}/payments/creditcards/${guid}/company`);
  }

  updateCompanyCreditCard(guid: string, model: creditCardCustomerModel): Observable<creditCardCustomerModel> {
    return this.http.put<creditCardCustomerModel>(`${this.API_URL}/payments/creditcards/${guid}/company`, model);
  }

  addCompanyCreditCard(guid: string, model: creditCardCustomerModel): Observable<creditCardCustomerModel> {
    return this.http.post<creditCardCustomerModel>(`${this.API_URL}/payments/creditcards/${guid}/company`, model);
  }

  // ************** //
  // BOOKING WIZARD
  // ************** //

  leaseUnit(guid: string): Observable<any> {
    return this.http.post<LeaseUnitModel>(`${this.API_URL}/inquiries/${guid}/converttolease`, null);
  }

  completeLease(guid: string): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/inquiries/${guid}/completelease`, null,
      { responseType: 'text' as 'json' });
  }

  confirmUnitAvailability(inquiryGuid: string): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/inquiries/${inquiryGuid}/confirmAvailability`);
  }

  setInquiryStatus(inquiryGuid: string, inquiryStatusId: number, reasonId?: number): Observable<boolean> {
    const qs = reasonId ? `?status=${inquiryStatusId}&reason=${reasonId}` : `?status=${inquiryStatusId}`;
    return this.http.patch<boolean>(`${this.API_URL}/inquiries/${inquiryGuid}/status${qs}`, null);
  }

  // ---------- Create ------- //
  createIndividualAccount(individual: CreateIndividualAccountModel): Observable<any> {
    return this.http.post<CreateIndividualAccountModel>(`${this.API_URL}/clientcontacts/create`, individual);
  }
  createCompanyAccount(company: CreateCompanyAccountModel): Observable<any> {
    return this.http.post<CreateCompanyAccountModel>(`${this.API_URL}/company/create`, company);
  }
  createInquiry(inquiry: CreateInquiryModel): Observable<any> {
    return this.http.put<any>(`${this.API_URL}/inquiries/create`, inquiry);
  }

  getBookingNew(booking: CreateBookingWizardModel): Observable<BookingVM> {
    return this.http.put<any>(`${this.API_URL}/booking/getbooking`, booking);
  }

  updateBooking(booking: BookingVM): Observable<BookingVM> {
    return this.http.put<BookingVM>(`${this.API_URL}/booking/updatebooking`, booking);
  }
  finalizeBooking(booking: BookingVM): Observable<BookingVM> {
    return this.http.put<BookingVM>(`${this.API_URL}/booking/finalizeBooking`, booking);
  }

  // ---------- Get ---------- //
  getBooking(bookingGuid: string): Observable<BookingWizardModel> {
    return this.http.get<BookingWizardModel>(`${this.API_URL}/inquiries/${bookingGuid}`);
  }
  getReservation(guid: string): Observable<BookingWizardModel> {
    return this.http.get<BookingWizardModel>(`${this.API_URL}/reservations/${guid}/details`);
  }

  getReservationInfo(guid: string): Observable<BookingVM> {
    return this.http.get<BookingVM>(`${this.API_URL}/reservations/${guid}/details`);
  }
  getReservationinfo(guid: string): Observable<BookingVM> {
    return this.http.get<BookingVM>(`${this.API_URL}/reservations/${guid}/details`);
  }
  getAddressBook(guid: string): Observable<ClientContactModel[] | any> {
    return this.http.get<ClientContactModel[] | any>(`${this.API_URL}/clientcontacts/${guid}/addressbook`);
  }
  getAddressBookContact(guid: string): Observable<AddressBookContact> {
    return this.http.get<AddressBookContact>(`${this.API_URL}/clientcontacts/addressbook/${guid}`);
  }
  createAddressBookContact(guid: string, vm: AddressBookContact): Observable<any> {
    return this.http.post(`${this.API_URL}/clientcontacts/${guid}/addressbook`, vm);
  }
  updateAddressBookContact(guid: string, vm: AddressBookContact): Observable<any> {
    return this.http.put(`${this.API_URL}/clientcontacts/${guid}/addressbook`, vm);
  }
  deleteAddressBookContact(guid: string): Observable<any> {
    return this.http.delete(`${this.API_URL}/clientcontacts/${guid}/addressbook`);
  }
  // TODO: Replace number response with actual object model
  getCreditCheckStatus(creditCheckControl: CreditCheckReturnModel): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/creditcheck/status`, creditCheckControl);
  }
  // TODO: Replace any response with actual object model
  getCreditCheck(creditCheckControl: CreditCheckReturnModel): Observable<StatusCheckModel> {
    return this.http.post<StatusCheckModel>(`${this.API_URL}/creditcheck/get`, creditCheckControl);
  }
  getUserAccount(identityGuid: string): Observable<ClientContactModel> {
    return this.http.get<ClientContactModel>(`${this.API_URL}/clientcontacts/identity/${identityGuid}`);
  }
  getClientContactFromEmail(email: string): Observable<ClientEmailCheckModel> {
    return this.http.post<ClientEmailCheckModel>(`${this.API_URL}/clientcontacts/search/`, { email: email });
  }
  getBackgroundCheckStatus(backgroundCheckControl: CreditCheckReturnModel): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/backgroundcheck/status`, backgroundCheckControl);
  }
  getBackgroundCheck(backgroundCheckControl: CreditCheckReturnModel): Observable<StatusCheckModel> {
    return this.http.post<StatusCheckModel>(`${this.API_URL}/backgroundcheck/get`, backgroundCheckControl);
  }
  getUnitCustomizations(quoteGuid: string): Observable<CustomizationModel[]> {
    return this.http.get<CustomizationModel[]>(`${this.API_URL}/quotes/${quoteGuid}/customizations`);
  }
  getSavedCustomizations(quoteGuid: string): Observable<CustomizationModel[]> {
    return this.http.get<CustomizationModel[]>(`${this.API_URL}/quotes/${quoteGuid}/customizations/selected`);
  }
  getParkingOptions(quoteGuid: string): Observable<PropertyFee[]> {
    return this.http.get<PropertyFee[]>(`${this.API_URL}/quotes/${quoteGuid}/parking`);
  }
  getSelectedParking(quoteGuid: string): Observable<SelectedParkingModel> {
    return this.http.get<SelectedParkingModel>(`${this.API_URL}/quotes/${quoteGuid}/parking/selected`);
  }
  setParkingOption(quoteGuid: string, globalParkingTypeId: number): Observable<QuoteFeeValue> {
    return this.http.post<QuoteFeeValue>(`${this.API_URL}/quotes/${quoteGuid}/parking/${globalParkingTypeId}`, null);
  }
  getTenants(inquiryGuid: string): Observable<ClientContactContainerModel[]> {
    return this.http.get<ClientContactContainerModel[]>(`${this.API_URL}/inquiries/${inquiryGuid}/tenants`);
  }
  getNewCost(inquiryGuid: string, verifyUnitAvailability: boolean = true): Observable<NewPriceModel> {
    return this.http.get<NewPriceModel>(`${this.API_URL}/inquiries/${inquiryGuid}/cost?verifyAvailability=${verifyUnitAvailability}`);
  }
  getReservationCost(guid: string): Observable<NewPriceModel> {
    return this.http.get<NewPriceModel>(`${this.API_URL}/reservations/${guid}/cost`);
  }
  getReservationBilling(guid: string): Observable<BookingBillingDetails> {
    return this.http.get<BookingBillingDetails>(`${this.API_URL}/reservations/${guid}/billing`);
  }
  getInquiryBilling(guid: string): Observable<BookingBillingDetails> {
    return this.http.get<BookingBillingDetails>(`${this.API_URL}/inquiries/${guid}/billing`);
  }

  getAdditionalOccupant(key: string) {
    let params = new HttpParams();
    params = params.append('key', encodeURIComponent(key));
    return this.http.get<ClientContactModel>(`${this.API_URL}/clientcontacts/occupant`, { params: params });
  }

  checkAdditionalOccupantExpiry(expiry: string) {
    let params = new HttpParams();
    params = params.append('expiry', encodeURIComponent(expiry));
    return this.http.get<boolean>(`${this.API_URL}/clientcontacts/expiry`, { params: params });
  }

  updateOccupantAddress(key: string, details: ContactDetailsModel) {
    let params = new HttpParams();
    params = params.append('key', encodeURIComponent(key));
    params = params.append('street1', details.streetAddressLine1);
    params = params.append('city', details.city);
    params = params.append('stateId', encodeURIComponent(details.globalStateProvId));
    params = params.append('postal', details.postalCode);
    params = params.append('countryId', encodeURIComponent(details.globalCountryId));
    return this.http.put<ContactDetailsModel>(`${this.API_URL}/clientcontacts/occupant`, null, { params: params })
  }

  occupantNewBackground(key: string, property: string) {
    let params = new HttpParams();
    params = params.append('key', encodeURIComponent(key));
    params = params.append('property', encodeURIComponent(property));

    return this.http.post(`${this.API_URL}/clientcontacts/occupant/consent/new`, null, { params: params });
  }

  additionalOccupantConsent(key: string, expiry: string, property: string, dob: string,ssn:string,driversLicenseNo: string,driverStateId: string) {
    let params = new HttpParams();
    params = params.append('expiry', encodeURIComponent(expiry));
    params = params.append('key', encodeURIComponent(key));
    params = params.append('property', encodeURIComponent(property));
    params = params.append('dob', dob);
    params = params.append("ssn",ssn);
    params = params.append('driversLicenseNo',driversLicenseNo);
    params = params.append('driverStateId',driverStateId);
    return this.http.post<Array<BackgroundCheckModel>>(`${this.API_URL}/clientcontacts/occupant/consent`, null, { params: params });

  }

  createCreditOrBackgroundCheck(clientContactGuid: string, model: SubmitScreeningModel): Observable<StatusCheckModel[]> {
    return this.http.post<StatusCheckModel[]>(`${this.API_URL}/clientcontacts/${clientContactGuid}/submitscreening`, model);
  }
  getReservationInvoices(reservationGuid: string): Observable<BookingInvoice[]> {
    return this.http.get<BookingInvoice[]>(`${this.API_URL}/reservations/${reservationGuid}/invoices`);
  }
  updateReservationPayment(reservationGuid: string, chargeCardAccountingId: number) {
    var model = {
      chargeCardId: chargeCardAccountingId
    };

    return this.http.post(`${this.API_URL}/reservations/${reservationGuid}/setpayment`, model);
  }

  // ---------- Updates ---------- //
  updateBasicInfo(basicInfo: BasicInfoModel, bookingGuid): Observable<any> {
    return this.http.patch<any>(`${this.API_URL}/inquiries/${bookingGuid}/update/basicinfo`, basicInfo);
  }
  updateContactDetails(contactGuid: string, details: ContactDetailsModel, contactType: string): Observable<ContactDetailsModel> {
    return this.http.patch<ContactDetailsModel>(`${this.API_URL}/${contactType}/${contactGuid}/update/contactdetails`, details);
  }
  updateCompanyCreditCheckBasicInfo(companyBasicInfo: ClientCompanyModel): Observable<any> {
    return this.http.patch<ClientCompanyModel>(`${this.API_URL}/company/${companyBasicInfo.clientCompanyGuid}/update`, companyBasicInfo);
  }
  updateClientContactBasicInfo(ccBasicInfo: ClientContactModel): Observable<ClientContactModel> {


    var model = (<any>ccBasicInfo);

    if (ccBasicInfo.profileComplete) {
      model.isProfileComplete = ccBasicInfo.profileComplete;
    }

    return this.http.patch<ClientContactModel>(
      `${this.API_URL}/clientcontacts/${ccBasicInfo.clientContactGuid}/update`,
      ccBasicInfo
    );
  }
  updateEmergencyContact(emContactInfo: EmergencyContactModel, clientContactGuid: string): Observable<EmergencyContactModel> {
    return this.http.patch<EmergencyContactModel>(`${this.API_URL}/clientcontacts/${clientContactGuid}/emergencycontact`, emContactInfo);
  }
  updateCustomization(quoteGuid: string, customization: CustomizationModel): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/quotes/${quoteGuid}/customizations/modify`, customization);
  }
  updatePet(clientContactGuid: string, pet: PetModel): Observable<PetModel> {
    return this.http.post<any>(`${this.API_URL}/clientcontacts/${clientContactGuid}/pet/${pet.petId}`, pet);
  }
  updateTenantCount(inquiryGuid: string, adultCount: number, childCount: number): Observable<any> {
    const tenantObject = {
      numberOfChildren: childCount,
      numberOfAdults: adultCount
    };

    return this.http.post<any>(`${this.API_URL}/inquiries/${inquiryGuid}/tenants/count`, tenantObject);
  }
  addTenantAndPetCount(inquiryGuid: string, model: BWTenantsModel) {
    return this.http.post<any>(`${this.API_URL}/inquiries/${inquiryGuid}/tenantspets/count`, model);
  }
  updateClientActiveRole(companyGuid: string, employeeGuid: string, isActive: boolean): Observable<any> {
    return this.http.patch(`${this.API_URL}/company/${companyGuid}/employee/${employeeGuid}/activate/${isActive}`, null);
  }
  submitCreditCheck(creditSubmitInfo: CreditCheckSubmitModel): Observable<CreditCheckReturnModel> {
    return this.http.post<CreditCheckReturnModel>(`${this.API_URL}/creditcheck/create`, creditSubmitInfo);
  }
  submitBackgroundCheck(clientContactGuid: string): Observable<CreditCheckReturnModel> {
    return this.http.post<CreditCheckReturnModel>(`${this.API_URL}/backgroundcheck/create`, { clientContactGuid: clientContactGuid });
  }
  submitPetToInquiry(inquiryGuid, pet: PetModel): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/inquiries/${inquiryGuid}/pets/add`, pet);
  }

  updateClientPassword(changePasswordViewModel: ChangePasswordViewModel) {
    return this.http.post<any>(`${this.API_URL}/accounts/changepw`, changePasswordViewModel);
  }

  // ---------- Misc ---------- //
  sendAdditionalOcuppantsInvite(inquiryId: number) {
    return this.http.get<any>(`${this.API_URL}/inquiries/${inquiryId}/invites`)
  }
  sendNewTenantInvite(tenant: TenantInviteModel): Observable<any> {
    return this.http.post<TenantInviteModel>(`${this.API_URL}/tenant/invite`, tenant);
  }
  sendExistingClientTenantInvite(tenant: TenantInviteModel, clientContactGuid: string): Observable<any> {
    return this.http.post<TenantInviteModel>(`${this.API_URL}/tenant/invite/${clientContactGuid}`, tenant);
  }
  addTenantsToInquiry(inquiryGuid: string, tenants: TenantInviteModel[]) {
    return this.http.post<any>(`${this.API_URL}/inquiries/${inquiryGuid}/tenants`, tenants);
  }
  removeTenant(tenantGuid: string, inquiryId: number): Observable<any> {
    const removeData = {
      inquiryId: inquiryId
    };

    return this.http.post<{ inquiryId: number }>(`${this.API_URL}/tenant/${tenantGuid}/remove`, removeData);
  }
  resendEmailInvite(email: string, inquiryId: number): Observable<any> {
    var model = {
      email: email,
      inquiryId: inquiryId
    };
    return this.http.post<null>(`${this.API_URL}/tenant/email/invitation`, model);
  }
  lockBooking(inquiryGuid: string): Observable<InquiryStatusModel> {
    return this.http.post<InquiryStatusModel>(`${this.API_URL}/inquiries/${inquiryGuid}/status/pending`, null);
  }
  unlockBoooking(inquiryGuid: string): Observable<InquiryStatusModel> {
    return this.http.post<InquiryStatusModel>(`${this.API_URL}/inquiries/${inquiryGuid}/status/new`, null);
  }

  // *********** //
  // IDENTITY
  // *********** //
  isVerified(clientContactGuid: string) {
    return this.http.get<ContactVerificationStatus>(`${this.API_URL}/clientcontacts/${clientContactGuid}/isVerified`);
  }

  createIdentityCheck(clientContactGuid: string): Observable<VerificationResponseModel> {
    return this.http.post<VerificationResponseModel>(`${this.API_URL}/clientcontacts/${clientContactGuid}/verifyidentity`, null);
  }

  getIdentityQuestions(clientContactGuid: string): Observable<VerificationResponseModel> {
    return this.http.post<VerificationResponseModel>(`${this.API_URL}/clientcontacts/${clientContactGuid}/requestverificationexam`, null);
  }

  getIdentityCode(clientContactGuid: string, callInsteadOfText = false): Observable<VerificationResponseModel> {
    return this.http.post<VerificationResponseModel>(`${this.API_URL}/clientcontacts/${clientContactGuid}/requestverificationcode?byPhoneCall=${callInsteadOfText}`, null);
  }

  submitIdentityQuestions(clientContactGuid: string, model: IdentityExamResponsesModel): Observable<IdentityVerificationResult> {
    return this.http.post<IdentityVerificationResult>(`${this.API_URL}/clientcontacts/${clientContactGuid}/submitverificationanswers`, model);
  }

  submitIdentityCode(clientContactGuid: string, model: SubmitVerificationCodeModel): Observable<IdentityVerificationResult> {
    return this.http.post<IdentityVerificationResult>(`${this.API_URL}/clientcontacts/${clientContactGuid}/submitverificationcode`, model);
  }

  resendVerificationCode(clientContactGuid: string): Observable<VerificationResponseModel> {
    return this.http.post<VerificationResponseModel>(`${this.API_URL}/clientcontacts/${clientContactGuid}/resendverificationcode`, null);
  }

  resendCompanyInvitation(clientCompanyGuid: string, clientContactGuid: string): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/company/${clientCompanyGuid}/employee/${clientContactGuid}/invite`, null);
  }

  changePassword(passwordModel: ChangePasswordViewModel) {
    return this.http.post<any>(`${this.API_URL}/accounts/changepw`, passwordModel);
  }

  sendPasswordRestEmail(passwordModel: GenerateResetPasswordTokenModel) {
    return this.http.post<any>(`${this.API_URL}/accounts/pwresettoken`, passwordModel);
  }

  resetPasswordConfirmation(resetPasswordModel: ResetPasswordModel) {
    return this.http.post<any>(`${this.API_URL}/accounts/pwreset`, resetPasswordModel);
  }

  getClientContactMiscData(clientContactGuid: string, miscKeyName: string) {
    return this.http.get<any>(`${this.API_URL}/clientcontacts/${clientContactGuid}/misc/${miscKeyName}`);
  }

  updateClientContactMiscSetting(clientContactGuid: string, miscKeyName: string, miscKeyValue: string): Observable<string> {
    return this.http.post<any>(`${this.API_URL}/clientcontacts/${clientContactGuid}/misc/${miscKeyName}`,
      `\"${miscKeyValue}\"`,
      {
        headers: {
          'Content-Type': 'application/json'
        }, responseType: 'text' as 'json',
      },
    );
  }

  uploadDocusignDocuments(inquiryGuid: string, envelopeId: string): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/inquiries/${inquiryGuid}/retrieveSavedLease?eid=${envelopeId}`, null);
  }

  generateDocusignLeaseUrl(inquiryGuid: string): Observable<DocusignSigningModel> {
    return this.http.get<DocusignSigningModel>(`${this.API_URL}/inquiries/${inquiryGuid}/docusign`);
  }

  generatePortalDocusignLeaseUrl(inquiryGuid: string): Observable<DocusignSigningModel> {
    return this.http.get<DocusignSigningModel>(`${this.API_URL}/inquiries/${inquiryGuid}/docusign/portal`);
  }

  // ************** //
  // CUSTOMER PORTAL
  // ************** //
  getReservations(clientGuid: string, isCompany: boolean): Observable<ReservationModel[]> {
    const guidString = isCompany ? 'companyGuid' : 'contactGuid';

    return this.http.get<ReservationModel[]>(`${this.API_URL}/reservations?period=0&${guidString}=${clientGuid}`);
  }

  createExtensionRequest(reservationGuid: string, newCheckOut: string): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/reservations/${reservationGuid}/extension?checkOutDate=${newCheckOut}`,
      null,
      { responseType: 'text' as 'json' });
  }

  getReservationsByPeriod(clientGuid: string, isCompany: boolean, period: number, paginationParams: PaginationParamsModel): Observable<PaginationModel<ReservationModel>> {

    let params = new HttpParams()
      .set('sort', paginationParams.sort)
      .set('sortby', paginationParams.sortby)
      .set('page', paginationParams.pageIndex.toString())
      .set('pageSize', paginationParams.pageSize.toString());

    // Do not include filter if null
    if (paginationParams.filter != null) {
      params = params.append('filter', paginationParams.filter);
    }

    if (paginationParams.filters != null) {
      if (paginationParams.filters.columnTextFilters.length != 0) {
        params = params.append('columntextfilters', paginationParams.filters.TextFilterString());
      }
      if (paginationParams.filters.columnDataFilters.length != 0) {
        params = params.append('columndatafilters', paginationParams.filters.DataFilterString());
      }
    }

    if (isCompany) {
      return this.http.get<PaginationModel<ReservationModel>>(`${this.API_URL}/reservations/company/${clientGuid}/${period}`, { params: params });
    }
    else {
      return this.http.get<PaginationModel<ReservationModel>>(`${this.API_URL}/reservations/contact/${clientGuid}/${period}`, { params: params });
    }

  }

  getReservationByGuid(guid: string) {
    return this.http.get<ReservationModel>(`${this.API_URL}/reservations/${guid}`);
  }

  getTicketReservations(): Observable<TicketReservationModel[]> {
    return this.http.get<TicketReservationModel[]>(`${this.API_URL}/reservations/basic`);
  }

  addTicketComment(guid: string, model: any): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/tickets/${guid}/comment`, model);
  }

  addTicketDocument(guid: string, file: FormData): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('image', 'image');
    headers = headers.append('Accept', 'application/json');

    return this.http.post<any>(`${this.API_URL}/tickets/${guid}/adddocument`, file, {
      headers: headers,
      observe: 'events', reportProgress: true, responseType: "json"
    });
  }

  /*  getBookings(paginationParams: PaginationParamsModel): Observable<PaginationModel<BookingModel>> {
     let params = new HttpParams()
       .set('sort', paginationParams.sort)
       .set('sortby', paginationParams.sortby)
       .set('page', paginationParams.pageIndex.toString())
       .set('pageSize', paginationParams.pageSize.toString());

     // Do not include filter if null
     if (paginationParams.filter != null) {
       params = params.append('filter', paginationParams.filter);
     }

     return this.http.get<PaginationModel<BookingModel>>(`${this.API_URL}/bookings`, { params: params });
   } */


  getOpenBookings(paginationParams: PaginationParamsModel): Observable<PaginationModel<OpenBookingModel>> {
    let params = new HttpParams()
      .set('sort', paginationParams.sort)
      .set('sortby', paginationParams.sortby)
      .set('page', paginationParams.pageIndex.toString())
      .set('pageSize', paginationParams.pageSize.toString());

    // Do not include filter if null
    if (paginationParams.filter != null) {
      params = params.append('filter', paginationParams.filter);
    }

    if (paginationParams.filters != null) {
      if (paginationParams.filters.columnTextFilters.length != 0) {
        params = params.append('columntextfilters', paginationParams.filters.TextFilterString());
      }
      if (paginationParams.filters.columnDataFilters.length != 0) {
        params = params.append('columndatafilters', paginationParams.filters.DataFilterString());
      }


    }

    /*  if (columnTextFilter) {
       paramString = paramString + "&columntextfilters=" + columnTextFilter;
     }

     if (columnDataFilter) {
       paramString = paramString + "&columndatafilters=" + columnDataFilter;
     }
    */
    return this.http.get<PaginationModel<OpenBookingModel>>(`${this.API_URL}/inquiries/open`, { params: params });
  }

  getMyOpenBookings(paginationParams: PaginationParamsModel): Observable<PaginationModel<OpenBookingModel>> {
    let params = new HttpParams()
      .set('sort', paginationParams.sort)
      .set('sortby', paginationParams.sortby)
      .set('page', paginationParams.pageIndex.toString())
      .set('pageSize', paginationParams.pageSize.toString());

    // Do not include filter if null
    if (paginationParams.filter != null) {
      params = params.append('filter', paginationParams.filter);
    }

    if (paginationParams.filters != null) {
      if (paginationParams.filters.columnTextFilters.length != 0) {
        params = params.append('columntextfilters', paginationParams.filters.TextFilterString());
      }
      if (paginationParams.filters.columnDataFilters.length != 0) {
        params = params.append('columndatafilters', paginationParams.filters.DataFilterString());
      }


    }

    return this.http.get<PaginationModel<OpenBookingModel>>(`${this.API_URL}/inquiries/open/mine`, { params: params });
  }

  getBookingsById(bookingIds: number[]): Observable<OpenBookingModel[]> {


    return this.http.post<OpenBookingModel[]>(`${this.API_URL}/inquiries/open/list`, bookingIds);
  }

  getBookingTenants(guid: string) {
    return this.http.get<BookingTenantModel[]>(`${this.API_URL}/inquiries/${guid}/tenants`);
  }

  getReservationTenants(guid: string) {
    return this.http.get<BookingTenantModel[]>(`${this.API_URL}/reservations/${guid}/tenants`);
  }

  getPendingApprovals(paginationParams: PaginationParamsModel): Observable<PaginationModel<ApprovalModel>> {
    let params = new HttpParams()
      .set('sort', paginationParams.sort)
      .set('sortby', paginationParams.sortby)
      .set('page', paginationParams.pageIndex.toString())
      .set('pageSize', paginationParams.pageSize.toString());

    // Do not include filter if null
    if (paginationParams.filter != null) {
      params = params.append('filter', paginationParams.filter);
    }

    if (paginationParams.filters != null) {
      if (paginationParams.filters.columnTextFilters.length != 0) {
        params = params.append('columntextfilters', paginationParams.filters.TextFilterString());
      }
      if (paginationParams.filters.columnDataFilters.length != 0) {
        params = params.append('columndatafilters', paginationParams.filters.DataFilterString());
      }
    }
    return this.http.get<PaginationModel<ApprovalModel>>(`${this.API_URL}/inquiries/pending`, { params: params });
  }

  getBookingsForApproval(companyGuid: string): Observable<BookingVM[]> {
    return this.http.get<BookingVM[]>(`${this.API_URL}/booking/approval/${companyGuid}`);
  }

  getApprovedBookings(companyGuid: string): Observable<BookingVM[]> {
    return this.http.get<BookingVM[]>(`${this.API_URL}/booking/approved/${companyGuid}`);
  }

  getSingleBooking(guid: string): Observable<BookingVM> {
    return this.http.get<BookingVM>(`${this.API_URL}/booking/${guid}`);
  }
  getBookingByLeaseId(id: number): Observable<BookingVM> {
    return this.http.get<BookingVM>(`${this.API_URL}/booking/lease/${id}`);
  }
  // retunrs approved inquirystatusId
  approveBooking(guid: string): Observable<number> {
    return this.http.put<number>(`${this.API_URL}/booking/${guid}/status/approved`, null);
  }
  denyBookingCosmos(guid: string): Observable<number> {
    return this.http.put<number>(`${this.API_URL}/booking/${guid}/status/deny`, null);
  }

  finalizeCosmosBooking(guid: string, terms: TermsAndSignature): Observable<BookingVM> {
    return this.http.put<any>(`${this.API_URL}/booking/${guid}/finalizecosmos`, terms);
  }

  getBookingBillingInfo(guid: string): Observable<BookingBillingDetails> {
    return this.http.get<BookingBillingDetails>(`${this.API_URL}/booking/${guid}/getbillinginfo`);
  }

  getPendingAndApprovedBookings(paginationParams: PaginationParamsModel): Observable<PaginationModel<ApprovalModel>> {
    let params = new HttpParams()
      .set('sort', paginationParams.sort)
      .set('sortby', paginationParams.sortby)
      .set('page', paginationParams.pageIndex.toString())
      .set('pageSize', paginationParams.pageSize.toString());

    // Do not include filter if null
    if (paginationParams.filter != null) {
      params = params.append('filter', paginationParams.filter);
    }

    if (paginationParams.filters != null) {
      if (paginationParams.filters.columnTextFilters.length != 0) {
        params = params.append('columntextfilters', paginationParams.filters.TextFilterString());
      }
      if (paginationParams.filters.columnDataFilters.length != 0) {
        params = params.append('columndatafilters', paginationParams.filters.DataFilterString());
      }
    }
    return this.http.get<PaginationModel<ApprovalModel>>(`${this.API_URL}/inquiries/pending-and-approved`, { params: params });
  }

  // approveBooking(guid: string) {
  //   return this.http.post<any>(`${this.API_URL}/inquiries/${guid}/status/approved`, null);
  // }

  denyBooking(guid: string) {
    return this.http.post<any>(`${this.API_URL}/inquiries/${guid}/status/dead`, null);
  }

  signBooking(guid: string) {
    return this.http.post<any>(`${this.API_URL}/inquiries/${guid}/status/completed`, null);
  }


  getSignedApprovals(paginationParams: PaginationParamsModel): Observable<PaginationModel<ApprovalModel>> {
    let params = new HttpParams()
      .set('sort', paginationParams.sort)
      .set('sortby', paginationParams.sortby)
      .set('page', paginationParams.pageIndex.toString())
      .set('pageSize', paginationParams.pageSize.toString());

    // Do not include filter if null
    if (paginationParams.filter != null) {
      params = params.append('filter', paginationParams.filter);
    }

    if (paginationParams.filters != null) {
      if (paginationParams.filters.columnTextFilters.length != 0) {
        params = params.append('columntextfilters', paginationParams.filters.TextFilterString());
      }
      if (paginationParams.filters.columnDataFilters.length != 0) {
        params = params.append('columndatafilters', paginationParams.filters.DataFilterString());
      }
    }
    return this.http.get<PaginationModel<ApprovalModel>>(`${this.API_URL}/inquiries/approved`, { params: params });
  }


  getTickets(paginationParams: PaginationParamsModel, mode: string, reservationGuid: string = ''): Observable<PaginationModel<TicketModel>> {
    let params = new HttpParams()
      .set('sort', paginationParams.sort)
      .set('sortby', paginationParams.sortby)
      .set('page', paginationParams.pageIndex.toString())
      .set('pageSize', paginationParams.pageSize.toString());

    // Do not include filter if null
    if (paginationParams.filter != null) {
      params = params.append('filter', paginationParams.filter);
    }

    if (paginationParams.filters != null) {
      if (paginationParams.filters.columnTextFilters.length != 0) {
        params = params.append('columntextfilters', paginationParams.filters.TextFilterString());
      }
      if (paginationParams.filters.columnDataFilters.length != 0) {
        params = params.append('columndatafilters', paginationParams.filters.DataFilterString());
      }
    }
    return this.http.get<PaginationModel<TicketModel>>(`${this.API_URL}/tickets?type=${mode}&reservationGuid=${reservationGuid}`, { params: params });
  }

  deleteTicket(ticketId: number): Observable<any> {
    return this.http.delete<any>(`${this.API_URL}/tickets/${ticketId}`);
  }

  getTicketDetails(guid: string): Observable<TicketDetailsModel> {
    return this.http.get<TicketDetailsModel>(`${this.API_URL}/tickets/${guid}`);
  }

  getTicketContacts(ticketId: number): Observable<TicketContactModel[]> {
    return this.http.get<TicketContactModel[]>(`${this.API_URL}/tickets/${ticketId}/contacts`);
  }

  postTicketContact(vm: TicketContactModel, contactGuid: string = '') {
    return this.http.post(`${this.API_URL}/tickets/contacts?contactGuid=${contactGuid}`, vm);
  }

  updateTicketContact(vm: TicketContactModel) {
    return this.http.put(`${this.API_URL}/tickets/contacts`, vm);
  }

  deleteTicketContact(ticketId: number, contactId: number) {
    return this.http.delete(`${this.API_URL}/tickets/${ticketId}/contacts/${contactId}`);
  }

  getContacts(companyGuid: string, paginationParams: PaginationParamsModel, withBookings: boolean = false): Observable<PaginationModel<ContactModel>> {
    let params = new HttpParams()
      .set('sort', paginationParams.sort)
      .set('sortby', paginationParams.sortby)
      .set('page', paginationParams.pageIndex.toString())
      .set('pageSize', paginationParams.pageSize.toString());

    // Do not include filter if null
    if (paginationParams.filter != null) {
      params = params.append('filter', paginationParams.filter);
    }

    if (paginationParams.filters != null) {
      if (paginationParams.filters.columnTextFilters.length != 0) {
        params = params.append('columntextfilters', paginationParams.filters.TextFilterString());
      }
      if (paginationParams.filters.columnDataFilters.length != 0) {
        params = params.append('columndatafilters', paginationParams.filters.DataFilterString());
      }


    }

    return this.http.get<PaginationModel<ContactModel>>(`${this.API_URL}/company/${companyGuid}/contacts/${withBookings}/`, { params: params });
  }
  cancelBooking(bookingGuid: string) {
    return this.http.delete<BookingModel>(`${this.API_URL}/inquiries/${bookingGuid}`);
  }



  updateBookingAgent(bookingGuid: string, clientCOntactGuid: string) {
    return this.http.post<any>(`${this.API_URL}/inquiries/${bookingGuid}/updateAgent/${clientCOntactGuid}`, null);
  }


  cancelTicket(ticket: TicketModel): Observable<TicketModel> {
    return this.http.put<TicketModel>(`${this.API_URL}/tickets/`, ticket);
  }
  submitTicket(ticket: FormData): Observable<TicketModel> {
    const headers = {
      headers: new HttpHeaders({
        'Accept': 'multipart/form-data',
      })
    };

    return this.http.post<TicketModel>(`${this.API_URL}/tickets/`, ticket, headers);
  }
  sendEmployeeInvite(companyGuid: string, employee: EmployeeInviteModel): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/company/invite`, employee);
  }
  // we need to check in the booking if a properties lease limit has been reached and cancel them out if so
  isLeaseAllowedForProperty(propertyGuid: string, moveInDate: string): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/property/${propertyGuid}/LeasesAvailable?MoveInDate=${moveInDate}`);
  }

  isCompanyOnHold(companyGuid: string, checkDatabase: boolean): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/company/onhold/${checkDatabase}`);
  }

  UpdateEmployee(companyGuid: string, employee: EmployeeInviteModel): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/company/invite`, employee);
  }

  getClientContact(identityGuid: string): Observable<ClientContactModel> {
    return this.http.get<ClientContactModel>(`${this.API_URL}/clientcontacts/${identityGuid}`);
  }

  getPets(guid: string) {
    return this.http.get<PetModel[]>(`${this.API_URL}/clientcontacts/${guid}/pets/activeOnly`);
  }

  getPetProperty(petId: number) {
    return this.http.get<string>(`${this.API_URL}/clientcontacts/pets/${petId}/getPetProperty`);
  }

  getPetDetailsRequiredDocumentsDueDate(petId: number) {
    return this.http.get<Date>(`${this.API_URL}/clientcontacts/pets/${petId}/getRequiredDocumentDueDate`);
  }

  getAccountPetImages(guid: string, petid: number, folder: string) {
    return this.http.get<AccountPetImage[]>(`${this.API_URL}/clientcontacts/${guid}/pet/${petid}/images/${folder}`);
  }

  deleteAccountPetImages(guid: string, petid: number, imageId: number) {

    return this.http.delete<any>(`${this.API_URL}/clientcontacts/${guid}/pet/${petid}/images/${imageId}`);

  }

  getClientContactSettings(guid: string): Observable<ClientContactSettings> {
    return this.http.get<ClientContactSettings>(`${this.API_URL}/clientcontacts/${guid}/settings`);
  }

  setClientContactSettings(guid: string, model: ClientContactSettings): Observable<any> {
    return this.http.patch<any>(`${this.API_URL}/clientcontacts/${guid}/settings`, model);
  }

  updateAccountUsername(username: string, password: string): Observable<any> {
    var model = {
      newAccountName: username,
      password: password
    };
    return this.http.patch<any>(`${this.API_URL}/accounts/changeaccountname`, model);
  }

  //COMPANY TRADE REFERENCES

  updateCompanyTradeReference(guid: string, ref: ClientCompanyTradeReference) {
    return this.http.post<ClientCompanyTradeReference>(`${this.API_URL}/company/${guid}/tradereferences`, ref);
  }

  deleteCompanyTradeReference(clientCompanyGuid: string, tradeReferenceId: number) {
    return this.http.delete<any>(`${this.API_URL}/company/${clientCompanyGuid}/tradereference/${tradeReferenceId}`);
  }

  addCompanyTradeReference(companyGuid: string, tradeReference: ClientCompanyTradeReference): Observable<ClientCompanyTradeReference> {
    return this.http.post<ClientCompanyTradeReference>(`${this.API_URL}/company/${companyGuid}/tradereference`, tradeReference);
  }

  addAccountPetImage(guid: string, petid: number, folder: string, file: FormData) {
    //[Route("{guid}/pet/{petid}/images/{folder}")]
    let headers = new HttpHeaders();
    headers = headers.append('image', 'image');
    headers = headers.append('Accept', 'application/json');

    return this.http.post<AccountPetImage>(`${this.API_URL}/clientcontacts/${guid}/pet/${petid}/images/${folder}`, file, {
      headers: headers,
      observe: 'events', reportProgress: true, responseType: "json"
    });

  }

  // COMPANY DETAILS
  getCompanyDetails(guid: string) {
    return this.http.get<CompanyDetailsModel>(`${this.API_URL}/company/${guid}`);
  }


  updateCompanyDetsils(company: CompanyDetailsModel) {
    return this.http.post<CompanyDetailsModel>(`${this.API_URL}/company/`, company);
  }

  updateCompanyPOC(guid: string, clientContactGuid: string) {
    return this.http.patch<any>(`${this.API_URL}/company/${guid}/updatepoc/${clientContactGuid}`, null);
  }


  getCompanySettings(guid: string): Observable<companySettings> {
    return this.http.get<companySettings>(`${this.API_URL}/company/${guid}/settings`);
  }

  updateCompanySettings(guid: string, settings: companySettings): Observable<companySettings> {
    return this.http.post<companySettings>(`${this.API_URL}/company/${guid}/settings`, settings);
  }

  getExtensionByGuid(guid: string): Observable<InquiriesQuotesModel> {
    return this.http.get<InquiriesQuotesModel>(`${this.API_URL}/inquiries/${guid}/extension`);
  }

  // ************** //
  // Utility
  // ************** //
  getStates(): Observable<CountryStateModel> {
    return this.http.get<CountryStateModel>(`${this.API_URL}/utility/states`);
  }
  getRefreshToken(value: string): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/accounts/refresh?refreshToken=${value}`);
  }
  getReservationInvoiceReport(reservationGuid: string, invoiceKey: string) {
    return this.http.get<any>(`${this.API_URL}/reservations/${reservationGuid}/invoices/${invoiceKey}/report`, { 'responseType': 'blob' as 'json' });
  }

  log(model: UtilityLogModel, level?: string): Observable<any> {
    if (!level) {
      level = 'debug';
    }
    return this.http.post<any>(`${this.API_URL}/utility/log/${level}`, model);
  }
  getPortalLookups(): Observable<PortalLookupModel> {
    return this.http.get<PortalLookupModel>(`${this.API_URL}/utility/portallookups`);
  }

  createAccount(createAcctModel: CreateAccountModel, token: string) {
    var headers_object = new HttpHeaders().set("Authorization", "Bearer " + token);
    headers_object.append('Content-Type', 'application/json')
    return this.http.post<any>(`${this.API_URL}/accounts/create`, createAcctModel, {
      headers: headers_object
    });
  }

  createClientAndCompany(clientAccount: ClientAccount, token: string) {
    var headers_object = new HttpHeaders().set("Authorization", "Bearer " + token);
    headers_object.append('Content-Type', 'application/json')
    return this.http.post<any>(`${this.API_URL}/clientcontacts/create/company`, clientAccount, {
      headers: headers_object
    });
  }

  createClient(clientAccount: ClientAccount, token: string) {
    var headers_object = new HttpHeaders().set("Authorization", "Bearer " + token);
    headers_object.append('Content-Type', 'application/json')
    return this.http.post<any>(`${this.API_URL}/clientcontacts/create`, clientAccount, {
      headers: headers_object
    });
  }

  getValidationToken() {
    return this.http.get<any>(`${this.API_URL}/accounts/getvaltoken`);
  }

  searchProperties(searchCriteria: any) {
    return this.http.get<any>(`${this.API_URL}/properties/propertysearch?location=${searchCriteria.location}&moveIn=${searchCriteria.startDate}&moveOut=${searchCriteria.endDate}&range=${searchCriteria.range}&isCoordinates=${searchCriteria.isCoordinates}`);
  }

  checkEmailExists(email: any) {
    return this.http.get<any>(`${this.API_URL}/accounts/isavailable?email=${encodeURIComponent(email)}`);
  }
  getPropertyDetails(id: string, startDate: string, endDate: string) {
    return this.http.get<any>(`${this.API_URL}/properties/${id}/details?moveIn=${startDate}&moveOut=${endDate}`);
  }

  login(loginModel: any) {
    return this.http.post<any>(`${this.API_URL}/accounts/Login`, loginModel);
    // let headers = new HttpHeaders();
    // headers = headers.append('__RequestVerificationToken', 'dpVEum-Y8sATxaI_Z0eg0G6BWGzqufYj9VkwK62rda5k2SkFcrL-Y1q_Vm8Vd7nfwA6a0ArnLDKStMnmBovUIVyJPPpESnoj4ajLDFWQgaQ1')
    // return this.http.post<any>(`${this.API_URL}/accounts/login`, loginModel, {
    //   headers: {
    //     '__RequestVerificationToken': 'YDVhXnzYyDYcMrOwoGZ8TeO9drEL44lKk31WBRTrd4cdlasrkx70__n23jRWL5j2nS3YjVLDvFqF34C9adIGWyR5iFEVra0ibINZWvjVJaU1',
    //     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    //   }
    // });
  }
  confirmAccount(userToken: string) {
    return this.http.post<any>(`${this.API_URL}/accounts/confirm`, JSON.stringify(userToken), { headers: new HttpHeaders().set('Content-type', 'application/json') });
  }
  resendEmailConfirmation(ResendEmailProperty: any, token: string) {
    var headers_object = new HttpHeaders().set("Authorization", "Bearer " + token);
    headers_object.append('Content-Type', 'application/json')
    return this.http.post<any>(`${this.API_URL}/accounts/resendconfirmation`, ResendEmailProperty, {
      headers: headers_object
    });
  }

  canCreateBooking(authToken: string) {
    var headers_object = new HttpHeaders().set("Authorization", "Bearer " + authToken);
    headers_object.append('Content-Type', 'application/json')
    return this.http.get<any>(`${this.API_URL}/inquiries/cancreatebooking`, {
      headers: headers_object
    });
  }

  getSetPasswordTokenByGUID(token_guid: string) {
    return this.http.get<any>(`${this.API_URL}/utility/redeemtoken/${token_guid}`);
  }

  // ************** //
  // Legal Logging
  // ************** //

  getClientIPAddress() {
    return this.http.get<any>(`${this.IPAddress_URL}`);
  }

  createLegalLogging(legalLogging: LegalLogging) {
    return this.http.post<any>(`${this.API_URL}/legallogging`, legalLogging);
  }

  uploadClientLeaseDocument(docModel: ClientLeaseDocModel) {
    return this.http.post<any>(`${this.API_URL}/legallogging/addclientdocument`, docModel);
  }

  getLegalLoggingRecord(inquiryGuid: string) {
    return this.http.get<any>(`${this.API_URL}/legallogging/${inquiryGuid}`);
  }
}
