import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppLayoutComponent } from './app-layout-component/app-layout.component';
import { NavSidebarComponent } from '../nav-sidebar/nav-sidebar.component';
import { RouterModule } from '@angular/router';
import { BackgroundCheckFormComponent } from 'app/landing-pages/background-check/background-check-form/background-check-form.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material';

@NgModule({
  imports: [CommonModule, RouterModule, MatProgressBarModule, MatMenuModule],
  declarations: [AppLayoutComponent,
    NavSidebarComponent
  ]
})
export class AppLayoutModule { }
