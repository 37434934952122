import { Injectable, OnInit } from '@angular/core';
import { PriceModel, RoomUpgrade, MonthlyPayment, InitialPayment, NewPriceModel } from 'app/models/PriceModel';
import { ApiService } from './api.service';
import { NGXLogger } from 'ngx-logger';
import { CountryStateService } from './country-state.service';
import { BookingWizardModel } from 'app/models/BookingWizardModel';
import { Subject, BehaviorSubject } from 'rxjs';
import { BookingCostModel } from 'app/models/BookingCostModel';
import { ERRORS_COSTCALC_UNITUNAVAILABLE } from 'app/common/Utils';

@Injectable()

export class CostCalculatorService {

  costModel: NewPriceModel;

  adultTotal = 0;
  childTotal = 0;

  upgradedCustomizations: RoomUpgrade[] = [];
  standardCustomizations: RoomUpgrade[] = [];
  proratedUpgradedCustomizations: RoomUpgrade[] = [];
  hasDoneInitialCheck = false;

  private costChangedSubject = new BehaviorSubject<BookingCostModel>(null);
  private tenantsChangedSubject = new BehaviorSubject<any>(null);
  private sidebarUpdatingSubject = new BehaviorSubject<string>('updated');
  private firstCostCalculatedSubject = new BehaviorSubject<BookingCostModel>(null);

  costUpdated$ = this.costChangedSubject.asObservable();
  tenantsUpdated$ = this.tenantsChangedSubject.asObservable();
  sidebarUpdating$ = this.sidebarUpdatingSubject.asObservable();
  firstCostCalculated$ = this.firstCostCalculatedSubject.asObservable();
  currentCostCalculatorStack = 0;

  constructor(private apiService: ApiService, public countryStateService: CountryStateService, private logger: NGXLogger) {
    this.init();
  }

  init() {
    this.initEmptyCostModel();
  }

  showSidebarBlurOverlay(): void {

    this.sidebarUpdatingSubject.next('updating');
    this.logger.log('sidebar updating kicked off');

  }

  hideSidebarBlurOverlay(): void {
    this.sidebarUpdatingSubject.next('updated');
    this.logger.log('sidebar updating called off manually');
  }

  onBookingWizardDestroy() {

    this.initEmptyCostModel();

    this.adultTotal = 0;
    this.childTotal = 0;
    this.hasDoneInitialCheck = false;
  }

  initEmptyMonthlyPayment(): MonthlyPayment {
    return {
      parkingCost: 0,
      petRent: 0,
      roomUpgrades: [],
      roomUpgradeTotal: 0,
      totalPrice: 0,
      unitRent: 0
    };
  }

  initEmptyInitialPayment(): InitialPayment {
    return {
      applicationFee: 0,
      petDeposit: 0,
      proratedMonthlyPayment: this.initEmptyMonthlyPayment(),
      securityDeposit: 0,
      totalPrice: 0,
      petFee: 0
    };
  }

  createEmptyCostModel(): void {
    this.costModel = {
      initialPayment: this.initEmptyInitialPayment(),
      monthlyPayment: this.initEmptyMonthlyPayment(),
      bathrooms: 0,
      bedrooms: 0,
      checkInDate: new Date(),
      checkOutDate: new Date(),
      parkingType: '',
      propertyName: '',
      unitNumber: ''
    };
  }

  calculateAndDrawSidebar(booking: BookingWizardModel): void {

    this.currentCostCalculatorStack++;

    if (booking != null) {

      this.apiService.getNewCost(booking.inquiry.guid).subscribe(
        response => {
          this.costModel = response;

          const model = this.getBookingPriceModel();
          this.getCustomizationsTotal(); // this rechecks our selected standard and upgraded customizations
          this.logger.log('cost model calculated...', this.costModel);

          if (this.hasDoneInitialCheck === false) {
            this.hasDoneInitialCheck = true;
            this.firstCostCalculatedSubject.next(model);
          }

          this.costChangedSubject.next(model);
        },
        error => {
          this.logger.error(error);

          // the cost calculator has determined this unit is no longer available so lets show the appropriate error
          if (error && error.error && error.error.includes(ERRORS_COSTCALC_UNITUNAVAILABLE)) {
            this.firstCostCalculatedSubject.error(ERRORS_COSTCALC_UNITUNAVAILABLE);
            return;
          }

          // if we error out on the first call we still need to send
          // a follow up I think since the load booking modal hinges on this return...
          if (this.hasDoneInitialCheck === false) {
            // this.hasDoneInitialCheck = true;
            this.firstCostCalculatedSubject.error('error');
          }
        },
        () => { // this gets called when the subscribe is complete
          this.currentCostCalculatorStack--;

          if (this.currentCostCalculatorStack === 0) {
            this.sidebarUpdatingSubject.next('updated');
          }
        }
      );

      this.updateTenantAmount(booking);
    }
  }

  updateTenantAmount(booking: BookingWizardModel): void {
    if (booking.tenants != null) {
      this.calcTenantTypesString(booking);
    }
  }

  getBookingPriceModel(): BookingCostModel {

    const model: BookingCostModel = {
      costModel: this.costModel,
      adultTotal: this.adultTotal,
      childTotal: this.childTotal
    };

    return model;
  }

  getCustomizationsTotal(): void {

    this.upgradedCustomizations = [];
    this.standardCustomizations = [];
    this.proratedUpgradedCustomizations = [];

    this.costModel.monthlyPayment.roomUpgrades.forEach(customization => {
      if (customization.amount > 0) {
        this.upgradedCustomizations.push(customization);
      } else {
        this.standardCustomizations.push(customization);
      }
    });

    this.costModel.initialPayment.proratedMonthlyPayment.roomUpgrades.forEach(customization => {
      if (customization.amount > 0) {
        this.proratedUpgradedCustomizations.push(customization);
      }
    });
  }

  initTenantCount(booking: BookingWizardModel): void {

    // this loads up our initial counts for children / adults
    booking.tenants.forEach(tenant => {
      if (tenant.clientContact.clientContactType.includes('Child') || tenant.clientContact.clientContactType.includes('Infant')) {
        this.childTotal++;
      } else {
        this.adultTotal++;
      }
    });

    this.tenantsChangedSubject.next(this.getTenantModel());
  }

  calcTenantTypesString(booking: BookingWizardModel): void {

    let tempChildCount = 0;
    let tempAdultCount = 0;

    booking.tenants.forEach(tenant => {
      if (tenant.clientContact.clientContactType.includes('Child') || tenant.clientContact.clientContactType.includes('Infant')) {
        tempChildCount++;
      } else {
        tempAdultCount++;
      }
    });

    // only call the api to update these counts if they have changed for the current inquiry
    if (this.adultTotal !== tempAdultCount || this.childTotal !== tempChildCount) {

      this.adultTotal = tempAdultCount;
      this.childTotal = tempChildCount;

      // this.sidebarUpdatingSubject.next('updating');

      this.apiService.updateTenantCount(booking.inquiry.guid, this.adultTotal, this.childTotal).subscribe(
        response => {
          // 200 is a success
          this.tenantsChangedSubject.next(this.getTenantModel());
        },
        error => {
          this.logger.error(error);
        },
        () => {
          this.sidebarUpdatingSubject.next('updated');
          this.logger.log('sidebar updating completed');
        }
      );
    } else {
      this.tenantsChangedSubject.next(this.getTenantModel());
      this.logger.log('tenants changed subject');
    }
  }

  getTenantModel(): any {
    return {
      adultTotal: this.adultTotal,
      childTotal: this.childTotal
    };
  }

  initEmptyCostModel(): void {

    // zero out our customizations in case the booking is being changed
    this.upgradedCustomizations = [];
    this.standardCustomizations = [];
    this.proratedUpgradedCustomizations = [];

    this.createEmptyCostModel();
    const model = this.getBookingPriceModel();

    this.costChangedSubject.next(model);
  }
}
