import { Directive, HostListener, ElementRef, OnInit, HostBinding } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ScreenService } from 'app/services/screen.service';

@Directive({
  selector: '[appScrollToInvalidField]'
})

export class ScrollToInvalidFieldDirective implements OnInit {

  constructor(private el: ElementRef, private logger: NGXLogger, private screenService: ScreenService) { }

  ngOnInit() {
    this.logger.log('Directive called');
  }

  // @HostBinding('class.btn-submit')
  @HostListener('submit') onFormSubmit() {

    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    if (invalidElements.length > 0) {

      if (!this.screenService.isElementInViewport(invalidElements[0])) {
        const scroll = document.documentElement.scrollTop || document.body.scrollTop;
        this.logger.log('first invalid form element', invalidElements[0]);

        window.scroll(0, findPos(invalidElements[0]) - 120);

        invalidElements[0].focus();
      } else {
        invalidElements[0].focus();
      }
    }
  }
}

//Finds y value of given object
function findPos(obj) {
  var curtop = 0;
  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
    } while (obj = obj.offsetParent);
    return curtop;
  }
}
