import { NgModule } from '@angular/core';
//import { NgForm } from '@angular/forms';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateAccountComponent } from './create-account-form/create-account-form.component'
import { SignInComponent } from './sign-in-form/sign-in-form.component'
import { AccountRoutingModule } from './account-routing.module'
import { ForgotPasswordComponent } from './forgot-password/forgot-password-form.component';
import { SetPasswordComponent } from './set-password/set-password.component';


@NgModule({
  declarations: [CreateAccountComponent, SignInComponent, ForgotPasswordComponent, SetPasswordComponent],
  imports: [AccountRoutingModule, CommonModule, FormsModule, ReactiveFormsModule],
  entryComponents: [SignInComponent, CreateAccountComponent, ForgotPasswordComponent, SetPasswordComponent]
})

export class AccountModule { }