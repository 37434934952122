import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToDirective } from './scroll-to-directive.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TruncatePipe } from './TruncatePipe';
import { MatTableModule, MatSortModule, MatPaginatorModule, MatRadioModule, MatSnackBarModule, MAT_SNACK_BAR_DATA, MatProgressSpinnerModule, MatSlideToggleModule, MatCheckbox, MatCheckboxModule, MatChipsModule, MatIconModule, MatMenuModule } from '@angular/material';
import { AddPersonModalComponent } from './add-person-modal/add-person-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreditCardInfoComponent } from './credit-card-info/credit-card-info.component';
import { InvitationConfirmationModalComponent } from './invitation-confirmation-modal/invitation-confirmation-modal.component';
import { IdentityVerificationComponent } from './identity-verification/identity-verification.component';
import { IdentityVerificationFailedComponent } from './identity-verification/identity-verification-failed/identity-verification-failed.component';
import { BackgroundCheckResultsComponent } from './background-check-results/background-check-results.component';
import { ScrollToInvalidFieldDirective } from './scroll-to-invalid-field.directive';
import { RouterModule } from '@angular/router';
import { BaseComponent } from './base/base.component';
import { DragDropDirective } from './drag-drop.directive';
import { SecureMessageComponent } from './secure-message/secure-message.component';
import { SliderComponent } from './slider/slider.component';
import { AddTicketModalComponent } from '../portal/tickets/add-ticket-modal/add-ticket-modal.component';
import { AddCreditCardComponent } from './add-credit-card/add-credit-card.component';
import { ManageCreditCardsComponent } from './manage-credit-cards/manage-credit-cards.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OnlyNumericDirective } from './only-numeric.directive';
import { GrgTabNavComponent } from './grg-tab-navigation/grg-tab-nav.component';
import { SpecialCharPipe, TabListPipe } from './SpecialCharPipe';
import { AddTradeReferenceComponent } from './add-trade-reference/add-trade-reference.component';
import { NgLetDirective } from './ng-let.directive';
import { GrgTableComponent } from './grg-table/grg-table.component';
import { CustomerCreditCardComponent } from './customer-credit-card/customer-credit-card.component';
import { PetSpeciesPipe } from './PetSpeciesPipe';
import { TableRowClickDirective } from './table-row-click.directive';
import { TableValuePipe } from './table-value.pipe';
import { CreditCardTypePipe } from './credit-card-type.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TermsOfServiceModalComponent } from './terms-of-service-modal/terms-of-service-modal.component';
import { WhyBackgroundCheckModalComponent } from './why-background-check-modal/why-background-check-modal.component';
import { StateByIdPipe } from './state-by-id.pipe';
import { MaintenanceModalComponent } from './maintenance-modal/maintenance-modal.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    RouterModule,
    MatCheckboxModule,
    DragDropModule,
    MatChipsModule,
    MatIconModule,
    MatMenuModule
  ],
  declarations: [
    ScrollToDirective,
    TruncatePipe,
    AddPersonModalComponent,
    CreditCardInfoComponent,
    InvitationConfirmationModalComponent,
    IdentityVerificationComponent,
    IdentityVerificationFailedComponent,
    BackgroundCheckResultsComponent,
    ScrollToInvalidFieldDirective,
    BaseComponent,
    DragDropDirective,
    SecureMessageComponent,
    SliderComponent,
    AddTicketModalComponent,
    AddCreditCardComponent,
    ManageCreditCardsComponent,
    GrgTabNavComponent,
    SpecialCharPipe,
    OnlyNumericDirective,
    AddTradeReferenceComponent,
    NgLetDirective,
    GrgTableComponent,
    CustomerCreditCardComponent,
    PetSpeciesPipe,
    TableRowClickDirective,
    TableValuePipe,
    TabListPipe,
    CreditCardTypePipe,
    TermsOfServiceModalComponent,
    WhyBackgroundCheckModalComponent,
    SafeHtmlPipe,
    StateByIdPipe,
    MaintenanceModalComponent
  ],
  exports: [
    ScrollToDirective,
    TruncatePipe,
    AddPersonModalComponent,
    IdentityVerificationComponent,
    ScrollToInvalidFieldDirective,
    BaseComponent,
    BackgroundCheckResultsComponent,
    DragDropDirective,
    SecureMessageComponent,
    AddTicketModalComponent,
    ManageCreditCardsComponent,
    GrgTabNavComponent,
    SpecialCharPipe,
    OnlyNumericDirective,
    AddTradeReferenceComponent,
    NgLetDirective,
    GrgTableComponent,
    PetSpeciesPipe,
    TableValuePipe,
    TableRowClickDirective,
    TabListPipe,
    CreditCardTypePipe,
    SafeHtmlPipe,
    StateByIdPipe,
    AddCreditCardComponent,
    MaintenanceModalComponent
  ],
  entryComponents: [
    AddPersonModalComponent,
    InvitationConfirmationModalComponent,
    AddTicketModalComponent,
    AddCreditCardComponent,
    ManageCreditCardsComponent,
    AddTradeReferenceComponent,
    TermsOfServiceModalComponent,
    WhyBackgroundCheckModalComponent
  ]
})
export class CommonUIModule { }
