export interface PaginationParamsModel {
  filter?: string;
  sort: string;
  sortby: string;
  pageIndex: number;
  pageSize: number;
  filters?: FilterUtility;
}

export class columnTextFilter {
  public key: string;
  public value: string;
}



export class columnDataFilter {
  public key: string;
  public value: string;
}

export class FilterUtility {

  public columnTextFilters: columnTextFilter[] = [];
  public columnDataFilters: columnDataFilter[] = [];


  addTextFilter(key: string, value: string): number {
    let c: columnTextFilter = this.columnTextFilters.find(f => f.key === key);
    if (c) {
      c.value = value;
    }
    else {
      c = new columnTextFilter();
      c.key = key;
      c.value = value;
      this.columnTextFilters.push(c);
    }
    return this.columnTextFilters.length;
  }

  removeTextFilter(key: string): number {
    let i = this.columnTextFilters.findIndex(f => f.key === key);
    if (i != -1) {
      this.columnTextFilters.splice(i, 1);
      this.columnTextFilters = this.columnTextFilters.slice();
    }
    return i;
  }

  TextFilterString(key?: string): string {
    var filter;
    if (this.columnTextFilters.length >> 0) {
      if (key) {
        let i = this.columnTextFilters.findIndex(f => f.key === key);
        if (i != -1) {
          filter = this.columnTextFilters[i].key + ":" + this.columnTextFilters[i].value;
        }
      }
      else {
        filter = "";
        for (let i = 0; i < this.columnTextFilters.length; i++) {
          filter += this.columnTextFilters[i].key + ":" + this.columnTextFilters[i].value;
          if (i != this.columnTextFilters.length - 1) {
            filter += ";";
          }

        }
      }
    }

    return filter;
  }

  addDataFilter(key: string, value: any[]) {
    let c: columnDataFilter = this.columnDataFilters.find(f => f.key === key);
    if (c) {
      c.value = value.join(",");
    }
    else {
      c = new columnDataFilter();
      c.key = key;
      c.value = value.join(",");
      this.columnDataFilters.push(c);
    }

  }


  removeDataFilter(key: string): number {
    let i = this.columnDataFilters.findIndex(f => f.key === key);
    if (i != -1) {
      this.columnDataFilters.splice(i, 1);
      this.columnDataFilters = this.columnDataFilters.slice();
    }
    return i;
  }

  GetTextFilterValues(key: string): string {
    if (this.columnTextFilters.find(f => f.key === key)) {
      return this.columnTextFilters.find(f => f.key === key).value;
    }
    else {
      return undefined;
    }
  }

  GetDataFilterValues(key: string): string[] {
    if (this.columnDataFilters.find(f => f.key === key)) {
      return this.columnDataFilters.find(f => f.key === key).value.split(",");
    }
    else {
      return [];
    }

  }

  DataFilterString(key?: string): string {
    var filter;
    if (this.columnDataFilters.length >> 0) {
      if (key) {
        let i = this.columnDataFilters.findIndex(f => f.key === key);
        if (i != -1) {
          filter = this.columnDataFilters[i].key + ":" + this.columnDataFilters[i].value;
        }
      }
      else {
        filter = "";
        for (let i = 0; i < this.columnDataFilters.length; i++) {
          filter += this.columnDataFilters[i].key + ":" + this.columnDataFilters[i].value;
          if (i != this.columnDataFilters.length - 1) {
            filter += ";";
          }

        }
      }
    }

    return filter;
  }


  ActiveFilters(): number {
    return this.columnDataFilters.length + this.columnTextFilters.length;
  }

  ClearAllFilters() {
    this.columnTextFilters = [];
    this.columnDataFilters = [];
  }

}