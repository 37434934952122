import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StatusCheckModel } from 'app/models/StatusCheckModel';
import { CreditStatus } from 'app/models/CreditStatusEnum';
import { Router } from '@angular/router';
import Utils, { ROUTE_PORTAL_HOME } from '../Utils';

@Component({
  selector: 'app-background-check-results',
  templateUrl: './background-check-results.component.html',
  styleUrls: ['./background-check-results.component.scss']
})

export class BackgroundCheckResultsComponent implements OnInit {

  @Input()
  status: CreditStatus;

  @Input()
  removeSidebar: boolean;
  creditStatusEnum = CreditStatus;

  @Output() onReturnToTenants: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigateBackToDashboard(e: any): void {
    if (e) {
      e.preventDefault();
    }

    this.router.navigate([ROUTE_PORTAL_HOME]);
  }

  navigateToTenantsList() {
    this.onReturnToTenants.emit(true);
  }

}
