import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardType'
})
export class CreditCardTypePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) {
      return '';
    }

    var cardUpdate = value.replace(/American Express/g, 'AMEX');
    return cardUpdate;
  }

}
