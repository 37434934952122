import { Component, OnInit } from '@angular/core';
import { ImportDataService } from 'app/services/import-data.service';
import { UserService } from 'app/services/user.service';
import { ApiService } from 'app/services/api.service';
import { NGXLogInterface, NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private importedDataService: ImportDataService,
    private apiService: ApiService,
    private logger: NGXLogger) { }

  ngOnInit() {
      
    try {
      this.importedDataService.logout(false);
      this.logger.debug('logout complete!');
    }
    catch (error) {
      this.logger.warn('logout could not be completed!');
      this.logger.error(error);
    }

      if (window.parent) {
        this.logger.debug('parent window found...sending message...');
        window.parent.postMessage('done', '*'); // this should be locked down better but we aren't sending sensitive data so no worries..  
      }
    }
}
