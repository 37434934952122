import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'specialChar'
})
export class SpecialCharPipe implements PipeTransform {

  transform(value: string, letterCase?: string): string {
    let newVal = value.replace(/[^\w\s]/gi, ' ')
    
    switch (letterCase) {
      case 'uppercase':
        return (newVal.charAt(0) + newVal.slice(1)).toUpperCase();
      case 'lowercase':
        return (newVal.charAt(0) + newVal.slice(1)).toLowerCase();
      case 'capitalize':
        return ((newVal.charAt(0).toUpperCase() + newVal.slice(1))) ;
      default:
        return (newVal.charAt(0) + newVal.slice(1));
    }
  }
}

@Pipe({
  name: 'tabList'
})
export class TabListPipe implements PipeTransform {

  transform(value: string, letterCase?: string): string {
    let newVal = value.replace(/[\-]/gi, ' ')
    
    switch (letterCase) {
      case 'uppercase':
        return (newVal.charAt(0) + newVal.slice(1)).toUpperCase();
      case 'lowercase':
        return (newVal.charAt(0) + newVal.slice(1)).toLowerCase();
      case 'capitalize':
        return ((newVal.charAt(0).toUpperCase() + newVal.slice(1))) ;
      default:
        return (newVal.charAt(0) + newVal.slice(1));
    }
  }
}