import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableValue'
})
export class TableValuePipe implements PipeTransform {

  transform(value: any, args?: any[]): any {
    // if no value was provided, then pass back a dash
    if (!value) {
      return '-';
    } else {
      return value;
    }
  }

}
