import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { CountryStateModel } from 'app/models/CountryStateModel';
import { ImportDataService } from './import-data.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class CountryStateService {
  countryStates = new BehaviorSubject<CountryStateModel>(null);

  countriesById = {};
  statesById = {};

  stateNamesById = {};

  countriesByIdBehaviorSubject = new BehaviorSubject<Object>(null);
  statesByIdBehaviorSubject = new BehaviorSubject<Object>(null);

  constructor(public logger: NGXLogger) {
  }

  initWithData(data: CountryStateModel): void {
    this.countryStates.next(data);
    this.initCountriesById();
    this.initStatesById();
  }

  initCountriesById(): void {
    Object.keys(this.countryStates.value.countries).forEach(country => {
      // Create a hashmap to look up the country string by id.
      this.countriesById[this.countryStates.value.countries[country][0].countryId] = country;
    });

    // Send update
    this.countriesByIdBehaviorSubject.next(this.countriesById);
  }

  initStatesById(): void {
    Object.keys(this.countryStates.value.countries).forEach(country => {
      this.countryStates.value.countries[country].forEach(state => {
        this.statesById[state.stateId] = state.stateAbbreviation; // stateName ?
        this.stateNamesById[state.stateId] = state.stateName;
      });
    });

    // Send update
    this.statesByIdBehaviorSubject.next(this.statesById);
  }
}
