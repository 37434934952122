import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/services/api.service';
import { forkJoin } from 'rxjs';
import { ClientContactModel } from 'app/models/ClientContactModel';
import { BackgroundCheckModel } from 'app/models/EmployeesModel';
import { REGEX_ALPHANUMERIC } from 'app/common/Utils';
import { CountryStateModel, StateModel } from 'app/models/CountryStateModel';
import { CountryStateService } from 'app/services/country-state.service';

@Component({
    templateUrl: './additional-occupant-background-check.component.html',
    styleUrls: ['./additional-occupant-background-check.component.scss']
})

export class AdditionalOccupantBackgroundCheckComponent implements OnInit {
    signLeaseForm: FormGroup;
    authorizeCreditCheck = false;
    consentCheck = false;
    countryStates: CountryStateModel;
    expired: boolean = true;
    content: 'expired' | 'invalid' | 'checking' | 'identity' | 'consent' | 'success' | 'error' = 'checking';
    contactGuid: string;
    contact: ClientContactModel;
    key: string;
    private _expiry: string;
    private _property: string;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private countryStateService: CountryStateService,
        private api: ApiService
    ) {
        this.initForm();
    }

    initForm() {
        this.signLeaseForm = this.fb.group({
            fullName: [{ value: '', disabled: false }, [Validators.required]],
            ssn: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(9)]],
            driversLicenseNo: [{ value: '', disabled: false }, [Validators.required, Validators.pattern(REGEX_ALPHANUMERIC), Validators.maxLength(50)]],
            dob: [{ value: '', disabled: false }, [Validators.required]],
            globalStateProvId: [{ value: '' }, [Validators.required]],
            globalCountryId: [{ value: 'United States' }],
            signedDate: [{ value: '', disabled: true }, [Validators.required]]
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe(res => {
            this._expiry = res['expired'];
            this.key = res['key'];
            this._property = res['property'];
            forkJoin([
                this.api.checkAdditionalOccupantExpiry(this._expiry),
                this.api.getAdditionalOccupant(this.key)
            ]).subscribe(res => {
                const isExpired = res[0];
                this.expired = isExpired;
                this.contact = res[1];


                this.contactGuid = this.contact.clientContactGuid;

                if (isExpired) {
                    this.content = 'expired';
                } else {
                    this.content = 'identity';
                }
            })
        })
        this.countryStateService.countryStates
            .subscribe(
                (response: CountryStateModel) => {
                    this.countryStates = response;
                });
    }
    isInvalid(controlName: string): boolean {
        const control = this.signLeaseForm.get(controlName);
        return control.invalid && (control.dirty || control.touched);
    }


    getStateArray(country?: string): StateModel[] {
        console.log(country);

        console.log(this.countryStates);
        // Use selected country as hash key. 'United States' is default.
        // If country was entered, provide the state list for the specific country.
        // Otherwise use the country that has been selected on the form.
        return country
            ? this.countryStates.countries[country] : this.countryStates.countries[country]
    }

    requestNewConsent() {
        this.api.occupantNewBackground(this.key, this._property).subscribe(() => { })
    }

    signDateOnCheckMarked() {
        const now = new Date().toLocaleString("en-US");
        // if user needs one of the checks make sure he clicks on both checkboxes
        if (this.authorizeCreditCheck && this.consentCheck) {
            this.signLeaseForm.patchValue({
                signedDate: now
            });
        }
        else {
            if (this.consentCheck) {
                this.signLeaseForm.patchValue({
                    signedDate: null
                });
            }
        }
    }

    getCountryArray(): string[] {
        // Handle loading case
        if (this.countryStates == null) {
            return null;
        }
        // Use hash keys as country choices
        return Object.keys(this.countryStates.countries);
    }

    countryChanged(field: string) {
        // when the country dropdown is changed, we will select the first state for them by
        // default to keep the country & state selections consistent
        const states = this.getContactStateArray(this.signLeaseForm.get('globalCountryId').value);
        const stateId = states && states.length ? states[0].stateId : null;

        this.signLeaseForm.get('globalStateProvId').patchValue(stateId);
        this.signLeaseForm.get('globalStateProvId').updateValueAndValidity();
    }

    getContactStateArray(country?: string): StateModel[] {
        // Handle loading case
        if (this.countryStates == null) {
            return null;
        }

        // Use selected country as hash key. 'United States' is default.
        // If country was entered, provide the state list for the specific country.
        // Otherwise use the country that has been selected on the form.
        return country ? this.countryStates.countries[country]
            : this.countryStates.countries[this.signLeaseForm.get('globalCountryId').value];
    }
    consentBackground() {
        this.content = 'checking';
        this.api.additionalOccupantConsent(this.key, this._expiry, this._property, this.signLeaseForm.get('dob').value, this.signLeaseForm.get('ssn').value, this.signLeaseForm.get('driversLicenseNo').value, this.signLeaseForm.get('globalStateProvId').value)
            .subscribe(res => {
                this._checkIfApproved(res);
            }, error => {
                this.content = "error";
            })
    }

    private _checkIfApproved(result: BackgroundCheckModel[]) {
        const allApproved = result.every(c => c.status === ('Approved'))
        if (allApproved) {
            this.content = 'success';
        } else {
            this.content = 'error';
        }
    }

    verificationPassed(passed: boolean) {
        if (passed) {
            this.content = 'consent';
        }
    }
}