export interface PropertySearch {
    location: string,
    checkIn: string,
    checkOut: string
}


export interface PropertySearchAPI {
    location: string,
    startDate: string,
    endDate: string,
    isCoordinates: boolean,
    range: number,
    panToCenterMap: boolean,
    bounds?: MapBounds
}

export interface SearchPropertyResultModel {
    coordinates: Coordinates;
    results: APISearchResult[];
    SEOData: SEOModel;
    PropertiesInArea: boolean;
    terms: number;
}

export interface MapBounds {
    MinPoint: [number, number]
    MaxPoint: [number, number]
}

export interface Coordinates {
    latitude: number;
    longitude: number;
    horizontalAccuracy: string;
    verticalAccuracy: string;
    speed: string;
    course: string;
    isUnknown: boolean;
    altitude: string;
}

export interface APISearchResult {
    id: string;
    guid: string;
    name: string;
    description: null | string;
    address: Address;
    propertyType: null | string;
    availableUnits: number;
    unitAmenities: any[];
    propertyAmenities: string[];
    pricePerNight: number;
    pricedFrom: string;
    calcLowestPrice: number;
    calcAvailableUnits: number;
    o_calcLowestPrice: number;
    o_calcAvailableUnits: number;
    images: Image[];
    floorplans: FloorPlan[];
    map: null;
    propertyBuildingTypeId: number;
    squareFootage: any[];
    startDate: string;
    endDate: string;
    url: string;
}

export interface Address {
    address1: string;
    address2: string;
    city: string;
    state: string;
    stateAbbreviation: null;
    zip: string;
    country: null;
    latitude: number;
    longitude: number;
}

export interface FloorPlan {
    bedrooms: number;
    bathrooms: number;
    unitsAvailable: number;
    price: number;
}

export interface Image {
    id: number;
    propertyGuid: string;
    etag: string;
    url: string;
    description: null;
    orderIndex: number;
    uploadedBy: string;
    uploadedOn: string;
    propertyGu: null;
}



export interface SearchResultFilter {
    filters: SearchFilter[],
    amenities: AmenityModel[],
    minPrice: number,
    maxPrice: number,
    startDate: string,
    endDate: string,
    latLon: [number, number],
    city: string,
    sortBy: SearchFilterSort,
    page: number,
    perPage: number,
    zoom?: number
}

interface SearchFilterSort {
    name: string,
    slug: string,
    value: string,
    options: SearchFilterSortOption[]
}

interface SearchFilterSortOption {
    name: string,
    value: string,
    valueField: string,
    ascOrDesc: string
}

export interface SEOModel {
    nodeId?: number,
    CityTitle: string,
    CityUrl: string,
    //search results page properties
    StateTitle: string,
    StateAbbreviation: string,
    StateMeta_Title: string,
    SearchMeta_Description: string,
    H1Title: string,
    IntroDescription: string,
    SEOContentBlockTitle: string,
    SEOContentBlockTextColumns: string,
    SEOContentBlockEndText: string,
}

export interface PropertyModel {
    id: string,
    guid: string,
    name: string,
    description: string,
    address: PropertyAddress,
    propertyType: string,
    availableUnits: number,
    pricedFrom: string,
    bedrooms: number[],
    bathrooms: number[],
    squareFootage: number[],
    unitAmenities: string[],
    propertyAmenities: string[],
    propertyBuildingTypeId?: number
    images: any,
    floorplans: any,
    url: any,
    filteredData?: any[]
}

interface PropertyAddress {
    address1: string,
    address2: string,
    city: string,
    state: string,
    stateAbbreviation: string,
    zip: string,
    latitude: string,
    longitude: string,
    country: string
}

export interface searchParams {
    start: string,
    end: string,
    lat: number,
    lon: number,
    zoom: string,
    amenities: string,
    filters: string,
    minPrice: string,
    maxPrice: string,
    location: string
}

export interface AmenityModel {
    display: string,
    name: string,
    value: string,
    active: boolean
}

export interface SearchFilter {
    name: string,
    slug: string,
    mappingField: string,
    filterType: string,
    value: string,
    options: any
}

export interface AppliedFilter {
    amenities: string[],
    minPrice?: number,
    maxPrice?: number,
    sortBy: string,
    bedrooms: string,
    bathrooms: string,
    propertyBuildingTypeId: string
}

export interface paginationButtonModel {
    pageNumber: string,
    text: string, buttonType: string, active: boolean
}

export const filters: SearchFilter[] = [
    {
        name: "Building Type",
        slug: "building-type",
        value: "",
        mappingField: "propertyBuildingTypeId",
        filterType: "match",
        options: [
            { id: 1, name: "Low Rise - 1 to 3 Stories", value: "1" },
            { id: 2, name: "Mid Rise - 4 to 6 Stories", value: "2" },
            { id: 3, name: "High Rise - 7+ Stories", value: "3" }
        ]
    },
    {
        name: "Bedrooms",
        slug: "bedrooms",
        value: "",
        mappingField: "bedrooms",
        filterType: "numeric",
        options: [{
            name: "1 Bedroom",
            value: "1"
        },
        {
            name: "2 to 3 Bedrooms",
            value: "2-to-3"
        },
        {
            name: "4+ Bedrooms",
            value: "4+"
        }
        ]
    },
    {
        name: "Bathrooms",
        slug: "bathrooms",
        mappingField: "bathrooms",
        filterType: "numeric",
        value: "",
        options: [{
            name: "1 Bathroom",
            value: "1"
        },
        {
            name: "2-3 Bathrooms",
            value: "2-to-3"
        },
        {
            name: "4+ Bathrooms",
            value: "4+"
        }
        ]
    }
]
export const amenities: AmenityModel[] = [
    {
        display: "Business Center", //front end display
        name: "Business Center",    //matches what value from server is
        value: "business-center",   //url version
        active: false
    },
    {
        display: "Elevator",
        name: "Elevator",
        value: "elevator",
        active: false
    },
    {
        display: "Fitness Center",
        name: "Fitness Center",
        value: "fitness-center",
        active: false
    },
    {
        display: "Pool",
        name: "Pool",
        value: "pool",
        active: false
    },
    {
        display: "Smoke Free",
        name: "Smoke Free",
        value: "smoke-free",
        active: false
    },
    {
        display: "Pet Friendly",
        name: "Pets",
        value: "pets",
        active: false
    },
    {
        display: "Doorman",
        name: "Doorman",
        value: "doorman",
        active: false
    },
    {
        display: "In-suite Laundry",
        name: "InSuiteLaundry",
        value: "in-suite-laundry",
        active: false
    }
]

export const noResultsMessage: string = `We are sorry, there are no available units in for [fromDate] - [endDate]. Please adjust parameters and redo your search. Or call us at <a href="tel:+12818975100">281-897-5100</a>.`;

export const GlobalBookingsSettingsModel = {
    minimumStartBookDays: 7,
    maximumStartBookMonths: 6,
    minimumEndBookDays: 30,
    maximumEndBookMonths: 15
}

export const SortByData: SearchFilterSort = {
    name: "Sort By",
    slug: "sort-by",
    value: "price-low-high",
    options: [
        {
            name: "Sort By Price: Low-High",
            value: "price-low-high",
            valueField: "calcLowestPrice",
            ascOrDesc: "asc"
        }, {
            name: "Sort By Price: High-Low",
            value: "price-high-low",
            valueField: "calcLowestPrice",
            ascOrDesc: "desc"
        }, {
            name: "Sort By # of Units: High-Low",
            value: "units-high-low",
            valueField: "calcAvailableUnits",
            ascOrDesc: "desc"
        }, {
            name: "Sort By # of Units: Low-High",
            value: "units-low-high",
            valueField: "calcAvailableUnits",
            ascOrDesc: "asc"
        }
    ]
}

// export const SearchResultData: SearchPropertyResultModel = {
//     "coordinates": {
//         "latitude": 34.0194543, "longitude": -118.4911912,
//         "horizontalAccuracy": "NaN", "verticalAccuracy": "NaN", "speed": "NaN", "course": "NaN", "isUnknown": false, "altitude": "NaN"
//     }, "results": [
//         {
//             "id": "17233", "guid": "84974348-a60d-43f1-9cb4-c4b9ae8db52f", "name": "GRG Test 1", "description": "\u003cp\u003eBasic property description...\u003c/p\u003e",
//             "address": { "address1": "100 West Channel Road", "address2": "", "city": "Santa Monica", "state": "California", "stateAbbreviation": null, "zip": "90402", "country": null, "latitude": 34.0282368, "longitude": -118.5190919 },
//             "propertyType": null, "availableUnits": 14, "unitAmenities": [], "propertyAmenities": ["Business Center", "Elevator", "Pool", "Smoke Free", "Pet Friendly", "Doorman", "In-suite Laundry"],
//             "pricePerNight": 50.16, "pricedFrom": "50.16", "calcLowestPrice": 50.16, "calcAvailableUnits": 14, "o_calcLowestPrice": 50.16, "o_calcAvailableUnits": 14,
//             "images": [{
//                 "id": 216225, "propertyGuid": "84974348-a60d-43f1-9cb4-c4b9ae8db52f", "etag": "5aea39b8-2aff-43bd-a7a6-279d10d30697",
//                 "url": "https://hmtesting.blob.core.windows.net/propertyphotos/84974348-a60d-43f1-9cb4-c4b9ae8db52f/2020-10-12:13:34:34_newport_mansion2.jpg", "description": null, "orderIndex": 1, "uploadedBy": "GRG", "uploadedOn": "\/Date(1602509674150)\/", "propertyGu": null
//             },
//             { "id": 216226, "propertyGuid": "84974348-a60d-43f1-9cb4-c4b9ae8db52f", "etag": "a64ee98a-eb19-40e9-b10c-a7ed56d5706b", "url": "https://hmtesting.blob.core.windows.net/propertyphotos/84974348-a60d-43f1-9cb4-c4b9ae8db52f/2020-10-12:13:34:42_newport_mansion1.jpg", "description": null, "orderIndex": 2, "uploadedBy": "GRG", "uploadedOn": "\/Date(1602509682603)\/", "propertyGu": null }],
//             "floorPlans": [{ "bedrooms": 1, "bathrooms": 1, "unitsAvailable": 3, "price": 50.16 }, { "bedrooms": 2, "bathrooms": 2, "unitsAvailable": 11, "price": 64.62 }], "map": null, "propertyBuildingTypeId": 2, "squareFootage": [], "startDate": "08-24-2021", "endDate": "09-29-2021", "url": "/properties/grg-test-1/84974348-a60d-43f1-9cb4-c4b9ae8db52f/?start=08-24-2021\u0026end=09-29-2021"
//         },
//         {
//             "id": "17281", "guid": "e004a909-1f4a-4d6c-b08e-139d96e6fc81", "name": "GRG Test 1 - Ashton", "description": null,
//             "address": { "address1": "100 West Channel Road", "address2": "", "city": "Santa Monica", "state": "California", "stateAbbreviation": null, "zip": "90402", "country": null, "latitude": 34.0282368, "longitude": -118.5190919 }, "propertyType": "Apartment", "availableUnits": 14, "unitAmenities": [], "propertyAmenities": ["Business Center", "Elevator", "Fitness Center", "Pool", "Smoke Free", "Pet Friendly", "In-suite Laundry"],
//             "pricePerNight": 53, "pricedFrom": "53.00", "calcLowestPrice": 53, "calcAvailableUnits": 14, "o_calcLowestPrice": 53, "o_calcAvailableUnits": 14, "images": [{ "id": 216197, "propertyGuid": "e004a909-1f4a-4d6c-b08e-139d96e6fc81", "etag": "0585017c-168f-44a0-9154-6dc00d31f248", "url": "https://gorovergotest1storage.blob.core.windows.net/propertyphotos/e004a909-1f4a-4d6c-b08e-139d96e6fc81/2020-06-23:18:52:52_Lakeside Row GRG Test.jpg", "description": null, "orderIndex": 1, "uploadedBy": "GRG", "uploadedOn": "\/Date(1592938372231)\/", "propertyGu": null }], "floorPlans": [{ "bedrooms": 1, "bathrooms": 1, "unitsAvailable": 3, "price": 53 }, { "bedrooms": 2, "bathrooms": 2, "unitsAvailable": 11, "price": 74.98 }], "map": null, "propertyBuildingTypeId": 2, "squareFootage": [], "startDate": "08-24-2021", "endDate": "09-29-2021", "url": "/properties/grg-test-1---ashton/e004a909-1f4a-4d6c-b08e-139d96e6fc81/?start=08-24-2021\u0026end=09-29-2021"
//         },
//         {
//             "id": "17235", "guid": "21cf17d3-cd1e-4f56-aa63-a0fdabc89920", "name": "GoRoverGo 3 Test Property - TN", "description": null,
//             "address": { "address1": "250 North Channel Road", "address2": "", "city": "Santa Monica", "state": "California", "stateAbbreviation": null, "zip": "90402", "country": null, "latitude": 34.0300107, "longitude": -118.5162891 }, "propertyType": null, "availableUnits": 19, "unitAmenities": [], "propertyAmenities": ["Pool", "Smoke Free"], "pricePerNight": 70.09, "pricedFrom": "70.09", "calcLowestPrice": 70.09, "calcAvailableUnits": 19, "o_calcLowestPrice": 70.09, "o_calcAvailableUnits": 19, "images": [{ "id": 216214, "propertyGuid": "21cf17d3-cd1e-4f56-aa63-a0fdabc89920", "etag": "5d1ff68a-c852-4245-bf5e-0bdf41e0cf4e", "url": "https://gorovergotest1storage.blob.core.windows.net/propertyphotos/21cf17d3-cd1e-4f56-aa63-a0fdabc89920/2020-08-28:19:22:58_Capture.JPG", "description": null, "orderIndex": 1, "uploadedBy": "GRG", "uploadedOn": "\/Date(1598642578599)\/", "propertyGu": null }], "floorPlans": [{ "bedrooms": 2, "bathrooms": 2, "unitsAvailable": 15, "price": 92.82 }, { "bedrooms": 1, "bathrooms": 1, "unitsAvailable": 4, "price": 70.09 }], "map": null, "propertyBuildingTypeId": 2, "squareFootage": [], "startDate": "08-24-2021", "endDate": "09-29-2021", "url": "/properties/gorovergo-3-test-property---tn/21cf17d3-cd1e-4f56-aa63-a0fdabc89920/?start=08-24-2021\u0026end=09-29-2021"
//         }],
//     "SEOData": null, "PropertiesInArea": true
// };