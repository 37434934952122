import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { TokenGuard } from './auth/token.guard';
import { UserService } from './services/user.service';
import { CompanyRoleGuard } from './auth/company-role.guard';
import { TagManagerService } from './services/tag-manager.service';
import { OpenGuard } from './auth/open.guard';
import { ImportDataService } from './services/import-data.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from './services/api.service';
import { LookupService } from './services/lookup.service';
import { PermissionsService } from './services/permissions.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  progressMode = 'determinate';
  progressValue = 0;
  messageArray = ['Loading GRG Portal',
    'Loading Bookings'];
  finalMessage = 'Preparing for launch...';
  redirectMessage = 'Redirecting...';
  loadingMessage = 'Loading GRG Portal';
  timer: any;
  isUserAuthenticated = false;

  constructor(private router: Router,
    private logger: NGXLogger,
    public tokenGuard: TokenGuard,
    private userService: UserService,
    public companyRoleGuard: CompanyRoleGuard,
    public openGuard: OpenGuard,
    private importDataService: ImportDataService,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionsService,
    private apiService: ApiService,
    private tagManagerService: TagManagerService, private lookupService: LookupService) {
    // keep tag manager service here, even though its not being used..this allows the singleton to be created earlier in the pipeline

    router.events.subscribe((val) => {

      // Some events we can hook into...:
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized

      if (val instanceof NavigationStart) {
        // this.logger.debug('router..', [val]);
      }
      if (val instanceof NavigationEnd) {
        // this.logger.debug('router end..', [val]);
      }
    });
  }

  ngOnInit() {
    const token = this.userService.getTokenStorage();
    const refreshToken = this.userService.getRefreshTokenStorage();
    const user = this.userService.getUserStorage();
    if (token) {
      if (new JwtHelperService().isTokenExpired(token) && refreshToken !== undefined) {
        this.apiService.getRefreshToken(refreshToken).subscribe((response) => {
          if (response) {
            this.userService.setRefreshTokenStorage(response.refreshTokenGuid)
            this.userService.setTokenStorage(response.token);
          } else {
            this.importDataService.logout(true);
          }
        });
      }
      this.importDataService.init(token, refreshToken, null, null, null, null);
      if (user) {
        this.userService.clientContact = user;
        this.userService.isAuthenticated = true;
        this.userService.clientContactBehaviorSub.next(user);

      } else {
        const jwt = new JwtHelperService().decodeToken(token);
        this.apiService.getUserAccount(jwt.sub).subscribe((response => {
          this.userService.clientContact = response;
          this.userService.isAuthenticated = true;
          this.userService.clientContactBehaviorSub.next(response);
        }));
      }
      if(!this.lookupService.data){
        this.lookupService.load().subscribe(() => {
          this.permissionService.refreshPermissions(this.userService.clientContact, this.lookupService);
        })
      }
    }

    // kick off the progress messages after a short delay
    const this$ = this;
    // this$.loadProgressMessage();

    // wait for the user token to be retrieved before dismissing the loading screen
    this.userService.clientContactBehaviorSub.subscribe(
      userInfo => {
        if (userInfo != null) {
          this.prepareToCloseLoadingScreen(false);
        }
      });

    // user is about to be redirected...whether we couldn't find their account
    // or they had an invalid token, etc etc so lets update the splash for them
    this.userService.urlBehaviorSub.subscribe(
      behavior => {
        if (behavior === 'redirect' && !this.userService.isAuthenticated) {
          this.prepareToCloseLoadingScreen(true);
        }
      });

    // enable logout endpoint to be hit
    if (location.href.includes('/logout')) {
      console.warn('logout hit from app component');
      this.prepareToCloseLoadingScreen(false);
    }
  }

  prepareToCloseLoadingScreen(isRedirect: boolean) {
    clearTimeout(this.timer);
    this.progressValue = 100;
    this.loadingMessage = isRedirect ? this.redirectMessage : this.finalMessage;

    if (!isRedirect) {
      const this$ = this;
      setTimeout(function () {
        this$.isUserAuthenticated = true;
      }, 50);
    }
  }

  loadProgressMessage(): void {

    if (this.progressValue > 100) {
      this.loadingMessage = this.finalMessage;
      clearTimeout(this.timer);
      return;
    }

    this.progressValue += Math.floor((Math.random() * 25) + 1);
    const this$ = this;
    const arrMessages = this$.messageArray.splice(0, 1);

    if (arrMessages && arrMessages.length) {
      this$.loadingMessage = arrMessages[0];
    }

    this.timer = setTimeout(function () {
      this$.loadProgressMessage();
    }, (this$.progressValue * 10) + 100);
  }


}
