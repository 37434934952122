import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { VerificationQuestion } from 'app/models/verification-question';
import { NGXLogger } from 'ngx-logger';
import { ApiService } from 'app/services/api.service';
import { IdentityVerificationService } from 'app/services/identity-verification.service';
import { IdentityExamResponsesModel } from 'app/models/IdentityExamResponsesModel';
import { IdentityExamResponse } from 'app/models/IdentityExamResponse';
import { takeUntil } from 'rxjs/operators';
import { ScreenService } from 'app/services/screen.service';
import { SubmitVerificationCodeModel } from 'app/models/SubmitVerificationCodeModel';
import { CreditStatus } from 'app/models/CreditStatusEnum';
import { BaseComponent } from '../base/base.component';
import { UserService } from 'app/services/user.service';
import { GRGSnackBarService } from '../grg-snack-bar/grg-snak-bar.service';
import { Router } from '@angular/router';
import { ROUTE_PORTAL_HOME } from '../Utils';
import { ClientContactModel } from 'app/models/ClientContactModel';

@Component({
  selector: 'app-identity-verification',
  templateUrl: './identity-verification.component.html',
  styleUrls: ['./identity-verification.component.scss']
})
export class IdentityVerificationComponent extends BaseComponent implements OnInit {

  @Input() clientContactGuid: string;
  @Input() clientContact: ClientContactModel;
  @Input() removeSidebar: boolean;
  @Input() showSuccessStatus: boolean = false; // by default most places won't show the identity success but the identity check landing page will
  @Output()
  NavContinue = new EventEmitter();
  @Output()
  navigateBackToDetails = new EventEmitter();

  submittedVerification = false;
  passedVerification = false;
  isQuestions = false;
  isOneTimePass = false;
  data: VerificationQuestion[] = [];
  isOneTimePassAllowed = false;
  disableSubmit = false;
  hasCodeBeenResent = false;
  isSendingAdditionalCode = false;
  verificationForm: FormGroup;
  phoneNumber: string;

  // button text
  questionButtonText = '';
  callButtonText = '';
  smsButtonText = '';
  loadingHtml = 'Loading... <span class="spinner-border spinner-border-sm" aria-hidden="true" role="status"></span>';

  verifyOptions = [];
  selectedOption: string;
  passCodeBtnInactive = true;
  isVerifying = false;

  constructor(private fb: FormBuilder,
    public logger: NGXLogger,
    private identityVerificationService: IdentityVerificationService,
    private apiService: ApiService,
    private screenService: ScreenService,
    private userService: UserService,
    private notificationService: GRGSnackBarService,
    private router: Router) {

    super(logger);
    this.verificationForm = new FormGroup({
      passcode: new FormControl(''),
      questions: new FormArray(this.data.map(x => new FormGroup({
        'question': new FormControl(x.questionDisplayName),
        'answer': new FormControl(''),
        'key': new FormControl(x.questionKeyName)
      })))
    });

    this.identityVerificationService.identityStatusChanged.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(response => {

      // if this subscription hits then that means they submitted the form since
      // this component doesn't display unless they need to verify their identity
      if (this.identityVerificationService.submittedVerification) {
        this.isVerifying = false;
        this.passedVerification = this.identityVerificationService.isIdentityVerified();
        if (this.passedVerification) {
          this.showSuccessStatus = true;
          this.submittedVerification = true;
          setTimeout(() => { this.NavContinue.emit() }, 2000);
        } else {
          this.submittedVerification = false;
          this.notificationService.show('Invalid code!', 'error');
        }
      }
    });

    // // if no client contact guid was passed in, then lets default it to the current logged in user
    // if (!this.clientContactGuid) {
    //   this.clientContactGuid = this.userService.clientContact.clientContactGuid;
    // }

    // if (this.userService.clientContact.globalContactDetails && this.userService.clientContact.globalContactDetails.primaryPhoneNumber) {
    //   this.phoneNumber = this.userService.clientContact.globalContactDetails.primaryPhoneNumber;//parsePhoneNumber(this.userService.clientContact.globalContactDetails.primaryPhoneNumber, 'US').formatNational();
    // }

    this.verifyOptions = [
      // { key: "question", value: "Answer Security Questions" },
      { key: "text", value: `Send me a code through text to ${this.phoneNumber}` },
      { key: "call", value: `Give me a call with the code at ${this.phoneNumber}` }
    ]

  }

  ngOnInit() {
    this.verifyMeOptions()
    this.addQuestions();
    this.resetButtonText();
  }

  // since identity is one component with different views we have to figure out what they are allowed to see if they wish to go backwards
  identityNavBackward(): boolean {

    this.disableSubmit = false;
    this.resetButtonText();

    // they are at starting screen so lets not let them go any further back
    if (!this.isOneTimePass && !this.isQuestions) {
      return false;
    } else if (this.isOneTimePassAllowed) {
      this.isOneTimePass = false;
      this.isQuestions = false;
      return true;
    } else {
      return false;
    }
  }

  onIdentityNavigate(resetSubmit: boolean = true): void {
    // reset the submit button for them and scroll them to the top of the screen
    this.screenService.returnScreenToTop();
    this.screenService.returnBookingWizardToTop();
    if (resetSubmit) {
      this.disableSubmit = false;
    }
  }

  resetButtonText() {
    this.questionButtonText = 'Answer Security Questions';
    this.callButtonText = 'Receive a call';
    this.smsButtonText = 'Send text message';
  }

  questions() {
    return this.verificationForm.get('questions') as FormArray;
  }
  verifyMeOptions() {

    this.phoneNumber = this.clientContact.globalContactDetails.primaryPhoneNumber;
    this.verifyOptions = [
      // { key: "question", value: "Answer Security Questions" },
      { key: "text", value: `Send me a code through text to ${this.phoneNumber}` },
      { key: "call", value: `Give me a call with the code at ${this.phoneNumber}` }
    ]
  }
  addQuestions() {

    // if this is not populated, then they already had an identity check loaded via the service
    if (!this.identityVerificationService.initialResponse) {
      if (this.identityVerificationService.identityStatus !== CreditStatus.NOT_SUBMITTED) {
        if (this.identityVerificationService.isIdentityVerified()) {
          this.submittedVerification = true;
          this.passedVerification = true;
        } else if (this.identityVerificationService.isIdentityDenied()) {
          this.submittedVerification = true;
          this.passedVerification = false;
        }

        return;
      }
    }

    // if (this.identityVerificationService.initialResponse.examQuestions
    //   && this.identityVerificationService.initialResponse.examQuestions.length) {
    //   this.data = this.identityVerificationService.initialResponse.examQuestions;
    // }

    this.verificationForm = new FormGroup({
      passcode: new FormControl(''),
      questions: new FormArray(this.data.map(x => new FormGroup({
        'question': new FormControl(x.questionDisplayName),
        'answer': new FormControl(''),
        'key': new FormControl(x.questionKeyName)
      })))
    });

    if (this.identityVerificationService.initialResponse.otpAllowed) {
      this.isOneTimePassAllowed = true;
    } else if (this.data && this.data.length) {
      // if OTP is not allowed, we should have received questions in the initial response
      this.setVerificationApproach('question');
    }

    this.logger.log('verification form', this.verificationForm);
  }

  setVerificationApproach(e: any): void {

    this.disableSubmit = true;

    // We are not doing questions anymore
    // if (e === 'question' || (e.target && e.target.innerText && e.target.innerText.toLowerCase().includes('question'))) {
    //   const tempButtonText = this.questionButtonText;
    //   this.questionButtonText = this.loadingHtml;
    //   if (!this.data.length) {
    //     this.apiService.getIdentityQuestions(this.clientContactGuid).subscribe(res => {
    //       if (res && res.examQuestions) {
    //         this.data = res.examQuestions;

    //         this.verificationForm = new FormGroup({
    //           passcode: new FormControl(''),
    //           questions: new FormArray(this.data.map(x => new FormGroup({
    //             'question': new FormControl(x.questionDisplayName),
    //             'answer': new FormControl(''),
    //             'key': new FormControl(x.questionKeyName)
    //           })))
    //         });

    //         this.configureFormQuestions();
    //       }
    //     },
    //       error => {
    //         this.logger.error(error);
    //         this.notificationService.show('There was an error loading the identity verification questions.  Please try again.', 'error');
    //         this.questionButtonText = tempButtonText;
    //         this.disableSubmit = false;
    //       });
    //   } else {
    //     this.configureFormQuestions();
    //   }
    // } 

    const shouldCallInsteadOfText = e && e.target && e.target.innerText && e.target.innerText.toLowerCase().includes('call');
    const tempCallText = this.callButtonText;
    const tempSmsText = this.smsButtonText;

    if (shouldCallInsteadOfText) {
      this.callButtonText = this.loadingHtml;
    } else {
      this.smsButtonText = this.loadingHtml;
    }

    this.apiService.getIdentityCode(this.clientContactGuid, shouldCallInsteadOfText).subscribe(response => {
      this.verificationForm.controls['passcode'].setValidators([Validators.required]);
      this.verificationForm.controls['passcode'].updateValueAndValidity();
      this.isOneTimePass = true;
      this.onIdentityNavigate();
    }, error => {
      // reset form if error occurs
      this.callButtonText = tempCallText;
      this.smsButtonText = tempSmsText;
      this.disableSubmit = false;
      this.notificationService.show('There was an error sending the verification code.  Please try again.', 'error');

      this.logger.error(error);
    });

    this.logger.log('question controls', this.questions().controls);
  }

  configureFormQuestions(): void {
    for (let i = 0; i < this.questions().length; i++) {
      (<FormGroup>this.questions().at(i)).controls['answer'].setValidators([Validators.required]);
      (<FormGroup>this.questions().at(i)).controls['answer'].updateValueAndValidity();
    }

    this.isQuestions = true;
    this.onIdentityNavigate();
  }

  isInvalid(control: FormControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  isInvalidByName(name: string): boolean {
    const control = <FormControl>this.verificationForm.get(name);
    return this.isInvalid(control);
  }

  touchVerificationForm(): void {
    for (let i = 0; i < this.questions().length; i++) {
      (<FormGroup>this.questions().at(i)).controls['answer'].markAsTouched();
    }
    this.verificationForm.controls['passcode'].markAsTouched();
  }

  mouseOffinput(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    if (value != "") {
      this.passCodeBtnInactive = false;
    }
    else {
      this.passCodeBtnInactive = true;
    }
  }

  submitVerification(): void {
    this.touchVerificationForm();

    if (!this.verificationForm.valid) {
      this.logger.log('n o t   v a l i d');
      return;
    }

    this.disableSubmit = true;
    this.isVerifying = true;
    this.submittedVerification = true;
    if (this.isOneTimePass) {
      this.logger.log('one time pass');

      const apiModel: SubmitVerificationCodeModel = {
        verificationCode: this.verificationForm.get('passcode').value
      };

      this.identityVerificationService.submitVerificationCode(apiModel);
    } else {
      this.logger.log('questions');

      const responses: IdentityExamResponse[] = [];
      for (let i = 0; i < this.questions().length; i++) {
        const currentGroup = (<FormGroup>this.questions().at(i));

        const response: IdentityExamResponse = {
          question: currentGroup.controls['key'].value,
          response: currentGroup.controls['answer'].value
        };

        responses.push(response);
      }

      const apiModel: IdentityExamResponsesModel = {
        responses: responses
      };

      this.identityVerificationService.submitVerificationQuestions(apiModel);
    }

    // if (this.identityVerificationService.isIdentityVerified()) {
    //   this.showSuccessStatus = true;
    //   setTimeout(() => { this.NavContinue.emit() }, 2000);
    // } else {
    //   this.submittedVerification = false;
    //   this.notificationService.show('Invalid code!', 'error');
    // }

    // this.NavContinue.emit();
    //this.onIdentityNavigate(false);
  }

  resendCode(evt): void {
    evt.preventDefault();

    this.isSendingAdditionalCode = true;
    this.apiService.resendVerificationCode(this.clientContactGuid).subscribe(response => {
      this.isSendingAdditionalCode = false;
      this.hasCodeBeenResent = true;
      this.logger.log('code has been successfully resent', evt);
    }, error => {
      this.logger.error(error);
      this.hasCodeBeenResent = false;
      this.notificationService.show('There was an error resending the verification code.  Please try again.', 'error');
    });
  }

  acknowledgeStatus(): void {

    this.router.navigate([ROUTE_PORTAL_HOME]);

  }

  navigateBack() {
    // if IsOneTimePass true hide it and display verification selection page
    if (this.isOneTimePass) {
      this.isOneTimePass = !this.isOneTimePass;
    }
    else {
      this.navigateBackToDetails.emit();
    }

  }

}
