import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PaginationParamsModel, FilterUtility } from 'app/models/PaginationParamsModel';
import { TableConfiguration } from 'app/models/TableConfiguration';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, of, observable, Subscriber } from 'rxjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'grg-table',
  templateUrl: 'grg-table.component.html',
  styleUrls: ['grg-table.component.scss']
})

export class GrgTableComponent implements OnInit {

  @Input() columns: TableConfiguration[]
  @Input() dataSource: MatTableDataSource<any>;
  @Input() templates: TemplateRef<any>[];

  @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public filters: FilterUtility = new FilterUtility();
  public paginationParams: PaginationParamsModel
  public filterChangeObserver: Subscriber<string>;

  public displayedColumns: string[] = [];

  constructor() { }

  ngOnInit() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
  }

  updateSort(event: any): void {
    this.paginationParams.sortby = event.active;
    this.paginationParams.sort = event.direction;
    this.onChange.emit(true);
  }

  applyFilter(searchValue: string, filterName: string) {
    if (!this.filterChangeObserver) {
      new Observable<string>(observable => {
        this.filterChangeObserver = observable;
      })
        .pipe(debounceTime(300)) // wait 300ms after the last event before emitting last event
        .pipe(distinctUntilChanged()) // only emit if value is different from previous value
        .subscribe(searchText => {
          if (!searchText) {
            this.clearFilter(filterName);
          } else {
            this.filters.addTextFilter(filterName, searchText);
          }
          this.paginationParams.pageIndex = 1;
          this.onChange.emit(true);
        })
    }
    this.filterChangeObserver.next(searchValue);
  }

  closeFilter(control: NgbPopover, filterName: string) {
    this.clearFilter(filterName);
    control.close();
    this.onChange.emit(true);
  }

  clearFilter(name: string) {
    this.columns.find(c => c.name == name).filterValue = null;
  }

  destroyFilter() {
    this.filterChangeObserver.unsubscribe();
  }

  updatePage(event: PageEvent): void {
    // If user changes pageSize, reset page to 1.
    if (event.pageSize !== this.paginationParams.pageSize) {
      this.paginationParams.pageIndex = 1;
    } else {
      this.paginationParams.pageIndex = event.pageIndex + 1;
    }

    this.paginationParams.pageSize = event.pageSize;
    this.onChange.emit();
  }
}
