import { Directive, Output, HostListener, EventEmitter, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[drag-drop]'
})
export class DragDropDirective {

  @Output() dropHandler: EventEmitter<any> = new EventEmitter<any>();

  public dragging: boolean;
  public loaded: boolean
  public imageLoaded: boolean;
  public imageSrc: string;
  public invalidFlag: boolean;


  @HostListener('dragover') onDragOver() {
    return false
  }
  @HostListener('dragenter') handleDragEnter() {
    this.dragging = true;
  }
  @HostListener('dragleave') handleDragLeave() {
    this.dragging = false;
  }
  @HostListener('drop', ['$event']) handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  OnInit() {

  }

  handleInputChange(e) {
    if (e.dataTransfer) {
      var files: File[] = e.dataTransfer.files;

      for (let file of files) {

        this.invalidFlag = false;
        var pattern = /image-* || pdf-*/;


        if (!file.type.match(pattern)) {
          this.invalidFlag = true;
          alert('invalid format');
          return this.dropHandler.emit({ event: e, invalidFlag: this.invalidFlag });
        }
      }
      this.dropHandler.emit({ event: e.dataTransfer.files, invalidFlag: this.invalidFlag });
    }


  }
  handleReaderLoaded(e) {
    var reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;
    this.dropHandler.emit({ event: e, invalidFlag: this.invalidFlag });
  }
}

