import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class LoadingService {
    hasRoverFetched = false;

    private roverFetchingSubject = new BehaviorSubject<boolean>(false);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    roverFetching$ = this.roverFetchingSubject.asObservable();
    loading$ = this.loadingSubject.asObservable();

    constructor() { }

    fetch() {
        this.roverFetchingSubject.next(true);
        this.hasRoverFetched = true;
    }
    pet() {
        this.roverFetchingSubject.next(false);
    }

    isLoading() {
        this.loadingSubject.next(true);
    }
    loadingComplete() {
        this.loadingSubject.next(false);
    }
}