import { ngModuleJitUrl } from '@angular/compiler';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateAccountComponent } from './create-account-form/create-account-form.component';
import { SignInComponent } from './sign-in-form/sign-in-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password-form.component';
import { SetPasswordComponent } from './set-password/set-password.component';

const routes: Routes = [
    { path: 'create-account', component: CreateAccountComponent },
    { path: 'sign-in', component: SignInComponent },
    { path: 'sign-in/:redir', component: SignInComponent },
    { path: 'password-reset', component: ForgotPasswordComponent },
    { path: 'set-password', component: SetPasswordComponent },
    { path: '', redirectTo: 'create-account', pathMatch: 'full' }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AccountRoutingModule { }