import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { UserService } from 'app/services/user.service';
import { PaginationParamsModel } from 'app/models/PaginationParamsModel';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { BookingService } from 'app/services/booking.service';
import { NGXLogger } from 'ngx-logger';
import { BaseComponent } from 'app/common/base/base.component';
import { takeUntil } from 'rxjs/operators';
import { PermissionsService } from 'app/services/permissions.service';
import { ROUTE_PORTAL_HOME, ROUTE_PORTAL_RESERVATIONS, ROUTE_PORTAL_APPROVALS, ROUTE_PORTAL_BOOKINGS, ROUTE_PORTAL_COMPANY_SETTINGS, ROUTE_PORTAL_USER_SETTINGS, ROUTE_PORTAL_TICKETS, ROUTE_PORTAL_QUOTES, ROUTE_PORTAL_EXTENSIONS } from 'app/common/Utils';
import { ScreenService } from 'app/services/screen.service';

interface IMenu {
  routerLink: string;
  icon: string;
  text: string;
  menuType: MenuType;
  horizontalRule: boolean;
}

enum MenuType {
  Dashboard,
  Organization,
  Payment,
  Bookings,
  Approve,
  Reservations,
  Support,
  Account,
  Company,
  Inquiries,
  Extensions
}



@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('full', style({
        width: '275px'
      })),
      state('minimized', style({
        width: '57px'
      })),
      transition('full => minimized', animate('200ms ease-in')),
      transition('minimized => full', animate('300ms ease-out'))
    ])
  ]
})
export class NavSidebarComponent extends BaseComponent implements OnInit {
  openBookings = 0;
  openTickets = 0;
  openInquiries = 0;
  openApprovals = 0;
  openExtensions = 0;
  fullBarStatus = 'full';
  showMenuText = true;

  menuData: IMenu[] = [
    { routerLink: ROUTE_PORTAL_HOME, icon: 'far fa-radar', text: 'Dashboard', menuType: MenuType.Dashboard, horizontalRule: true },
    // { routerLink: ROUTE_PORTAL_QUOTES, icon: 'far fa-calendar-edit', text: 'Quotes', menuType: MenuType.Inquiries, horizontalRule: false },
    //{ routerLink: ROUTE_PORTAL_EXTENSIONS, icon: 'far fa-layer-plus', text: 'Extensions', menuType: MenuType.Extensions, horizontalRule: false },
    //{ routerLink: ROUTE_PORTAL_BOOKINGS, icon: 'far fa-calendar-plus', text: 'Open Bookings', menuType: MenuType.Bookings, horizontalRule: false },
    { routerLink: ROUTE_PORTAL_RESERVATIONS, icon: 'far fa-calendar-alt', text: 'Reservations', menuType: MenuType.Reservations, horizontalRule: false },
    { routerLink: ROUTE_PORTAL_TICKETS, icon: 'far fa-receipt', text: 'Support', menuType: MenuType.Support, horizontalRule: true },
    { routerLink: ROUTE_PORTAL_APPROVALS, icon: 'far fa-check-square', text: this.getNavTitleApproveSign(), menuType: MenuType.Approve, horizontalRule: true },
    { routerLink: ROUTE_PORTAL_USER_SETTINGS, icon: 'far fa-user-circle', text: 'Account Profile', menuType: MenuType.Account, horizontalRule: false },
    { routerLink: ROUTE_PORTAL_COMPANY_SETTINGS, icon: 'far fa-suitcase', text: 'My Organization', menuType: MenuType.Company, horizontalRule: false }
  ];

  @Output() sizeChange = new EventEmitter();
  @Output() linkSelected = new EventEmitter<boolean>();

  constructor(private apiService: ApiService,
    private userService: UserService,
    public bookingService: BookingService,
    public logger: NGXLogger,
    private permissions: PermissionsService,
    private screenService: ScreenService) {
    super(logger);
  }

  ngOnInit() {
    this.userService.clientContactBehaviorSub.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(
      userInfo => {
        // User information has been updated.
        if (userInfo != null) {
          this.logger.log('we have user info!', userInfo);
          this.getBookings();
          this.getTickets();
          this.getInquiries();
          this.getApprovals();
          this.getExtensions();
        }
      }
    );

    this.bookingService.totalBookingsCount.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(
      bookingCount => {
        if (bookingCount != null) {
          this.openBookings = bookingCount;
        }
      }
    );

    this.bookingService.totalInquiriesCount.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(
      inquiryCount => {
        if (inquiryCount != null) {
          this.openInquiries = inquiryCount;
        }
      }
    );

    this.bookingService.totalTicketsCount.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(
      ticketCount => {
        if (ticketCount != null) {
          this.openTickets = ticketCount;
        }
      }
    );

    this.bookingService.totalPendingApprovalsCount.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(
      approvalCount => {
        if (approvalCount != null) {
          this.openApprovals = approvalCount;
        }
      }
    );

    this.bookingService.totalPendingApprovalsCount.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(
      approvalCount => {
        if (approvalCount != null) {
          this.openApprovals = approvalCount;
        }
      }
    );

    this.bookingService.totalExtensionsCount.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe(
      extensionCount => {
        if (extensionCount != null) {
          this.openExtensions = extensionCount;
        }
      }
    );
  }

  CanShowMenu(menuItem: IMenu): boolean {
    var canShow: boolean = false;
    //add rules for hiding individual menu items
    switch (menuItem.menuType) {
      case MenuType.Organization: {
        canShow = this.permissions.canSeeOrganizationMenu();
        break;
      }
      case MenuType.Company: {
        canShow = this.permissions.canSeeCompanyMenu();
        break;
      }
      case MenuType.Approve: {
        canShow = this.permissions.canSeeApproveMenu();
        break;
      }
      case MenuType.Bookings: {
        canShow = this.permissions.canSeeOpenBookingsMenu();
        break;
      }
      case MenuType.Inquiries: {
        canShow = this.permissions.canSeeInquiriesMenu();
        break;
      }
      case MenuType.Extensions: {
        canShow = this.permissions.canSeeExtensionsMenu();
        break;
      }
      default: {
        canShow = true;
        break;
      }
    }
    return canShow;
  }

  getInquiries(): void {
    this.bookingService.getTotalInquiries();
  }

  getBookings(): void {
    this.bookingService.getTotalBookings();
  }

  getTickets(): void {
    this.bookingService.getTotalTickets();
  }

  getExtensions(): void {
    this.bookingService.getTotalExtensions();
  }

  getApprovals(): void {
    this.bookingService.getTotalPendingApprovals();
  }

  toggleBarStatus(): void {
    if (this.isMenuOpen()) {
      this.fullBarStatus = 'minimized';
      this.showMenuText = false;
    } else {
      this.fullBarStatus = 'full';
    }

    this.sizeChange.emit(this.fullBarStatus);
  }

  isMenuOpen(): boolean {
    return this.fullBarStatus === 'full';
  }

  onAnimationEnd(event: AnimationEvent): void {
    if (this.isMenuOpen()) {
      this.showMenuText = true;
    }
  }

  handleMenuClick(): void {
    if (!this.screenService.isSidebarOpenedAtThisViewport() && this.isMenuOpen()) {
      this.linkSelected.emit(true);
    } else {
      this.linkSelected.emit(false);
    }
  }

  getNavTitleApproveSign(): string {
    if (this.permissions.canApproveBookings() && !this.permissions.canSignLease()) {
      return 'Approve Requests'
    }
    if (this.permissions.canSignLease() && !this.permissions.canApproveBookings()) {
      return 'Sign Requests'
    }
    else {
      return 'Approve & Sign'
    }
  }

}
