import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'why-background-check-modal',
    templateUrl: 'why-background-check-modal.component.html'
})

export class WhyBackgroundCheckModalComponent {
    msg = `GoRoverGo requires credit and background checks to comply with the policies and procedures of our property management partners. This is because we have a one-month minimum rental term and rent for an average of three months, a substantially longer time period than conventional homesharing services. Due to this long rental period, credit and background checks are required to help protect you and our other guests by ensuring all guests at GoRoverGo properties meet specific criminal and credit history standards.`;

    constructor(private modalService: NgbModal) { }

    close() {
        this.modalService.dismissAll();
    }
}