import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { PaginationParamsModel } from 'app/models/PaginationParamsModel';
import { NGXLogger } from 'ngx-logger';
import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  // these guys will tell us when we are polling for updated results
  totalBookingsCountLoading = new BehaviorSubject<boolean>(false);
  totalTicketsCountLoading = new BehaviorSubject<boolean>(false);
  totalInquiriesCountLoading = new BehaviorSubject<boolean>(false);
  totalExtensionsCountLoading = new BehaviorSubject<boolean>(false);
  totalApprovalsCountLoading = new BehaviorSubject<boolean>(false);

  // these guys will actually provide the new result for us
  totalBookingsCount = new BehaviorSubject<number>(null);
  totalMyBookingsCount = new BehaviorSubject<number>(null);
  totalInquiriesCount = new BehaviorSubject<number>(null);
  totalTicketsCount = new BehaviorSubject<number>(null);
  totalExtensionsCount = new BehaviorSubject<number>(null);
  totalSignedApprovalsCount = new BehaviorSubject<number>(null);
  totalPendingApprovalsCount = new BehaviorSubject<number>(null);

  constructor(private apiService: ApiService,
    private logger: NGXLogger,
    private permissionsService: PermissionsService) { }

  getTotalBookings() {

    // if (this.permissionsService.canSeeOpenBookingsMenu()) {
    //   this.totalBookingsCountLoading.next(true);
    //   this.apiService.getOpenBookings(this.getDefaultParamModel()).subscribe(
    //     response => {
    //       this.totalBookingsCount.next(response.total);
    //       this.totalBookingsCountLoading.next(false);
    //     },
    //     error => {
    //       this.logger.error(error);
    //       this.totalBookingsCountLoading.next(false);
    //     }
    //   );
    // }

    // since we are now showing tenants on the my tab, its probably more appropriate to get the total for the mine tab since that is shown by default if you click on 
    // open bookings anyways
    this.getMyTotalBookings();
  }

  getMyTotalBookings() {
    if (this.permissionsService.canSeeOpenBookingsMenu()) {
      this.totalBookingsCountLoading.next(true);
      this.apiService.getMyOpenBookings(this.getDefaultParamModel()).subscribe(
        response => {
          // if they are an admin and the 'mine' tab is empty, then we will check the 'all' tab count for them to try and provide a useful count
          if (response.total === 0 && this.permissionsService.canSeeBookingsButtons()) {
            this.apiService.getOpenBookings(this.getDefaultParamModel()).subscribe(res => {
              this.totalBookingsCount.next(res.total);
              this.totalBookingsCountLoading.next(false);
            }, err => {
              this.logger.error(err);
              this.totalBookingsCountLoading.next(false);    
            });
          } else {
            this.totalBookingsCount.next(response.total);
            this.totalBookingsCountLoading.next(false);
          }
        },
        error => {
          this.logger.error(error);
          this.totalBookingsCountLoading.next(false);
        }
      );
    }
  }

  getTotalSignedApprovals() {
    this.apiService.getSignedApprovals(this.getDefaultParamModel()).subscribe(
      response => {
        this.totalSignedApprovalsCount.next(response.total);
      },
      error => {
        this.logger.error(error);
      }
    );
  }

  getTotalPendingApprovals() {
    if (this.permissionsService.canSeeApproveMenu()) {
      this.totalApprovalsCountLoading.next(true);
      this.apiService.getPendingAndApprovedBookings(this.getDefaultParamModel()).subscribe(
        response => {
          this.totalPendingApprovalsCount.next(response.total);
          this.totalApprovalsCountLoading.next(false);
        },
        error => {
          this.logger.error(error);
          this.totalApprovalsCountLoading.next(false);
        }
      );
    }
  }

  getTotalInquiries() {
    // if we can't see the inquiries menu, then lets not ask for that count in the sidebar
    if (this.permissionsService.canSeeInquiriesMenu()) {
      this.totalInquiriesCountLoading.next(true);
      this.apiService.getInquiriesAndQuotes(this.getDefaultParamModel()).subscribe(
        response => {
          this.totalInquiriesCount.next(response.total);
          this.totalInquiriesCountLoading.next(false);
        },
        error => {
          this.logger.error(error);
          this.totalInquiriesCountLoading.next(false);
        }
      );
    }
  }

  getTotalExtensions() {
    // if we can't see the inquiries menu, then lets not ask for that count in the sidebar
    if (this.permissionsService.canSeeExtensionsMenu()) {
      this.totalExtensionsCountLoading.next(true);
      this.apiService.getExtensions(this.getDefaultParamModel()).subscribe(
        response => {
          this.totalExtensionsCount.next(response.total);
          this.totalExtensionsCountLoading.next(false);
        },
        error => {
          this.logger.error(error);
          this.totalExtensionsCountLoading.next(false);
        }
      );
    }
  }

  getTotalTickets() {
    this.totalTicketsCountLoading.next(true);
    this.apiService.getTickets(this.getDefaultParamModel(), 'CURRENT').subscribe(
      response => {
        this.totalTicketsCount.next(response.total);
        this.totalTicketsCountLoading.next(false);
      },
      error => {
        this.logger.error(error);
        this.totalTicketsCountLoading.next(false);
      }
    );
  }

  private getDefaultParamModel(sortBy: string = 'date') {
    const params: PaginationParamsModel = {
      pageIndex: 1,
      pageSize: 1,
      sort: 'asc',
      sortby: sortBy
    };

    return params;
  }
}

export enum ApprovalStatus {
  Pending = 30,
  Approved = 31
}
