import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { UrlService } from 'app/services/url.service';
import { ImportDataService } from 'app/services/import-data.service';
import { catchError, debounceTime, map, windowTime } from 'rxjs/operators';
import { UserService } from 'app/services/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from 'app/services/api.service';
import { ClientContactModel } from 'app/models/ClientContactModel';
import { LookupService } from '../services/lookup.service';

@Injectable({
  providedIn: 'root'
})
export class TokenGuard implements CanActivate {

  hasRun = false;
  private loader = new Subject<boolean>();
  public loader$ = this.loader.asObservable();

  constructor(private router: Router,
    private logger: NGXLogger,
    private urlService: UrlService,
    private lookupService: LookupService,
    private importedDataService: ImportDataService, private userService: UserService, private apiService: ApiService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const token = this.userService.getTokenStorage();

    if (this.userService.isAuthenticated && this.userService.clientContact && this.importedDataService.importedData) {
      if (token) {
        this.userService.userImportedData = new JwtHelperService().decodeToken(token);
      }
      if (this.lookupService.data) {
        return true;
      }
      return this.lookupService.load();
    } else {
      if (token) {
        const refresToken = this.userService.getRefreshTokenStorage();
        const user = this.userService.getUserStorage();
        this.importedDataService.init(token, refresToken, null, null, null, null);
        if (user) {
          this.userService.clientContact = user;
          return this.lookupService.load();;
        } else {
          const jwt = new JwtHelperService().decodeToken(token);
          return this.apiService.getUserAccount(jwt.sub).pipe(
            map(response => {
              // this.userService.setUserStorage(response);
              return true;
            }),
            catchError(error => of(false)) // Auth failed : return false to prevent route access
          )
        }
      } else {
        return false;
      }
    }

    // if (this.hasRun) {
    //   this.loader.next(false);
    //   return true;
    // } else {
    //   this.loader.next(true);
    // }

    // const url = state.url;

    // //const token = window.localStorage.getItem('REFRESH_GUID');
    // const token = this.urlService.getUrlParameterFromUrl('token', url);
    // this.logger.log('Token guard running...loading app and inspecting token', token);

    // // if a token was passed in, we should clear it from the URL
    // this.hasRun = true;
    // if (token) {
    //   this.logger.log('we have a token');
    //   this.importedDataService.initFromGuard(token);
    //   this.logger.log('init from guard and parse url', location.pathname);
    //   return this.router.parseUrl(location.pathname);
    // } else {
    //   this.logger.log('no token');
    //   this.importedDataService.initFromGuard();
    //   this.loader.next(false);
    //   return true;
    // }

  }
}
