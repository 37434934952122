import { StatusCheckModel } from "./../models/StatusCheckModel";
import { CreditStatus } from "./../models/CreditStatusEnum";
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormGroup, FormControl, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { BookingWizardModel } from 'app/models/BookingWizardModel';
import { HashTable } from 'app/models/HashTable';
import { CostCalculatorService } from 'app/services/cost-calculator.service';
import { DatePipe } from '@angular/common';
import { ApiService } from 'app/services/api.service';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { UserService } from 'app/services/user.service';
import { PortalPathModel } from 'app/models/PortalPathModel';
import { ClientContactModel } from 'app/models/ClientContactModel';
import { BookingVM } from "app/models/NewBookingWizardModels";

export const usStateArray = [
  'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
  'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
  'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
  'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
];
export const ORG_ID_ECH = 1;
export const ORG_ID_GRG = 2;
// owner ID
export const OWNERID_GRG = 98;

export const ROOM_OPTION_TYPE_BEDS = 'Beds';
export const ROOM_OPTION_TYPE_ELECTRONICS = 'Electronics';
export const ROOM_OPTION_TYPE_FURNITURE = 'Furniture';
export const ROOM_OPTION_TYPE_HOUSEWARES = 'Housewares';
export const ROOM_OPTION_TYPE_APPLIANCE = 'Appliance';

// updated sections for customization in BW
export const SECTION_ROOM_TYPE = 'Bedroom';
export const SECTION_LIVING_AREA = 'Living';

// quote statuses
export const QUOTE_PENDING = 'Pending';
export const QUOTE_REJECTED = 'Rejected';
export const QUOTE_ACCEPTED = 'Accepted';
export const QUOTE_EXPIRED = 'Expired';

// inquiry statuses
export const INQUIRY_NEW = 'New';
export const INQUIRY_PENDING = 'Pending';
export const INQUIRY_APPROVED = 'Approved';
export const INQUIRY_DEAD = 'Dead';
export const INQUIRY_COMPLETE = 'Completed';
export const INQUIRY_DEAD_ID = 23;
export const INQUIRY_REASON_UNIT_NOT_AVAILABLE = 25;

// bookingwizard page names
export const BW_START_PAGE = 'Start';
export const BW_PAGE_BASIC_INFO = 'Personal Details';
export const BW_PAGE_CREDIT_CHECK = 'Credit Check';
export const BW_PAGE_TENANTS = 'Tenants';
export const BW_PAGE_CUSTOMIZE = 'Customize';
export const BW_PAGE_PETS = 'Pets';
export const BW_PAGE_CONFIRM_DETAILS = 'Confirm Details';
export const BW_PAGE_PAYMENT = 'Payment';
export const BW_PAGE_REVIEW_LEASE = 'Review Lease'
export const BW_PAGE_DOCUSIGN = 'Docusign';
export const BW_PAGE_FINAL_CONFIRMATION = 'Final Confirmation';
export const BW_BOOKING_DETAILS_PAGE = 'Booking Details';
export const BW_ADDITIONAL_OCCUPANTS = 'Additional Occupans';

// docusign statuses
export const DOCUSIGN_STATUS_CANCEL = 'cancel'; // the recipient decides to finish later
export const DOCUSIGN_STATUS_DECLINE = 'decline'; // the recipient declines signing
export const DOCUSIGN_STATUS_EXCEPTION = 'exception'; // processing error occurs during the signing session
export const DOCUSIGN_STATUS_FAX_PENDING = 'fax_pending'; // probably wont' use this - used when printing and signing on paper
export const DOCUSIGN_STATUS_ID_CHECK_FAILED = 'id_check_failed'; // probably won't use this - used when auth is added to a document
export const DOCUSIGN_STATUS_SESSION_TIMEOUT = 'session_timeout'; // if the signing session times out when the recipient goes idle
export const DOCUSIGN_STATUS_SIGNING_COMPLETE = 'signing_complete'; // the recipient has completed signing
export const DOCUSIGN_STATUS_TTL_EXPIRED = 'ttl_expired'; // the token was not used within the timeout period or the token was already accessed
export const DOCUSIGN_STATUS_VIEWING_COMPLETE = 'viewing_complete'; // a recipient that does not need to sign completes the viewing ceremony

// misc
export const MONTHS_NUMBER = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
export const MONTHS_NAME = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

// status checks
export const STATUS_CHECK_BACKGROUND = 'Background';
export const STATUS_CHECK_IDENTITY = 'Identity';
export const STATUS_CHECK_CREDIT = 'Credit';
export const STATUS_CHECK_APPROVED = 'Approved';
export const STATUS_CHECK_DENIED = 'Denied';
export const STATUS_CHECK_INREVIEW = 'In Review';
export const STATUS_CHECK_MANUALREVIEW = 'Manual Review';
export const STATUS_CHECK_SUBMITTED = 'Submitted';
export const STATUS_CHECK_EXPIRED = 'Expired';

export const PAYMENT_DEBIT_CREDIT = 'debit-credit';
export const PAYMENT_COMPANY_CARD = 'company-card';
export const PAYMENT_COMPANY_BY_INVOICE = 'pay-by-invoice';

export const CARD_IMG_VISA = 'assets/images/visa.png';
export const CARD_IMG_MASTER_CARD = 'assets/images/mastercard.png';
export const CARD_IMG_DISCOVER = 'assets/images/discover.png';
export const CARD_IMG_AMEX = 'assets/images/amex.png'

export const CARD_VISA = 'Visa';
export const CARD_MASTER_CARD = 'Mastercard';
export const CARD_DISCOVER = 'Discover';
export const CARD_AMEX = 'American Express';

export const INVALID_TERMS = "Terms are invalid";
export const SCREENING_FAILED = "Failed Screening";
export const SCREENING_PAYMENT_ERROR = "Communication Error, try again later";
export const PAYMENT_DECLINED = "Card declined";
export const PAYMENT_ERROR = "Error processing Card";
export const ON_HOLD = "Held for manual review";
export const NEED_MANUAL_REVIEW = "Booking has been held for manual review";
export const UNIT_UNAVAILABLE = "Unit no longer available";
export const COMPANY_CREDIT_CHECK_INVALID = "Company credit check invalid";
export const COMPANY_CREDIT_CHECK_INVALID_MESSAGE = "Your company account is currently not enabled. Please contact GoRoverGo at accounting@echgrg.com.";

// infamous routes
export const ROUTE_PORTAL_HOME = '/portal/home';
export const ROUTE_PORTAL_APPROVALS = '/portal/approvals';
export const ROUTE_PORTAL_RESERVATIONS = '/portal/reservations';
export const ROUTE_PORTAL_BOOKING_DETAILS = '/portal/booking';
export const ROUTE_PORTAL_BOOKINGS = '/portal/bookings';
export const ROUTE_PORTAL_COMPANY_SETTINGS = '/portal/settings/company';
export const ROUTE_PORTAL_USER_SETTINGS = '/portal/settings/account';
export const ROUTE_PORTAL_ADD_TENANT = 'portal/add';
export const ROUTE_PORTAL_IDENTITY_CHECK = '/portal/identity';
export const ROUTE_PORTAL_TICKETS = '/portal/support';
export const ROUTE_PORTAL_QUOTES = '/portal/inquiries';
export const ROUTE_PORTAL_EXTENSIONS = '/portal/extensions';

// common regex patterns
export const FORM_MASKED_INPUT = '*'; // if the mask is updated, update alphanumeric & ssn regex below
export const REGEX_ALPHABET = /^[a-zA-Z]*$/;
export const REGEX_ALPHANUMERIC = /^[a-zA-Z0-9*]*$/;
export const REGEX_NUMBERS = /^[0-9]*$/;
export const REGEX_NUMERIC = /^\d\d?[,.]\d\d?$/;
export const REGEX_CC_DATE = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/; // MM/YY
export const REGEX_SSN = /(^\d{3}-?\d{2}-?\d{4}$|^[*]{1,5}\d{4}$)/;
export const REGEX_DOUBLE_QUOTES = /["]+/g;
export const REGEX_PHONE_NUMBERS = /^[\d\(\)\.\-\s\+]+$/;
export const REGEX_MULTIPLE_SLASHES = /([^:])([\/]{2,})/g;
export const REGEX_API_ERROR_CODE = /^\[ERR(\d+)\]/;
export const REGEX_URL_DOMAIN = /https?:\/\/(?:www\.)?([a-z0-9\-]+)(?:\.[a-z\.]+[\/]?)/i;
export const REGEX_TRANSUNION_NAME = /^[a-zA-Z.'\- ]*$/; // No # allowed, Only special characters allowed: “.” (period), “’” (single quote) and “-“ (hyphen); any other special characters are not allowed, Spaces are allowed between words
export const REGEX_TRANSUNION_ADDRESS = /^[a-zA-Z0-9.',\-() ]*$/; //TU address line does allow numbers  // Only special characters allowed “ “ (space) “.” (period) “,” (comma) “’” (single quote) “-“ (hyphen) “(“ and “)” (parentheses)
export const REGEX_TRANSUNION_CITY = /^[a-zA-Z.',\-() ]*$/; //TU city does not allows numbers // Only special characters allowed “ “ (space) “.” (period) “,” (comma) “’” (single quote) “-“ (hyphen) “(“ and “)” (parentheses)
export const REGEX_TWILIO = /^\+[1-9]\d{1,14}$/; //+18888997829

// common API errors
export const ERRORS_ONHOLD = '[ERR101]'; // Company is on hold';
export const ERRORS_NOTACTIVE = '[ERR102]'; // company is not active
export const ERRORS_LEASE_LEASELIMIT = '[ERR201]'; // Property has reached its lease limit, no additional leases are permitted';
export const ERRORS_COSTCALC_UNITUNAVAILABLE = '[ERR301]'; // Unit not available for lease';
export const ERRORS_UNIT_OCCUPANCY = '[ERR302]';
export const ERRORS_CONVERTLEASE_LEASEFAILED = '[ERR203]'; // yardi - lease failed
export const ERRORS_CONVERTLEASE_FAILEDVALIDATION = '[ERR506]'; // inquiry failed validation
export const ERRORS_CONVERTLEASE_PAYMENTFAILURE = '[ERR601]'; // ECH / SAGE - Payment Failure
export const ERRORS_CONVERTLEASE_ACCOUNTINGPROFILE = '[ERR602]'; // ECH / SAGE - Accounting profile error
export const ERRORS_CONVERTLEASE_INVOICEERROR = '[ERR603]'; // ECH / SAGE - Invoice Error
export const ERRORS_ACCOUNT_DUPLICATENAME = '[ERR401]';
export const ERRORS_ACCOUNT_INVALIDPASSWORD = '[ERR402]';

// local storage keys
export const KEY_BOOKING_GUID = 'bookingGuid';
export const KEY_DOCUSIGN_GUID = 'docusignguid';
export const KEY_DOCUSIGN_ENVELOPEID = 'docusignEnvelopeId';
export const KEY_RETURN_PORTAL_PATH = 'returnPortalPath';

// set payment method enum
export const SETPAYMENT_BILLING = 1;
export const SETPAYMENT_CREDITCARD = 2;

// pets
export const MAX_PETS_ALLOWED = 4;

// tour stuffs
export const TOUR_BUTTON_NEXT = [
  { classes: 'btn btn-blue-round round', text: 'Next', type: 'next' }
];
export const TOUR_BUTTON_BOTH = [
  { classes: 'btn btn-blue-round-outline round', text: 'Back', type: 'back' },
  { classes: 'btn btn-blue-round round', text: 'Next', type: 'next' }
];
export const TOUR_BUTTON_PREV = [
  { classes: 'btn btn-blue-round-outline round', text: 'Back', type: 'back' }
];
export const TOUR_DEFAULTS = {
  classes: 'grg-tour-step',
  scrollTo: { behavior: 'smooth', block: 'center' },
  highlightClass: 'highlight',
  cancelIcon: {
    enabled: true
  }
};

export const GLOBAL_GRG_SETTINGS = {
  minimumStartBookDays: 7,
  maximumStartBookMonths: 6,
  minimumEndBookDays: 30,
  maximumEndBookMonths: 15
}

export interface TourButton {
  type: 'next' | 'back';
  text?: string;
  class?: string;
}

export default class Utils {

  static isValidState(input: string): boolean { return usStateArray.includes(input.toUpperCase()); }

  static getTourButtons(buttons: TourButton[]) {

    const tourContainer = [];

    buttons.forEach(button => {
      // first figure out which button type it is

      const defaultClass = button.type === 'next' ? TOUR_BUTTON_NEXT[0].classes : TOUR_BUTTON_PREV[0].classes;
      const defaultText = button.type === 'next' ? TOUR_BUTTON_NEXT[0].text : TOUR_BUTTON_PREV[0].text;

      tourContainer.push({
        type: button.type,
        text: button.text && button.text.length ? button.text : defaultText,
        classes: button.class && button.class.length ? button.class : defaultClass,
      });

    });

    return tourContainer;
  }

  static getUserType(model: ClientContactModel): string {
    if (!model) {
      return '';
    }

    if (model.clientCompanyGuid && model.clientCompanyGuid.length) {
      return 'Company';
    } else {
      return 'Individual';
    }
  }

  static getParsedPhoneNumber(phone: string): string {
    if (phone == null || phone.length < 7) {
      return '';
    }

    // *** FORMATTING REMOVED FOR NOW BUT MAY RETURN AT A LATER DATE *** //
    // Attempt to parse with US format first. Switches to international if it can't.
    return phone;//parsePhoneNumber(phone, 'US').formatNational();
  }

  // If a name has more than one space, lastname will be counted as all names after the first space.
  static getLastName(fullname: string): string {
    if (fullname == null || !fullname.includes(' ')) {
      return '';
    }

    const names = fullname.split(' ');

    let lastname = '';

    // Skip first index (firstname) and iterate through the array.
    names.slice(1).forEach(name => {
      lastname += name + ' ';
    });

    return lastname.trim();
  }

  static getFirstName(fullname: string): string {
    if (fullname == null || !fullname.includes(' ')) {
      return '';
    }

    return fullname.split(' ')[0];
  }

  // If a value is null, return an empty string
  static nullToEmptyString(string?: string): any {
    return string == null ? '' : string;
  }

  static getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  static touchFormFields(form: FormGroup | FormArray) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.touchFormFields(control);
      }
    });
  }

  // given a group of status checks and a type, return the corresponding check
  static getStatusCheckOfType(statusChecks: StatusCheckModel[], statusType: string): StatusCheckModel {
    if (!statusChecks) {
      return null;
    }

    let foundCheck: StatusCheckModel = null;
    statusChecks.forEach(check => {
      if (check.type === statusType) {
        foundCheck = check;
      }
    });

    return foundCheck;
  }

  // given a status check, get the status of the check
  static getStatus(statusCheck: StatusCheckModel): CreditStatus {

    let status: CreditStatus = 0;

    if (statusCheck === null || !statusCheck) {
      status = CreditStatus.NOT_SUBMITTED;
    } else {
      // Has credit check
      // Check if check is expired.
      if (new Date(statusCheck.expiresOn) < new Date()) {
        // Show expired check.
        status = CreditStatus.EXPIRED;
      } else {
        // Show status of check.
        switch (statusCheck.status) {
          case STATUS_CHECK_MANUALREVIEW:
          case 'Manual':
          case STATUS_CHECK_INREVIEW:
            status = CreditStatus.IN_REVIEW;
            break;
          case STATUS_CHECK_APPROVED:
            status = CreditStatus.APPROVED;
            break;
          case STATUS_CHECK_DENIED:
            status = CreditStatus.DENIED;
            break;
          default:
            status = CreditStatus.SUBMITTED;
        }
      }
    }
    return status;
  }

  static getCardType(number) {
    var re = new RegExp("^4");
    if (number.match(re) != null)
      return CARD_VISA;

    re = new RegExp("^(34|37)");
    if (number.match(re) != null)
      return CARD_AMEX;

    re = new RegExp("^5[1-5]");
    if (number.match(re) != null)
      return CARD_MASTER_CARD;

    re = new RegExp("^6011");
    if (number.match(re) != null)
      return CARD_DISCOVER;

    return "";
  }

  static isCardLengthCorrect(cardNumber, cardType) {
    var number = cardNumber.replace(/\D/g, '');

    switch (cardType) {
      case CARD_VISA:
        return number.length === 13 || number.length === 16;
      case CARD_AMEX:
        return number.length === 15;
      case CARD_MASTER_CARD:
        return number.length === 16;
      case CARD_DISCOVER:
        return number.length === 16;
      default:
        return false;
    }
  }

  static getMonthsHash() {
    const monthNames: HashTable<string> = {};

    for (let i = 0; i < MONTHS_NUMBER.length; i++) {
      monthNames[MONTHS_NUMBER[i]] = MONTHS_NAME[i];
    }

    return monthNames;
  }

  static formatPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) {
      return '';
    }
    return phoneNumber; //parsePhoneNumber(phoneNumber, 'US').formatNational();
  }

  // given a group of status checks, if one is found that matches the new one, insert it and replace the old one
  static replaceStatusCheckWithNewCheck(checks: StatusCheckModel[], newCheck: StatusCheckModel): void {
    if (!newCheck) {
      return;
    }

    const statusCheckType = newCheck.type;
    const statusArrLength = checks && checks.length ? checks.length : 0;

    if (statusArrLength) {
      for (let i = 0; i < statusArrLength; i++) {
        if (checks[i].type === statusCheckType) {
          checks.splice(i, 1);
          checks.push(newCheck);
          return;
        }
      }
      checks.push(newCheck);
    } else if (checks) {
      checks.push(newCheck);
    } else {
      console.warn('status checks array not present, nothing to push new check into');
      checks = [];
      checks.push(newCheck);
    }
  }

  static updateBookingAgentTenantObject(booking: BookingWizardModel): void {

    if (booking && booking.clientContact && booking.tenants && booking.tenants.length) {
      booking.tenants.forEach(tenant => {
        let isLeesee = false;
        if (tenant.clientContact.clientContactGuid === booking.clientContact.clientContactGuid) {
          if (tenant.clientContact.isLeesee) {
            isLeesee = true;
          }

          // update the tenant client contact object and maintain the isLeesee field
          tenant.clientContact = booking.clientContact;
          tenant.clientContact.isLeesee = isLeesee;
        }
      });
    }
  }

  static doesBookingAllowPets(booking: BookingVM, clientContact?: ClientContactModel): boolean {

    // lets first see if the current property allows pets
    if (!booking || !booking.property || !booking.property.propertyAllowsPets) {
      return false;
    }

    // if this is a company booking, lets check the company settings
    if (booking.company.Guid) {
      return clientContact.clientCompany.settings.petsAllowed;
    }

    // otherwise we can assume they are allowed
    return true;
  }

  static getEmptyInvalidForm(): FormGroup {
    const x = new FormBuilder();
    const form = x.group({
      prop: ['', [Validators.required]],
    });
    return form;
  }

  static getEmptyValidForm(): FormGroup {
    const x = new FormBuilder();
    const form = x.group({
      prop: ['', []],
    });
    return form;
  }

  static getValidControlStringValue(form: FormGroup | FormArray, controlName: string, nestedControlName?: string): any {
    if (nestedControlName != null) {
      return form.get(controlName).get(nestedControlName).valid
        ? form.get(controlName).get(nestedControlName).value
        : '';
    }
    // If valid, return value. Otherwise return an empty string
    return form.get(controlName).valid
      ? form.get(controlName).value
      : '';
  }

  static getValidControlDateValue(form: FormGroup | FormArray, controlName: string): any {
    // If valid, return value. Otherwise return null
    return form.get(controlName).valid
      ? form.get(controlName).value
      : null;
  }

  static getValidControlNumberValue(form: FormGroup | FormArray, controlName: string): any {
    // If valid, return value. Otherwise return 0
    return form.get(controlName).valid && form.get(controlName).value
      ? parseInt(form.get(controlName).value, 10)
      : 0;
  }

  static getValidControlBooleanValue(form: FormGroup | FormArray, controlName: string): any {
    // If valid, return value. Otherwise return false
    return form.get(controlName).valid
      ? form.get(controlName).value
      : false;
  }

  static getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  static isOver18() {
    return (control: AbstractControl): ValidationErrors | null => {
      let isLegal = true;
      let date: Date;

      // 10312020
      if (control.value.includes('/')) {
        if (control.value.length == 10) {
          date = new Date(control.value);
        }
      } else if (control.value.length === 8) {
        date = new Date(control.value.replace(/(..)(..)(....)/, "$1/$2/$3"))
      }

      if (date) {
        const date18YrsAgo = new Date();
        date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
        isLegal = date <= date18YrsAgo;
      }

      return !isLegal ? { isOver18: { value: control.value } } : null;
    }
  }

  static isEqual(fullName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isEqual = fullName.localeCompare(control.value, 'en', { sensitivity: 'base' }) == 0
      return !isEqual ? { equals: { value: control.value } } : null;
    }
  }

  static isValidCreditCard(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = this.isCreditCardValid(control.value);
      return !isValid ? { luhnCheck: { value: control.value } } : null;
    }
  }

  static isCreditCardValid(cardNumber: string): boolean {
    if (!cardNumber) {
      return false;
    }
    const numdigits = cardNumber.length;
    let sum = 0;
    const parity = numdigits % 2;
    for (var i = numdigits - 1; i >= 0; i--) {
      var d = parseInt(cardNumber.charAt(i))
      if (i % 2 == parity) { d *= 2 }
      if (d > 9) { d -= 9 }
      sum += d
    }
    return (sum % 10) === 0;
  }

  static navigateToPropertySearch(booking: BookingWizardModel, costCalculatorService: CostCalculatorService, apiService: ApiService, logger: NGXLogger): void {
    if (!booking || !booking.property || !costCalculatorService || !costCalculatorService.costModel) {
      window.open('/search', `_self`); // if we cant rely on params then just send them to the general search
    }

    const cityName = booking.property.city.trim().replace(/[\s]+/, '-');
    const state = booking.property.state.trim();
    const country = booking.property.country.trim();
    const startDate = new DatePipe('en-US').transform(booking.quote.reservationStart, 'MM-dd-yyyy');
    const endDate = new DatePipe('en-US').transform(booking.quote.reservationEnd, 'MM-dd-yyyy');
    localStorage.removeItem(KEY_BOOKING_GUID);

    const propertySearchUrl = `/search?location=${country}, ${state}, ${cityName}?start=${startDate}&end=${endDate}&bw=true`;
    var cleanUrl = this.removeDoubleSlashes(propertySearchUrl);
    logger.log('Navigating to property search!', cleanUrl);
    window.location.href = cleanUrl;
  }

  static removeDomainFromUrl(url: string, logger?: NGXLogger): string {
    if (!url) {
      return '';
    }

    var relativeUrl = url.replace(REGEX_URL_DOMAIN, '');

    if (logger) {
      logger.debug(`replacing domain from url..${url} -> ${relativeUrl}`);
    }

    return relativeUrl;
  }

  static removeDoubleSlashes(url: string): string {
    const newUrl = url.replace(REGEX_MULTIPLE_SLASHES, '$1/');
    return newUrl;
  }

  static navigateToTermsOfService(apiService: ApiService): void {
    var url = this.removeDoubleSlashes(`/terms-and-conditions`);
    window.open(url, '_blank');
  }


  // mat calendar doesn't support custom headers so we ahve to get a little creative..
  // provide html if you want to use your own html otherwise just a title
  static addTitleToMatCalendar(title: string, html?: string): void {

    setTimeout(function () {
      const item = document.getElementsByClassName('mat-calendar-header')[0];

      if (item) {
        const newHtml = document.createElement('DIV');
        newHtml.innerHTML = html ? html : `<h3 class="mobile-filter-title">${title}</h3>`;
        item.insertBefore(newHtml, item.childNodes[0]);
      }
    }, 1);

  }

  static isClientContactProfileComplete(clientContact: ClientContactModel): boolean {
    return clientContact
      && clientContact
      && clientContact.firstName
      && clientContact.lastName
      && clientContact.globalContactDetails
      && (clientContact.globalContactDetails.billingIsSameAsPrimary  // city
        ? clientContact.globalContactDetails.city && clientContact.globalContactDetails.city.length > 0
        : clientContact.globalContactDetails.billingCity && clientContact.globalContactDetails.billingCity.length > 0)
      && (clientContact.globalContactDetails.billingIsSameAsPrimary  // state
        ? clientContact.globalContactDetails.globalStateProvId && clientContact.globalContactDetails.globalStateProvId > 0
        : clientContact.globalContactDetails.billingGlobalStateProvId && clientContact.globalContactDetails.billingGlobalStateProvId > 0)
      && (clientContact.globalContactDetails.billingIsSameAsPrimary  // country
        ? clientContact.globalContactDetails.globalCountryId && clientContact.globalContactDetails.globalCountryId > 0
        : clientContact.globalContactDetails.billingGlobalCountryId && clientContact.globalContactDetails.billingGlobalCountryId > 0)
      && (clientContact.globalContactDetails.billingIsSameAsPrimary  // postal code
        ? clientContact.globalContactDetails.postalCode && clientContact.globalContactDetails.postalCode.length > 0
        : clientContact.globalContactDetails.billingPostalCode && clientContact.globalContactDetails.billingPostalCode.length > 0)
      && (clientContact.globalContactDetails.billingIsSameAsPrimary  // address line 1
        ? clientContact.globalContactDetails.streetAddressLine1 && clientContact.globalContactDetails.streetAddressLine1.length > 0
        : clientContact.globalContactDetails.billingStreetAddressLine1 && clientContact.globalContactDetails.billingStreetAddressLine1.length > 0);
  }

  static isIndividualsProfileComplete(userService: UserService): boolean {
    return userService
      && this.isClientContactProfileComplete(userService.clientContact);
  }

  static isCompanyProfileComplete(userService: UserService): boolean {
    return userService
      && userService.clientContact
      && userService.clientContact.clientCompany
      && userService.clientContact.clientCompany.globalContactDetails
      && (userService.clientContact.clientCompany.globalContactDetails.billingIsSameAsPrimary // city
        ? userService.clientContact.clientCompany.globalContactDetails.city && userService.clientContact.clientCompany.globalContactDetails.city.length > 0
        : userService.clientContact.clientCompany.globalContactDetails.billingCity && userService.clientContact.clientCompany.globalContactDetails.billingCity.length > 0)
      && (userService.clientContact.clientCompany.globalContactDetails.billingIsSameAsPrimary  // state
        ? userService.clientContact.clientCompany.globalContactDetails.globalStateProvId && userService.clientContact.clientCompany.globalContactDetails.globalStateProvId > 0
        : userService.clientContact.clientCompany.globalContactDetails.billingGlobalStateProvId && userService.clientContact.clientCompany.globalContactDetails.billingGlobalStateProvId > 0)
      && (userService.clientContact.clientCompany.globalContactDetails.billingIsSameAsPrimary  // country
        ? userService.clientContact.clientCompany.globalContactDetails.globalCountryId && userService.clientContact.clientCompany.globalContactDetails.globalCountryId > 0
        : userService.clientContact.clientCompany.globalContactDetails.billingGlobalCountryId && userService.clientContact.clientCompany.globalContactDetails.billingGlobalCountryId > 0)
      && (userService.clientContact.clientCompany.globalContactDetails.billingIsSameAsPrimary  // postal code
        ? userService.clientContact.clientCompany.globalContactDetails.postalCode && userService.clientContact.clientCompany.globalContactDetails.postalCode.length > 0
        : userService.clientContact.clientCompany.globalContactDetails.billingPostalCode && userService.clientContact.clientCompany.globalContactDetails.billingPostalCode.length > 0)
      && (userService.clientContact.clientCompany.globalContactDetails.billingIsSameAsPrimary  // address line 1
        ? userService.clientContact.clientCompany.globalContactDetails.streetAddressLine1 && userService.clientContact.clientCompany.globalContactDetails.streetAddressLine1.length > 0
        : userService.clientContact.clientCompany.globalContactDetails.billingStreetAddressLine1 && userService.clientContact.clientCompany.globalContactDetails.billingStreetAddressLine1.length > 0);
  }

  static getJsDateByNumberOfDaysAgo(days: number) {
    return new Date(Date.now() - (days * 24 * 60 * 60 * 1000));
  }

  static getJsDateByNumberOfMonthsAgo(months: number) {
    return new Date(Date.now() - (months * 31 * 24 * 60 * 60 * 1000));
  }

  static getJsDateByNumberOfYearsAgo(years: number) {
    const d = new Date();
    d.setFullYear(d.getFullYear() - years);
    return d;
  }

  static createPortalPath(path: string, params: object, source: string, router?: Router, url?: string): void {
    const model: PortalPathModel = {
      path: path,
      date: new Date(),
      source: source,
      params: params
    };

    sessionStorage.setItem(KEY_RETURN_PORTAL_PATH, JSON.stringify(model));

    if (url && router) {
      router.navigate([url],
        {
          queryParams: {
            src: 'portal'
          }
        });
    }

  }

  static handlePortalReturnPath(router: Router, logger: NGXLogger): boolean {

    if (!sessionStorage.getItem(KEY_RETURN_PORTAL_PATH)) {
      logger.info('no return path found, falling to next set of logic');
      return false;
    }

    const returnPath = (<PortalPathModel>JSON.parse(sessionStorage.getItem(KEY_RETURN_PORTAL_PATH)));

    if (returnPath) {
      logger.info(`Return via portal path model...source: ${returnPath.source}, set at: ${returnPath.date}`);
      if (returnPath.params) {

        // clear out src on the way back to clean the url up
        returnPath.params.src = null;

        logger.debug(returnPath.params.tab);
        router.navigate([returnPath.path], {
          queryParams: returnPath.params,
          queryParamsHandling: 'merge'
        }).then(val => {
          logger.log('clearing return key');
          sessionStorage.removeItem(KEY_RETURN_PORTAL_PATH);
        });
        return true;
      } else {
        router.navigate([returnPath.path], {
          queryParams: {
            src: null
          },
          queryParamsHandling: 'merge'
        }).then(val => {
          logger.log('clearing return key');
          sessionStorage.removeItem(KEY_RETURN_PORTAL_PATH);
        });
        return true;
      }
    }

    logger.info('no return path found, falling to next set of logic');
    return false;
  }

  //Booking Extensions
  static getDateString(date: Date) {
    let string = "";
    if (date !== null || date !== undefined) {
      const year = date.getFullYear();
      const month = this.padNumber(date.getMonth() + 1);
      const day = this.padNumber(date.getDate())
      string = `${month}/${day}/${year}`;
    }
    return string;
  }
  static padNumber(number: Number): String {
    let ret = new String(number);
    if (ret.length === 1) ret = "0" + ret;
    return ret;
  }

  //Google Maps Helpers
  static createMarker(width, height, title, imgMarker, fontStyle, position): string {
    var canvas, context, radius = 4;
    let dataUrl = "";
    canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    context = canvas.getContext("2d");
    context.clearRect(0, 0, width, height);
    context.drawImage(imgMarker, 0, 0, width, height);
    context.font = fontStyle; //"bold 10pt Arial"
    context.textAlign = "center";
    context.fillStyle = "rgb(255,255,255)";
    context.fillText(title, position[0], position[1]);
    dataUrl = canvas.toDataURL("image/png");
    return dataUrl;
  }

  static zoomToMiles(zoom) {
    //values for 800px x 800px window
    let radiusMileKeys = [
      [20, 0.0837901798261496],
      [19, 0.16776417862393525],
      [18, 0.33552835213810533],
      [17, 0.6710567126564838],
      [16, 1.3421134197638334],
      [15, 2.6842268204119253],
      [14, 5.3684534872497265],
      [13, 10.736905740071133],
      [12, 21.473801606431724],
      [11, 42.94752422441664],
      [10, 85.77344290256586],
      [9, 171.7985926417136],
      [8, 343.4563966079052],
      [7, 685.6814737885826],
      [6, 1366.614350557506],
      [5, 2700.2763695609374],
      [4, 5230.647443560195],
      [3, 9078.639932879161],
      [2, 10919.753416970045],
      [1, 12285.05300779707]
    ];
    for (var i = 0; i < radiusMileKeys.length; i++) {
      //[0,1]
      if (radiusMileKeys[i][0] === zoom) {
        //
        return radiusMileKeys[i][1];
      }
    }
  }

  static calculateDistance(lat1, lon1, lat2, lon2, unit): number {
    let radlat1 = Math.PI * lat1 / 180;
    let radlat2 = Math.PI * lat2 / 180;
    let theta = lon1 - lon2;
    let radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") { dist = dist * 1.609344; }
    if (unit === "N") { dist = dist * 0.8684; }
    return dist;
  }

  static formatShortCurrency(input: number) {
    if (input !== undefined) {
      return "$" + input.toFixed(0);
    } else {
      return "";
    }
  }

  static formatShortDate(date: string) {
    let newDate = date ? date.replace(/-/g, '/') : null;
    return this.formatShortDay(new Date(newDate));
  }

  static formatShortDay(date: Date) {
    return date.toLocaleDateString('en-US', { month: 'short', weekday: 'short', day: '2-digit' })
  }

  static ToshortDate(date: string) {
    //eg. Fri, Oct 31
    return Utils.formatShortDate(date);
  }

  static getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}