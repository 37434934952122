import { CountryStateService } from 'app/services/country-state.service';
import { ContactDetailsModel } from './../../models/ContactDetailsModel';
import { VerificationResponseModel } from 'app/models/verification-response-model';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { VerificationQuestion } from 'app/models/verification-question';
import { NGXLogger } from 'ngx-logger';
import { ApiService } from 'app/services/api.service';
import { IdentityExamResponsesModel } from 'app/models/IdentityExamResponsesModel';
import { IdentityExamResponse } from 'app/models/IdentityExamResponse';
import { ScreenService } from 'app/services/screen.service';
import { SubmitVerificationCodeModel } from 'app/models/SubmitVerificationCodeModel';
import { BaseComponent } from '../base/base.component';
import { GRGSnackBarService } from '../grg-snack-bar/grg-snak-bar.service';
import { REGEX_NUMBERS, REGEX_TRANSUNION_ADDRESS, REGEX_TRANSUNION_CITY, STATUS_CHECK_APPROVED, STATUS_CHECK_DENIED } from '../Utils';
import { ClientContactModel } from 'app/models/ClientContactModel';
import { VerificationStatus } from 'app/models/ContactVerificationStatus';
import { of } from 'rxjs';
import { IdentityVerificationResult } from 'app/models/IdentityVerificationResult';
import { CountryStateModel, StateModel } from 'app/models/CountryStateModel';
import { LookupService } from 'app/services/lookup.service';

@Component({
  selector: 'app-contact-verification',
  templateUrl: './contact-verification.component.html',
  styleUrls: ['./contact-verification.component.scss']
})
export class ContactVerificationComponent extends BaseComponent implements OnInit {

  @Input() key: string;
  @Input() clientContactGuid: string;
  @Input() clientContact: ClientContactModel;
  @Input() showSuccessStatus: boolean = false; // by default most places won't show the identity success but the identity check landing page will
  @Output() verificationPassed = new EventEmitter<boolean>();

  submittedVerification = false;
  passedVerification = false;
  isQuestions = false;
  isOneTimePass = false;
  data: VerificationQuestion[] = [];
  isOneTimePassAllowed = false;
  disableSubmit = false;
  hasCodeBeenResent = false;
  isSendingAdditionalCode = false;
  verificationForm: FormGroup;
  phoneNumber: string;
  displayAddress: boolean = false;
  addressForm: FormGroup;
  countryStates: CountryStateModel;

  // button text
  questionButtonText = '';
  callButtonText = '';
  smsButtonText = '';
  loadingHtml = 'Loading... <span class="spinner-border spinner-border-sm" aria-hidden="true" role="status"></span>';

  verifyOptions = [];
  selectedOption: string;
  isVerifying = false;
  checkingIfVerified = true;

  constructor(private fb: FormBuilder,
    public logger: NGXLogger,
    private apiService: ApiService,
    private screenService: ScreenService,
    private notificationService: GRGSnackBarService,
    private countryStateService: CountryStateService) {

    super(logger);
    this.verificationForm = new FormGroup({
      passcode: new FormControl(''),
      questions: new FormArray(this.data.map(x => new FormGroup({
        'question': new FormControl(x.questionDisplayName),
        'answer': new FormControl(''),
        'key': new FormControl(x.questionKeyName)
      })))
    });

    this.verifyOptions = [
      // { key: "question", value: "Answer Security Questions" },
      { key: "text", value: `Send me a code through text to ${this.phoneNumber}` },
      { key: "call", value: `Give me a call with the code at ${this.phoneNumber}` }
    ]

  }

  ngOnInit() {
    this.verifyMeOptions()
    this.addQuestions();
    this.resetButtonText();
    this.checkIfVerified();
  }

  //makes a verification request to transunion, this is required
  //before we can actually make the request for the codes.
  private checkIfVerified() {
    if (this._hasAddressInfo(this.clientContact.globalContactDetails)) {
      this.apiService.isVerified(this.clientContactGuid)
        .pipe(switchMap((data) => {
          this.checkingIfVerified = false;
          switch (data.identityVerified) {

            case VerificationStatus.Verified:
              this.verificationPassed.emit(true);
              return of(null);


            case VerificationStatus.Failed:
              this.verificationPassed.emit(false);
              return of(null);

            default:
              return this.apiService.createIdentityCheck(this.clientContactGuid)
          }
        })).subscribe(res => {
          if (res instanceof VerificationResponseModel) {

          }
        })
    } else {
      this._initAddressForm();
      this.displayAddress = true;
    }
  }

  private _hasAddressInfo(details: ContactDetailsModel) {
    if (!details)
      return false;

    return details.city && details.globalStateProvId && details.postalCode && details.streetAddressLine1;
  }

  private _initAddressForm() {
    this.apiService.getPortalLookups()
      .subscribe(response => {
        if (response != null) {
          this.countryStateService.initWithData(response.countriesAndStates);
        }
      });

    this.countryStateService.countryStates
      .pipe(takeUntil(this.unsubscribeOnDestroy$))
      .subscribe(
        (response: CountryStateModel) => {
          this.countryStates = response;
        });

    const details = this.clientContact.globalContactDetails;
    this.addressForm = this.fb.group({
      streetAddressLine1: [details.streetAddressLine1 || '', [Validators.required, Validators.pattern(REGEX_TRANSUNION_ADDRESS)]],
      streetAddressLine2: [details.streetAddressLine2 || '', [Validators.pattern(REGEX_TRANSUNION_ADDRESS)]],
      city: [details.city || '', [Validators.required, Validators.pattern(REGEX_TRANSUNION_CITY)]],
      globalStateProvId: [details.billingGlobalStateProvId || '', [Validators.required]],
      postalCode: [details.postalCode || '', [Validators.required, Validators.minLength(5), Validators.maxLength(9), Validators.pattern(REGEX_NUMBERS)]],
      globalCountryId: [details.globalCountryId != null ? this.countryStateService.countriesById[details.globalCountryId] : 'United States', [Validators.required]]
    })
  }

  onIdentityNavigate(resetSubmit: boolean = true): void {
    // reset the submit button for them and scroll them to the top of the screen
    this.screenService.returnScreenToTop();
    // this.screenService.returnBookingWizardToTop();
    if (resetSubmit) {
      this.disableSubmit = false;
    }
  }

  getCountryArray(): string[] {
    // Handle loading case
    if (this.countryStates == null) {
      return null;
    }
    // Use hash keys as country choices
    return Object.keys(this.countryStates.countries);
  }

  countryChanged(field: string) {
    // when the country dropdown is changed, we will select the first state for them by
    // default to keep the country & state selections consistent
    const states = this.getContactStateArray(this.addressForm.get('globalCountryId').value);
    const stateId = states && states.length ? states[0].stateId : null;

    this.addressForm.get('globalStateProvId').patchValue(stateId);
    this.addressForm.get('globalStateProvId').updateValueAndValidity();
  }

  getContactStateArray(country?: string): StateModel[] {
    // Handle loading case
    if (this.countryStates == null) {
      return null;
    }

    // Use selected country as hash key. 'United States' is default.
    // If country was entered, provide the state list for the specific country.
    // Otherwise use the country that has been selected on the form.
    return country ? this.countryStates.countries[country]
      : this.countryStates.countries[this.addressForm.get('globalCountryId').value];
  }

  resetButtonText() {
    this.questionButtonText = 'Answer Security Questions';
    this.callButtonText = 'Receive a call';
    this.smsButtonText = 'Send text message';
  }

  questions() {
    return this.verificationForm.get('questions') as FormArray;
  }

  verifyMeOptions() {

    this.phoneNumber = this.clientContact.globalContactDetails.primaryPhoneNumber || '';
    this.verifyOptions = [
      // { key: "question", value: "Answer Security Questions" },
      { key: "text", value: `Send me a code through text to ${this.phoneNumber}` },
      { key: "call", value: `Give me a call with the code at ${this.phoneNumber}` }
    ]
  }

  addQuestions() {

    this.verificationForm = new FormGroup({
      passcode: new FormControl(''),
      questions: new FormArray(this.data.map(x => new FormGroup({
        // 'question': new FormControl(x.questionDisplayName),
        'answer': new FormControl(''),
        'key': new FormControl(x.questionKeyName)
      })))
    });

    this.logger.log('verification form', this.verificationForm);
  }

  setVerificationApproach(e: any): void {
    this.isVerifying = true;
    this.disableSubmit = true;
    const shouldCallInsteadOfText = e && e.target && e.target.innerText && e.target.innerText.toLowerCase().includes('call');
    const tempCallText = this.callButtonText;
    const tempSmsText = this.smsButtonText;

    if (shouldCallInsteadOfText) {
      this.callButtonText = this.loadingHtml;
    } else {
      this.smsButtonText = this.loadingHtml;
    }
    if (this._hasAddressInfo(this.clientContact.globalContactDetails)) {
      this._getCode(tempCallText, tempSmsText, shouldCallInsteadOfText);
    } else {
      const countryName = this.addressForm.get('globalCountryId').value;
      const country = this.countryStates.countries[countryName][0];

      const details: ContactDetailsModel = {
        streetAddressLine1: this.addressForm.get('streetAddressLine1').value,
        city: this.addressForm.get('city').value,
        globalStateProvId: Number(this.addressForm.get('globalStateProvId').value),
        postalCode: this.addressForm.get('postalCode').value,
        globalCountryId: country.countryId
      }
      console.log('details', details)
      this.apiService.updateOccupantAddress(this.key, details)
        .subscribe(res => {
          this.displayAddress = false;
          this.apiService.createIdentityCheck(this.clientContactGuid).subscribe(rest => {
            this._getCode(tempCallText, tempSmsText, shouldCallInsteadOfText);
          },error => {
            this.isVerifying = false;
          })
        })
    }

    //this.logger.log('question controls', this.questions().controls);
  }

  private _getCode(tempCallText: string, tempSmsText: string, shouldCallInsteadOfText: boolean) {
    this.apiService.getIdentityCode(this.clientContactGuid, shouldCallInsteadOfText)
      .subscribe(response => {
        this.verificationForm.controls['passcode'].setValidators([Validators.required]);
        this.verificationForm.controls['passcode'].updateValueAndValidity();
        this.isOneTimePass = true;
        this.disableSubmit = false;
        this.isVerifying = false;
        this.onIdentityNavigate();
      }, error => {
        // reset form if error occurs
        this.callButtonText = tempCallText;
        this.smsButtonText = tempSmsText;
        this.disableSubmit = false;
        this.notificationService.show('There was an error sending the verification code.  Please try again.', 'error');
        this.isVerifying = false;
        this.logger.error(error);
      });
  }

  configureFormQuestions(): void {
    for (let i = 0; i < this.questions().length; i++) {
      (<FormGroup>this.questions().at(i)).controls['answer'].setValidators([Validators.required]);
      (<FormGroup>this.questions().at(i)).controls['answer'].updateValueAndValidity();
    }

    this.isQuestions = true;
    this.onIdentityNavigate();
  }

  isInvalid(control: FormControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  isInvalidByName(name: string): boolean {
    const control = <FormControl>this.verificationForm.get(name);
    return this.isInvalid(control);
  }

  touchVerificationForm(): void {
    for (let i = 0; i < this.questions().length; i++) {
      (<FormGroup>this.questions().at(i)).controls['answer'].markAsTouched();
    }
    this.verificationForm.controls['passcode'].markAsTouched();
  }

  submitVerification(): void {
    this.touchVerificationForm();

    if (!this.verificationForm.valid) {
      this.logger.log('n o t   v a l i d');
      return;
    }

    this.disableSubmit = true;
    this.isVerifying = true;
    this.submittedVerification = true;
    if (this.isOneTimePass) {
      this.logger.log('one time pass');

      const apiModel: SubmitVerificationCodeModel = {
        verificationCode: this.verificationForm.get('passcode').value
      };

      this.apiService.submitIdentityCode(this.clientContactGuid, apiModel)
        .subscribe(res => {
          this.isVerifying = false;
          this._handleVerificationResult(res);
        },error => {
          this.isVerifying = false;
        })
      //   this.identityVerificationService.submitVerificationCode(apiModel);
    } else {
      this.logger.log('questions');

      const responses: IdentityExamResponse[] = [];
      for (let i = 0; i < this.questions().length; i++) {
        const currentGroup = (<FormGroup>this.questions().at(i));

        const response: IdentityExamResponse = {
          question: currentGroup.controls['key'].value,
          response: currentGroup.controls['answer'].value
        };

        responses.push(response);
      }

      const apiModel: IdentityExamResponsesModel = {
        responses: responses
      };

      this.apiService.submitIdentityQuestions(this.clientContactGuid, apiModel)
        .subscribe(res => {
          this._handleVerificationResult(res);
        })
    }

    // if (this.identityVerificationService.isIdentityVerified()) {
    //   this.showSuccessStatus = true;
    //   setTimeout(() => { this.NavContinue.emit() }, 2000);
    // } else {
    //   this.submittedVerification = false;
    //   this.notificationService.show('Invalid code!', 'error');
    // }

    // this.NavContinue.emit();
    //this.onIdentityNavigate(false);
  }

  private _handleVerificationResult(res: IdentityVerificationResult) {
    const identityStatus = res.status === 2 ? STATUS_CHECK_APPROVED : STATUS_CHECK_DENIED;

    if (identityStatus === STATUS_CHECK_APPROVED) {
      // this.tagManagerService.pushIdentityCheckStatus('approved');
      this.verificationPassed.emit(true);
    } else {
      this.verificationPassed.emit(false);
      // this.tagManagerService.pushIdentityCheckStatus('denied');
    }
  }

  resendCode(evt): void {
    evt.preventDefault();

    this.isSendingAdditionalCode = true;
    this.apiService.resendVerificationCode(this.clientContactGuid).subscribe(response => {
      this.isSendingAdditionalCode = false;
      this.hasCodeBeenResent = true;
      this.logger.log('code has been successfully resent', evt);
    }, error => {
      this.logger.error(error);
      this.hasCodeBeenResent = false;
      this.notificationService.show('There was an error resending the verification code.  Please try again.', 'error');
    });
  }

  //   acknowledgeStatus(): void {

  //     this.router.navigate([ROUTE_PORTAL_HOME]);

  //   }

  //   navigateBack() {
  //     // if IsOneTimePass true hide it and display verification selection page
  //     if (this.isOneTimePass) {
  //       this.isOneTimePass = !this.isOneTimePass;
  //     }
  //     // else {
  //     //   this.navigateBackToDetails.emit();
  //     // }
  //   }
}
