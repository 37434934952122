export interface PropertyDetailModel {
    id: string;
    guid: string;
    name: string;
    url: string;
    description: null;
    address: Address;
    images: Image[];
    unitAmenities: string[];
    propertyAmenities: string[];
    allAmenities: string[];
    propertyType: null;
    floorPlans: FloorPlan[];
    lowestPrice: number;
    walkScore: WalkScore;
    startDate: string;
    endDate: string;
    specialHours: any[];
    officeHours: OfficeHour[];
    propertyDisclosure: null;
    parkingDisclosure: null;
    isPetFriendly: boolean;
    maxPetsPerUnit: number;
    petWeightLimit: null;
    petBreedRestrictions: null;
    minimumTerm: string;
    quickQuoteMode: boolean;
    leaseLimitReached: boolean;
    propertySitemaps: any[];
    cancellationPolicy: string;
    calcSpecialHours: any[];
    calcOfficeHours: CalcOfficeHour[];
    calcExcludedDates: any[];
    calcDaysClosed: number[];
    noLeaseEndOctober: boolean;
    noLeaseEndNovember: boolean;
    noLeaseEndDecember: boolean;
    availableUnits?: any;
}

export interface Address {
    address1: string;
    address2: string;
    city: string;
    state: string;
    stateAbbreviation: string;
    zip: string;
    country: string;
    latitude: number;
    longitude: number;
}

export interface CalcOfficeHour {
    open12Hour: string;
    close12Hour: string;
    dayOfWeekName: string;
    dayOfWeek: number;
}

export interface FloorPlan {
    name: string;
    floorplanId: number;
    bedrooms: number;
    bathrooms: number;
    squareFeet: string;
    lowestPrice: number;
    url: string;
    dateAvailable: string;
    hasDCL: boolean;
    hasDesk: boolean;
    units: Unit[];
    doesImageExist?: boolean;
}

export interface Unit {
    id: number;
    unitName?: string;
    unitNumber: string;
    squareFeet: string;
    pricedFrom: string;
    dateAvailable: string;
    bed: number;
    bath: number;
    floor: null;
    buildingNumber: null;
    pricePerNight: number;
    hasStudy: boolean;
    invisible: boolean;
    selected?: boolean;
    enableRemoveHover?: boolean;
}

export interface Image {
    id: number;
    propertyGuid: string;
    etag: string;
    url: string;
    description: null;
    orderIndex: number;
    uploadedBy: string;
    uploadedOn: string;
    propertyGu: null;
}

export interface OfficeHour {
    id: number;
    propertyId: number;
    specialDate: null;
    openTime: string;
    closeTime: string;
    closed: boolean;
    dayOfWeek: number;
}

export interface WalkScore {
    walkScoreResult: WalkScoreResult;
    transitResult: TransitResult;
}

export interface TransitResult {
    busStops: BusStop[];
    railStops: any[];
}

export interface BusStop {
    name: string;
    distance: string;
}

export interface WalkScoreResult {
    succeeded: string;
    error: null;
    status: string;
    walkscore?: number;
    transitscore?: number;
    bikescore?: number;
}

export const GoogleMapData = {
    commute_search_active: "DRIVING",
    commute_search_types: [
        { name: "DRIVING", active: true },
        { name: "BICYCLING", active: false },
        { name: "TRANSIT", active: false },
        { name: "WALKING", active: false }],
    commute_search_source: "",
    mode: "init", //values would be init, commute, nearby-[typename]
    allPlaces: [ //types
        {
            name: "Restaurants",
            search_name: "restaurant",
            description: "Find restaurants nearby",
            icon: "../../../assets/img/icons/directions-map-icons/food-map-icon.svg",
            selected_icon: "../../../assets/img/icons/directions-map-icons/food-map-icon-sel.svg",
            places: [],
            active: false
        },
        {
            name: "Bars",
            search_name: "bar",
            description: "Find bars nearby",
            icon: "../../../assets/img/icons/directions-map-icons/drinks-map-icon.svg",
            selected_icon: "../../../assets/img/icons/directions-map-icons/drinks-map-icon-sel.svg",
            places: [],
            active: false
        },
        {
            name: "Coffee",
            search_name: "cafe",
            description: "Find coffee shops nearby",
            icon: "../../../assets/img/icons/directions-map-icons/coffee-map-icon.svg",
            selected_icon: "../../../assets/img/icons/directions-map-icons/coffee-map-icon-sel.svg",
            places: [],
            active: false
        },
        {
            name: "Shopping",
            search_name: "shopping_mall",
            description: "Find shopping centers nearby",
            icon: "../../../assets/img/icons/directions-map-icons/shopping-map-icon.svg",
            selected_icon: "../../../assets/img/icons/directions-map-icons/shopping-map-icon-sel.svg",
            places: [],
            active: false
        },
        {
            name: "Groceries",
            search_name: "supermarket",
            description: "Find groceries nearby",
            icon: "../../../assets/img/icons/directions-map-icons/groceries-map-icon.svg",
            selected_icon: "../../../assets/img/icons/directions-map-icons/groceries-map-icon-sel.svg",
            places: [],
            active: false
        },
        {
            name: "Banks",
            search_name: "bank",
            description: "Find banks nearby",
            icon: "../../../assets/img/icons/directions-map-icons/bank-map-icon.svg",
            selected_icon: "../../../assets/img/icons/directions-map-icons/bank-map-icon-sel.svg",
            places: [],
            active: false
        }
    ]
}
export interface PropertyAddressModel {
    address1: string,
    address2?: string,
    city?: string
    state?: string,
    stateAbbreviation?: string,
    zip?: string
    country?: string
    latitude?: number
    longitude?: number
};

// This is a simplified version of the model above in order for object to not be too large
export interface PropertyAddressModelSimplpified {
    address1: string,
    city?: string
    state?: string,
    zip?: string
};

export interface PropertyBlobPhotos {
    id: string,
    propertyGuid: string,
    etag?: string,
    url?: string,
    description?: string,
    orderIndex?: string,
    uploadedBy?: string,
    uploadedOn?: Date
};

// This is a simplified version of the model above in order for object to not be too large
export interface PropertyBlobPhotosSimplified {
    id: string,
    url?: string,
};

export interface PropertyDetailCookieModel {
    id: string,
    guid: string,
    url: string,
    name: string,
    lowestPrice?: number,
    address?: PropertyAddressModelSimplpified,
    imageThumbnail?: PropertyBlobPhotosSimplified,
    availableUnitsCount?: number
};
