import { Injectable } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA, MatSnackBarModule } from '@angular/material';
import { GrgSnackBarComponent } from './grg-snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class GRGSnackBarService {
  constructor(private snackBar: MatSnackBar) { }
  public show(text: string, type?: string) {
    const _snackType: string =
      type != undefined ? type : 'success';

    this.snackBar.openFromComponent(GrgSnackBarComponent, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [_snackType],
      data: { text: text, type: _snackType }
    });
  }
}