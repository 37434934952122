import { BrowserModule } from '@angular/platform-browser';
import { CostCalculatorService } from "./services/cost-calculator.service";
import { NGXLogger } from 'ngx-logger';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImportDataService } from './services/import-data.service';
import { UserService } from './services/user.service';
import { CountryStateService } from './services/country-state.service';
import { UtilityService } from './services/utility.service';
import { AuthInterceptor } from './auth/auth-interceptor';
import { LoadingModalComponent } from './auth/loading-modal/loading-modal.component';
import { NgbModalBackdrop } from '@ng-bootstrap/ng-bootstrap/modal/modal-backdrop';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UrlService } from './services/url.service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from "./../environments/environment";
import { OverlayModule } from '@angular/cdk/overlay';
import { PropertyParkingFeeDialogComponent } from './property-parking-fee-dialog/property-parking-fee-dialog.component';
import { GrgSnackBarComponent } from './common/grg-snack-bar/grg-snack-bar.component';
import { MatSnackBar, MatSnackBarModule, MatProgressBarModule, MatMenuModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatFormFieldModule, MatSelectModule } from '@angular/material';
import { PermissionsService } from './services/permissions.service';
import { LogoutComponent } from './logout/logout.component';
import { SearchResultsComponent } from './pre-booking-wizard/search-results/search-results.component';
import { LayoutComponent } from './pre-booking-wizard/layout/layout.component';
import { WpIframeComponent } from './pre-booking-wizard/wp-iframe/wp-iframe.component';
import { FormsModule } from '@angular/forms';
import { PropertyDetailComponent } from './pre-booking-wizard/property-detail/property-detail.component';
import { ActivateAccountComponent } from './account/activate-account/activate-account.component';
import { SignInComponent } from './account/sign-in-form/sign-in-form.component';
import { CreateAccountComponent } from './account/create-account-form/create-account-form.component';
import { AccountModule } from './account/account.module';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password-form.component';
import { FavoritePropertiesComponent } from './pre-booking-wizard/favorite-properties/favorite-properties.component';
import { PropertyFavoriteButtonComponent } from './pre-booking-wizard/favorite-properties/property-favorite-button/property-favorite-button.component';
import { CookieService } from 'ngx-cookie-service';
import { LoadingSpinnerComponent } from './common/loading-spinner/loading-spinner.component';
import { LoadingService } from './services/loading.service';
import { AdditionalOccupantModule } from './landing-pages/additional-occupant-background-check/additional-occupant.module';
import { BookComponent } from './book/book.component';
import { BookModule } from './book/book.module';
import { CommonUIModule } from './common/common-ui.module';
import { MaintenanceModalComponent } from './common/maintenance-modal/maintenance-modal.component';

let disableConsoleLogs = false;
let serverLogUrl = '';

if ((<any>window).apiUrl) {
  serverLogUrl = (<any>window).apiUrl + 'api' + environment.logging.logUrl;
} else {
  serverLogUrl = environment.apiUrl + environment.logging.logUrl;
}

if (typeof ((<any>window).disableConsoleLogs) !== 'undefined') {
  disableConsoleLogs = (<any>window).disableConsoleLogs;
} else {
  disableConsoleLogs = environment.logging.disableConsoleLogs;
}

@NgModule({
  declarations: [AppComponent,
    LoadingModalComponent,
    PropertyParkingFeeDialogComponent,
    GrgSnackBarComponent,
    LogoutComponent,
    SearchResultsComponent,
    LayoutComponent,
    WpIframeComponent,
    PropertyDetailComponent,
    ActivateAccountComponent,
    FavoritePropertiesComponent,
    PropertyFavoriteButtonComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    OverlayModule,
    LoggerModule.forRoot({
      level: parseInt((<any>window).consoleLogLevel || environment.logging.level, 10),
      serverLogLevel: parseInt((<any>window).serverLogLevel || environment.logging.serverLogLevel, 10),
      disableConsoleLogging: disableConsoleLogs,
      serverLoggingUrl: serverLogUrl
    }),
    MatSnackBarModule,
    MatProgressBarModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    AccountModule,
    AdditionalOccupantModule,
    CommonUIModule
  ],
  providers: [
    ImportDataService,
    PermissionsService,
    UserService,
    UrlService,
    CountryStateService,
    UtilityService,
    CostCalculatorService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MatSnackBar,
    NgbActiveModal,
    CookieService,
    LoadingService
  ],
  bootstrap: [AppComponent],
  entryComponents: [LoadingModalComponent, GrgSnackBarComponent, SignInComponent, CreateAccountComponent, ForgotPasswordComponent, MaintenanceModalComponent]
})
export class AppModule { }
