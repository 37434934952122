import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonUIModule } from './../common-ui.module';

import { ContactVerificationComponent } from './contact-verification.component';
import { MatProgressSpinnerModule, MatRadioModule } from '@angular/material';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    imports: [
        CommonUIModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        NgxMaskModule.forRoot()
    ],
    exports: [ContactVerificationComponent],
    declarations: [ContactVerificationComponent],
    providers: [],
})
export class ContactVerificationModule { }
