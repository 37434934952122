import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, ViewContainerRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { filter, takeUntil, take } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { Location } from '@angular/common';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'grg-tab-nav',
  templateUrl: 'grg-tab-nav.component.html',
  styleUrls: ['./grg-tab-nav.component.scss']
})

export class GrgTabNavComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input() header: string;
  @Input() subHeader: string;
  @Input() subText: string;
  @Input() tabList: string[] = [];
  @Output() onTabChange = new EventEmitter<string>()

  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('shadowContainer', { read: ViewContainerRef, static: true }) shadowContainer: ViewContainerRef;
  @ViewChild('shadowTemplate', { static: true }) shadowTemplate: TemplateRef<any>;

  activeTab: string;
  shadowScrollStyles: string = '';

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private logger: NGXLogger) {
    super(logger);
  }

  ngOnInit() {
    this.activeTab = this.tabList[0];

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribeOnDestroy$))
      .pipe(take(1)) // take only first loaded result on page
      .subscribe(params => {
        // check if a tab is attmepting to be loaded
        if (params.tab) {
          if (this.tabList.indexOf(params.tab) > -1) {
            this.logger.log('yeehaw tab loaded!', params.tab);
            var this$ = this;
            // push the tab click to the next cpu cycle so angular can finish its expression evaluation first
            setTimeout(x => this$.tabClicked(params.tab, false), 1);
          } else {
            // the tab wasn't found in this case..
            this.logger.warn(`tab not found to be accessible: ${params.tab}`);
          }
        }
      });
  }

  ngAfterViewInit() {
    if (this.container.nativeElement.clientWidth < this.container.nativeElement.scrollWidth) {
      this.shadowContainer.insert(this.shadowTemplate.createEmbeddedView(null));
    }
  }

  tabClicked(item: string, fromAction: boolean = true) {
    this.activeTab = item;
    this.onTabChange.emit(item);

    if (fromAction) {
      this.router.navigate([], {
        queryParams: {
          tab: item
        },
        queryParamsHandling: 'merge'
      });
    }
  }
}
