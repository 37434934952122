import { Injectable } from '@angular/core';
import { ClientContactModel } from 'app/models/ClientContactModel';
import { LookupService } from './lookup.service';
import { CompanyRoles } from 'app/models/CompanyRoles';
import { NGXLogger } from 'ngx-logger';
import { UserService } from './user.service';
import { BookingWizardModel } from 'app/models/BookingWizardModel';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { UrlTree, Router } from '@angular/router';
import { ROUTE_PORTAL_HOME } from 'app/common/Utils';
import { OpenBookingModel } from 'app/models/BookingModel';
import { HashTable } from 'app/models/HashTable';
import { BookingVM } from 'app/models/NewBookingWizardModels';

@Injectable({
  providedIn: 'root'
})

export class PermissionsService {

  private isAdmin = false;
  private isLeaseApprover = false;
  private isLeaseSigner = false;
  private isUser = false;
  private isLeaseApproverAndSigner = false;
  private isTenantOnly = false;
  hasLoadedRoles = false;
  isEmployee = false;
  role = '';

  private roleChanged = new BehaviorSubject<string>(null);
  public onRoleChanged$ = this.roleChanged.asObservable();

  constructor(private logger: NGXLogger, private router: Router) { }

  private resetRoles(): void {
    this.isAdmin = false;
    this.isLeaseApprover = false;
    this.isLeaseSigner = false;
    this.isUser = false;
    this.isLeaseApproverAndSigner = false;
    this.isTenantOnly = false;
    this.isEmployee = false;
  }

  private isUserInRole(roles: string[]): boolean {
    return roles && this.role && roles.indexOf(this.role) > -1;
  }

  isUserInRoleWhenAuthenticated(roles: string[]): boolean {
    return this.isUserInRole(roles);
  }

  isUserInRoles(roles: string[], subject: Subject<boolean>): Promise<boolean | UrlTree> {
    return new Promise((resolve, reject) => {
      this.roleChanged.subscribe(response => {
        if (response != null) {
          if (this.isUserInRole(roles)) {
            this.logger.log(`Route allowed for role: ${this.role}`);
            setTimeout(x => subject.next(false), 25);
            resolve(true);
          } else {
            this.logger.warn(`Route not allowed for role: ${this.role}`);
            setTimeout(x => subject.next(false), 25);
            resolve(this.router.parseUrl(ROUTE_PORTAL_HOME));
          }
        }
      });
    });
  }

  refreshPermissions(
    user: ClientContactModel,
    lookupService: LookupService) {


    if (lookupService && user) {
      this.logger.debug('Permissions service refresh: isEmployee?', user.clientCompanyGuid ? true : false);
      this.loadRoles(user, lookupService);
    }
  }

  private loadRoles(user: ClientContactModel,
    lookupService: LookupService) {
    this.resetRoles();
    if (user.clientCompanyGuid) {
      this.isEmployee = true;
      switch (lookupService.companyRoles[user.companyRoleId]) {
        case CompanyRoles.Admin: {
          this.isAdmin = true;
          this.logger.debug('Admin role loaded');
          break;
        }
        case CompanyRoles.LeaseApprover: {
          this.isLeaseApprover = true;
          this.logger.debug('Lease approver role loaded');
          break;
        }
        case CompanyRoles.LeaseApproverAndSigner: {
          this.logger.debug('Lease approver and signer role loaded');
          this.isLeaseApproverAndSigner = true;
          break;
        }
        case CompanyRoles.LeaseSigner: {
          this.logger.debug('Lease signer role loaded');
          this.isLeaseSigner = true;
          break;
        }
        case CompanyRoles.TenantOnly: {
          this.logger.debug('Tenant only role loaded');
          this.isTenantOnly = true;
          break;
        }
        default: {
          this.logger.debug('User role loaded');
          this.isUser = true;
          break;
        }
      }
    } else {
      this.logger.debug('User role loaded');
      this.isUser = true;
    }

    this.role = lookupService.companyRoles[user.companyRoleId];
    this.roleChanged.next(this.role);
  }

  init(
    user: ClientContactModel,
    lookupService: LookupService,
    userService: UserService): void {

    lookupService.$portalLookupsObservable.subscribe(response => {
      this.logger.debug('Permissions service init: isEmployee?', user.clientCompanyGuid ? true : false);

      if (response != null && !this.hasLoadedRoles) {
        this.hasLoadedRoles = true;
        this.loadRoles(user, lookupService);
        // after permissions have been doled out, we can send out the main notification to the app that we have a verified loaded user
        userService.clientContactBehaviorSub.next(user);
      }
    });
  }

  // if you need to check permissions of the user, create a new method below to access the private role members initialized above

  //#region Navigation Menu Permissions
  canSeeOrganizationMenu() {
    return this.isAdmin;
  }

  canSeeCompanyMenu() {
    return this.isAdmin;
  }

  canSeeApproveMenu() {
    return (this.isAdmin || this.isLeaseApprover || this.isLeaseApproverAndSigner || this.isLeaseSigner);
  }

  canSeeOpenBookingsMenu() {
    return !this.isTenantOnly;
  }

  canSeeInquiriesMenu() {
    return !this.isTenantOnly;
  }

  canSeeExtensionsMenu() {
    return !this.isTenantOnly;
  }

  canSeeBookingsButtons() {
    if (!this.isAdmin) {
      return false;
    } else {
      return true;
    }
  }
  //#endregion

  canViewLease(currentUserGuid: string, reservationOwnerGuid: string) {
    // only admins of companies or the owner of the reservation can view the signed lease
    return this.isAdmin || currentUserGuid === reservationOwnerGuid;
  }

  //#region SIgn and Approve Permissions
  canApproveBookings() {
    return (this.isAdmin || this.isLeaseApprover || this.isLeaseApproverAndSigner);
  }

  canSignLease() {
    return (this.isAdmin || this.isLeaseSigner || this.isLeaseApproverAndSigner || (!this.isEmployee && this.isUser));
  }
  //#endregion

  //#region Open Bookings Permissions
  canSeeAssignBookingAgent() {
    return this.isAdmin;
  }

  canSeeCancelBooking(bookingOwnerGuid: string, currentUserGuid: string) {
    // only admins or owners of the booking can cancel the booking
    return this.isAdmin || bookingOwnerGuid === currentUserGuid;
  }
  //#endregion

  //#region Booking Wizard Permissions
  canSeeRoleDropdownOnAddPersonModal(): boolean {
    return this.isAdmin;
  }

  canSeeCompanyCreditForm(): boolean {
    return this.isAdmin;
  }

  isCompanyUserStoppedFromProceedingForLeaseApproval(booking: BookingVM, isInquiryApproved: boolean): boolean {
    // return booking &&
    //   booking.clientCompany &&
    //   booking.clientCompany.settings &&
    //   booking.clientCompany.settings.companyApprovesLeases &&
    //   !isInquiryApproved &&
    //   booking.clientContact &&
    //   !this.isAdmin;

    return false;
  }

  isCompanyUserStoppedFromProceedingForLeaseSigning(booking: BookingVM): boolean {
    // return booking &&
    //   booking.clientCompany &&
    //   booking.clientCompany.settings &&
    //   booking.clientCompany.settings.companySignsLeases &&
    //   !this.isAdmin;

    return false;
  }

  //#endregion

  canRequestReservationExtension(): boolean {
    return this.isAdmin;
  }

  canEditReservationBilling(bookingAgentGuid: string, currentUserGuid: string, currentUserBillingId: string, reservationBillingId: string): boolean {
    // only admins of companies, booking agents (owner of the reservation), and / or the person who is currently paying for the reservation can change the payment method
    return bookingAgentGuid === currentUserGuid || this.isAdmin || currentUserBillingId === reservationBillingId;
  }

  //#region dashboard permissions
  canSeeAllCompanyReservationsOnDashboard(): boolean {
    return this.isAdmin;
  }

  canSeeLeasesToApproveOnDashboard(): boolean {
    return this.isAdmin || this.isLeaseApproverAndSigner || this.isLeaseApprover;
  }

  canSeeLeasesToSignOnDashboard(): boolean {
    return this.isAdmin || this.isLeaseApproverAndSigner || this.isLeaseSigner;
  }

  //#endregion
}


