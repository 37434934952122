import { DatePipe, Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, Input, NgZone } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { amenities, AmenityModel, APISearchResult, AppliedFilter, filters, FloorPlan, Image, noResultsMessage, PropertyModel, PropertySearchAPI, SearchFilter, searchParams, SearchPropertyResultModel, SearchResultFilter, SEOModel } from 'app/models/SearchResultModel';
import { ApiService } from 'app/services/api.service';
import { initDateRange, initMobile } from './date-range';
import Utils from 'app/common/Utils';
import Extensions from '../extensions';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from 'app/services/user.service';
import { LoadingService } from 'app/services/loading.service';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';
import { ModalDismissReasons, NgbDropdown, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
declare var google: any;
declare var Slider: any;
declare var slick: any;
declare var matchHeight: any;

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SearchResultsComponent implements OnInit, AfterViewInit {
  //#region Properties
  search$: Subject<any> = new Subject();
  buildingType: any;
  bedRooms: any;
  bathrooms: any;
  amenities: any;
  hideResult: boolean = false;
  dropdownBuildingTypeTitle: string = "Building Type";
  dropdownBedroomsTitle: string = "Bedrooms"
  dropdownMoreFilterTitle: string = "More Filters"
  holdingValueAmenities: any = "";
  closeResult = '';
  addFilterModal: NgbModalRef;
  wordPressUrl = "/locations-2"
  hideData: boolean = false;
  public current: number = 1;
  paginatedButtons = [];
  mapInitialized: boolean = false;
  prevRemoteUrl: string = "";
  geocodeRequired: boolean = false;
  searchResultFilter: SearchResultFilter;
  activeFilters: AppliedFilter = { amenities: [], sortBy: '', bedrooms: '', bathrooms: '', propertyBuildingTypeId: '' };
  SEOData: SEOModel;
  results: APISearchResult[];
  filteredData: APISearchResult[] = [];
  filteredToDisplay: APISearchResult[] = [];
  routeParams: searchParams;
  initLocation: string;
  isLoadingResults = false
  mobileDatesOpen = false;
  mobileFiltersOpen = false;
  loaded = false;
  locationChanged = true;
  canSearch = false;
  invalidLocation = false;
  monthTerms = 1;

  appliedLocalFiltersCount: number = 0;

  noResultsTitle = "No Results Available For Selected Criteria";
  noResultsMessage: string;

  // Price Range
  maxPriceValue = 100;
  minPriceValue = 0;
  fromPrice = "NO MIN";
  toPrice = "NO MAX";
  $sliderChange: any;

  initStartDate: Date;
  initEndDate: Date;

  @ViewChild('dateStart', { static: false }) dateStartElement: ElementRef;
  @ViewChild('mobileTextCity', { static: false }) mobileCityInput: ElementRef
  @ViewChild('dateEnd', { static: false }) dateEndElement: ElementRef;

  @ViewChild('map', { static: false }) mapElement: ElementRef;
  map: any;
  geocoder: any;
  searchOnDrag: boolean = true;
  wasLastPanned: boolean = false;
  mapClick = false;
  mapDrag = false;
  initLatLon: [number, number] = [29.7604267, -95.3698028];
  initZoom: number = 10;
  currentZoom: number = 10;
  minMapZoom: number = 6;
  maxMapZoom: number = 16;
  mapMode: boolean = false;
  Markers = [];
  infoWindow: any;
  mileRange: number = 85.77344290256586;  // radiusMileKeys {10, 85.77344290256586},;
  centerMarker: any;

  //gmap icons
  iconLeftOffset = 48;
  iconTopOffset = 44;
  iconWidth = 100;
  iconHeight = 80;
  iconWidthScaledDown = 50;
  iconHeightScaledDown = 40;
  iconFontSize = 32;
  //marker images
  imgMarkerPreload1: HTMLImageElement;
  imgMarkerPreload2: HTMLImageElement;

  // recently viewed properties
  recentProperties = [];


  @ViewChild('city', { static: false }) ciltyElement: ElementRef;
  @ViewChild('mobileCity', { static: false }) mobileCiltyElement: ElementRef;
  autocomplete: any;
  mobileAutocomplete: any;

  @ViewChild('priceRange', { static: false }) sliderElement: ElementRef;
  @ViewChild('mobilePriceRange', { static: false }) mobileSliderElement: ElementRef;
  slider: any;
  mobileSlider: any;
  initialSliderOptions: any = {
    min: 0,
    max: 100,
    value: [0, 100],
    focus: true,
    tooltip: "hide",
    ticks: [0, 10, 20, 40, 60, 80, 100],
    step: 10,
    labelledby: ['desktop-pricerange-min', 'desktop-pricerange-max']
  };
  @Input()
  set value(value: number | any[]) {
    if (this.slider) {
      this.slider.setValue(value);
    } else {
      this.initialSliderOptions.value = value;
    }
  }
  //#endregion

  constructor(private router: Router, private route: ActivatedRoute, private location: Location, private zone: NgZone,
    private apiService: ApiService, private cookieService: CookieService, private userService: UserService, private loadingService: LoadingService, private modalService: NgbModal) {
    // loadingService.fetch();
  }

  async ngOnInit() {
    this.search$.pipe(debounceTime(300), switchMap((params) => combineLatest([this.apiService.searchProperties(params).pipe(catchError(error => { this.errorResponse(); return of({ response: null }) })), of(params)]))).subscribe(([response, params]) => this.processResponse(response, params.panToCenterMap), (error) => this.errorResponse());

    this.getUrlParams();
    await this.initSearchFilters();
    this.localFiltersApplied();

    if (this.userService.isAuthenticated) {
      this.userService.getLikedProperties().subscribe();
    }

    this.getRecentlyViewed();
    this.loaded = true;
  }

  ngAfterViewInit(): void {
    initDateRange(this);
    Extensions.bindGoogleAutoComplete(this.mobileCityInput.nativeElement as HTMLInputElement, this);
    initMobile(this);
    //this.initSlider();
    // this.initAutoComplete();
  }

  //#region Property Search Events
  searchProperties(panToCenterMap?: boolean) {
    this.mobileFiltersOpen = false;
    this.mobileDatesOpen = false;

    this.setUrlFromFilters();

    if (this.searchResultFilter.startDate == "" || this.searchResultFilter.endDate == "") {
      this.loadingService.pet();
      return;
    }

    // if (this.locationChanged) {
    //   this.results = [];
    //   this.paginatedButtons = [];
    //   this.filteredData = [];
    // }

    this.isLoadingResults = true;

    if (panToCenterMap == undefined || panToCenterMap == null) {
      panToCenterMap = true;
    }

    let searchParams: PropertySearchAPI = {
      startDate: this.searchResultFilter.startDate.replace(/\//g, '-'),
      endDate: this.searchResultFilter.endDate.replace(/\//g, '-'),
      range: this.mileRange,
      isCoordinates: false,
      location: '',
      panToCenterMap: panToCenterMap
    };

    if (this.searchResultFilter.latLon !== undefined && this.searchResultFilter.latLon !== null && this.searchResultFilter.latLon.length > 0) {
      searchParams.location = this.searchResultFilter.latLon.toString();
      searchParams.isCoordinates = true;
    }

    if (this.initLocation !== undefined && this.initLocation !== null && this.initLocation.length > 0) {
      searchParams.location = this.searchResultFilter.city;
      searchParams.isCoordinates = false;
      this.initLocation = null;
    }




    this.search$.next(searchParams);
  }

  private _hasRequiredFields() {
    return !!(this.searchResultFilter.city && this.searchResultFilter.startDate.replace(/\//g, '-') && this.searchResultFilter.endDate.replace(/\//g, '-'));
  }

  autoCompleteCallback(location) {
    this.zone.run(() => {
      this.searchResultFilter.city = location;
      if (this._hasRequiredFields()) {
        this.searchHousing();
      }
    });
  }


  searchHousing() {
    let location = "";
    let split = this.searchResultFilter.city.split(',');
    if (split.length) {
      split.slice().reverse().forEach((x, i) => {
        if (split.length !== i + 1) {
          location += `${x.trim()}-`;
        } else {
          location += x.trim();
        }
      });
    }
    let start = this.searchResultFilter.startDate.replace(/\//g, '-');
    let end = this.searchResultFilter.endDate.replace(/\//g, '-');
    this.router.navigate([`/search`], { queryParams: { start: start, end: end, location: location } });
  }

  processResponse(response: SearchPropertyResultModel, panToCenterMap: boolean) {
    this.wordPressUrl = undefined
    this.loadingService.pet();
    this.isLoadingResults = false;
    this.SEOData = response.SEOData;
    this.results = response.results;
    this.monthTerms = response.terms;
    if (!response || !response.results || response.results.length == 0) {
      // if (this.mileRange == this.initialMileRange) {
      //   this.mileRange = this.maxMileRange;
      //   this.wasLastPanned = true;
      //   this.searchProperties(true);
      //   return;
      // }
      // if (this.locationChanged) {
      //   this.canSearch = true;
      //   this.locationChanged = false;
      //   this.currentZoom = this.initZoom - 1;
      //   this.searchResultFilter.zoom = this.currentZoom;
      //   this.map.setZoom(this.currentZoom);
      //   return;
      // }
      this.noResultsMessage = noResultsMessage;
      this.hideData = true;
      this.wordPressUrl = "/no-location-properties"
      this.noResultsMessage = this.noResultsMessage.replace("[fromDate]", this.searchResultFilter.startDate);
      this.noResultsMessage = this.noResultsMessage.replace("[endDate]", this.searchResultFilter.endDate);
    } else {
      // this.locationChanged = false;
      this.hideData = false;
      this.wordPressUrl = undefined;
      this.noResultsMessage = null;
    }
    if (response && response.coordinates && !Number.isNaN(response.coordinates.latitude) && Number.isNaN(response.coordinates.longitude)) {
      this.searchResultFilter.latLon = [response.coordinates.latitude, response.coordinates.longitude];
    }
    this.searchExecuted(panToCenterMap);
    this.paginationButtons();
    this.loadingService.pet();
    this.filteredToDisplay = this.paginate(this.current, 12);
  }
  errorResponse() {
    this.loadingService.pet();
    this.isLoadingResults = false;
  }
  searchExecuted(panToCenterMap?: boolean) {
    this.filterProperties();
    let self = this;
    if (panToCenterMap === undefined || panToCenterMap === null) { panToCenterMap = true; }
    setTimeout(function () {
      self.propertiesLoaded();
    }, 10);
    if (this.map !== undefined) {
      self.populateGoogleMap(this.filteredData, self.searchResultFilter.latLon, panToCenterMap);
    }
  }

  async initSearchFilters() {
    let minPrice = this.minPriceValue;
    let maxPrice = this.maxPriceValue;
    if (this.routeParams) {
      //Dates
      if (this.routeParams.start !== undefined && this.routeParams.start.length > 0) {
        this.initStartDate = new Date(this.routeParams.start.replace(/(..).(..).(....)/, "$3-$1-$2").replace(/-/g, '/'));
        if (this.routeParams.end !== undefined && this.routeParams.end.length > 0) {
          this.initEndDate = new Date(this.routeParams.end.replace(/(..).(..).(....)/, "$3-$1-$2").replace(/-/g, '/'));
        } else {
          this.initEndDate = new Date(this.initStartDate);
          this.initEndDate.setDate(this.initEndDate.getDate() + 30);
        }
      }
      //Price
      if (this.routeParams.minPrice) {
        this.fromPrice = this.routeParams.minPrice;
        minPrice = +this.routeParams.minPrice.replace("$", "");
      }
      if (this.routeParams.maxPrice) {
        this.toPrice = this.routeParams.maxPrice;
        maxPrice = +this.routeParams.maxPrice.replace("$", "");
      }
      this.initialSliderOptions.value = [minPrice, maxPrice];
    }
    if (this.routeParams.location) {
      let location = this.routeParams.location.replace(/-/g, ', ');
      let lastChar = location.charAt(location.length - 1);
      if (lastChar === '-') {
        location = location.slice(0, -1);
      }
      this.initLocation = location;
    }
    if (!this.initStartDate) {
      this.initStartDate = new Date();
      this.initStartDate.setDate(this.initStartDate.getDate() + 8);
      this.initEndDate = new Date(this.initStartDate);
      this.initEndDate.setDate(this.initEndDate.getDate() + 30);
    }

    this.searchResultFilter = {
      filters: filters,
      amenities: amenities,
      minPrice: minPrice,
      maxPrice: maxPrice,
      startDate: Extensions.getDateStringFromDate(this.initStartDate),
      endDate: Extensions.getDateStringFromDate(this.initEndDate),
      latLon: this.initLatLon,
      city: this.initLocation,
      sortBy: {
        name: "Sort By",
        slug: "sort-by",
        value: "price-low-high",
        options: [
          {
            name: "Sort By Price: Low-High",
            value: "price-low-high",
            valueField: "calcLowestPrice",
            ascOrDesc: "asc"
          }, {
            name: "Sort By Price: High-Low",
            value: "price-high-low",
            valueField: "calcLowestPrice",
            ascOrDesc: "desc"
          }, {
            name: "Sort By # of Units: High-Low",
            value: "units-high-low",
            valueField: "calcAvailableUnits",
            ascOrDesc: "desc"
          }, {
            name: "Sort By # of Units: Low-High",
            value: "units-low-high",
            valueField: "calcAvailableUnits",
            ascOrDesc: "asc"
          }
        ]
      },
      page: 1,
      perPage: 12
    }
    //Amenities
    if (this.routeParams.amenities !== undefined && this.routeParams.amenities.length > 0) {
      let amenities = this.routeParams.amenities.split(',');
      for (let i = 0; i < amenities.length; i++) {
        for (let j = 0; j < this.searchResultFilter.amenities.length; j++) {
          if (this.searchResultFilter.amenities[j].value == amenities[i]) {
            this.searchResultFilter.amenities[j].active = true;
            // this.setValueTesting(this.searchResultFilter.amenities[j].value,this.searchResultFilter.amenities[j].active);
            continue;
          }
        }
      }
    }
    //Filters
    if (this.routeParams.filters !== undefined) {
      let filter = this.routeParams.filters.split("|");
      if (filter) {
        for (let i = 0; i < filter.length; i++) {
          let type = filter[i].split("=");
          for (let j = 0; j < this.searchResultFilter.filters.length; j++) {
            if (this.searchResultFilter.filters[j].slug == type[0]) {
              this.searchResultFilter.filters[j].value = type[1].replace(" ", "+");
              if (this.searchResultFilter.filters[j].slug === "building-type" && this.searchResultFilter.filters[j].value !== "") {
                this.buildingType = this.searchResultFilter.filters[j].value;
                this.verifyDropdownBuildingTypeTitle(this.buildingType);
              }
              if (this.searchResultFilter.filters[j].slug === "bedrooms" && this.searchResultFilter.filters[j].value !== "") {
                this.bedRooms = this.searchResultFilter.filters[j].value;
                this.verifyDropdownBedroomTitle(this.bedRooms);
              }
              // if(this.searchResultFilter.filters[j].slug === "bathrooms" && this.searchResultFilter.filters[j].value !== ""){
              //   this.bathrooms = this.searchResultFilter.filters[j].value;
              // }
              continue;
            }

          }
        }
      } else {
        for (let i = 0; i < this.searchResultFilter.filters.length; i++) {
          if (this.searchResultFilter.filters[i].slug == filter[0]) {
            this.searchResultFilter.filters[i].value = filter[1].replace(" ", "+");
            continue;
          }
        }
      }
    }

    //LatLon
    if (this.routeParams.lat !== undefined && this.routeParams.lon != undefined) {
      this.searchResultFilter.latLon = [this.routeParams.lat, this.routeParams.lon];
      this.searchResultFilter.city = '';
      this.wasLastPanned = true;
    }
    else {
      this.initLocation = null;
      if (this.searchResultFilter.city !== undefined && this.searchResultFilter.city.length > 0) {
        let coordinates = await this.getCoordinates(this).catch(() => {
          this.searchResultFilter.latLon = [this.initLatLon[0], this.initLatLon[1]];
        });
        if (coordinates) {
          this.searchResultFilter.latLon = [coordinates.results[0].geometry.location.lat(), coordinates.results[0].geometry.location.lng()];
        }
      } else {
        this.searchResultFilter.latLon = [this.initLatLon[0], this.initLatLon[1]];
        this.searchResultFilter.city = '';
      }
    }
    if (!this.loaded) {
      this.initMap();
    }
  }

  getUrlParams() {
    this.route.queryParams.subscribe((params: searchParams) => {
      this.routeParams = params
      if (this.loaded) {
        setTimeout(async () => {
          await this.initSearchFilters();
          this.searchProperties(true);
        }, 100)
      }
    });

    // this.route.url.subscribe((r) => {
    //   console.log('url route', r);
    //   if (this.loaded) {
    //     setTimeout(() => {
    //       this.initSearchFilters();
    //       this.searchProperties(true);
    //     }, 100)
    //   }
    // });
  }
  getUrlParams2(test: any) {
    this.route.queryParams.subscribe((params: searchParams) => {
      this.routeParams = test
      if (this.loaded) {
        setTimeout(async () => {
          //await this.initSearchFilters();
          this.searchProperties(true);
        }, 100)
      }
    });

    // this.route.url.subscribe((r) => {
    //   console.log('url route', r);
    //   if (this.loaded) {
    //     setTimeout(() => {
    //       this.initSearchFilters();
    //       this.searchProperties(true);
    //     }, 100)
    //   }
    // });
  }

  open(content) {
    this.addFilterModal = this.modalService.open(content, {
      centered: true,
      backdrop: 'static',
      windowClass: 'cancel-booking-modal',
      size: 'sm'
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  setUrlFromFilters() {
    let params: any = {};
    let cityRoute = '/search/';
    if (this.searchResultFilter.city !== undefined && this.searchResultFilter.city.length > 0) {
      params.location = this.searchResultFilter.city;
      // .replace(/\,/g, '')
    }
    if (this.searchResultFilter.startDate !== null || this.searchResultFilter.startDate !== undefined) {
      params.start = new DatePipe('en-US').transform(this.searchResultFilter.startDate, 'MM-dd-yyyy');
    }
    if (this.searchResultFilter.endDate !== null || this.searchResultFilter.endDate !== undefined) {
      params.end = new DatePipe('en-US').transform(this.searchResultFilter.endDate, 'MM-dd-yyyy');
    }
    if (this.wasLastPanned && this.searchResultFilter.latLon !== null && this.searchResultFilter.latLon.length > 1) {
      params.lat = this.searchResultFilter.latLon[0];
      params.lon = this.searchResultFilter.latLon[1];
      this.wasLastPanned = false;
    }
    let activeFilters = "";
    if (this.searchResultFilter.filters.length > 0) {
      for (let i = 0; i < this.searchResultFilter.filters.length; i++) {
        if (this.searchResultFilter.filters[i].value !== "") {
          for (let j = 0; j < this.searchResultFilter.filters[i].options.length; j++) {
            if (this.searchResultFilter.filters[i].options[j].value == this.searchResultFilter.filters[i].value) {
              activeFilters += this.searchResultFilter.filters[i].slug + "=" + this.searchResultFilter.filters[i].options[j].value + "|";
            }
          }
        }
      }
      if (activeFilters !== "") {
        if (activeFilters !== "" && activeFilters.substring(activeFilters.length - 1) == "|") {
          activeFilters = activeFilters.substring(0, activeFilters.length - 1);
        }
        params.filters = activeFilters;
      }
    }

    let amenityFilters = "";
    if (this.searchResultFilter.amenities.length > 0) {
      for (let i = 0; i < this.searchResultFilter.amenities.length; i++) {
        if (this.searchResultFilter.amenities[i].active) {
          amenityFilters += this.searchResultFilter.amenities[i].value + ",";
        }
      }
    }
    if (amenityFilters !== "") {
      if (amenityFilters.substring(amenityFilters.length - 1) == ",") {
        amenityFilters = amenityFilters.substring(0, amenityFilters.length - 1);
      }
      params.amenities = amenityFilters;
      this.holdingValueAmenities = amenityFilters;
      var amentities = this.holdingValueAmenities.split(',');
      this.dropdownMoreFilterTitle = amentities.length + " Items Selected";
    }

    if (this.searchResultFilter.minPrice > 0) {
      params.minPrice = "$" + this.searchResultFilter.minPrice;
    }
    if (this.searchResultFilter.maxPrice < 100) {
      params.maxPrice = "$" + this.searchResultFilter.maxPrice
    }

    params.zoom = this.currentZoom;

    let urlTree = this.router.createUrlTree([cityRoute], {
      relativeTo: this.route, queryParams: params
    });

    let str = urlTree.toString().replace(/%3D/gi, '=');
    str = str.replace(/%7C/gi, '|');
    str = str.replace(/%2B/gi, '+');
    // str = str.replace(/%20/gi, '/');
    this.location.go(str);
  }

  propertyURL(item: APISearchResult): string {
    let name = item.name.toLowerCase();
    name = name.replace(/ /g, '-');

    return `/properties/${name}/${item.guid}`;
  }
  propertyURLQueryParams() {
    return {
      location: this.searchResultFilter.city,
      start: this.searchResultFilter.startDate.replace(/\//g, '-'),
      end: this.searchResultFilter.endDate.replace(/\//g, '-')
    }
  }
  properyURLwithParams(item: APISearchResult) {
    let params = ''
    if (this.searchResultFilter.startDate && this.searchResultFilter.endDate) {
      params = `?start=${this.searchResultFilter.startDate.replace(/\//g, '-')}&end=${this.searchResultFilter.endDate.replace(/\//g, '-')}`
    }
    return this.propertyURL(item) + params;
  }


  localFilterChanged() {
    console.log('Filter Changed!');
    this.setUrlFromFilters();
    this.searchExecuted();
  }

  hasMultipleImages(item: APISearchResult): boolean { return item.images.length > 1 }
  //#endregion

  //#region City Field AutoComplete
  // initAutoComplete() {
  //   const options = {
  //     // types: ['(cities)'],
  //     types: ['geocode'],
  //     componentRestrictions: { country: ["us", "ca"] }
  //   };
  //   let inputElement = this.ciltyElement.nativeElement as HTMLInputElement;
  //   this.autocomplete = new google.maps.places.Autocomplete(inputElement, options);
  //   this.mobileAutocomplete = new google.maps.places.Autocomplete(this.mobileCiltyElement.nativeElement as HTMLElement, options);

  //   this.autocomplete.addListener('place_changed', () => {
  //     const place = this.autocomplete.getPlace();
  //     this.placeChanged(this, place);
  //   });
  //   this.mobileAutocomplete.addListener('place_changed', () => {
  //     const place = this.mobileAutocomplete.getPlace();
  //     this.placeChanged(this, place);
  //   });
  // }
  async placeChanged(self, place) {
    self.locationChanged = true;

    self.currentZoom = self.initZoom;
    self.searchResultFilter.zoom = self.currentZoom;
    // self.map.setZoom(self.currentZoom);

    if (place.formatted_address !== undefined) {
      self.searchResultFilter.city = place.formatted_address;
      self.searchResultFilter.latLon = [place.geometry.location.lat(), place.geometry.location.lng()];
      self.map.panTo(place.geometry.location);
      this.canSearch = true;
      this.invalidLocation = false;
    } else {
      self.searchResultFilter.city = place.name;
      self.searchResultFilter.latLon = null;
      const coordinates = await this.getCoordinates(self).catch(() => { this.invalidLocation = true; });
      if (coordinates) {
        this.invalidLocation = false;
        self.searchResultFilter.latLon = [coordinates.results[0].geometry.location.lat(), coordinates.results[0].geometry.location.lng()];
        self.map.panTo(coordinates.results[0].geometry.location);
        this.canSearch = true;
      }
    }
  }
  //#endregion

  //#region DateRange
  submitDateField(startDate, endDate, isMobile = false) {
    this.zone.run(() => {
      this.searchResultFilter.startDate = startDate;
      this.searchResultFilter.endDate = endDate;
      if (!isMobile) {
        this.searchProperties();
      } else {
        this.setUrlFromFilters();
      }
    });
  }
  clearDates() {
    // this.searchResultFilter.startDate = "";
    // this.searchResultFilter.endDate = "";
  }
  dateEndClick() {
    $('#date-start').datepicker('show');
  }
  //#endregion

  //#region Filters Dropdown
  onFilterChange(filterType: SearchFilter) {
    this.setUrlFromFilters();
  }
  formateDate(date: Date): string {
    return Extensions.getDateStringFromDate(date);
  }
  //#endregion

  // //#region Price Range
  // initSlider() {
  //   this.slider = new Slider(this.sliderElement.nativeElement, this.initialSliderOptions);
  //   this.slider.on('change', (value: { newValue: [number, number], oldValue: [number, number] }) => {
  //     this.setSliderValues(value.newValue);
  //   });
  //   this.mobileSlider = new Slider(this.mobileSliderElement.nativeElement, this.initialSliderOptions);
  //   this.mobileSlider.on('change', (value: { newValue: [number, number], oldValue: [number, number] }) => {
  //     this.setSliderValues(value.newValue);
  //   });
  // }
  // setSliderValues(value: number[]) {
  //   this.fromPrice = (value[0] === this.minPriceValue) ? "NO MIN" : "$" + value[0];
  //   this.toPrice = (value[1] === this.maxPriceValue) ? "NO MAX" : "$" + value[1];
  //   this.searchResultFilter.minPrice = value[0];
  //   this.searchResultFilter.maxPrice = value[1];

  //   this.setUrlFromFilters();
  //   this.searchExecuted();
  // }
  //#endregion

  //#region Map
  initMap() {
    this.imgMarkerPreload1 = new Image();
    this.imgMarkerPreload2 = new Image();
    this.imgMarkerPreload1.src = "/assets/img/PricePin.png";
    this.imgMarkerPreload2.src = "/assets/img/PricePinHover.png";

    let center = { lat: this.initLatLon[0], lng: +this.initLatLon[1] };
    if (this.searchResultFilter.latLon) {
      center = { lat: +this.searchResultFilter.latLon[0], lng: +this.searchResultFilter.latLon[1] }
    }
    this.map = new google.maps.Map(document.getElementById('google_map') as HTMLElement,
      {
        center: center,
        zoom: 10,
        streetViewControl: false,
      });
    // map Loaded, search Properties      
    let mapIdleListener = this.map.addListener('idle', () => {
      this.loaded = true;
      google.maps.event.removeListener(mapIdleListener);
      this.canSearch = true;
    });
    this.map.addListener('dragend', () => {
      let self = this;
      let dragIdleListener = this.map.addListener('idle', function () {
        google.maps.event.removeListener(dragIdleListener);
        self.mapDrag = true;

        if (self.searchOnDrag) {
          //fire lat lon change
          let previousLatLon = self.searchResultFilter.latLon;
          self.wasLastPanned = true;
          self.searchResultFilter.latLon = [this.getCenter().lat(), this.getCenter().lng()];
          self.searchResultFilter.city = "";
          self.searchProperties(false);

          // self.getCity();
          // Extensions.scrollToElementTop($("#google_map"));
        }
      });
    });
    this.map.addListener('bounds_changed', () => {
      let idle = this.map.addListener('idle', () => {
        google.maps.event.removeListener(idle);
        this.currentZoom = this.map.getZoom();
        this.searchResultFilter.zoom = this.currentZoom;
        this.searchResultFilter.latLon = [this.map.getCenter().lat(), this.map.getCenter().lng()];
        if (this.canSearch) {
          this.canSearch = false;
          this.searchProperties();
        }
      })
    })
    this.map.addListener('zoom_changed', () => {
      let idle = this.map.addListener('idle', () => {
        google.maps.event.removeListener(idle);
        if (!this.locationChanged) {
          this.canSearch = true
        }
      });
    });
    this.map.addListener('click', (mapsMouseEvent) => {
      this.mapClick = true;
      if (this.currentZoom < this.minMapZoom) {
        this.currentZoom = this.initZoom;
        this.map.setZoom(this.currentZoom);
      }
      this.map.panTo(mapsMouseEvent.latLng);
      this.wasLastPanned = true;
      this.searchResultFilter.city = "";
      this.searchResultFilter.latLon = [mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng()];
      this.searchProperties(true);
    });
  }
  setCenterMarker(latLng) {
    let map = this.map;
    if (this.centerMarker !== undefined) {
      this.centerMarker.setMap(null);
    }
    this.centerMarker = new google.maps.Marker({
      position: latLng,
      map
    });
  }

  saveBuildingOption(content) {
    this.verifyDropdownBuildingTypeTitle(this.buildingType);
    content.close();
  }
  clearBuildingOption(content) {
    this.buildingType = "";
    this.verifyDropdownBuildingTypeTitle(this.buildingType);
    content.close();
  }
  saveBedRoomOption(content) {
    this.verifyDropdownBedroomTitle(this.bedRooms);
    content.close();
  }
  clearBedroomOption(content) {
    content.close();
    this.bedRooms = "";
    this.verifyDropdownBedroomTitle(this.bedRooms);
  }


  clearAnemnititesOption(content) {
    this.holdingValueAmenities = "";
    this.searchResultFilter.amenities.forEach(function (element) {
      element.active = false;
    })
    this.clearAmenities()
    content.close();
  }
  verifyDropdownBuildingTypeTitle(testing: string) {
    switch (testing) {
      case "1":
        this.dropdownBuildingTypeTitle = "Low Rise (1-3 stories)";
        break;
      case "2":
        // code block
        this.dropdownBuildingTypeTitle = "Mid Rise (4-6 stories)";
        break;
      case "3":
        // code block
        this.dropdownBuildingTypeTitle = "High Rise (7+ stories)";
        break;
      default:
        // code block
        this.dropdownBuildingTypeTitle = "Building Type";
    }
  }

  verifyDropdownBedroomTitle(testing: string) {
    switch (testing) {
      case "1":
        this.dropdownBedroomsTitle = "1 Bedroom";
        break;
      case "2-to-3":
        // code block
        this.dropdownBedroomsTitle = "2 to 3 Bedrooms";
        break;
      case "4+":
        // code block
        this.dropdownBedroomsTitle = "4+ Bedrooms";
        break;
      default:
        // code block
        this.dropdownBedroomsTitle = "Bedrooms";
    }
  }

  checkDropDown(open: boolean, dropdown: NgbDropdown, dropdown2: NgbDropdown, dropdown3: NgbDropdown) {
    if (open == true) {
      dropdown2.close();
      dropdown3.close();
    }

  }
  saveAmentitiesOption(content) {
    const last = this.holdingValueAmenities.charAt(this.holdingValueAmenities.length - 1);
    if (last === ",")
      this.holdingValueAmenities = this.holdingValueAmenities.slice(0, -1) //'abcde'
    var amentities = this.holdingValueAmenities.split(',');
    if (this.holdingValueAmenities === "") {
      this.dropdownMoreFilterTitle = "More Filters";
    } else {
      this.dropdownMoreFilterTitle = amentities.length + " Items Selected";
    }
    content.close();
  }
  clearAmenities() {
    this.dropdownMoreFilterTitle = "More Filters";
  }
  closeModal() {
    this.modalService.dismissAll();
  }

  saveAll() {
    let params: any = {};
    let activatedFilters: string = "";
    let cityRoute = '/search/';
    if (this.searchResultFilter.city !== undefined && this.searchResultFilter.city.length > 0) {
      params.location = this.searchResultFilter.city;
      // .replace(/\,/g, '')
    }
    if (this.searchResultFilter.startDate !== null || this.searchResultFilter.startDate !== undefined) {
      params.start = new DatePipe('en-US').transform(this.searchResultFilter.startDate, 'MM-dd-yyyy');
    }
    if (this.searchResultFilter.endDate !== null || this.searchResultFilter.endDate !== undefined) {
      params.end = new DatePipe('en-US').transform(this.searchResultFilter.endDate, 'MM-dd-yyyy');
    }
    if (this.wasLastPanned && this.searchResultFilter.latLon !== null && this.searchResultFilter.latLon.length > 1) {
      params.lat = this.searchResultFilter.latLon[0];
      params.lon = this.searchResultFilter.latLon[1];
      this.wasLastPanned = false;
    }
    if (this.buildingType !== "" && this.buildingType !== undefined) {
      activatedFilters += "building-type=" + this.buildingType + "|";
      this.searchResultFilter.filters.find(x => x.slug === "building-type").value = this.buildingType;

    } else {
      this.searchResultFilter.filters.find(x => x.slug === "building-type").value = "";
    }
    if (this.bedRooms !== "" && this.bedRooms !== undefined) {
      activatedFilters += "bedrooms=" + this.bedRooms + "|";
      this.searchResultFilter.filters.find(x => x.slug === "bedrooms").value = this.bedRooms;
    } else {
      this.searchResultFilter.filters.find(x => x.slug === "bedrooms").value = "";
    }
    // if(this.bathrooms !=="" && this.bathrooms !== undefined){
    //   ttesttt+= "bathrooms="+this.bathrooms;
    // }
    if (activatedFilters !== "" && activatedFilters.substring(activatedFilters.length - 1) == "|") {
      activatedFilters = activatedFilters.substring(0, activatedFilters.length - 1);

    }
    if (activatedFilters !== "") {
      params.filters = activatedFilters;
    }

    if (this.holdingValueAmenities !== "") {
      if (this.holdingValueAmenities.substring(this.holdingValueAmenities.length - 1) == ",") {
        this.holdingValueAmenities = this.holdingValueAmenities.substring(0, this.holdingValueAmenities.length - 1);
      }
      params.amenities = this.holdingValueAmenities;
    }


    params.zoom = this.currentZoom;

    let urlTree = this.router.createUrlTree([cityRoute], {
      relativeTo: this.route, queryParams: params
    });

    let str = urlTree.toString().replace(/%3D/gi, '=');
    str = str.replace(/%7C/gi, '|');
    str = str.replace(/%2B/gi, '+');
    // str = str.replace(/%20/gi, '/');
    this.location.go(str);
    this.modalService.dismissAll();
    this.searchProperties();
    this.hideResult = false;
  }

  checboxUpdate(event: any, testing: string) {
    var isChecked;
    isChecked = event.target.checked;
    if (isChecked) {
      if (this.holdingValueAmenities.length > 0) {
        const last = this.holdingValueAmenities.charAt(this.holdingValueAmenities.length - 1);
        if (last === ",") {
          this.holdingValueAmenities += testing + ",";
        } else {
          this.holdingValueAmenities += "," + testing + ",";
        }
      } else {
        this.holdingValueAmenities += testing + ",";
      }
      this.searchResultFilter.amenities.find(x => x.value === testing).active = true
    } else {
      var valueRemoved = testing + ","
      if (this.holdingValueAmenities.includes(valueRemoved)) {
        this.holdingValueAmenities = this.holdingValueAmenities.replace(valueRemoved, '');
      } else if (this.holdingValueAmenities.includes(testing)) {
        this.holdingValueAmenities = this.holdingValueAmenities.replace(testing, '');
      }
      this.searchResultFilter.amenities.find(x => x.value === testing).active = false;
    }
    //this.setValueTesting(testing,isChecked);
  }

  // setValueTesting(t: string,checkedPattern: boolean){
  //   if(t === "business-center" && checkedPattern){
  //     this.isBusiness = true;
  //   }else if(t === "business-center" && checkedPattern===false){
  //     this.isBusiness = false;
  //   }
  //   if(t === "fitness-center" && checkedPattern){
  //     this.isFitnessCenter = true;
  //   }else if(t === "fitness-center" && checkedPattern===false){
  //     this.isFitnessCenter = false;
  //   }
  //   if(t === "pets" && checkedPattern){
  //     this.isPet = true;
  //   }else if(t === "pets" && checkedPattern===false){
  //     this.isPet = false;
  //   }
  //   if(t === "in-suite-laundry" && checkedPattern){
  //     this.isSuiteLaundry = true;
  //   }else if(t === "in-suite-laundry" && checkedPattern===false){
  //     this.isSuiteLaundry = false;
  //   }
  //   if(t === "pool" && checkedPattern){
  //     this.isPool = true;
  //   }else if(t === "pool" && checkedPattern===false){
  //     this.isPool = false;
  //   }
  //   if(t === "elevator" && checkedPattern){
  //     this.isElevator = true;
  //   }else if(t === "elevator" && checkedPattern===false){
  //     this.isElevator = false;
  //   }
  //   if(t === "doorman" && checkedPattern){
  //     this.isDoorman = true;
  //   }else if(t === "doorman" && checkedPattern===false){
  //     this.isDoorman = false;
  //   }
  //   if(t === "smoke-free" && checkedPattern){
  //     this.isSmokeFree = true;
  //   }else if(t === "smoke-free" && checkedPattern===false){
  //     this.isSmokeFree = false;
  //   }
  // }

  populateGoogleMap(arrayProperties: APISearchResult[], latLon, panToCenterMap) {
    let self = this;
    if (panToCenterMap == undefined || panToCenterMap == null) {
      panToCenterMap = true;
    }
    if (arrayProperties == undefined || arrayProperties == null) {
      arrayProperties = [];
    }
    for (let h = 0; h < this.Markers.length; h++) {
      this.Markers[h].setMap(null);
    }
    if (latLon.length == 2) {
      if (panToCenterMap) {
        this.map.panTo(new google.maps.LatLng(latLon[0], latLon[1]));
      }
      // const map = this.map;
      // const currentLatLng = new google.maps.LatLng(latLon[0], latLon[1]);
      // if (!this.mapClick && !this.mapDrag) {
      //   this.setCenterMarker(currentLatLng);
      // } else {
      //   this.mapClick = false;
      //   this.mapDrag = false;
      // }

      this.infoWindow = new google.maps.InfoWindow();
      google.maps.event.addListener(this.infoWindow, 'closeclick', function () {
        //close others
        for (var j = 0; j < self.Markers.length; j++) {
          var iconSrc = Utils.createMarker(self.iconWidth, self.iconHeight, Utils.formatShortCurrency(+self.Markers[j]._price) + "", self.imgMarkerPreload1, "bold " + self.iconFontSize + "px Poppins", [self.iconLeftOffset, self.iconTopOffset]);
          var myIcon = new google.maps.MarkerImage(iconSrc, null, null, null, new google.maps.Size(self.iconWidthScaledDown, self.iconHeightScaledDown));
          self.Markers[j].setIcon(myIcon);
          self.Markers[j].setZIndex(1);
        }
      });
      google.maps.event.addListener(this.infoWindow, 'domready', function () {

        // Reference to the DIV which receives the contents of the infowindow using jQuery
        var $iwOuter = $('.gm-style-iw');
        //var iwBackground = iwOuter.prev();
        $iwOuter.css("top", "1px").css("left", "1px");
        // Remove the background shadow DIV
        //iwBackground.children(':nth-child(2)').css({ 'display': 'none' });

        // Remove the white background DIV
        //iwBackground.children(':nth-child(4)').css({ 'display': 'none' });

      });
      for (var i = 0; i < arrayProperties.length; i++) {
        var latlng = new google.maps.LatLng(arrayProperties[i].address.latitude, arrayProperties[i].address.longitude);
        var myIconSrc = Utils.createMarker(this.iconWidth, this.iconHeight, Utils.formatShortCurrency(+arrayProperties[i].pricedFrom) + "", this.imgMarkerPreload1, "bold " + this.iconFontSize + "px Poppins", [this.iconLeftOffset, this.iconTopOffset]);
        var myIcon = new google.maps.MarkerImage(myIconSrc, null, null, null, new google.maps.Size(this.iconWidthScaledDown, this.iconHeightScaledDown));
        this.Markers[i] = new google.maps.Marker({
          map: this.map,
          position: latlng,
          title: arrayProperties[i].name,
          icon: myIcon
        });
        var imageThumbnail = "";
        if (arrayProperties[i].images !== null && arrayProperties[i].images.length > 0) {
          imageThumbnail = arrayProperties[i].images[0].url;
        }
        var addressLine = arrayProperties[i].address.address1 + " " + arrayProperties[i].address.address2 + "<br />" + arrayProperties[i].address.city + ", " + arrayProperties[i].address.state + " " + arrayProperties[i].address.zip;
        //+ "<img src=\"" + imageThumbnail + "\" alt=\"" + arrayProperties[i].name + "\">"
        var imgItem = "<div class=\"no-image\"></div>";
        if (imageThumbnail !== "") {
          imgItem = "<div class=\"image-col\"><div class=\"img\" style=\"background-image: url('" + imageThumbnail + "');\"><a class=\"img-cell-link\" href=\"" + this.properyURLwithParams(arrayProperties[i]) + "\"></a></div>" + "</div>";
        }
        this.Markers[i]._content = "<div class=\"infowindow-content\">" + imgItem + "<div class=\"content-col\">" + "<div class=\"units\">" + arrayProperties[i].availableUnits + " Units Available</div>" + "<a href=\"" + this.properyURLwithParams(arrayProperties[i]) + "\"><span class=\"name\">" + arrayProperties[i].name + "</span>" + "<span class=\"address\">" + "<span class=\"map-icon\"><svg><use xlink:href=\"#map-point-reuse\" class=\"map-point-reuse\" height=\"14\" width=\"10\"></use></svg></span>" + "<span class=\"address-value\">" + addressLine + "</span></span></a>" + "</div></div>";

        // this.Markers[i]._price = arrayProperties[i].calcLowestPrice;
        this.Markers[i]._price = arrayProperties[i].pricedFrom;
        this.Markers[i]._id = arrayProperties[i].id;
        this.Markers[i].addListener('click', function (e) {
          //close others
          for (var j = 0; j < self.Markers.length; j++) {
            var iconSrcOthers = Utils.createMarker(self.iconWidth, self.iconHeight, Utils.formatShortCurrency(+self.Markers[j]._price) + "", self.imgMarkerPreload1, "bold " + self.iconFontSize + "px Poppins", [self.iconLeftOffset, self.iconTopOffset]);
            var myIconOther = new google.maps.MarkerImage(iconSrcOthers, null, null, null, new google.maps.Size(self.iconWidthScaledDown, self.iconHeightScaledDown));
            self.Markers[j].setIcon(myIconOther);
            self.Markers[j].setZIndex(1);
          }
          var iconSrc = Utils.createMarker(self.iconWidth, self.iconHeight, Utils.formatShortCurrency(+this._price) + "", self.imgMarkerPreload2, "bold " + self.iconFontSize + "px Poppins", [self.iconLeftOffset, self.iconTopOffset]);
          var myIconActive = new google.maps.MarkerImage(iconSrc, null, null, null, new google.maps.Size(self.iconWidthScaledDown, self.iconHeightScaledDown));
          this.setIcon(myIconActive);
          this.setZIndex(4);

          self.infoWindow.setContent(this._content);
          self.infoWindow.open(this.map, this);

          //gtm marker click
          // window.GRGExtensions.GTMHelper.dataLayerPush({ 'mapAction': 'price' });
          // window.GRGExtensions.GTMHelper.dataLayerPushEvent('map-search');

          //bind click
          setTimeout(function () {
            var $openMarker = $(".infowindow-content");
            if (!$openMarker.hasClass("gtm-bound")) {
              $openMarker.find("a").click(function (e) {
                $openMarker.addClass("gtm-bound");
                // searchPageVue.GTMPropertyClick();
              });
            }
          }, 100);

        });

        window.onresize = function () {
          self.infoWindow.close();
        };

      }
    }
    google.maps.event.trigger(this.map, 'resize');
  }
  toggleMobileMap() {
    this.mapMode = !this.mapMode;
    this.getUrlParams();
  }

  async getCoordinates(self) {
    if (this.geocoder == null) {
      this.geocoder = new google.maps.Geocoder();
    }
    return this.geocoder.geocode({ address: self.searchResultFilter.city });
  }
  //#endregion

  //#region Properties Result
  filterProperties() {
    this.filteredData = [];
    const floorPlanFields = ["bedrooms", "bathrooms"];
    const hasLocalFilters = this.localFiltersApplied();
    if (this.results && this.results.length > 0) {
      if (hasLocalFilters) {
        for (let i = 0; i < this.results.length; i++) {
          let matchAmenCount = 0;
          let matchPrice = false;
          var totalFiltersCount = 0;
          var matchFiltersCount = 0;
          var totalFloorPlanFiltersCount = 0;
          var matchingFloorPlans = [];

          //reset calculated properties
          this.results[i] = this.resetCalculatedFloorPlanProperties(this.results[i]);

          //Amenities
          if (this.activeFilters.amenities !== undefined && this.activeFilters.amenities.length > 0) {
            let amenityName = "";
            let foundAmenity = false;
            for (let amnt = 0; amnt < this.activeFilters.amenities.length; amnt++) {
              const amenityVal = this.activeFilters.amenities[amnt];
              for (var j = 0; j < this.searchResultFilter.amenities.length; j++) {
                if (this.searchResultFilter.amenities[j].value === amenityVal) {
                  amenityName = this.searchResultFilter.amenities[j].name;
                }
              }
              if (amenityName !== "") {
                if (this.results[i].propertyAmenities !== null && this.results[i].propertyAmenities.length > 0) {
                  for (let pa = 0; pa < this.results[i].propertyAmenities.length && !foundAmenity; pa++) {
                    if (this.results[i].propertyAmenities[pa] === amenityName) {
                      foundAmenity = true;
                    }
                  }
                }
                if (this.results[i].unitAmenities !== null && this.results[i].unitAmenities.length > 0) {
                  for (let ua = 0; ua < this.results[i].unitAmenities.length && !foundAmenity; ua++) {
                    if (this.results[i].unitAmenities[ua] === amenityName) {
                      foundAmenity = true;
                    }
                  }
                }
              }
              if (foundAmenity) {
                matchAmenCount++;
              }
            }
          }
          else {
            matchAmenCount = -1;
          }
          // Filters, field mapping = mappingField
          if (this.activeFilters.propertyBuildingTypeId !== "" || this.activeFilters.bedrooms !== "" || this.activeFilters.bathrooms !== "") {
            //loop through floorplan level and filters
            //populate valid floorplans
            //loop floorplans
            if (this.results[i].floorplans !== undefined && this.results[i].floorplans.length > 0) {
              for (var fpi = 0; fpi < this.results[i].floorplans.length; fpi++) {
                var floorPlanFiltersCount = 0;
                var floorPlanFiltersPassedCount = 0;
                //filters
                for (var fiFP = 0; fiFP < this.searchResultFilter.filters.length; fiFP++) {
                  if (this.searchResultFilter.filters[fiFP].mappingField in this.activeFilters && this.activeFilters[this.searchResultFilter.filters[fiFP].mappingField] !== "") {
                    let filterLevelFP = "property";
                    let thisFilterPassedFP = false;
                    for (var fpf_index = 0; fpf_index < floorPlanFields.length; fpf_index++) {
                      if (this.searchResultFilter.filters[fiFP].slug === floorPlanFields[fpf_index]) {
                        filterLevelFP = "floorPlans";

                      }
                    }
                    if (filterLevelFP === "floorPlans") {
                      floorPlanFiltersCount++;
                      if (this.searchResultFilter.filters[fiFP].filterType === "match") {
                        //filter
                        if (this.results[i].floorplans[fpi][this.searchResultFilter.filters[fiFP].mappingField] === this.activeFilters[this.searchResultFilter.filters[fiFP].mappingField]) {
                          //matched, include
                          thisFilterPassedFP = true;
                        }
                      } else if (this.searchResultFilter.filters[fiFP].filterType === "numeric") {
                        //break apart & Filter
                        var minFilterFP = -1;
                        var maxFilterFP = -1;
                        var filterValueFP = this.activeFilters[this.searchResultFilter.filters[fiFP].mappingField];
                        var propertyValueFP = this.results[i].floorplans[fpi][this.searchResultFilter.filters[fiFP].mappingField];
                        var propertyValueArrayFP = [];
                        //handle single values and arrays
                        if (!$.isArray(propertyValueFP)) {
                          propertyValueArrayFP.push(propertyValueFP);
                        }
                        else {
                          for (var pvcFP = 0; pvcFP < propertyValueFP.length; pvcFP++) {
                            propertyValueArrayFP.push(propertyValueFP[pvcFP]);
                          }
                        }
                        if (filterValueFP.indexOf("-or-less") > -1) {
                          //between
                          maxFilterFP = filterValueFP.replace("-or-less", "") * 1;
                          for (var pvcorFP = 0; pvcorFP < propertyValueArrayFP.length; pvcorFP++) {
                            if (propertyValueArrayFP[pvcorFP] <= maxFilterFP) {
                              thisFilterPassedFP = true;
                            }
                          }
                        } else
                          if (filterValueFP.indexOf("-to-") > -1) {
                            //between
                            var filterMinMaxArrayFP = filterValueFP.replace("-to-", ",").split(",");
                            if (filterMinMaxArrayFP.length === 2) {
                              minFilterFP = filterMinMaxArrayFP[0] * 1;
                              maxFilterFP = filterMinMaxArrayFP[1] * 1;

                              for (var pvctoFP = 0; pvctoFP < propertyValueArrayFP.length; pvctoFP++) {
                                if (propertyValueArrayFP[pvctoFP] >= minFilterFP &&
                                  propertyValueArrayFP[pvctoFP] <= maxFilterFP) {
                                  thisFilterPassedFP = true;
                                }
                              }
                            }
                          } else
                            if (filterValueFP.indexOf("-or-more") > -1) {
                              //min
                              minFilterFP = filterValueFP.replace("-or-more", "") * 1;
                              for (var pvcormoreFP = 0; pvcormoreFP < propertyValueArrayFP.length; pvcormoreFP++) {
                                if (propertyValueArrayFP[pvcormoreFP] >= minFilterFP) {
                                  thisFilterPassedFP = true;
                                }
                              }
                            } else
                              if (filterValueFP.indexOf("+") > -1) {
                                //min
                                minFilterFP = filterValueFP.replace("+", "") * 1;
                                for (var pvcgreaterFP = 0; pvcgreaterFP < propertyValueArrayFP.length; pvcgreaterFP++) {
                                  if (propertyValueArrayFP[pvcgreaterFP] >= minFilterFP) {
                                    thisFilterPassedFP = true;
                                  }
                                }
                              }
                              else {
                                //single value match
                                for (var pvcsvFP = 0; pvcsvFP < propertyValueArrayFP.length; pvcsvFP++) {
                                  if (propertyValueArrayFP[pvcsvFP] === filterValueFP * 1) {
                                    //matched, include
                                    thisFilterPassedFP = true;
                                  }
                                }
                              }
                      }
                      if (thisFilterPassedFP) {
                        floorPlanFiltersPassedCount++;
                      }
                    }//end is floorplan filter
                  }
                } //end filters loop

                //if floor plan passed, add to array
                if (floorPlanFiltersCount === floorPlanFiltersPassedCount) {
                  matchingFloorPlans.push(this.results[i].floorplans[fpi]);

                }
              }//end floorplans loop
            }
            //filters loop 
            for (var fi = 0; fi < this.searchResultFilter.filters.length; fi++) {
              if (this.searchResultFilter.filters[fi].mappingField in this.activeFilters && this.activeFilters[this.searchResultFilter.filters[fi].mappingField] !== "") {
                var filterLevel = "property";
                // for (var fpfp_index = 0; fpfp_index < floorPlanFields.length; fpfp_index++) {
                //   if (this.searchResultFilter.filters[fi].slug === floorPlanFields[fpfp_index]) {
                //     filterLevel = "floorPlans";
                //     continue;
                //   }
                // }
                if (filterLevel === "floorPlans") {
                  totalFloorPlanFiltersCount++;
                } else if (filterLevel === "property") {
                  //located active filter
                  totalFiltersCount++;
                  var thisFilterPassed = false;
                  //Property-level filters, ex. building types
                  if (this.searchResultFilter.filters[fi].filterType === "match") {
                    //filter
                    if (this.searchResultFilter.filters[fi].mappingField in this.activeFilters && this.results[i][this.searchResultFilter.filters[fi].mappingField] == +this.activeFilters[this.searchResultFilter.filters[fi].mappingField]) {
                      //matched, include
                      thisFilterPassed = true;
                    }
                  } else if (this.searchResultFilter.filters[fi].filterType === "numeric") {
                    //break apart & Filter
                    var minFilter = -1;
                    var maxFilter = -1;
                    var filterValue = this.activeFilters[this.searchResultFilter.filters[fi].mappingField];
                    var propertyValue = this.results[i][this.searchResultFilter.filters[fi].mappingField];
                    var propertyValueArray = [];

                    //handle single values and arrays
                    if (!$.isArray(propertyValue)) {
                      propertyValueArray.push(propertyValue);
                    }
                    else {
                      for (var pvc = 0; pvc < propertyValue.length; pvc++) {
                        propertyValueArray.push(propertyValue[pvc]);
                      }
                    }
                    if (filterValue.indexOf("-or-less") > -1) {
                      //between
                      maxFilter = filterValue.replace("-or-less", "") * 1;
                      for (var pvcor = 0; pvcor < propertyValueArray.length; pvcor++) {
                        if (propertyValueArray[pvcor] <= maxFilter) {
                          thisFilterPassed = true;
                        }
                      }
                    } else
                      if (filterValue.indexOf("-to-") > -1) {
                        //between
                        var filterMinMaxArray = filterValue.replace("-to-", ",").split(",");
                        if (filterMinMaxArray.length === 2) {
                          minFilter = filterMinMaxArray[0] * 1;
                          maxFilter = filterMinMaxArray[1] * 1;

                          for (var pvcto = 0; pvcto < propertyValueArray.length; pvcto++) {
                            if (propertyValueArray[pvcto] >= minFilter &&
                              propertyValueArray[pvcto] <= maxFilter) {
                              thisFilterPassed = true;
                            }
                          }
                        }
                      } else
                        if (filterValue.indexOf("-or-more") > -1) {
                          //min
                          minFilter = filterValue.replace("-or-more", "") * 1;
                          for (var pvcormore = 0; pvcormore < propertyValueArray.length; pvcormore++) {
                            if (propertyValueArray[pvcormore] >= minFilter) {
                              thisFilterPassed = true;
                            }
                          }
                        } else
                          if (filterValue.indexOf("+") > -1) {
                            //min
                            minFilter = filterValue.replace("+", "") * 1;
                            for (var pvcgreater = 0; pvcgreater < propertyValueArray.length; pvcgreater++) {
                              if (propertyValueArray[pvcgreater] >= minFilter) {
                                thisFilterPassed = true;
                              }
                            }
                          }
                          else {
                            //single value match
                            for (var pvcsv = 0; pvcsv < propertyValueArray.length; pvcsv++) {
                              if (propertyValueArray[pvcsv] === filterValue * 1) {
                                //matched, include
                                thisFilterPassed = true;
                              }
                            }
                          }
                  }
                  if (thisFilterPassed) {
                    matchFiltersCount++;
                  }
                }// end property-level filters
              }
            } //end property level loop
          } else {
            //no amens given
            //pass amen filter
            matchFiltersCount = -1;
          }

          //Price
          if (this.activeFilters.minPrice !== undefined || this.activeFilters.maxPrice !== undefined) {
            let minPrice = -1;
            let maxPrice = -1;
            let propertyPrice = -1;

            //get lowest price from floorplans or from all
            if (matchingFloorPlans.length > 0) {
              propertyPrice = this.getLowestPrice(matchingFloorPlans);
            }
            else if (this.results[i].floorplans !== undefined && this.results[i].floorplans.length > 0) {
              propertyPrice = this.getLowestPrice(this.results[i].floorplans);
            }

            if (this.activeFilters.minPrice != undefined) {
              minPrice = this.activeFilters.minPrice;
            }
            if (this.activeFilters.maxPrice != undefined) {
              maxPrice = this.activeFilters.maxPrice;
            }
            if (minPrice === -1 && maxPrice !== -1) {
              if (propertyPrice <= maxPrice) {
                matchPrice = true;
              }
            }
            else {
              if (minPrice !== -1 && maxPrice === -1) {
                //min price only
                if (propertyPrice >= minPrice) {
                  matchPrice = true;
                }
              } else if (propertyPrice) {
                //between
                if (propertyPrice >= minPrice && propertyPrice <= maxPrice) {
                  matchPrice = true;
                }
              }
            }
          } else {
            matchPrice = true;
          }
          if (matchPrice && (matchAmenCount === -1 || matchAmenCount === this.activeFilters.amenities.length) && (matchFiltersCount === -1 || matchFiltersCount === totalFiltersCount) && ((totalFloorPlanFiltersCount > 0 && matchingFloorPlans.length > 0) || totalFloorPlanFiltersCount === 0)) {
            this.results[i] = this.resetCalculatedFloorPlanProperties(this.results[i]);
            this.filteredData.push(this.results[i]);
          }
        }
      } else {
        for (let i = 0; i < this.results.length; i++) {
          this.results[i] = this.resetCalculatedFloorPlanProperties(this.results[i]);
          this.filteredData.push(this.results[i]);
        }
      }
    }

    //sort
    let sortbyValue = this.searchResultFilter.sortBy.options[0].value;
    let sortbyValueField = "price-low-high";
    let ascOrDesc = "asc";
    if (hasLocalFilters && this.activeFilters.sortBy) {
      sortbyValue = this.activeFilters.sortBy;
      for (var si = 0; si < this.searchResultFilter.sortBy.options.length; si++) {
        if (this.searchResultFilter.sortBy.options[si].value === sortbyValue) {
          sortbyValueField = this.searchResultFilter.sortBy.options[si].valueField;
          ascOrDesc = this.searchResultFilter.sortBy.options[si].ascOrDesc;
        }
      }
    }

    if (ascOrDesc === "asc") {
      this.filteredData.sort(function (a, b) {
        if (a[sortbyValueField] < b[sortbyValueField])
          return -1;
        if (a[sortbyValueField] > b[sortbyValueField])
          return 1;
        return 0;
      });
    } else {
      this.filteredData.sort(function (a, b) {
        if (a[sortbyValueField] < b[sortbyValueField])
          return 1;
        if (a[sortbyValueField] > b[sortbyValueField])
          return -1;
        return 0;
      });
    }


    // this.popuplateGoogleMap(filteredProperties, this.searchResultFilter.latLon, false);
  }
  localFiltersApplied(): boolean {
    let hasLocalFilter = false;
    this.activeFilters.propertyBuildingTypeId = "";
    this.activeFilters.bedrooms = "";
    this.activeFilters.bathrooms = "";
    this.appliedLocalFiltersCount = 0;
    for (let i = 0; i < this.searchResultFilter.filters.length; i++) {
      if (this.searchResultFilter.filters[i].value !== "") {
        if (this.searchResultFilter.filters[i].mappingField == "propertyBuildingTypeId") {
          this.activeFilters.propertyBuildingTypeId = this.searchResultFilter.filters[i].value;
          hasLocalFilter = true;
          this.appliedLocalFiltersCount += 1;
        }
        if (this.searchResultFilter.filters[i].mappingField == "bedrooms") {
          this.activeFilters.bedrooms = this.searchResultFilter.filters[i].value;
          hasLocalFilter = true;
          this.appliedLocalFiltersCount += 1;
        }
        if (this.searchResultFilter.filters[i].mappingField == "bathrooms") {
          this.activeFilters.bathrooms = this.searchResultFilter.filters[i].value;
          hasLocalFilter = true;
          this.appliedLocalFiltersCount += 1;
        }
      }
    }
    this.activeFilters.amenities = [];
    for (let i = 0; i < this.searchResultFilter.amenities.length; i++) {
      if (this.searchResultFilter.amenities[i].active) {
        if (this.activeFilters.amenities == undefined) {
          this.activeFilters.amenities = [];
        }
        this.activeFilters.amenities.push(this.searchResultFilter.amenities[i].value);
        hasLocalFilter = true;
        this.appliedLocalFiltersCount += 1;
      }
    }
    if (this.fromPrice !== "NO MIN") {
      this.activeFilters.minPrice = this.searchResultFilter.minPrice;
      hasLocalFilter = true;
      this.appliedLocalFiltersCount += 1;
    }
    if (this.toPrice !== "NO MAX") {
      this.activeFilters.maxPrice = this.searchResultFilter.maxPrice;
      hasLocalFilter = true;
      this.appliedLocalFiltersCount += 1;
    }
    if (this.searchResultFilter.sortBy.value !== "price-low-high") {
      this.activeFilters.sortBy = this.searchResultFilter.sortBy.value;
      hasLocalFilter = true;
      this.appliedLocalFiltersCount += 1;
    }

    return hasLocalFilter
  }
  hasFilters(): boolean {
    let result = false;
    if (this.searchResultFilter !== null && this.searchResultFilter.filters !== null && this.searchResultFilter.filters.length > 0) {
      for (let i = 0; i < this.searchResultFilter.filters.length; i++) {
        if (this.searchResultFilter.filters[i].value !== "") {
          result = true;
          break;
        }
      }
    }
    return result;
  }
  getFilterNameFromValue(filterType: SearchFilter): string {
    let result = "";
    for (let i = 0; i < this.searchResultFilter.filters.length; i++) {
      if (this.searchResultFilter.filters[i].slug === filterType.slug) {
        for (let j = 0; j < this.searchResultFilter.filters[i].options.length; j++) {
          if (this.searchResultFilter.filters[i].options[j].value == filterType.value) {
            result = this.searchResultFilter.filters[i].options[j].name;
            break;
          }
        }
      }

    }
    return result;
  }
  removeFilter(filterType: SearchFilter) {
    for (let i = 0; i < this.searchResultFilter.filters.length; i++) {
      if (this.searchResultFilter.filters[i].slug === filterType.slug) {
        for (let j = 0; j < this.searchResultFilter.filters[i].options.length; j++) {
          if (this.searchResultFilter.filters[i].options[j].value === filterType.value) {
            this.searchResultFilter.filters[i].value = "";
            this.setUrlFromFilters();
            break;
          }
        }
      }
    }
    this.localFilterChanged();
  }
  clearFilters() {
    this.mobileFiltersOpen = false;
    for (let i = 0; i < this.searchResultFilter.filters.length; i++) {
      if (this.searchResultFilter.filters[i].value !== undefined) {
        this.searchResultFilter.filters[i].value = "";
      }
    }
    for (let i = 0; i < this.searchResultFilter.amenities.length; i++) {
      this.searchResultFilter.amenities[i].active = false;
    }
    this.searchResultFilter.minPrice = this.minPriceValue;
    this.searchResultFilter.maxPrice = this.maxPriceValue;

    // this.slider.destroy();
    // this.mobileSlider.destroy();
    //this.initSlider();

    this.localFilterChanged();
  }
  getLowestPrice(floorPlansArray: FloorPlan[]): number {
    var lowestPrice = -1;
    if (floorPlansArray !== null && floorPlansArray.length > 0) {
      lowestPrice = floorPlansArray[0].price;
      for (let pindex = 0; pindex < floorPlansArray.length; pindex++) {
        if (floorPlansArray[pindex].price < lowestPrice) {
          lowestPrice = floorPlansArray[pindex].price;
        }
      }
    }
    return lowestPrice;
  }
  getUnitCount(floorPlansArray: FloorPlan[]): number {
    let totalCount = 0;
    if (floorPlansArray !== null && floorPlansArray.length > 0) {
      for (let pindex = 0; pindex < floorPlansArray.length; pindex++) {
        totalCount += floorPlansArray[pindex].unitsAvailable;
      }
    }
    return totalCount;
  }
  resetCalculatedFloorPlanProperties(propertyItem: APISearchResult) {
    propertyItem.calcLowestPrice = +propertyItem.pricedFrom;
    propertyItem.calcAvailableUnits = propertyItem.availableUnits;
    return propertyItem;
  }
  setCalculatedFloorPlanProperties(propertyItem: APISearchResult, matchingFloorPlansArray: FloorPlan[]) {
    if (matchingFloorPlansArray === null || matchingFloorPlansArray.length < 1) {
      matchingFloorPlansArray = propertyItem.floorplans;
    }
    propertyItem.calcLowestPrice = this.getLowestPrice(matchingFloorPlansArray);
    propertyItem.calcAvailableUnits = this.getUnitCount(matchingFloorPlansArray);
    return propertyItem;
  }
  propertiesLoaded() {
    var $propertiesListDom = $("#ProperyItems");
    //$propertiesListDom.find(".images").
    //$propertiesListDom.imagesLoaded(function () {
    //BindSizing();
    $propertiesListDom.each(function () {
      var $thisProperty = $(this);
      $thisProperty.find(".images-section").each(function () {
        var $thisSection = $(this);
        var $thisSlider = $thisSection.children(".image-slider");
        if (($thisSlider).hasClass("slick-initialized")) {
          $thisSlider.slick("unslick");
        }
        $thisSection.children(".image-slider").remove();

        //generate new slider dom, bind
        var $imageSource = $thisSection.find(".images.image-slider-source");
        if ($imageSource.length > 0) {
          $thisSection.append("<div class=\"image-slider\">" + $imageSource.clone().html() + "</div>");
          //$thisSection.append("<div class=\"image-slider\">" + "<div>my bubbles</div><div>my bubbles2</div>" + "</div>");
          $thisSlider = $thisSection.children(".image-slider");
          $thisSlider.slick({
            dots: false,
            autoplay: false,
            arrows: true,
            autoplaySpeed: 2000,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1
          });
        }
      });
    });

    $propertiesListDom.find(".name").matchHeight();
    $propertiesListDom.find(".address").matchHeight();
    $propertiesListDom.find(".images-section").css("height", "");

    // wait till imaegs loaded
    $propertiesListDom.imagesLoaded(function () {
      $propertiesListDom.find(".images-section").matchHeight();
    });
    $propertiesListDom.find(".scaled-image").Lazy({
      bind: "event",
      onFinishedAll: function () {
        $propertiesListDom.find(".images-section").matchHeight();
      }
    });

    //update after content seo data
    // this.Vue.SEOData = searchPageVue.SEOData;
  }
  getRecentlyViewed() {
    if (this.cookieService.get("recentlyViewedProperties") != '') {
      var recentlyViewedProperties = JSON.parse(this.cookieService.get("recentlyViewedProperties"))

      recentlyViewedProperties.forEach(element => {
        this.recentProperties.push(element);
      });
    }
  }
  propertyName(name: string) {
    return name.replace(/-/g, ' ').toUpperCase();
  }
  public paginate(current: number, perPage: number): APISearchResult[] {
    return [...this.filteredData.slice((current - 1) * perPage).slice(0, perPage)]
  }
  paginationButtons() {
    //generate buttons
    var shortenThreshold = 9;
    var shortenClosest = 2;
    var currentPage = this.searchResultFilter.page * 1;
    var renderedEllipsisBefore = false;
    var renderedEllipsisAfter = false;
    if (this.filteredData) {
      var totalButtonsCount = Math.ceil(this.filteredData.length / this.searchResultFilter.perPage);
      for (var i = 0; i < totalButtonsCount; i++) {
        var buttonType = "normal";
        let text = (i + 1).toString();
        if (totalButtonsCount >= shortenThreshold) {
          //determine whether to show this one
          //first or last? always show.
          if (i !== 0 && i !== totalButtonsCount - 1) {
            //within threshhold? render
            if (i + 1 > (currentPage - (shortenClosest + 1)) && i + 1 < (currentPage + (shortenClosest + 1))) {
              //show
            }
            else {
              //hide
              buttonType = "hidden";
              //if first, render ellipsis
              if (i + 1 < currentPage && !renderedEllipsisBefore) {
                //make sure
                buttonType = "ellipsis";
                text = "...";
                renderedEllipsisBefore = true;
              } else
                if (i + 1 > currentPage && !renderedEllipsisAfter) {
                  //make sure
                  buttonType = "ellipsis";
                  text = "...";
                  renderedEllipsisAfter = true;
                }
            }
          }
        }
        if (totalButtonsCount > 1) {
          this.paginatedButtons.push({
            pageNumber: i + 1,
            text: text,
            buttonType: buttonType,
            active: (i + 1) + "" === this.searchResultFilter.page + "" ? true : false
          });
        }
      }
    }
    return this.paginatedButtons;
  }
  paginateTo(number) {
    this.searchResultFilter.page = number;
    this.filteredToDisplay = this.paginate(this.searchResultFilter.page, 12);
    let self = this;
    setTimeout(function () {
      self.propertiesLoaded();
    }, 10);
  }

  //#endregion

  //#region Mobile Events
  toggleMobileDate() {
    this.mobileDatesOpen = !this.mobileDatesOpen;
  }
  toggleMobileFilters() {
    this.mobileFiltersOpen = !this.mobileFiltersOpen;
  }

  getLocation() {
    return this.routeParams.location.replace(/-/g, ', ');
  }
  //#endregion
}
