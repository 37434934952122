import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from 'app/services/user.service';
import { NavSidebarService } from 'app/services/nav-sidebar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/services/api.service';
import { NGXLogger } from 'ngx-logger';
import { BaseComponent } from 'app/common/base/base.component';
import { LoginModel, LoginResponseModel } from 'app/models/UserJwtModel';
import Utils from 'app/common/Utils';
import { ApiResponseMessage } from 'app/models/ApiResponseModel';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ImportDataService } from 'app/services/import-data.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupService } from 'app/services/lookup.service';
import { PermissionsService } from 'app/services/permissions.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss']
})

export class SignInComponent extends BaseComponent implements OnInit {
  signInForm: FormGroup;
  showclicked = false;
  loginFormFields: LoginModel;
  errorMessages: string[];
  redirect: string;
  @Input() isModal = false;
  @Input() unitId?: number;
  @Input() startDate: string;
  @Input() endDate: string;
  isLoading = false;
  pathLastSegment: string

  constructor(private fb: FormBuilder, private apiService: ApiService, private logger: NGXLogger, private route: ActivatedRoute, private permissionsService: PermissionsService,
    private userService: UserService, private importDataService: ImportDataService, private router: Router, private activeModal: NgbActiveModal, private lookupService: LookupService) {
    super(logger)
  }

  ngOnInit() {
    console.log(this.unitId);
    this.pathLastSegment = this.router.url.split('?')[0].split('/').pop();
    this.redirect = this.route.snapshot.queryParamMap.get('redirtoken');
    if (this.redirect) {
      this.importDataService.logout(false);
      this.userService.isAuthenticated = false;
    }
    this.initForm();
  }

  initForm(): void {
    this.signInForm = this.fb.group({
      email: [{ value: '', disabled: false }, [Validators.required]],
      password: [{ value: '', disabled: false }, [Validators.required]]
    })
    this.initializeLoginModel();
  }
  initializeLoginModel(): void {
    var urlparams = this.route.snapshot.paramMap;
    this.loginFormFields = {
      email: '',
      password: '',
    }
  }
  onSubmitClick(): void {
    this.errorMessages = [];

    if (this.isFormValid(this.signInForm)) {
      this.isLoading = true;
      this.assignFormToModel();
      this.apiService.login(this.loginFormFields).subscribe((res: ApiResponseMessage<LoginResponseModel>) => {
        if (res.success) {
          this.importDataService.init(res.data.token, res.data.refreshTokenGuid, null, null, null, 0);
          this.getUser(res.data.token);
          this.lookupService.load().subscribe(() => {
            this.permissionsService.refreshPermissions(this.userService.clientContact, this.lookupService);
          })
        } else {
          this.errorMessages = res.messages;
          this.isLoading = false;
        }
      },
        error => {
          this.isLoading = false;
        });
    }
    else {
      this.logger.debug("Form is not valid")
    }
  }
  hideShowPassword() {
    this.showclicked = !this.showclicked;
  }
  isFormValid(form: FormGroup): boolean {
    Utils.touchFormFields(form);
    return form.valid;
  }
  isInvalid(controlName: string): boolean {
    const control = this.signInForm.get(controlName);
    // if we can't find the control for whatever reason...return false
    if (!control) {
      this.logger.warn('Control not found...FYI', controlName);
      return false;
    }
    const isInvalid = control.invalid && (control.dirty || control.touched);
    return isInvalid;
  }
  assignFormToModel(): void {
    this.loginFormFields.email = this.signInForm.get('email').value
    this.loginFormFields.password = this.signInForm.get('password').value
  }
  createAccountModal() {
    if (this.isModal) {
      this.activeModal.close('create');
    }
  }
  forgotModal() {
    if (this.isModal) {
      this.activeModal.close('forgot');
    }
  }

  getUser(token: string) {
   console.log(this.route.snapshot.url);
    const url = this.router.url;
    console.log(url);
    const jwt = new JwtHelperService().decodeToken(token);
    this.apiService.getUserAccount(jwt.sub).subscribe((response) => {
      if (response) {
        this.userService.setUserStorage(response);
      }
      if (this.isModal) {
        this.activeModal.close();
        this.router.navigate(['/booking'], { queryParams: { property: this.pathLastSegment, startdate: this.startDate, enddate: this.endDate, unit: this.unitId } });
      } else {
        if (this.redirect) {
          if (this.redirect.substring(this.redirect.length - 1, this.redirect.length) == '/') {
            this.redirect = this.redirect.substring(0, this.redirect.length - 1)
          }
          var url = new URL(this.redirect)
          setTimeout(() => {
            this.router.navigateByUrl(url.pathname);
          }, 500);
        }
        else {
          this.router.navigate(['/']);
        }
      }
      this.isLoading = false;
    });
  }
}