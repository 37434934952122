// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  apiUrl: 'https://grg-uscentral-api-staging.azurewebsites.net/api',
  logging: {
    disableConsoleLogs: false,
    logUrl: '/utility/log/',
    level: NgxLoggerLevel.TRACE, // what level of logging should we use for console logs
    serverLogLevel: NgxLoggerLevel.ERROR // what level of logging should we use for API logs
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// TODO: COMMENT THIS OUT BEFORE LAUNCH FOR PERFORMANCE
import 'zone.js/dist/zone-error';  // Included with Angular CLI


