import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  public _checked: boolean = false;
  public label: string = "";
  public _checkedText: string = "";
  public _uncheckedText: string = "";
  public _disabled: boolean = false;

  @Input()
  set checked(checked: boolean) {
    this._checked = checked;
    if (checked) {
      this.label = this._checkedText;

    }
    else {
      this.label = this._uncheckedText;
    }
  }

  @Input()
  set checkedText(text: string) {
    this._checkedText = text;
    if (this._checked) {
      this.label = this._checkedText;

    }
    else {
      this.label = this._uncheckedText;
    }

  }

  @Input()
  set uncheckedText(text: string) {
    this._uncheckedText = text;
    if (this._checked) {
      this.label = this._checkedText;

    }
    else {
      this.label = this._uncheckedText;
    }

  }

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;


  }

  @Output() onChange = new EventEmitter<boolean>();



  click() {
    this._checked = !this._checked;
    if (this._checked) {
      this.label = this._checkedText;

    }
    else {
      this.label = this._uncheckedText;
    }
    this.onChange.emit(this._checked);

  }

  constructor() { }

  ngOnInit() {
  }

}
