import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-property-parking-fee-dialog',
  templateUrl: './property-parking-fee-dialog.component.html',
  styleUrls: ['./property-parking-fee-dialog.component.scss']
})
export class PropertyParkingFeeDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
