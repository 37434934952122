import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import Utils from '../Utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'app/services/api.service';
import { ClientCompanyTradeReference } from 'app/models/ClientCompanyModel';
import { NGXLogger } from 'ngx-logger';
import { GRGSnackBarService } from '../grg-snack-bar/grg-snak-bar.service';

@Component({
  selector: 'app-add-trade-reference',
  templateUrl: './add-trade-reference.component.html',
  styleUrls: ['./add-trade-reference.component.scss']
})
export class AddTradeReferenceComponent implements OnInit, OnChanges {

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private logger: NGXLogger,
    private notificationService: GRGSnackBarService) { }

  @Input()
  params: string; // pass in client company guid to the modal so it can add the trade reference

  addTradeReference: FormGroup;
  isSubmitDisabled = false;

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges() {

  }

  initForm(): void {
    this.addTradeReference = this.fb.group({
      name: [{ value: '', disabled: false }, [Validators.required]],
      contactName: [{ value: '', disabled: false }, [Validators.required]],
      phone: [{ value: '', disabled: false }, [Validators.required]]
    });
  }

  isInvalid(controlName: string): boolean {
    const control = this.addTradeReference.get(controlName);
    const isInvalid = control.invalid && (control.dirty || control.touched);

    return isInvalid;
  }

  onPhoneInputChange(controlName: string): void {
    // *** FORMATTING REMOVED FOR NOW BUT MAY RETURN AT A LATER DATE *** //
    // if (this.addTradeReference.get(controlName).value == null || this.addTradeReference.get(controlName).value.length < 5) {
    //   return;
    // }

    // Format phone number as the user is typing.
    // this.addTradeReference.get(controlName).patchValue(new AsYouType('US').input(this.addTradeReference.get(controlName).value));
  }

  saveInfo(): void {
    Utils.touchFormFields(this.addTradeReference);
    if (this.addTradeReference.valid && this.params) {
      this.isSubmitDisabled = true;
      const tradeReference: ClientCompanyTradeReference = {
        tradeReferenceName: this.addTradeReference.get('name').value,
        tradeReferenceContactName: this.addTradeReference.get('contactName').value,
        tradeReferenceContactPhone: this.addTradeReference.get('phone').value
      };

      this.apiService.addCompanyTradeReference(this.params, tradeReference).subscribe(result => {
        this.logger.log(`Trade reference added for: ${tradeReference.tradeReferenceName}`);
        this.activeModal.close(result);
        this.isSubmitDisabled = false;
        this.notificationService.show(`Trade reference: ${tradeReference.tradeReferenceName} added!`, 'success');
      },
        error => {
          this.logger.error(error);
          this.notificationService.show('The trade reference was unable to be added', 'error');
          this.isSubmitDisabled = false;
        });
    }
  }
}
