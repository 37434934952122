import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { ApiResponseMessage } from 'app/models/ApiResponseModel';
import { LoginSetPasswordToken, LoginModel, LoginResponseModel } from 'app/models/UserJwtModel';
import { ActivatedRoute, Router, } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ApiService } from 'app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ImportDataService } from 'app/services/import-data.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ResetPasswordModel } from 'app/models/EmployeesModel';
import Utils from 'app/common/Utils';
import { UserService } from 'app/services/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';


@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
  //encapsulation: ViewEncapsulation.None
})


export class SetPasswordComponent extends BaseComponent implements OnInit {

  // token information
  userToken: string;
  resetMode = false;
  redirObj: any;
  userTokenValues: LoginSetPasswordToken;
  h1Title = "";
  introText = "";
  defaultH1Title = "Set your password";
  defaultIntroText = "Please use the form below to set your password and sign in to your account.";

  // sign in form section
  SetPasswordFormGroup: FormGroup;
  hints = {
    hintstep1: false,
    hintstep2: false,
    hintstep3: false,
    hintstep4: false,
    hintstep5: false,
  };
  doPswdsMatch: boolean;
  IsPasswordInvalid: boolean;
  displayHints = "none";
  SetPassword: ResetPasswordModel;
  isLoading = false;


  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private apiService: ApiService,
    private importedDataService: ImportDataService,
    private router: Router,
    private userService: UserService,) {
    super(logger);
    this.InitForms();
  }

  async ngOnInit() {
    this.importedDataService.logout(false);
    // get values from parameters 
    this.userToken = this.route.snapshot.queryParamMap.get('token');
    this.redirObj = {
      redirTarget: this.route.snapshot.queryParamMap.get('redir'),
      redirToken: this.route.snapshot.queryParamMap.get('redirtoken')
    };

    // check if redirToken exists and if so, logOut current user
    if (this.redirObj.redirToken != undefined && this.redirObj.redirToken != '') {
      console.log(this.route.snapshot.queryParamMap.get('redirtoken'))
      this.importedDataService.logout(false);
      this.userService.isAuthenticated = false;
    }

    // get the user information from the token provided
    if (this.userToken != undefined && this.userToken != '') {
      this.SetPassword = {
        password: '',
        confirmPassword: '',
        token: this.userToken
      }
      this.resetMode = true;
      this.userTokenValues = JSON.parse(await this.apiService.getSetPasswordTokenByGUID(this.userToken).toPromise())

      if (this.userTokenValues != null && this.userTokenValues != undefined) {

        // same title for all
        this.h1Title = "We're excited for you to stay with us!"

        if (this.userTokenValues.IsTenantInvite) {

          // company booking
          if (this.userTokenValues.CompanyName != '' && this.userTokenValues.CompanyName != undefined) {

            this.introText = "You were invited to join a GoRoverGo booking by " + this.userTokenValues.InviterName + " with " + this.userTokenValues.CompanyName + ". Set your password below to complete your account registration and begin inputting your information for booking."
          }
          // individual booking
          else {
            this.introText = "You were invited to join a GoRoverGo booking by " + this.userTokenValues.InviterName + ". Set your password below to complete your account registration and begin inputting your information for booking."
          }
        }
        else {
          this.introText = "You were invited to join GoRoverGo by " + this.userTokenValues.InviterName + " at " + this.userTokenValues.CompanyName + ". Set your password below to start searching for corporate apartments at professionally managed communities across North America."

        }
      }

      this.h1Title = (this.h1Title != '') ? this.h1Title : this.defaultH1Title;
      this.introText = (this.introText != '') ? this.introText : this.defaultIntroText;
    }
  }

  SetPasswordSubmit() {

    let passwordSetSuccesful = false;
    this.SetPassword.password = this.SetPasswordFormGroup.get('createPassword').value;
    this.SetPassword.confirmPassword = this.SetPasswordFormGroup.get('createPassword2').value;

    if (this.isFormValid(this.SetPasswordFormGroup)) {
      // Set password from user form
      this.apiService.resetPasswordConfirmation(this.SetPassword).subscribe(setPswrdres => {
        // if succesful set emailAddress to response
        let emailAddress = setPswrdres
        passwordSetSuccesful = true;
        // check if redirect token is not null and login the user 
        if (this.redirObj.redirToken != '' && this.redirObj.redirToken != undefined) {
          var LoginFields = <LoginModel>{
            email: emailAddress,
            password: this.SetPassword.password
          };

          this.apiService.login(LoginFields).subscribe((res: ApiResponseMessage<LoginResponseModel>) => {

            if (res.success) {
              // if user got logged in succesfully, save response to local storage, and get user and redirect
              this.importedDataService.init(res.data.token, res.data.refreshTokenGuid, null, null, null, 0);
              this.getUser(res.data.token);
            }
          },
            (error: HttpErrorResponse) => {
              console.log(error);
            });
        }
      },
        (error: HttpErrorResponse) => {
          console.log(error);
        })
    }
  }

  isFormValid(form: FormGroup): boolean {
    Utils.touchFormFields(form);
    return form.valid;
  }

  isInvalid(controlName: string): boolean {
    const control = this.SetPasswordFormGroup.get(controlName);

    // if we can't find the control for whatever reason...return false
    if (!control) {
      this.logger.warn('Control not found...FYI', controlName);
      return false;
    }

    const isInvalid = control.invalid && (control.dirty || control.touched);

    return isInvalid;
  }

  isPasswordInvalid(controlName: string): boolean {
    var hint = this.hints

    if (controlName == "createPassword2") {
      if (this.SetPasswordFormGroup.get(controlName).value != this.SetPasswordFormGroup.get("createPassword").value) {
        this.doPswdsMatch = false
        return true
      }
      else {
        this.doPswdsMatch = true
        return false
      }
    }
    else if (!hint.hintstep1 || !hint.hintstep2 || !hint.hintstep3 || !hint.hintstep4 || !hint.hintstep5) {
      return true
    }


    return false
  }


  InitForms() {
    this.SetPasswordFormGroup = this.fb.group({

      createPassword: [{ value: '', disabled: false }, [Validators.required]],
      createPassword2: [{ value: '', disabled: false }, [Validators.required]]
    });
  }

  PasswordEventHandler(value: string): void {

    if (value.length >= 8) {
      this.hints.hintstep1 = true
    }
    else {
      this.hints.hintstep1 = false
    }
    if ((/[A-Z]/.test(value))) {
      this.hints.hintstep2 = true
    }
    else {
      this.hints.hintstep2 = false
    }
    if ((/[a-z]/.test(value))) {
      this.hints.hintstep3 = true
    }
    else {
      this.hints.hintstep3 = false
    }
    if ((/\d/.test(value))) {
      this.hints.hintstep4 = true
    }
    else {
      this.hints.hintstep4 = false
    }
    if ((/[!@#$%^&*+~-]/.test(value))) {
      this.hints.hintstep5 = true
    }
    else {
      this.hints.hintstep5 = false
    }

    this.IsPasswordInvalid = this.isPasswordInvalid(value)

  }
  onFocusPswdEvent(event: any) {
    this.displayHints = "block"
  }
  onFocusOutPswdEvent(event: any) {
    this.displayHints = "none"
  }


  getUser(token: string) {
    const jwt = new JwtHelperService().decodeToken(token);
    this.apiService.getUserAccount(jwt.sub).subscribe((response) => {
      if (response) {
        this.userService.setUserStorage(response);
        //this will allow for when you add an aditional tenant and they set the password and redirect to the identity check that they can continue
        this.userService.clientContactBehaviorSub.next(response);
      }

      if (this.redirObj.redirToken != '' && this.redirObj.redirToken != undefined) {
        if (this.redirObj.redirToken.substring(this.redirObj.redirToken.length - 1, this.redirObj.redirToken.length) == '/') {
          this.redirObj.redirToken = this.redirObj.redirToken.substring(0, this.redirObj.redirToken.length - 1)
        }
        var url = new URL(this.redirObj.redirToken)
        setTimeout(() => {
          this.router.navigateByUrl(url.pathname);
        }, 1500);

      }

    });
  }
}